import {isValEmpty} from '@pluto-tv/assemble';
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {RootState} from 'app/store';
import {
  ICampaign,
  IContentImage,
  IContentImageDelete,
  IContentImageQuery,
  IContentImageUpload,
  IListCampaignQuery,
} from 'models/campaigns';
import {IListPayload, IFormPayload} from 'models/generic';

const buildSearchQueryString = (params: IListCampaignQuery): string => {
  const q = Object.keys(params).map(k => {
    if (Array.isArray(params[k])) {
      return params[k]
        .filter(v => v)
        .map(v => `${k}=${encodeURIComponent(v)}`)
        .join('&');
    }
    if (!isValEmpty(params[k])) {
      return `${k}=${encodeURIComponent(params[k])}`;
    }

    return '';
  });
  return q.filter(f => f !== '').join('&');
};

export const campaignsApi = createApi({
  reducerPath: 'campaignsApi',
  tagTypes: ['Campaigns'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/v1/',
    prepareHeaders(headers, {getState}) {
      const token = (getState() as RootState).user.jwt;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    find: builder.query<IListPayload<ICampaign>, IListCampaignQuery>({
      query: params => ({
        url: `campaigns?${buildSearchQueryString(params)}`,
        method: 'GET',
      }),
      providesTags: ['Campaigns'],
    }),
    findById: builder.query<ICampaign, string>({
      query: (id: string) => ({
        url: `campaigns/${id}`,
        method: 'GET',
      }),
      providesTags: ['Campaigns'],
    }),
    insert: builder.mutation<IFormPayload<ICampaign>, Partial<ICampaign>>({
      query: campaign => ({
        url: `campaigns`,
        method: 'POST',
        body: campaign,
      }),
      invalidatesTags: ['Campaigns'],
    }),
    delete: builder.mutation<ICampaign, string>({
      query: (id: string) => ({
        url: `campaigns/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Campaigns'],
    }),
    update: builder.mutation<IFormPayload<ICampaign>, {id: string; campaign: Partial<ICampaign>}>({
      query: ({id, campaign}) => ({
        url: `campaigns/${id}`,
        method: 'PUT',
        body: campaign,
      }),
      invalidatesTags: ['Campaigns'],
    }),
    findImages: builder.query<IContentImage, IContentImageQuery>({
      query: ({campaignId, type = 'series', contentId}) => ({
        url: `campaigns/${campaignId}/content/${type}/${contentId}`,
        method: 'GET',
      }),
    }),
    createContentUploadUrl: builder.mutation<
      IContentImageUpload,
      {campaignId: string; contentType: string; contentId: string; imageType: string; mimeType: string; size: number}
    >({
      query: ({campaignId, contentType, contentId, imageType, mimeType, size}) => ({
        url: `campaigns/${campaignId}/content/${contentType}/${contentId}/${imageType}`,
        body: {mimeType, size},
        method: 'POST',
      }),
    }),
    deleteContentImage: builder.mutation<
      IContentImageDelete,
      {campaignId: string; contentType: string; contentId: string; imageType: string}
    >({
      query: ({campaignId, contentType, contentId, imageType}) => ({
        url: `campaigns/${campaignId}/content/${contentType}/${contentId}/${imageType}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFindQuery,
  useLazyFindQuery,
  useFindByIdQuery,
  useInsertMutation,
  useUpdateMutation,
  useDeleteMutation,
  useFindImagesQuery,
  useCreateContentUploadUrlMutation,
  useDeleteContentImageMutation,
} = campaignsApi;
