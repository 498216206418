import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {RootState} from 'app/store';
import {IUser, IUserPreferences} from 'models/users';
import {IListPayload, IListQuery} from 'models/generic';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  tagTypes: ['Users', 'User', 'UserPreferences'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/v1/',
    prepareHeaders(headers, {getState}) {
      const token = (getState() as RootState).user.jwt;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    me: builder.query<{active: boolean; permissions: string[]; regions: string[]; roles: []}, void>({
      query: () => ({
        url: `users/me`,
        method: 'GET',
      }),
      providesTags: ['User'],
    }),
    find: builder.query<IListPayload<IUser>, IListQuery>({
      query: ({offset = 0, limit = 10, sort = '_id:dsc', search = ''}) => ({
        url: `users?offset=${offset}&limit=${limit}&sort=${sort}${search && search.length ? '&search=' + search : ''}`,
        method: 'GET',
      }),
      providesTags: ['Users'],
    }),
    findById: builder.query<IUser, string>({
      query: (id: string) => ({
        url: `users/${id}`,
        method: 'GET',
      }),
      providesTags: ['Users'],
    }),
    insert: builder.mutation<IUser, Partial<IUser>>({
      query: user => ({
        url: `users`,
        method: 'POST',
        body: user,
      }),
      invalidatesTags: ['Users'],
    }),
    update: builder.mutation<IUser, {id: string; user: Partial<IUser>}>({
      query: ({id, user}) => ({
        url: `users/${id}`,
        method: 'PUT',
        body: user,
      }),
      invalidatesTags: ['Users', 'User'],
    }),
    delete: builder.mutation<IUser, string>({
      query: (id: string) => ({
        url: `users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),
    preferences: builder.query<IUserPreferences<unknown>, void>({
      query: () => ({
        url: 'users/me/preferences',
        method: 'GET',
      }),
      providesTags: ['UserPreferences'],
    }),
    updatePreferences: builder.mutation<IUserPreferences<unknown>, IUserPreferences<unknown>>({
      query: prefs => ({
        url: 'users/me/preferences',
        method: 'PUT',
        body: prefs,
      }),
      invalidatesTags: ['UserPreferences'],
    }),
  }),
});

export const {
  useMeQuery,
  useFindQuery,
  useFindByIdQuery,
  useInsertMutation,
  useUpdateMutation,
  useDeleteMutation,
  usePreferencesQuery,
  useUpdatePreferencesMutation,
} = usersApi;
