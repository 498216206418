import * as React from 'react';
import {Cluster, Toggle} from '@pluto-tv/assemble';
import {TPermissionChangeCallback} from '../utils';

export interface IPermissionItemProps {
  selectedPermissions: string[];
  name: string;
  displayName: string;
  dependsOn: string[];
  onPermissionChange: TPermissionChangeCallback;
  readonly: boolean;
}

export default ({
  selectedPermissions,
  name,
  displayName,
  dependsOn,
  onPermissionChange,
  readonly = false,
}: IPermissionItemProps): JSX.Element => {
  const canBeEnabled = (dependsOn || []).every(d => selectedPermissions.includes(d));

  return (
    <Cluster justify='space-between'>
      <Toggle
        value={selectedPermissions?.some(p => p === name)}
        onChange={value => onPermissionChange([name], value)}
        state={readonly || !canBeEnabled ? 'disabled' : ''}
        label={displayName}
      />
    </Cluster>
  );
};
