export const reorderList = <T>(fromIndices: number[], toIndex: number, items: T[]): T[] => {
  const updatedItems = [...items];
  const itemsToMove = fromIndices.map(index => updatedItems[index]);

  // Adjust toIndex if necessary
  if (toIndex > Math.min(...fromIndices)) {
    toIndex -= fromIndices.length;
  }

  // Remove items in reverse order to prevent reindexing issues
  fromIndices
    .sort((a, b) => b - a)
    .forEach(index => {
      updatedItems.splice(index, 1);
    });

  // Insert items in reverse order to preserve original order
  itemsToMove.reverse().forEach((item, i) => {
    updatedItems.splice(toIndex + i, 0, item);
  });

  return updatedItems;
};
