import * as React from 'react';
import {Cluster, Icon, TIcons} from '@pluto-tv/assemble';

export interface IDeleteActions<T> {
  row: T;
  icons: TIcons[];
  onClick(row: T, icon: TIcons): void;
}

const DeleteActionWithoutPopoverFn = <T,>({icons, onClick, row}: IDeleteActions<T>) => {
  return (
    <Cluster>
      {icons.map(icon => (
        <Icon color='delete' key={icon} icon={icon} onClick={() => onClick(row, icon)} space='small' />
      ))}
    </Cluster>
  );
};

DeleteActionWithoutPopoverFn.displayName = 'DeleteActionWithoutPopover';

export const DeleteActionWithoutPopover = React.memo(
  DeleteActionWithoutPopoverFn,
) as typeof DeleteActionWithoutPopoverFn;
