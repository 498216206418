import * as React from 'react';
import {
  Expand,
  Template,
  Box,
  Cover,
  Stack,
  Cluster,
  Icon,
  Heading,
  Divider,
  FormItem,
  TextInput,
  Button,
  Select,
  TagList,
  useValidateForm,
  DateTime,
  IDateRange,
} from '@pluto-tv/assemble';
import {SeriesFavoriteSearch} from 'components/favoriteSearch/favoriteSearch';
import {useUserRegions} from 'helpers/useUserRegions';
import {TSortDirection} from 'models/generic';
import {IListSeriesQuery} from 'models/series';
import {IUserSeriesSearch} from 'models/users';
import {useFindQuery as useFindGenresQuery} from 'features/genres/genresApi';
import {useFindQuery as useFindLanguagesQuery} from 'features/languages/languagesApi';
import {useUserRatings} from 'helpers/useUserRatings';
import {orderBy} from 'lodash-es';
import useToggleSearchBarOnSlash from 'helpers/useToggleSearchBarOnSlash';

export interface ISeriesSearchBarProps {
  sortDir: TSortDirection;
  sortCol: string;
  isSearchExpanded: boolean;
  inModal: boolean;
  type?: 'importing' | 'adding';
  importing?: boolean;
  showFavoriteSearch: boolean;
  isFetching: boolean;
  search?: IUserSeriesSearch;
  canViewOO: boolean;
  canViewPartner: boolean;
  setIsSearchExpanded: (isExpanded: boolean) => void;
  onSearch: (searchParams: IUserSeriesSearch) => void;
  onClear: () => void;
}

const seriesTitleInputId = 'seriesTitle';

const SeriesSearchBar = React.memo(
  ({
    sortDir,
    sortCol,
    isSearchExpanded,
    inModal,
    type,
    showFavoriteSearch,
    isFetching,
    search,
    canViewOO,
    canViewPartner,
    setIsSearchExpanded,
    onSearch,
    onClear,
  }: ISeriesSearchBarProps) => {
    const {
      model: searchModel,
      onChange: searchOnChange,
      setFields: searchSetFields,
      setModel: searchSetModel,
      form: searchForm,
      state: searchState,
      onBlur: searchOnBlur,
      reset: searchReset,
    } = useValidateForm<IListSeriesQuery>(
      [
        {name: 'name', label: 'Series Name'},
        {name: 'activeRegions'},
        {name: 'metadataLanguage'},
        {name: 'tags'},
        {name: 'genres'},
        {name: 'rating'},
        {name: 'plutoTvOO'},
        {name: 'published'},
        {name: 'distributeAsAVOD'},
        {name: 'distributeAsLinear'},
        {name: 'minCreatedAt'},
        {name: 'maxCreatedAt'},
        {name: 'minUpdatedAt'},
        {name: 'maxUpdatedAt'},
        {name: 'seasonCount', label: 'Number of Seasons'},
        {name: 'episodeCount', label: 'Number of Episodes'},
        {name: 'type'},
        {name: 'availWindow', label: 'In Window'},
        {name: 'id'},
        {name: 'timeWindow', label: 'Time Window'},
        {name: 'tmsID', label: 'TMS ID'},
        {name: 'contentUUID', label: 'Content UUID'},
      ],
      'immediate',
    );

    const [searchSelected, setSearchSelected] = React.useState<IUserSeriesSearch | undefined>();

    const defaultPlutoTvOOVal = React.useMemo((): boolean | undefined => {
      if (canViewOO && canViewPartner) {
        return searchModel?.plutoTvOO;
      } else if (!canViewOO) {
        return false;
      } else {
        return true;
      }
    }, [searchModel, canViewOO, canViewPartner]);

    const {contentRatings} = useUserRatings();
    useToggleSearchBarOnSlash(setIsSearchExpanded, isSearchExpanded);

    const {activeRegions} = useUserRegions();
    const {data: genres} = useFindGenresQuery();
    const {data: languages} = useFindLanguagesQuery();

    const handleSearch = () => {
      onSearch({
        name: '',
        model: searchModel,
        sortCol: sortCol,
        sortDir: sortDir,
      });
    };

    React.useEffect(() => {
      if (search) {
        searchSetModel(search.model);
      }
    }, [search, searchSetModel]);

    const searchSelectedHandler = (search: IUserSeriesSearch) => {
      setSearchSelected(search);
      searchSetModel(search.model);
      onSearch(search);
    };

    const clearHandler = () => {
      searchReset();
      onClear();
      setSearchSelected(undefined);
    };

    React.useEffect(() => {
      if (!isFetching && isSearchExpanded) {
        const seriesTitleInput = document.getElementById(seriesTitleInputId);
        seriesTitleInput?.focus({
          preventScroll: true,
        });
      }
    }, [isFetching, isSearchExpanded]);

    const ratingsForSelectedRegions = React.useMemo(() => {
      const selectedRegions = searchModel.activeRegions || [];

      if (selectedRegions.length === 0) {
        return contentRatings;
      }

      const newContentRatings = contentRatings.filter(r => r.group === '' || selectedRegions.includes(r.group || ''));

      if (searchModel.rating && !newContentRatings.some(c => c.label === searchModel.rating)) {
        searchSetFields({rating: undefined});
      }

      return newContentRatings;
    }, [searchModel.activeRegions, contentRatings, searchModel.rating, searchSetFields]);

    return (
      <Expand width='18.75rem' height='100%' fullHeightContainer={true} isExpanded={isSearchExpanded}>
        <Template label='expandable'>
          <Box
            background='pewter'
            paddingY={inModal ? 'none' : 'medium'}
            paddingRight='medium'
            paddingLeft={inModal ? 'none' : 'medium'}
            fullHeight={true}
          >
            <Cover scrolling={true} gutter='medium'>
              <Template label='header'>
                <Stack space='medium'>
                  <Cluster align='center' justify='space-between'>
                    <Icon icon='tune' space='small' size='large' iconAlign='center'>
                      <Heading level='h4'>Search Filters</Heading>
                    </Icon>
                    <Icon icon='collapseleft' size='large' onClick={() => setIsSearchExpanded(!isSearchExpanded)} />
                  </Cluster>
                  {showFavoriteSearch && (
                    <SeriesFavoriteSearch
                      searchModel={{
                        name: '',
                        model: searchModel,
                        sortCol: sortCol,
                        sortDir: sortDir,
                      }}
                      onSearchSelected={searchSelectedHandler}
                      searchSelected={searchSelected}
                      onClearSelection={clearHandler}
                    />
                  )}
                  <Divider color='graphite' />
                </Stack>
              </Template>
              <Template label='cover'>
                {/* Need to force form re-render on open searchBar to make autoFocus field work properly */}
                {isSearchExpanded && (
                  <form
                    id='series-search-form'
                    onSubmit={ev => {
                      ev.preventDefault();
                      setTimeout(() => handleSearch());
                    }}
                  >
                    <Stack space='small'>
                      {/* Using this to allow pressing enter to submit form */}
                      <input type='submit' style={{display: 'none'}} />
                      {/* negative margin here to counter act the odd space from the input on the line above. */}
                      <Box marginTop='xsmallNegative'>
                        <FormItem
                          {...searchForm.name}
                          onBlur={() => {
                            searchOnBlur('name');
                          }}
                        >
                          <TextInput
                            id={seriesTitleInputId}
                            placeholder='Title'
                            value={searchModel.name}
                            onChange={val => {
                              searchOnChange('name', val);
                            }}
                          />
                        </FormItem>
                      </Box>
                      <FormItem label='Active Region'>
                        <Select
                          multiselect={true}
                          placeholder='Select Active Region'
                          clearable={true}
                          id='targetRegion'
                          value={searchModel.activeRegions?.map(v => ({label: v, value: v})) || []}
                          options={activeRegions.map(ar => ({
                            label: `${ar.name} (${ar.code})`,
                            value: ar.code,
                          }))}
                          onChange={value => {
                            if (!value) {
                              searchSetFields({activeRegions: []});
                            } else {
                              searchSetFields({activeRegions: value.map(v => v.value)});
                            }
                          }}
                          predicate='value'
                        />
                      </FormItem>
                      {type !== 'importing' && (
                        <FormItem label='Genre'>
                          <Select
                            clearable={true}
                            placeholder='Select Genre'
                            multiselect={true}
                            onChange={value => {
                              if (!value) {
                                searchSetFields({genres: []});
                              } else {
                                searchSetFields({genres: value.map(v => v.label)});
                              }
                            }}
                            value={searchModel.genres?.map(v => ({label: v, value: v} || []))}
                            id='genre'
                            options={(genres || []).map(g => ({label: g.genre}))}
                          />
                        </FormItem>
                      )}
                      <FormItem label='Series Type'>
                        <Select
                          id='type'
                          clearable={true}
                          placeholder='Select Type'
                          predicate='value'
                          value={{label: searchModel.type || '', value: searchModel.type}}
                          onChange={val => searchSetFields({type: val?.value})}
                          options={[
                            {label: 'Film', value: 'film'},
                            {label: 'TV', value: 'tv'},
                            {label: 'Web Original', value: 'web-original'},
                            {label: 'Music Video', value: 'music-video'},
                            {label: 'Live', value: 'live'},
                          ]}
                        />
                      </FormItem>
                      <FormItem label='Published'>
                        <Select
                          id='published'
                          clearable={true}
                          placeholder='Published?'
                          options={[{label: 'Yes'}, {label: 'No'}]}
                          value={
                            searchModel.published === undefined
                              ? undefined
                              : searchModel.published
                              ? {label: 'Yes'}
                              : {label: 'No'}
                          }
                          onChange={value => {
                            if (!value) {
                              searchSetFields({published: undefined});
                            } else {
                              searchSetFields({published: value.label === 'Yes'});
                            }
                          }}
                        />
                      </FormItem>
                      {type !== 'importing' && (
                        <FormItem label='Rating'>
                          <Select
                            placeholder='Select rating'
                            clearable={true}
                            onChange={value => {
                              if (!value) {
                                searchSetFields({rating: undefined});
                              } else {
                                searchSetFields({rating: value.label});
                              }
                            }}
                            value={{label: searchModel.rating || ''}}
                            id='rating'
                            sortField='weight'
                            options={ratingsForSelectedRegions}
                          />
                        </FormItem>
                      )}
                      {type !== 'importing' && (
                        <FormItem
                          label='Pluto TV O&O'
                          permission={canViewOO && canViewPartner ? 'enabled' : 'disabled'}
                        >
                          <Select
                            clearable={true}
                            predicate='value'
                            placeholder='Select Pluto TV O&O'
                            options={[
                              {label: 'Yes', value: true},
                              {label: 'No', value: false},
                            ]}
                            value={{label: '', value: defaultPlutoTvOOVal}}
                            onChange={value => searchSetFields({plutoTvOO: value?.value})}
                          />
                        </FormItem>
                      )}
                      {type !== 'importing' && (
                        <FormItem label='Language'>
                          <Select
                            placeholder='Select Language'
                            multiselect={true}
                            onChange={value => {
                              if (!value) {
                                searchSetFields({metadataLanguage: []});
                              } else {
                                searchSetFields({metadataLanguage: value?.map(v => v.value)});
                              }
                            }}
                            value={searchModel.metadataLanguage?.map(mdl => ({
                              label: mdl || '',
                              value: mdl,
                            }))}
                            id='metadataLanguage'
                            predicate='value'
                            clearable={true}
                            searchable={true}
                            searchPlaceholder='Search for language'
                            onSearch={val =>
                              orderBy(
                                (languages || [])
                                  .filter(language => language.name.toLowerCase().indexOf(val.toLowerCase()) > -1)
                                  .map(language => ({label: language.name, value: language.iso639_1}), 'label'),
                              ) || []
                            }
                            options={orderBy(
                              (languages || []).map(language => ({label: language.name, value: language.iso639_1})),
                              'label',
                            )}
                          />
                        </FormItem>
                      )}
                      {type !== 'importing' && (
                        <FormItem label='Tags'>
                          <TagList
                            placeholder='Search by Tags'
                            value={searchModel.tags}
                            onChange={value => searchSetFields({tags: value})}
                          />
                        </FormItem>
                      )}
                      {type !== 'importing' && (
                        <FormItem label='Upload Date' state={searchModel?.timeWindow ? 'disabled' : ''}>
                          <DateTime
                            id='uploadDate'
                            range={true}
                            placeholder='Search by upload date range'
                            value={
                              searchModel?.minCreatedAt && searchModel.maxCreatedAt
                                ? {start: new Date(searchModel.minCreatedAt), end: new Date(searchModel.maxCreatedAt)}
                                : undefined
                            }
                            clearable={true}
                            appendToBody={true}
                            onChange={value => {
                              if (!value) {
                                searchSetFields({
                                  minCreatedAt: undefined,
                                  maxCreatedAt: undefined,
                                });
                              } else {
                                const period = value as IDateRange;
                                searchSetFields({
                                  minCreatedAt: period.start.toISOString(),
                                  maxCreatedAt: (period.end || period.start).toISOString(),
                                });
                              }
                            }}
                          />
                        </FormItem>
                      )}
                      {type !== 'importing' && (
                        <FormItem label='Time Window' state={searchModel?.minCreatedAt ? 'disabled' : ''}>
                          <Select
                            id='timeWindow'
                            placeholder='Time Window'
                            clearable={true}
                            options={[
                              {label: 'Daily (24-Hour Range)', value: 'day'},
                              {label: 'Weekly (7-Day Range)', value: 'week'},
                              {label: 'Monthly (30-Day Range)', value: 'month'},
                            ]}
                            predicate='value'
                            value={{label: '', value: searchModel.timeWindow}}
                            onChange={val =>
                              searchSetFields({
                                timeWindow: val?.value,
                              })
                            }
                          />
                        </FormItem>
                      )}
                      {type !== 'importing' && (
                        <FormItem {...searchForm.seasonCount} onBlur={() => searchOnBlur('seasonCount')}>
                          <TextInput
                            type='number'
                            placeholder='Season Count'
                            clearable={true}
                            value={searchModel.seasonCount}
                            onChange={val => searchOnChange('seasonCount', val)}
                          />
                        </FormItem>
                      )}
                      {type !== 'importing' && (
                        <FormItem {...searchForm.episodeCount} onBlur={() => searchOnBlur('episodeCount')}>
                          <TextInput
                            type='number'
                            placeholder='Episode Count'
                            clearable={true}
                            value={searchModel.episodeCount}
                            onChange={val => searchOnChange('episodeCount', val)}
                          />
                        </FormItem>
                      )}
                      {type !== 'importing' && (
                        <FormItem {...searchForm.availWindow} onBlur={() => searchOnBlur('availWindow')}>
                          <Select
                            clearable={true}
                            predicate='value'
                            placeholder='Avail. Window'
                            value={{label: '', value: searchModel.availWindow}}
                            onChange={val => searchOnChange('availWindow', val?.value)}
                            options={[
                              {label: 'Active AVOD avail.', value: 'AVOD'},
                              {label: 'Active Linear avail.', value: 'linear'},
                            ]}
                          />
                        </FormItem>
                      )}
                      <FormItem
                        {...searchForm.tmsID}
                        onBlur={() => {
                          searchOnBlur('tmsID');
                        }}
                      >
                        <TextInput
                          clearable={true}
                          placeholder='Search by TMS ID'
                          value={searchModel.tmsID}
                          onChange={value => {
                            searchSetFields({tmsID: value});
                          }}
                        />
                      </FormItem>
                      <FormItem
                        {...searchForm.contentUUID}
                        onBlur={() => {
                          searchOnBlur('contentUUID');
                        }}
                      >
                        <TextInput
                          clearable={true}
                          placeholder='Search by Content UUID'
                          value={searchModel.contentUUID}
                          onChange={value => {
                            searchSetFields({contentUUID: value});
                          }}
                        />
                      </FormItem>
                    </Stack>
                  </form>
                )}
              </Template>
              <Template label='footer'>
                <Cluster justify='space-between'>
                  <div></div>
                  <Cluster space='small'>
                    <Button ghost={true} state={isFetching ? 'disabled' : ''} onClick={clearHandler}>
                      Clear
                    </Button>
                    <Button
                      type='primary'
                      state={!searchState.isDirty ? 'disabled' : isFetching ? 'thinking' : ''}
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                  </Cluster>
                </Cluster>
              </Template>
            </Cover>
          </Box>
        </Template>
      </Expand>
    );
  },
);

SeriesSearchBar.displayName = 'EpisodeSearchBar';
export default SeriesSearchBar;
