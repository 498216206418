type Orderable = {order?: number};

export const shuffle = <T extends Orderable>(list: T[]): T[] => {
  const tempList = [...list];

  for (let i = tempList.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [tempList[i], tempList[j]] = [tempList[j], tempList[i]];
    tempList[i].order = i;
    tempList[j].order = j;
  }

  return tempList;
};
