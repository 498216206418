import * as React from 'react';
import {useParams} from 'react-router-dom';
import {
  Box,
  Button,
  Cluster,
  FormItem,
  IValidatorField,
  Select,
  Stack,
  TextInput,
  Toggle,
  summon,
  useValidateForm,
} from '@pluto-tv/assemble';
import {useUserRegions} from 'helpers/useUserRegions';
import {IChannel, ISamsungChannelProperties} from 'models/channels';
import {orderBy} from 'lodash-es';
import {ITerritory} from 'models/territory';
import {IActiveRegion} from 'models/activeRegions';

const emptyModel = {
  id: '',
  channel: '',
  serviceId: '',
  region: '',
  intileEnabled: false,
};

const searchChannels = async (
  samsungId: string,
  allRegions: IActiveRegion[] | undefined,
): Promise<{data: IChannel[]}> => {
  const regionsQuery = allRegions?.reduce((acc, r) => acc.concat(`&activeRegion=${r.code.toLowerCase()}`), '');
  return summon.get<void, {data: IChannel[]}>(`channels?samsungId=${samsungId}${regionsQuery}`);
};

export interface ISamsungServiceFormProps {
  channel: Partial<IChannel>;
  isNew?: boolean;
  value?: Partial<ISamsungChannelProperties>;
  onCancel(): void;
  onSave(savedObj: ISamsungChannelProperties): void;
  visible?: boolean;
}

const SamsungServiceForm = ({
  channel,
  isNew = false,
  onCancel,
  value,
  onSave,
  visible,
}: ISamsungServiceFormProps): JSX.Element => {
  const {id} = useParams<{id: string}>();
  const {territoriesByRegion, allRegions} = useUserRegions();

  const samsungServiceValidator: IValidatorField<ISamsungChannelProperties>[] = [
    {
      name: 'id',
      label: 'ID',
    },
    {
      name: 'intilesEnabled',
      label: 'Intiles Enabled',
    },
    {
      name: 'serviceId',
      label: 'Service ID',
      required: true,
      validators: [
        async (serviceId: string): Promise<string | undefined> => {
          try {
            if (serviceId) {
              const serviceChannel = await searchChannels(serviceId, allRegions);
              const channelUsingServiceId = serviceChannel?.data.find(c => {
                const hasSamsungChannelProperties = !!c?.samsungChannelProperties?.length;
                const isSameChannel = c.id === id;
                return hasSamsungChannelProperties && (!isSameChannel || (isSameChannel && isNew));
              });

              if (channelUsingServiceId) {
                return `This service ID is currently used in ${channelUsingServiceId.name}`;
              }
            }
          } catch (err: any) {
            if (err?.status === 404) {
              return undefined;
            } else {
              return 'Failed to check Service ID.';
            }
          }
        },
      ],
    },
    {name: 'region', label: 'Territory', required: true},
  ];

  const {
    model,
    onChange,
    form,
    setModel,
    setFields,
    state: formState,
    onBlur,
  } = useValidateForm<ISamsungChannelProperties>(samsungServiceValidator, 'immediate');

  const [isAlreadyInserted, setIsAlreadyInserted] = React.useState(false);
  const [defaultTerritory, setDefaultTerritory] = React.useState<ITerritory>();
  const [territories, setTerritories] = React.useState<ITerritory[]>([]);

  React.useEffect(() => {
    if (model.serviceId) {
      const isNewAlreadyIncluded =
        (channel?.samsungChannelProperties &&
          channel?.samsungChannelProperties.filter(e => !e.id && e?.serviceId === model.serviceId)?.length) ||
        0;
      setIsAlreadyInserted(isNewAlreadyIncluded > 0);
    }
  }, [model.serviceId, channel?.samsungChannelProperties]);

  React.useEffect(() => {
    if (channel?.activeRegion) {
      const activeRegion = channel.activeRegion.toUpperCase();
      const arTerritories = territoriesByRegion[activeRegion] || [];
      const [arDefaultTerritory] = arTerritories.filter(el => el.id === channel?.activeRegion?.toUpperCase());

      setTerritories(arTerritories);
      setDefaultTerritory(arDefaultTerritory);
    }
  }, [territoriesByRegion, channel?.activeRegion]);

  React.useEffect(() => {
    if (visible && value) {
      setModel(value);
    } else {
      if (defaultTerritory) {
        setModel({
          ...emptyModel,
          region: defaultTerritory.id,
        });
      }
    }
  }, [defaultTerritory, setModel, visible, value]);

  return (
    <Box padding='small' background='charcoal' width='18rem'>
      <Stack space='small'>
        <FormItem
          {...form.serviceId}
          onBlur={() => onBlur('serviceId', false)}
          helpText={form.serviceId?.helpText}
          state={form.serviceId?.state}
        >
          <TextInput onChange={value => onChange('serviceId', value)} value={model.serviceId} id='serviceId' />
        </FormItem>
        <FormItem {...form.region} onBlur={() => onBlur('region', false)}>
          <Select
            placeholder='Select a Territory'
            value={{label: '', value: model.region?.toUpperCase()}}
            predicate='value'
            searchable={true}
            options={
              orderBy(
                territories.map(t => ({label: `${t.name} (${t.id})`, value: t.id.toUpperCase()})),
                'label',
              ) || []
            }
            onChange={val =>
              setFields({
                region: val.value,
              })
            }
          />
        </FormItem>
        <FormItem child='Toggle' label='Intiles Enabled'>
          <Toggle label='Yes' value={model.intilesEnabled} onChange={val => setFields({intilesEnabled: val})} />
        </FormItem>
        <Cluster justify='space-between'>
          <div></div>
          <Cluster space='small'>
            <Button ghost={true} onClick={() => onCancel()}>
              Cancel
            </Button>
            <Button
              type='primary'
              onClick={() => onSave(model as ISamsungChannelProperties)}
              state={!formState.isValid || !formState.isDirty || isAlreadyInserted ? 'disabled' : ''}
            >
              {isNew ? '+ Add' : 'Update'}
            </Button>
          </Cluster>
        </Cluster>
      </Stack>
    </Box>
  );
};

export default SamsungServiceForm;
