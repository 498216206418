export const seriesPermissions = {
  SERIES_VIEW: {
    enabled: [
      'series_view_oo',
      'series_edit_oo',
      'series_create_oo',
      'series_view_partner',
      'series_edit_partner',
      'series_create_partner',
    ],
  },
  SERIES_CREATE: {
    enabled: ['series_create_oo', 'series_create_partner'],
  },
  SERIES_EDIT: {
    enabled: ['series_edit_oo', 'series_edit_partner'],
    disabled: [
      'series_view_oo',
      'series_delete_oo',
      'series_create_oo',
      'series_view_partner',
      'series_delete_partner',
      'series_create_partner',
    ],
  },
  SERIES_DELETE: {
    enabled: ['series_delete_oo', 'series_delete_partner'],
  },
};
