import * as React from 'react';

interface IFormSearchWrapperProps {
  children: React.ReactNode;
  onSearch(): void;
}

export const FormSearchWrapper = React.memo(
  ({children, onSearch}: IFormSearchWrapperProps): JSX.Element => (
    <form
      onSubmit={ev => {
        ev.preventDefault();

        setTimeout(() => onSearch());
      }}
    >
      {children}
    </form>
  ),
);
