import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {RootState} from 'app/store';
import {IContent} from 'models/content';
import {IListQuery, IListPayload} from 'models/generic';

interface IListContentQuery extends IListQuery {
  type: 'series' | 'movie' | 'channel' | 'vodcategory';
  campaignID: string;
  series?: string;
}

export const contentApi = createApi({
  reducerPath: 'contentApi',
  tagTypes: ['Content'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/v1/',
    prepareHeaders(headers, {getState}) {
      const token = (getState() as RootState).user.jwt;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    find: builder.query<IListPayload<IContent>, IListContentQuery>({
      query: ({offset = 0, limit = 10, sort, type = 'series', search, campaignID, series = null}) => {
        let url = `content?offset=${offset}&limit=${limit}&type=${type}&campaignID=${campaignID}`;

        if (search) {
          url = `${url}&search=${encodeURIComponent(search)}`;
        }

        if (sort) {
          url = `${url}&sort=${sort}`;
        }

        if (series) {
          url = `${url}&series=${series}`;
        }

        return {
          url,
          method: 'GET',
        };
      },
      providesTags: ['Content'],
    }),
    findById: builder.query<IContent, string>({
      query: (id: string) => ({
        url: `content/${id}`,
        method: 'GET',
      }),
      providesTags: ['Content'],
    }),
  }),
});

export const {useFindQuery, useFindByIdQuery} = contentApi;
