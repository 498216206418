import {IValidatorField} from '@pluto-tv/assemble';
import {groupBy, isEmpty, some} from 'lodash-es';
import {DateTime as luxon, Interval} from 'luxon';

import {IAvailbilityWindows, IClip, ISource, TAdSparxAdPolicy, TSourceType} from 'models/clips';
import {IClipDuration, IClipSearch, checkOriginUrlFileValidation} from '../utils';

export const clipHeaderValidator: IValidatorField<IClip>[] = [
  {
    name: 'published',
    label: 'Published?',
  },
];

const clipSimpleDetails: IValidatorField<IClip>[] = [
  {
    name: 'name',
    label: 'Clip Name',
    required: true,
    validators: [
      (name: string): string | undefined => {
        if (!name || !name.trim().length) {
          return 'Clip Name is required';
        } else if (name && name.trim().length > 100) {
          return 'Clip Name must be less than 100 characters';
        }
      },
    ],
  },
  {
    name: 'duration',
    label: 'Clip Duration',
    required: true,
    validators: [
      (duration: string): string | undefined => {
        if (!duration) {
          return 'Clip Duration is required';
        } else if (duration && typeof duration == 'string') {
          return 'Clip Duration must be in HH:MM:SS format and minumum 5 second';
        }
      },
    ],
  },
  {
    name: 'framerate',
    label: 'Clip Framerate',
    required: true,
    validators: [
      (framerate: string): string | undefined => {
        if (!framerate) {
          return 'Clip Framerate is required';
        }
      },
    ],
  },
  {
    name: 'partner',
    label: 'Partner',
    required: true,
    validators: [
      (partner: string): string | undefined => {
        if (!partner || !partner.trim().length) {
          return 'Partner is required';
        }
      },
    ],
  },
  {
    name: 'author',
    label: 'Author',
  },
];

const clipLocation: IValidatorField<IClip>[] = [
  {
    name: 'activeRegion',
    label: 'Region',
    required: true,
    validators: [
      (region: string): string | undefined => {
        if (!region) {
          return 'Region is required';
        }
      },
    ],
  },
  {
    name: 'regionFilter',
    label: 'Territories',
    required: true,
    validators: [
      (territories: string[]): string | undefined => {
        if (!territories || !territories.length) {
          return 'Territories is required';
        }
      },
    ],
  },
];

export const clipOrigin = (required = false): IValidatorField<IClip> => ({
  name: 'origin',
  label: 'Origin URL',
  required,
  validators: [
    (origin: {url: string; type?: string}): string | undefined => {
      if (!origin || !origin.url) {
        return 'Origin URL is required';
      }

      if (!checkOriginUrlFileValidation(origin.url, '.m3u8') && !checkOriginUrlFileValidation(origin.url, '.mp4')) {
        return 'Origin URL must be MP4 or M3U8 file';
      }
    },
  ],
});

export const clipDetailsValidator: IValidatorField<IClip>[] = [
  ...clipSimpleDetails,
  {
    name: 'summary',
    label: 'Summary',
  },
  {
    name: 'description',
    label: 'Long Description',
  },
  {
    name: 'rating',
    label: 'Rating',
    required: true,
  },
  {
    name: 'category',
    label: 'Category',
  },
  {
    name: 'language',
    label: 'Language',
  },
  {
    name: 'copyright',
    label: 'Copyright',
  },
  {
    name: 'directors',
    label: 'Directors',
  },
  {
    name: 'actors',
    label: 'Actors',
  },
  {
    name: 'writers',
    label: 'Writers',
  },
  {
    name: 'producers',
    label: 'Producers',
  },
  {
    name: 'originalReleaseDate',
    label: 'Original Release Date',
    validators: [
      (originalReleaseDate: Date): string | undefined => {
        const plusOneYear = luxon.now().plus({years: 1});
        const date = luxon.fromJSDate(originalReleaseDate);

        if (date > plusOneYear) {
          return "Original Release date can't be that far in the future";
        }
      },
    ],
  },
  {
    name: 'productionCompany',
    label: 'Production Company',
  },
  {
    name: 'productionCountries',
    label: 'Production Countries',
  },
  {
    name: 'productionYear',
    label: 'Production Year',
  },
  {
    name: 'duration',
  },
  {
    name: 'outPoint',
  },
];

export const clipSettingsValidator: IValidatorField<IClip>[] = [
  ...clipLocation,
  {
    name: 'programmingType',
    label: 'Programming Type',
  },
  {
    name: 'promotional',
    label: 'Promotional',
  },
  {
    name: 'liveBroadcast',
    label: 'Live Broadcast',
  },
  {
    name: 'captionsRequired',
    label: 'Captions Required',
  },
  {
    name: 'channelAssociation',
    label: 'Channel Association',
  },
  {
    name: 'tags',
    label: 'Tags List',
  },
  {
    name: 'distributeAs',
  },
  {
    name: 'duration',
    label: 'Clip Duration',
  },
  {
    name: 'availabilityWindows',
    validators: [
      (availabilityWindows: IAvailbilityWindows): string | undefined => {
        let errorMsg: string | undefined;

        if (!availabilityWindows || isEmpty(availabilityWindows)) {
          return;
        }

        Object.keys(availabilityWindows).forEach(key => {
          const windowType: keyof IAvailbilityWindows = key as keyof IAvailbilityWindows;

          if (!availabilityWindows[windowType] || isEmpty(availabilityWindows[windowType]) || errorMsg) {
            return;
          }

          availabilityWindows[windowType]?.forEach((windowA, iA) => {
            const windowAStart = luxon.fromISO(windowA.startDate as string);
            const windowAEnd = luxon.fromISO(windowA.endDate as string);

            availabilityWindows[windowType]?.forEach((windowB, iB) => {
              if (iA === iB || errorMsg) {
                return;
              }

              const windowBStart = luxon.fromISO(windowB.startDate as string);
              const windowBEnd = luxon.fromISO(windowB.endDate as string);

              if (
                Interval.fromDateTimes(windowAStart, windowAEnd).contains(windowBStart) ||
                Interval.fromDateTimes(windowAStart, windowAEnd).contains(windowBEnd) ||
                Interval.fromDateTimes(windowBStart, windowBEnd).contains(windowAStart) ||
                Interval.fromDateTimes(windowBStart, windowBEnd).contains(windowAEnd)
              ) {
                errorMsg = 'Cannot have overlapping availability windows';
              }
            });
          });
        });

        return errorMsg;
      },
    ],
  },
];

function isInvalidSourceCombination(groupedTypes, sourceType1, sourceType2) {
  // See https://plutotv.atlassian.net/browse/CMS-3113 for rules

  // Both sources are not present at same time
  const isInvalid = (!!groupedTypes[sourceType1] as any) ^ (!!groupedTypes[sourceType2] as any);

  /*
  Both sources are present but are mixed with some other source
  which is not liveDashDVB
  */
  const isMixed =
    !groupedTypes.liveDashDVB &&
    !!groupedTypes[sourceType1] &&
    !!groupedTypes[sourceType2] &&
    Object.keys(groupedTypes).length > 2;

  /*
    Sources are liveDashDVB, liveDash and liveHLS mixed 
    with other source

    This check happens after checking if all three are present
    in the sources.
  */
  const isMixedWithLiveDashDVB =
    !!groupedTypes.liveDashDVB &&
    !!groupedTypes.liveDash &&
    !!groupedTypes.liveHLS &&
    Object.keys(groupedTypes).length > 3;

  return isInvalid || isMixed || isMixedWithLiveDashDVB;
}

function checkSourcesMessage(sourceType1, sourceType2) {
  return `${sourceType1} and ${sourceType2} source types must always be used together and cannot be mixed with other source types.`;
}

export const clipMediaValidator: IValidatorField<IClip>[] = [
  {
    name: 'url',
    label: 'URL',
    required: true,
    validators: [
      (url: string): string | undefined => {
        if (!url || !url.trim().length) {
          return 'URL is required';
        }
      },
    ],
  },
  clipOrigin(),
  {
    name: 'framerate',
    label: 'Framerate',
  },
  {
    name: 'color',
    label: 'Color',
  },
  {
    name: 'breakpoints',
    label: 'Breakpoint List',
  },
  {
    name: 'sources',
    validators: [
      (sources: ISource[]): string | undefined => {
        const groupedTypes = groupBy(sources, 'type');

        if (some(Object.keys(groupedTypes), key => groupedTypes[key].length > 1)) {
          return 'Multiple of one source type is not allowed';
        }

        if (groupedTypes.liveDashDVB && !(groupedTypes.liveHLS && groupedTypes.liveDash)) {
          return 'liveDashDVB source type must always be used with liveHLS and liveDash source types.';
        }

        if (isInvalidSourceCombination(groupedTypes, 'liveHLS', 'liveDash')) {
          return checkSourcesMessage('liveHLS', 'liveDash');
        }

        if (isInvalidSourceCombination(groupedTypes, 'liveUrlHLS', 'liveUrlDash')) {
          return checkSourcesMessage('liveUrlHLS', 'liveUrlDash');
        }

        if (isInvalidSourceCombination(groupedTypes, 'liveGDaiHLS', 'liveGDaiDash')) {
          return checkSourcesMessage('liveGDaiHLS', 'liveGDaiDash');
        }
      },
    ],
  },
];

export const clipSourceValidator: IValidatorField<ISource>[] = [
  {
    name: 'type',
    label: 'Type',
    required: true,
    validators: [
      (sourceType: TSourceType): string | undefined => {
        if (!sourceType || !sourceType.trim().length) {
          return 'Type is required';
        }
      },
    ],
  },
  {
    name: 'file',
    label: 'File URL',
    required: true,
    validators: [
      (url: string): string | undefined => {
        if (!url || !url.trim().length) {
          return 'File URL is required';
        }
      },
    ],
  },
  {
    name: 'adSparxAdPolicy',
    label: 'Policy',
    required: true,
    validators: [
      (policy: TAdSparxAdPolicy, model: Partial<ISource>): string | undefined => {
        if (model.type !== 'liveAdsparx' && policy) {
          return 'Policy is not allowed for sources that are not liveAdsparx';
        }
        if (model.type === 'liveAdsparx' && (!policy || !policy.trim().length)) {
          return 'Policy is required';
        }
      },
    ],
  },
];

export const clipCreateValidator: IValidatorField<IClip>[] = [
  ...clipSimpleDetails,
  ...clipLocation,
  clipOrigin(true),
  {
    name: 'liveBroadcast',
    label: 'Live Broadcast',
  },
];

export const clipDurationValidator: IValidatorField<IClipDuration>[] = [
  {
    name: 'start',
    validators: [
      (start: string): string | undefined => {
        if (start && start.length !== 0 && start.length !== 8) {
          return 'Duration must be filled';
        }
      },
    ],
  },
  {
    name: 'end',
    validators: [
      (end: string): string | undefined => {
        if (end && end.length !== 0 && end.length !== 8) {
          return 'Duration must be filled';
        }
      },
    ],
  },
];

const clipSearchValidator: IValidatorField<IClipSearch> = {
  name: 'search',
  label: 'Search Name',
  validators: [
    (search: string): string | undefined => {
      if (!search || search.trim().length === 0) {
        return 'Search Name is required';
      }
    },
  ],
};

export const clipSearchAllValidators: IValidatorField<IClipSearch>[] = [
  clipSearchValidator,
  {
    name: 'name',
    label: 'Clip Name',
    required: true,
    validators: [
      (name: string): string | undefined => {
        if (!name || !name.trim().length) {
          return 'Clip Name is required';
        } else if (name && name.trim().length > 100) {
          return 'Clip Name must be less than 100 characters';
        }
      },
    ],
  },
  {
    name: 'activeRegion',
    label: 'Region',
  },
  {
    name: 'author',
    label: 'Author',
  },
  {
    name: 'tags',
    label: 'Tags List',
  },
  {
    name: 'category',
    label: 'Category',
  },
  {
    name: 'promotional',
    label: 'Promotional',
  },
  {
    name: 'language',
    label: 'Language',
  },
  {
    name: 'rating',
    label: 'Rating',
  },
  {
    name: 'url',
    label: 'URL',
  },
  {
    name: 'programmingType',
    label: 'Programming Type',
  },
  {
    name: 'liveBroadcast',
    label: 'Live Broadcast',
  },
  {
    name: 'summary',
    label: 'Summary',
  },
  {
    name: 'description',
    label: 'Long Description',
  },
  {
    name: 'directors',
    label: 'Directors',
  },
  {
    name: 'actors',
    label: 'Actors',
  },
  {
    name: 'writers',
    label: 'Writers',
  },
  {
    name: 'producers',
    label: 'Producers',
  },
  {
    name: 'regionFilter',
    label: 'Territories',
  },
  {
    name: 'distributeAs',
  },
  {
    name: 'plutoAvailsID',
    label: 'Pluto Avails ID',
  },
  {
    name: 'availabilityWindows',
    validators: [
      (availabilityWindows: IAvailbilityWindows): string | undefined => {
        let errorMsg: string | undefined;

        if (!availabilityWindows || isEmpty(availabilityWindows)) {
          return;
        }

        Object.keys(availabilityWindows).forEach(key => {
          const windowType: keyof IAvailbilityWindows = key as keyof IAvailbilityWindows;

          if (!availabilityWindows[windowType] || isEmpty(availabilityWindows[windowType]) || errorMsg) {
            return;
          }

          availabilityWindows[windowType]?.forEach((windowA, iA) => {
            const windowAStart = luxon.fromISO(windowA.startDate as string);
            const windowAEnd = luxon.fromISO(windowA.endDate as string);

            availabilityWindows[windowType]?.forEach((windowB, iB) => {
              if (iA === iB || errorMsg) {
                return;
              }

              const windowBStart = luxon.fromISO(windowB.startDate as string);
              const windowBEnd = luxon.fromISO(windowB.endDate as string);

              if (
                Interval.fromDateTimes(windowAStart, windowAEnd).contains(windowBStart) ||
                Interval.fromDateTimes(windowAStart, windowAEnd).contains(windowBEnd) ||
                Interval.fromDateTimes(windowBStart, windowBEnd).contains(windowAStart) ||
                Interval.fromDateTimes(windowBStart, windowBEnd).contains(windowAEnd)
              ) {
                errorMsg = 'Cannot have overlapping availability windows';
              }
            });
          });
        });

        return errorMsg;
      },
    ],
  },
];
