import * as React from 'react';
import {Sidebar, Toasts, UserPermissionsContext} from '@pluto-tv/assemble';

import {useAppDispatch, useAppSelector} from 'app/store/hooks';
import {BrowserRouter as Router} from 'react-router-dom';
import AppSidebar from 'app/appSidebar/AppSidebar';
import AppRouter from 'app/appRouter/AppRouter';
import {permissionsMap, getAppUserPermissions} from 'app/permissions';

import {getRoutesForUserPermissions} from 'routes';

import {useInfoQuery as useBackendInfoQuery} from 'features/backendInfo/backendInfoApi';
import {useMeQuery} from 'features/users/usersApi';
import {setProfile} from 'features/user/userSlice';

import {removeSplashPage} from 'helpers/removeSplashPage';
import RoleAccessError from 'components/RoleAccessError';

export default (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const {picture = '', name = '', email = ''} = useAppSelector(store => store.user);
  const {data: backendInfo} = useBackendInfoQuery();
  const {data: userProfile, isFetching, isError} = useMeQuery();

  const getRoutes = React.useCallback(() => getRoutesForUserPermissions(userProfile?.permissions || []), [userProfile]);

  React.useLayoutEffect(() => {
    if (!isFetching && !isError) {
      removeSplashPage();
    }
  }, [isFetching, isError]);

  React.useEffect(() => {
    if (userProfile) {
      dispatch(setProfile(userProfile));
    }
  }, [dispatch, userProfile]);

  if (!userProfile) {
    return null;
  }

  const userHasRoles = userProfile.active && userProfile.roles.length > 0;

  return (
    <UserPermissionsContext.Provider value={getAppUserPermissions(userProfile.permissions, permissionsMap)}>
      <Router>
        <Sidebar fullHeight={true} backgroundSidebar='cavern' contentMinWidth='25rem' sideWidth='3rem'>
          <>
            <AppSidebar hasRoles={userHasRoles} getRoutes={getRoutes} picture={picture} name={name} email={email} />
            {userHasRoles ? <AppRouter backendInfo={backendInfo} getRoutes={getRoutes} /> : <RoleAccessError />}
          </>
        </Sidebar>
        <Toasts />
      </Router>
    </UserPermissionsContext.Provider>
  );
};
