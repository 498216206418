import React from 'react';

const useToggleSearchBarOnSlash = (
  setIsSearchExpanded: (x: boolean) => void,
  isSearchExpanded: boolean,
  disabled?: boolean,
): void => {
  React.useEffect(() => {
    const keyDownHandler = event => {
      if (!disabled) {
        if (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) {
          return;
        }

        if (event.key === '/' || event.keyCode === 191) {
          event.preventDefault();
          event.stopPropagation();
          setIsSearchExpanded(!isSearchExpanded);
        }
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [isSearchExpanded, disabled, setIsSearchExpanded]);
};

export default useToggleSearchBarOnSlash;
