import {IValidatorField} from '@pluto-tv/assemble';
import {isNumber} from 'lodash-es';
import {IEpisode, IEpisodeSource, IListEpisodeQuery, ISelectedCategory} from 'models/episodes';

export const searchEpisodeValidator: IValidatorField<IListEpisodeQuery>[] = [
  {
    name: 'name',
    label: 'Episode Name',
  },
  {
    name: 'season',
    label: 'Number of Seasons',
  },
];

const episodeSimpleDetails: IValidatorField<IEpisode>[] = [
  {
    name: 'name',
    label: 'Episode Name',
    required: true,
    validators: [
      (name: string): string | undefined => {
        if (!name || !name.trim().length) {
          return 'Episode Name is required';
        } else if (name && name.trim().length > 100) {
          return 'Episode Name must be less than 100 characters';
        }
      },
    ],
  },
  {
    name: 'season',
    label: 'Season Number',
    required: true,
    validators: [
      (season: number): string | undefined => {
        if (!isNumber(season)) {
          return 'Season Number is required';
        }
      },
    ],
  },
  {
    name: 'number',
    label: 'Episode Number',
    required: true,
    validators: [
      (number: number): string | undefined => {
        if (!isNumber(number)) {
          return 'Episode Number is required';
        } else if (number < 1) {
          return 'Episode number must be greater than 0';
        }
      },
    ],
  },
  {
    name: 'metadataLanguage',
    label: 'Metadata Language',
    required: true,
    validators: [
      (metadataLanguage: string): string | undefined => {
        if (!metadataLanguage) {
          return 'Metadata Language is required';
        }
      },
    ],
  },
  {
    name: 'summary',
    label: 'Short Description',
    required: true,
    validators: [
      (summary: string): string | undefined => {
        if (!summary || !summary.trim().length) {
          return 'Short Description is required';
        }
      },
    ],
  },
  {
    name: 'displayName',
    label: 'Episode Display Name',
    required: false,
    validators: [
      (displayName: string): string | undefined => {
        if (displayName && displayName.trim().length > 100) {
          return 'Episode Display Name must be less than 100 characters';
        }
      },
    ],
  },
];

export const episodeCreateValidator: IValidatorField<IEpisode>[] = [
  ...episodeSimpleDetails,
  {
    name: 'allotment',
    label: 'Allotment',
    required: true,
    validators: [
      (allotment: number): string | undefined => {
        if (!isNumber(allotment)) {
          return 'Allotment is required';
        } else if (allotment < 5) {
          return 'Allotment must be at least 5 seconds';
        }
      },
    ],
  },
];

export const episodeGlobalValidator: IValidatorField<IEpisode>[] = [
  {
    name: 'status',
  },
  {
    name: 'published',
  },
];

export const episodeDetailsValidator: IValidatorField<IEpisode>[] = [
  ...episodeSimpleDetails,
  {
    name: 'displayOnGuide',
    label: 'Display On Guide',
    required: true,
  },
  {
    name: 'summary',
    label: 'Short Description',
    required: true,
    validators: [
      (summary: string): string | undefined => {
        if (!summary || !summary.trim().length) {
          return 'Episode Short Description is required';
        }
      },
    ],
  },
  {
    name: 'description',
    label: 'Long Description',
    required: true,
    validators: [
      (description: string): string | undefined => {
        if (!description || !description.trim().length) {
          return 'Episode Long Description is required';
        }
      },
    ],
  },
  {
    name: 'season',
    label: 'Season Number',
    required: true,
    validators: [
      (season: number, model: Partial<IEpisode>): string | undefined => {
        if (!(model.series?.seasons || []).some(s => s.number === season)) {
          if (!Number.isFinite(season)) {
            return `Cannot find season ${model.season} in series`;
          } else if (season < 0) {
            return 'Cannot be less than 0';
          } else {
            return 'Season is required';
          }
        }
      },
    ],
  },
  {
    name: 'number',
    label: 'Episode Number',
    required: true,
    validators: [
      (num: number): string | undefined => {
        if (typeof num != 'number') {
          return 'Episode number must be a valid number';
        } else if (num < 0) {
          return 'Episode number cannot be a negative number';
        }
      },
    ],
  },
  {
    name: 'rating',
    label: 'Rating',
  },
  {
    name: 'premiere',
    label: 'Pluto TV Premiere Date & Time',
  },
  {
    name: 'ratingDescriptors',
    label: 'Rating Descriptors',
  },
  {
    name: 'genre',
    label: 'Genre',
    required: true,
  },
  {
    name: 'subGenre',
    label: 'Sub-Genre',
    required: true,
    validators: [
      (subGenre: string): string | undefined => {
        if (!subGenre || !subGenre.length) {
          return 'Sub-Genre is required';
        }
      },
    ],
  },
  {
    name: 'category',
    label: 'Category',
    required: true,
  },
  {
    name: 'subCategory',
    label: 'Sub-Category',
    required: true,
    validators: [
      (subCategory: string): string | undefined => {
        if (!subCategory || !subCategory.length) {
          return 'Sub-Category is required';
        }
      },
    ],
  },
  {
    name: 'plutoTvOO',
    label: 'Pluto TV O&O',
    required: true,
  },
  {
    name: 'metadataLanguage',
    label: 'Metadata Language',
  },
  {
    name: 'notes',
    label: 'Notes',
  },
];

export const episodeSettingsValidator: IValidatorField<IEpisode>[] = [
  {
    name: 'regionFilter',
    label: 'Territories',
  },
  {
    name: 'distributeAs',
  },
  {
    name: 'customReferences',
  },
  {
    name: 'isFeed',
  },
  {
    name: 'slug',
    label: 'Episode Slug',
  },
  {
    name: 'tmsid',
    label: 'TMS ID',
  },
  {
    name: 'dashEnabled',
    label: 'Dash Enabled',
  },
  {
    name: 'tags',
    label: 'Tags List',
    required: true,
    validators: [
      (tags: string[]): string | undefined => {
        if (!tags || !tags.length) {
          return 'Episode must have tags assigned';
        }
      },
    ],
  },
];

export const episodeSchedulingValidator: IValidatorField<IEpisode>[] = [
  {
    name: 'vodCategoryEntries',
    label: 'Vod Category Entries',
  },
];

export const episodeVideoValidator: IValidatorField<IEpisode>[] = [
  {
    name: 'sources',
    validators: [
      (sources: IEpisodeSource[]): string | undefined => {
        if (
          (sources || []).some(source => {
            if (source.inPoint >= source.outPoint) {
              return true;
            }

            return false;
          })
        ) {
          return 'In Point must be before Out Point';
        }
      },
    ],
  },
  {
    name: 'duration',
  },
  {
    name: 'allotment',
  },
];

export const episodeAssetsValidator: IValidatorField<IEpisode>[] = [
  {
    name: 'screenshot16_9',
  },
  {
    name: 'screenshot4_3',
  },
  {
    name: 'poster',
  },
  {
    name: 'poster16_9',
  },
];

export const episodeVodCategoryValidator: IValidatorField<ISelectedCategory>[] = [
  {
    name: 'id',
  },
  {
    name: 'category',
    label: 'Collection name',
    required: true,
    validators: [
      (category: string): string | undefined => {
        if (!category || !category.trim().length) {
          return 'Category is required';
        }
      },
    ],
  },
  {
    name: 'order',
    label: 'Order',
    required: true,
    validators: [
      (num: number): string | undefined => {
        if (typeof num != 'number') {
          return 'Category order must be a valid number';
        } else if (num < 1) {
          return 'Category order must be a positive number';
        }
      },
    ],
  },
];
