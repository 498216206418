import * as React from 'react';
import {first, last, upperCase} from 'lodash-es';
import {Heading, ImageWrapper} from '@pluto-tv/assemble';

interface ISimpleAvatar {
  name?: string;
  src?: string;
  sidebar?: boolean;
}

// 'PL' for "PLuto"
const PLUTO_INITIALS = 'PL';

const pickColor = (str = PLUTO_INITIALS) => {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  return `hsl(${hash % 360}, 100%, 30%)`;
};

const getInitials = (name: string): string => {
  const nameArr = name.split(' ');

  if (!nameArr || !nameArr.length || nameArr.length === 1) {
    return PLUTO_INITIALS;
  }

  return upperCase(`${first(nameArr)!.charAt(0)}${last(nameArr)!.charAt(0)}`);
};

export const SimpleAvatar = React.memo(({name = '', src, sidebar = false}: ISimpleAvatar) => {
  const [initials, setInitials] = React.useState(getInitials(name));
  const [showImg, setShowImg] = React.useState(!!src);
  const [inlineStyle, setInlineStyle] = React.useState<React.CSSProperties>({
    maxWidth: 'unset',
    borderRadius: '50%',
  });

  React.useEffect(() => {
    setShowImg(!!src);
  }, [src]);

  React.useEffect(() => {
    setInlineStyle(prevVals => ({
      ...prevVals,
      ...(!showImg && {
        height: sidebar ? '1.875rem' : '1.5625rem',
        width: sidebar ? '1.875rem' : '1.5625rem',
      }),
    }));
  }, [sidebar, showImg]);

  React.useEffect(() => {
    setInitials(getInitials(name));
  }, [name]);

  return (
    <div
      style={{
        ...inlineStyle,
        backgroundColor: pickColor(initials),
        overflow: 'hidden',
        ...(!showImg && {
          textAlign: 'center',
          paddingTop: '0.5rem',
        }),
      }}
    >
      {showImg ? (
        <ImageWrapper
          height={sidebar ? '1.875rem' : '1.5625rem'}
          width={sidebar ? '1.875rem' : '1.5625rem'}
          onError={() => setShowImg(false)}
          src={src}
          alt={initials}
          spinnerSize='xxsmall'
        />
      ) : (
        <Heading level={sidebar ? 'h3' : 'h5'}>{initials}</Heading>
      )}
    </div>
  );
});
