import {AllStates, ISelectOption, Select, Toast} from '@pluto-tv/assemble';
import {IChannelQuery, useLazyFindLiteQuery} from 'features/channels/channelsApi';

import * as React from 'react';

interface IAutocompleteProps {
  value?: string;
  onChange: (value?: string) => void;
  activeRegions: string[];
  state?: AllStates;
}

const Autocomplete = ({value, onChange, activeRegions, state}: IAutocompleteProps): React.ReactElement => {
  const [searchChannels] = useLazyFindLiteQuery();
  const [channelValue, setChannelValue] = React.useState<ISelectOption | null>(null);

  const handleChange = (value?: ISelectOption) => {
    setChannelValue(value || null);
    onChange(value?.value);
  };

  React.useEffect(() => {
    // Keep in sync external model and local value
    if (value === undefined) {
      setChannelValue(null);
    }
  }, [value]);

  const handleChannelsSearch = async (search: string): Promise<ISelectOption[]> => {
    if (!activeRegions.length || !search) {
      return [];
    }

    const params: IChannelQuery = {
      name: search,
      activeRegions,
      archived: false,
    };
    try {
      const response = await searchChannels(params);
      return (
        response?.data?.data.map(channel => ({
          label: `${channel.name}${channel.activeRegion ? ' (' + channel.activeRegion.toUpperCase() + ')' : ''}`,
          value: channel.id,
        })) || []
      );
    } catch (error) {
      Toast.error('Error fetching channels');
      return [];
    }
  };

  return (
    <Select
      id='includesChannel'
      state={state}
      clearable={true}
      predicate='value'
      placeholder='Select'
      searchable={true}
      sortField='label'
      value={channelValue || undefined}
      onSearch={handleChannelsSearch}
      options={[]}
      autoComplete
      onChange={handleChange}
    />
  );
};

export default Autocomplete;
