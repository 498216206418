import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {IBackendInfo} from 'models/backendInfo';

export const backendInfoApi = createApi({
  reducerPath: 'backendInfoApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/v1/healthcheck',
  }),
  endpoints(builder) {
    return {
      info: builder.query<IBackendInfo, void>({
        query: () => ({
          url: `/info`,
          method: 'GET',
        }),
      }),
    };
  },
});

export const {useInfoQuery} = backendInfoApi;
