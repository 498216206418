import * as React from 'react';

import {useMeQuery} from 'features/users/usersApi';
import {ISeries} from 'models/series';

interface ISeriesListPermissions {
  canViewOO: boolean;
  canViewPartner: boolean;
}

export const useSeriesListPermissions = (): ISeriesListPermissions => {
  const {data: userProfile, isFetching, isError} = useMeQuery();

  const [canViewOO, setCanViewOO] = React.useState<boolean>(false);
  const [canViewPartner, setCanViewPartner] = React.useState<boolean>(false);

  const reset = () => {
    setCanViewOO(false);
    setCanViewPartner(false);
  };

  const checkPermissions = () => {
    if (!userProfile) {
      reset();
      return;
    }

    setCanViewOO((userProfile.permissions || []).find(perm => perm === 'series_view_oo') ? true : false);
    setCanViewPartner((userProfile.permissions || []).find(perm => perm === 'series_view_partner') ? true : false);
  };

  React.useEffect(() => {
    if (isError) {
      reset();
      return;
    }

    checkPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  React.useEffect(() => {
    if (!isFetching && userProfile) {
      checkPermissions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile, isFetching]);

  return {
    canViewOO,
    canViewPartner,
  };
};

interface ISeriesPermissions {
  CAN_VIEW: boolean;
  CAN_EDIT: boolean;
  CAN_DELETE: boolean;
  editPermission: 'enabled' | 'disabled';
  canCreateOO: boolean;
  canCreatePartner: boolean;
  canEditOO: boolean;
  canEditPartner: boolean;
}

export const useSeriesPermissions = (series?: Partial<ISeries>): ISeriesPermissions => {
  const {data: userProfile, isFetching, isError} = useMeQuery();

  const [canView, setCanView] = React.useState<boolean>(true);
  const [canEdit, setCanEdit] = React.useState<boolean>(false);
  const [canDelete, setCanDelete] = React.useState<boolean>(false);

  const [canCreateOO, setCanCreateOO] = React.useState<boolean>(false);
  const [canCreatePartner, setCanCreatePartner] = React.useState<boolean>(false);
  const [canEditOO, setCanEditOO] = React.useState<boolean>(false);
  const [canEditPartner, setCanEditPartner] = React.useState<boolean>(false);

  const editPermission = React.useMemo((): 'enabled' | 'disabled' => (canEdit ? 'enabled' : 'disabled'), [canEdit]);

  const reset = () => {
    setCanView(true);
    setCanEdit(false);
    setCanDelete(false);
  };

  const resetCanCreateEdit = () => {
    setCanCreateOO(false);
    setCanCreatePartner(false);
    setCanEditOO(false);
    setCanEditPartner(false);
  };

  const checkPermissions = () => {
    if (!userProfile) {
      reset();
      resetCanCreateEdit();
      return;
    }

    setCanCreateOO((userProfile.permissions || []).find(perm => perm === 'series_create_oo') ? true : false);
    setCanCreatePartner((userProfile.permissions || []).find(perm => perm === 'series_create_partner') ? true : false);
    setCanEditOO((userProfile.permissions || []).find(perm => perm === 'series_edit_oo') ? true : false);
    setCanEditPartner((userProfile.permissions || []).find(perm => perm === 'series_edit_partner') ? true : false);

    if (!series) {
      reset();
      return;
    }

    const isOO = series.plutoTvOO;

    setCanView(
      (userProfile.permissions || []).find(perm => perm === (isOO ? 'series_view_oo' : 'series_view_partner'))
        ? true
        : false,
    );
    setCanEdit(
      (userProfile.permissions || []).find(perm => perm === (isOO ? 'series_edit_oo' : 'series_edit_partner'))
        ? true
        : false,
    );
    setCanDelete(
      (userProfile.permissions || []).find(perm => perm === (isOO ? 'series_delete_oo' : 'series_delete_partner'))
        ? true
        : false,
    );
  };

  React.useEffect(() => {
    if (isError) {
      reset();
      resetCanCreateEdit();
      return;
    }

    checkPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  React.useEffect(() => {
    if (!isFetching && userProfile) {
      checkPermissions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile, isFetching, series]);

  return {
    CAN_EDIT: canEdit,
    CAN_VIEW: canView,
    CAN_DELETE: canDelete,
    editPermission,
    canCreateOO,
    canCreatePartner,
    canEditOO,
    canEditPartner,
  };
};
