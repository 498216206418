import * as React from 'react';
import {Select, AllStates, Grid} from '@pluto-tv/assemble';
import {generateYears, monthNames} from '../utils/dateUtils';

interface IMonthYearPickerProps {
  value: {month: string; year: string};
  onChange: (value: {month: string; year: string}) => void;
  state?: AllStates;
}

const yearsCount = 16;
const currentYear = new Date().getFullYear();

const yearsOptions = generateYears(currentYear, currentYear + yearsCount).map(year => ({label: year}));
const monthOptions = monthNames.map(month => ({label: month}));

const MonthYearPicker = ({value, onChange, state}: IMonthYearPickerProps): React.ReactElement => {
  const valueRef = React.useRef<{month: string; year: string}>({month: '', year: ''});

  React.useEffect(() => {
    valueRef.current = {...value};
  }, [value]);

  const handleMonthChange = value => {
    onChange({...valueRef.current, month: value.label});
  };

  const handleYearChange = value => {
    onChange({...valueRef.current, year: value.label});
  };

  return (
    <Grid cols={2} gap='medium'>
      <Select
        state={state}
        id='month'
        value={{label: value.month}}
        predicate='label'
        placeholder='Select'
        options={monthOptions}
        onChange={handleMonthChange}
      />
      <Select
        state={state}
        id='year'
        value={{label: value.year}}
        predicate='label'
        placeholder='Select'
        options={yearsOptions}
        onChange={handleYearChange}
      />
    </Grid>
  );
};

export default MonthYearPicker;
