import * as React from 'react';
import {useDeleteMutation, useLazyFindEpisodesByIdQuery} from 'features/clips/clipsApi';

export class ClipReferencesError extends Error {
  constructor(message = '') {
    super(message);
    this.name = 'ClipReferencesError';
    Object.setPrototypeOf(this, ClipReferencesError.prototype);
  }
}

export class ClipNotFoundError extends Error {
  constructor(message = '') {
    super(message);
    this.name = 'ClipNotFoundError';
    Object.setPrototypeOf(this, ClipNotFoundError.prototype);
  }
}

interface IClipDelete {
  handleDelete: (clipId: string) => Promise<void>;
}

const useClipDelete = (): IClipDelete => {
  const [deleteClip] = useDeleteMutation();
  const [trigger] = useLazyFindEpisodesByIdQuery();

  const checkClipReferences = React.useCallback(
    async (clipId: string) => {
      try {
        const {metadata} = await trigger({id: clipId, offset: 0, limit: 1}).unwrap();
        if (metadata.totalCount > 0) throw new ClipReferencesError();
      } catch (error: any) {
        if (error.status === 404) return;
        throw error;
      }
    },
    [trigger],
  );

  const handleDelete = React.useCallback(
    async (clipId: string) => {
      try {
        await checkClipReferences(clipId);
        await deleteClip(clipId).unwrap();
      } catch (error: any) {
        if (error.status === 404) throw new ClipNotFoundError();
        throw error;
      }
    },
    [checkClipReferences, deleteClip],
  );

  return {handleDelete};
};

export default useClipDelete;
