import {IValidatorField} from '@pluto-tv/assemble';
import {IUser} from 'models/users';

export const userValidator: IValidatorField<IUser>[] = [
  {
    name: 'displayName',
    label: 'Full Name',
    required: true,
    validators: [
      (name: string): string | undefined => {
        if (!name || !name.trim().length) {
          return 'Name is required';
        } else if (name && name.trim().length > 100) {
          return 'Name must be less than 100 characters';
        }
      },
    ],
  },
  {
    name: 'email',
    label: 'Email',
  },
  {
    name: 'active',
  },
  {
    name: 'roles',
    label: 'Role',
  },
  {
    name: 'regions',
    label: 'Region(s)',
  },
];
