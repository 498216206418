import * as React from 'react';
import {useFindQuery as useFindContentRatingsQuery} from 'features/contentRatings/contentRatingsApi';
import {IContentRating} from 'models/contentRatings';

export const useRatingsByRegion = (
  activeRegion = '',
): {
  ratings: IContentRating[];
  isFetching: boolean;
  isError: boolean;
} => {
  const {data, isFetching, isError} = useFindContentRatingsQuery();

  const ratingsForActiveRegion = React.useMemo(() => {
    if (!data) {
      return [];
    }

    return data?.filter(rating => rating.region?.toUpperCase() === activeRegion.toUpperCase());
  }, [activeRegion, data]);

  return {ratings: ratingsForActiveRegion, isFetching, isError};
};
