import * as React from 'react';
import {
  Box,
  Button,
  ContentBoxes,
  ContentBox,
  Cluster,
  FormItem,
  Heading,
  ITableCol,
  Popover,
  Select,
  Stack,
  Status,
  Table,
  TdLink,
  Template,
  TextInput,
  useValidateForm,
} from '@pluto-tv/assemble';

import {useAppPermissions} from 'app/permissions';
import {TableActions} from 'components/tableActions';

import {INestedSeriesProps} from '../nestedPropsInterface';
import {IChannel, ISeriesVodCategoryEntry} from 'models/series';
import vodCollectionRoutes from 'routes/programming.routes';

import {useLazyFindQuery as useFindVodCategoriesQuery} from 'features/vodCollections/vodCollectionsApi';
import {useLazyFindLiteQuery as useFindChannelsQuery} from 'features/channels/channelsApi';
import {ISelectedCategory, ISelectedChannel} from 'models/episodes';
import {seriesChannelsValidator, seriesVodCategoryValidator} from 'views/content/series/validators';
import {useSeriesPermissions} from 'views/content/series/permissions/useSeriesPermissions';
import {IVodCategory} from 'models/vodCategories';

interface IPopover {
  add?: boolean;
  edit?: boolean;
  id?: string;
}

export default ({model, pristineModel, setFields}: INestedSeriesProps): JSX.Element => {
  const {ableTo, permissions} = useAppPermissions();
  const {CAN_EDIT, editPermission} = useSeriesPermissions(pristineModel);

  const [vodCategories, setVodCategories] = React.useState<ISeriesVodCategoryEntry[]>([]);
  const [vodCategoriesOpen, setVodCategoriesOpen] = React.useState<IPopover>({});

  const [searchVodCategories, vodCategoryItems] = useFindVodCategoriesQuery();
  const [searchChannels, {data: channelsItems, isSuccess: isChannelsItemsSuccess, isLoading: isChannelsItemsLoading}] =
    useFindChannelsQuery();
  const [vodCategoriesDictionary, setVodCategoriesDictionary] = React.useState<{[key: string]: IVodCategory}>({});

  React.useEffect(() => {
    if (model.id) {
      searchVodCategories({limit: 1000, activeRegions: [model.activeRegion as string], enabled: true});
      searchChannels({limit: 1000, activeRegion: model.activeRegion, archived: false});
    }
  }, [model.activeRegion, model.id, searchChannels, , searchVodCategories]);

  React.useEffect(() => {
    if (vodCategoryItems.isSuccess) {
      setVodCategoriesDictionary(
        vodCategoryItems?.data?.data?.reduce((dic, cat) => {
          dic[cat.id] = cat;
          return dic;
        }, {}),
      );
    }
  }, [vodCategoryItems]);

  const {
    form: selectedCategoryForm,
    model: selectedCategoryModel,
    setFields: selectedCategorySetFields,
    state: formState,
    reset: selectedCategoryReset,
  } = useValidateForm<ISelectedCategory>(seriesVodCategoryValidator);

  const handleCancelAddOrUpdateCategory = () => {
    setVodCategoriesOpen({});
    selectedCategoryReset();
  };

  const handleAddOrUpdateCategoryEntry = () => {
    if (!selectedCategoryModel.category) {
      return;
    }

    const selectedVodCategory = vodCategoriesDictionary[selectedCategoryModel.category];

    if (vodCategoriesOpen.add) {
      setFields({
        vodCategoryEntries: [
          ...(vodCategories || []),
          {
            id: `new-${Date.now()}`,
            vodCategory: selectedVodCategory,
            order: selectedCategoryModel.order || 1,
            createdAt: new Date(),
            updatedAt: new Date(),
            series: model.id || '',
            changeType: 'I',
          },
        ],
      });
    } else if (vodCategoriesOpen.edit && vodCategoriesOpen.id) {
      setFields({
        vodCategoryEntries: (vodCategories || []).map(vod => {
          if (vod.id !== selectedCategoryModel?.id) {
            return vod;
          }
          return {
            ...vod,
            order: selectedCategoryModel?.order || 0,
            vodCategory: selectedVodCategory,
            updatedAt: new Date(),
            changeType: vodCategoriesOpen.id?.includes('new-') ? 'I' : 'U',
          };
        }),
      });
    }

    setVodCategoriesOpen({});
    selectedCategoryReset();
  };

  const handleEdit = (vodCategory: ISeriesVodCategoryEntry) => {
    setVodCategoriesOpen({edit: true, id: vodCategory.id});
    selectedCategorySetFields({
      id: vodCategory.id,
      category: vodCategory.vodCategory.id,
      order: vodCategory.order,
    });
  };

  const handleDelete = (vodCategory: ISeriesVodCategoryEntry) => {
    const cts = (vodCategories || [])
      .map(voce => {
        if (voce.id !== vodCategory?.id) {
          return voce;
        }

        return voce.changeType === 'I' ? {...voce, changeType: 'X'} : {...voce, changeType: 'D'};
      })
      .filter(voce => voce.changeType !== 'X');

    setFields({
      vodCategoryEntries: cts as ISeriesVodCategoryEntry[],
    });
    setVodCategoriesOpen({});
    selectedCategoryReset();
  };

  // CHANNELS SECTION
  const [channels, setChannels] = React.useState<IChannel[]>([]);
  const [channelsOpen, setChannelsOpen] = React.useState<IPopover>({});
  const [channelsDictionary, setChannelsDictionary] = React.useState<{[key: string]: IChannel}>({});

  React.useEffect(() => {
    if (isChannelsItemsSuccess) {
      setChannelsDictionary(
        channelsItems?.data.reduce((dic, cat) => {
          dic[cat.id] = cat;
          return dic;
        }, {}) || {},
      );
    }
  }, [channelsItems, isChannelsItemsSuccess]);

  const {
    form: selectedChannelForm,
    model: selectedChannelModel,
    setFields: selectedChannelSetFields,
    state: channelFormState,
    reset: selectedChannelReset,
  } = useValidateForm<ISelectedChannel>(seriesChannelsValidator);

  const handleDeleteChannel = (channel: IChannel) => {
    const channnelsUpdated = (channels || []).filter(ch => ch.id !== channel.id);

    setFields({channels: channnelsUpdated});
    selectedChannelReset();
    setChannelsOpen({});
  };

  const handleAddOrUpdateChannels = () => {
    if (!selectedChannelModel.channel) {
      return;
    }

    const selectedChannel = channelsDictionary[selectedChannelModel.channel];

    if (channelsOpen.add) {
      setFields({
        channels: [
          ...(channels || []),
          {
            ...selectedChannel,
          },
        ],
      });
    }

    setChannelsOpen({});
    selectedChannelReset();
  };

  React.useEffect(() => {
    if (!model) {
      return;
    }

    const vodCat: ISeriesVodCategoryEntry[] = [];
    const chan: IChannel[] = [];

    model.vodCategoryEntries?.forEach(
      vodCategory =>
        vodCategory.vodCategory &&
        vodCat.push({
          ...vodCategory,
        }),
    );

    model.channels?.forEach(
      channel =>
        channel.name &&
        chan.push({
          ...channel,
        }),
    );

    setVodCategories(vodCat);
    setChannels(chan);
  }, [model]);

  return (
    <ContentBoxes layout='stack'>
      <ContentBox>
        <Template label='header'>
          <Cluster justify='space-between' align='center'>
            <Heading level='h3' color='secondary'>
              VOD Collections
            </Heading>
            {CAN_EDIT && (
              <Popover
                manualTrigger={true}
                visible={vodCategoriesOpen.add || (vodCategoriesOpen.edit && !!vodCategoriesOpen.id)}
                onClickOutside={() => setVodCategoriesOpen({})}
                permission={editPermission && permissions.VOD_EDIT}
              >
                <Template label='trigger'>
                  <Button
                    type='primary'
                    permission={editPermission && permissions.VOD_EDIT}
                    onClick={() => setVodCategoriesOpen({add: true})}
                    state={
                      vodCategoryItems.isLoading
                        ? 'thinking'
                        : (vodCategoryItems?.error as any)?.status === 404
                        ? 'disabled'
                        : ''
                    }
                  >
                    + Add
                  </Button>
                </Template>
                <Template label='popover'>
                  <Box padding='small' background='charcoal' width='100%'>
                    <Stack space='small'>
                      <FormItem label='Collection Name' {...selectedCategoryForm.category}>
                        <Select
                          width='220px'
                          placeholder={
                            Object.keys(vodCategoriesDictionary).length > 0 ? 'Collection Name' : 'No VOD Collections'
                          }
                          id='category'
                          predicate='value'
                          onChange={value => selectedCategorySetFields({category: value.value})}
                          value={{
                            label: selectedCategoryModel.category
                              ? vodCategoriesDictionary[selectedCategoryModel.category]?.name ?? ''
                              : '',
                            value: selectedCategoryModel.category,
                          }}
                          options={Object.keys(vodCategoriesDictionary)
                            .filter(
                              vci =>
                                !vodCategories.find(
                                  existingVod =>
                                    existingVod.vodCategory.id === vci &&
                                    existingVod.vodCategory.id !== selectedCategoryModel.category,
                                ),
                            )
                            .map(vci => ({
                              label: vodCategoriesDictionary[vci].name,
                              value: vci,
                            }))}
                          searchable={!!Object.keys(vodCategoriesDictionary).length}
                          searchPlaceholder='Search Categories'
                          onSearch={val =>
                            Object.keys(vodCategoriesDictionary)
                              .filter(
                                vodCat =>
                                  vodCat === vodCategoriesOpen.id ||
                                  !vodCategories.some(existing => existing.vodCategory.id === vodCat),
                              )
                              .map(vci => ({
                                label: vodCategoriesDictionary[vci].name,
                                value: vci,
                              }))
                              .filter(vc => vc.label.toLowerCase().indexOf(val.toLowerCase()) > -1) || []
                          }
                        />
                      </FormItem>
                      <FormItem label='Order' {...selectedCategoryForm.order}>
                        <TextInput
                          type='number'
                          onChange={value => selectedCategorySetFields({order: value})}
                          value={selectedCategoryModel.order}
                        />
                      </FormItem>
                      <Cluster justify='space-around'>
                        <Button ghost={true} onClick={handleCancelAddOrUpdateCategory}>
                          Cancel
                        </Button>
                        <Button
                          type='primary'
                          state={!formState.isValid || !formState.isDirty ? 'disabled' : ''}
                          onClick={handleAddOrUpdateCategoryEntry}
                        >
                          {vodCategoriesOpen.edit && !!vodCategoriesOpen.id ? 'Update' : '+ Add'}
                        </Button>
                      </Cluster>
                    </Stack>
                  </Box>
                </Template>
              </Popover>
            )}
          </Cluster>
        </Template>
        <Template label='content'>
          <Table
            fixedHeader={true}
            emptyMsg={
              (vodCategoryItems?.error as any)?.status === 404
                ? 'No VOD collections available for the selected active region'
                : 'No VOD Collections'
            }
            cols={[
              {
                label: 'Collection Name',
                colMaxWidth: '25rem',
                transform: row => (
                  <TdLink
                    row={row}
                    title={row.vodCategory.name}
                    target='_blank'
                    url={vodCollectionRoutes.paths.vodCollectionEditProgramPage.replace(':id', row.vodCategory.id)}
                  />
                ),
              },
              {
                label: 'Display Name',
                transform: row => row.vodCategory.displayName,
              },
              {
                label: 'Published',
                colMinWidth: '6.875rem',
                transform: row => (
                  <Status
                    label={row.vodCategory.enabled ? 'Published' : 'Unpublished'}
                    state={row.vodCategory.enabled ? 'success' : 'neutral'}
                  />
                ),
              },
              {
                label: 'Archived',
                transform: row => (row.vodCategory.archived ? 'Yes' : 'No'),
              },
              {
                label: 'Order',
                transform: row => row.order,
              },
              ...(CAN_EDIT && ableTo('VOD_EDIT')
                ? [
                    {
                      label: 'Actions',
                      colWidth: '6.25rem',
                      transform: row => (
                        <TableActions
                          altTitle='collection'
                          row={row}
                          icons={['edit']}
                          deleteOption={ableTo('SERIES_DELETE') && ableTo('VOD_EDIT')}
                          onClick={(row, icon) => {
                            switch (icon) {
                              case 'edit':
                                handleEdit(row);
                                break;
                              case 'delete':
                                handleDelete(row);
                                break;
                              default:
                            }
                          }}
                        />
                      ),
                    } as ITableCol<ISeriesVodCategoryEntry>,
                  ]
                : []),
            ]}
            rows={vodCategories.filter(vod => vod.changeType !== 'D') || []}
          ></Table>
        </Template>
      </ContentBox>
      <ContentBox>
        <Template label='header'>
          <Cluster justify='space-between' align='center'>
            <Heading level='h3'>Channels</Heading>
            {CAN_EDIT && (
              <Popover manualTrigger={true} visible={channelsOpen.add} onClickOutside={() => setChannelsOpen({})}>
                <Template label='trigger'>
                  <Button
                    type='primary'
                    permission={editPermission && permissions.CHANNEL_EDIT}
                    onClick={() => setChannelsOpen({add: true})}
                    state={isChannelsItemsLoading ? 'thinking' : ''}
                  >
                    + Add
                  </Button>
                </Template>
                <Template label='popover'>
                  <Box padding='small' background='charcoal' width='100%'>
                    <Stack space='small'>
                      <FormItem label='Channel Name' {...selectedChannelForm.channel}>
                        <Select
                          width='220px'
                          placeholder={Object.keys(channelsDictionary).length > 0 ? 'Channel Name' : 'No Channels'}
                          id='channel'
                          predicate='value'
                          onChange={value => selectedChannelSetFields({channel: value.value})}
                          value={{
                            label: selectedChannelModel.channel
                              ? channelsDictionary[selectedChannelModel.channel]?.name ?? ''
                              : '',
                            value: selectedChannelModel.channel,
                          }}
                          options={Object.keys(channelsDictionary)
                            .filter(ch => !channels.find(existingCh => existingCh.id === ch))
                            .map(ch => ({
                              label: channelsDictionary[ch].name,
                              value: ch,
                            }))}
                          searchable={!!Object.keys(channelsDictionary).length}
                          searchPlaceholder='Search Channels'
                          onSearch={val =>
                            Object.keys(channelsDictionary)
                              .filter(ch => !channels.some(existing => existing.id === ch))
                              .map(vci => ({
                                label: channelsDictionary[vci].name,
                                value: vci,
                              }))
                              .filter(ch => ch.label.toLowerCase().indexOf(val.toLowerCase()) > -1) || []
                          }
                        />
                      </FormItem>
                      <Cluster justify='space-around'>
                        <Button ghost={true} onClick={() => setChannelsOpen({})}>
                          Cancel
                        </Button>
                        <Button
                          type='primary'
                          state={!channelFormState.isValid || !channelFormState.isDirty ? 'disabled' : ''}
                          onClick={handleAddOrUpdateChannels}
                        >
                          + Add
                        </Button>
                      </Cluster>
                    </Stack>
                  </Box>
                </Template>
              </Popover>
            )}
          </Cluster>
        </Template>
        <Template label='content'>
          <Table
            fixedHeader={true}
            emptyMsg='No Channels'
            cols={[
              {
                label: 'Channel Name',
                colMaxWidth: '25rem',
                transform: row => <TdLink row={row} title={row.name} url={`/channels/${row.id}/catalog`} />,
              },
              {
                label: 'Published',
                colMinWidth: '6.875rem',
                transform: row => (
                  <Status
                    label={row.published ? 'Published' : 'Unpublished'}
                    state={row.published ? 'success' : 'neutral'}
                  />
                ),
              },
              {
                label: 'Archived',
                transform: row => (row.archived ? 'Yes' : 'No'),
              },
              // NOTE: An actively scheduled piece of content cant be removed.
              // we will need to disable the 'Yes, Proceed' button or something
              // this is the case. Let's talk more about this when you get here Joe.
              ...(CAN_EDIT && ableTo('CHANNEL_EDIT')
                ? [
                    {
                      label: 'Actions',
                      colWidth: '3rem',

                      transform: row => (
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                          <TableActions
                            row={row}
                            icons={[]}
                            deleteOption={ableTo('SERIES_DELETE')}
                            onClick={(row, icon) => {
                              switch (icon) {
                                case 'delete':
                                  handleDeleteChannel(row);
                                  break;
                                default:
                              }
                            }}
                          />
                        </div>
                      ),
                    } as ITableCol<IChannel>,
                  ]
                : []),
            ]}
            rows={channels}
          ></Table>
          <Cluster justify='flex-end'></Cluster>
        </Template>
      </ContentBox>
    </ContentBoxes>
  );
};
