import * as React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {cloneDeep, filter, map, times, findIndex, isEqual, throttle, some, find, orderBy} from 'lodash-es';
import {DateTime as luxon} from 'luxon';
import {
  Box,
  Button,
  Cluster,
  Copy,
  Cover,
  FormItem,
  Grid,
  Heading,
  Dialog,
  Paragraph,
  Select,
  Spinner,
  Stack,
  TextInput,
  Template,
  useValidateForm,
  Toast,
  Help,
} from '@pluto-tv/assemble';

import runLimitsRoutes from 'routes/runLimits.routes';

import AddBox from 'components/addBox';
import CrudError from 'components/crudError';
import NotAuthorized from 'components/notAuthorized';

import {useAppPermissions} from 'app/permissions';

import {useFindByIdQuery} from 'features/licensedTitles/licensedTitlesApi';
import {
  useFindByLicensedTitleIdQuery,
  useInsertMutation,
  useUpdateMutation,
} from 'features/runLimitTrackers/runLimitTrackersApi';

import {useUserRegionModel} from 'helpers/useUserRegions';

import {IEditRunLimits, IRunLimitLicensed, IRunLimitObj} from 'models/licensedTitles';

import {createRunLimitValidator, editRunLimitValidator, licensedTitleValidator} from '../validators';
import RunLimit from '../components/run-limit';
import ConfirmRouteChange from 'components/confirmRouteChange';

const VALID_YEARS = map(times(10, num => `${2022 + num}`));
const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export default (): JSX.Element => {
  const history = useHistory();
  const {id}: {id: string} = useParams();
  const {ableTo, permissions} = useAppPermissions();

  const [insertRunLimit] = useInsertMutation();
  const [updateRunLimits] = useUpdateMutation();

  const [selectedYear, setSelectedYear] = React.useState<string>(`${new Date().getFullYear()}`);
  const [visibleRunLimits, setVisibleRunLimits] = React.useState<IRunLimitObj[]>([]);

  const cantModify = !ableTo('LICENSED_TITLES_EDIT');

  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  const [isEditSelectedOpen, setIsEditSelectedOpen] = React.useState(false);
  const [isAddOpen, setIsAddOpen] = React.useState(false);

  const [canModify, setCanModify] = React.useState(false);

  const [selected, setSelected] = React.useState<IRunLimitObj[]>([]);
  const [selectedNum, setSelectedNum] = React.useState<number>(0);
  const [isLeaving, setIsLeaving] = React.useState<boolean>(false);

  const {data: item, isError, error, isFetching} = useFindByIdQuery(id, {refetchOnMountOrArgChange: true});

  const {
    data: runLimitData,
    isError: isRunLimitsError,
    isFetching: isRunLimitsFetching,
    isLoading: isRunLimitsLoading,
  } = useFindByLicensedTitleIdQuery(id, {refetchOnMountOrArgChange: true, skip: isSaving});

  const canViewRegion = useUserRegionModel(item);

  const {
    model,
    setFields,
    state: formState,
    setModel,
  } = useValidateForm<IEditRunLimits>(licensedTitleValidator, 'onBlur');

  const {
    model: createModel,
    form: createForm,
    onChange: createOnChange,
    setFields: createSetFields,
    onBlur: createOnBlur,
    state: createFormState,
    setModel: createSetModel,
    reset: createReset,
  } = useValidateForm<IRunLimitLicensed>([...createRunLimitValidator, ...editRunLimitValidator]);

  const {
    model: editModel,
    form: editForm,
    onChange: editOnChange,
    onBlur: editOnBlur,
    state: editFormState,
    setModel: editSetModel,
    reset: editReset,
  } = useValidateForm<IRunLimitObj>(editRunLimitValidator);

  React.useEffect(() => {
    if (isLeaving) {
      history.push(runLimitsRoutes.paths.licensedTitlesListPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLeaving]);

  const cancelHandler = () => {
    setIsLeaving(true);
  };

  const runLimitSelected = (isSelected: boolean, index: number) => {
    const allSelected = cloneDeep(selected);

    if (isSelected) {
      allSelected[index] = visibleRunLimits[index];
    } else {
      delete allSelected[index];
    }

    setSelected(allSelected);
    setSelectedNum(filter(allSelected).length);
  };

  React.useEffect(() => {
    setModel({
      runLimits: runLimitData?.data ? orderBy(runLimitData.data, a => a.startDate.seconds) : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runLimitData]);

  React.useEffect(() => {
    let runLimits: IRunLimitObj[] = [];

    if (model?.runLimits && model.runLimits.length > 0) {
      runLimits = model.runLimits.filter(
        rl => luxon.fromSeconds(rl.startDate.seconds).setZone('America/Los_Angeles').toFormat('yyyy') === selectedYear,
      );
    }

    setVisibleRunLimits(runLimits);
  }, [selectedYear, model]);

  React.useEffect(() => {
    const now = luxon.now();
    const selected = luxon.fromFormat(`${selectedYear}`, 'yyyy').endOf('year');

    setCanModify(selected > now && ableTo('LICENSED_TITLES_EDIT'));
    setSelected([]);
    setSelectedNum(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  React.useEffect(() => {
    if (!isAddOpen) {
      if (createFormState.isDirty) {
        createReset();
      }
      createSetModel({
        activeYear: parseInt(selectedYear, 10),
      });
    }
    if (!isEditSelectedOpen) {
      if (editFormState.isDirty) {
        editReset();
      }
      editSetModel({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddOpen, isEditSelectedOpen, selectedYear, createReset, editReset]);

  const saveHandler = async () => {
    if (isSaving || cantModify) {
      return;
    }

    setIsSaving(true);

    try {
      const newRunLimits: IRunLimitObj[] = [];
      const updatedRunLimits: IRunLimitObj[] = [];

      (model.runLimits || []).forEach(rl => {
        if (!rl.id) {
          newRunLimits.push(rl);
        } else {
          const original = find(runLimitData?.data || [], oRl => oRl.id === rl.id);

          if (original && !isEqual(rl, original)) {
            updatedRunLimits.push(rl);
          }
        }
      });

      const promises: Promise<unknown>[] = [];

      newRunLimits.forEach(rl => promises.push(insertRunLimit(rl).unwrap()));
      promises.push(updateRunLimits(updatedRunLimits).unwrap());

      await Promise.all(promises);

      Toast.success('Success', 'Licensed Title Updated');
    } catch (e) {
      Toast.error('Error saving', 'Please try again');
    } finally {
      setIsSaving(false);
    }
  };

  const updateFromCard = throttle(
    (val: IRunLimitObj, index: number) => {
      const runLimits = cloneDeep(model.runLimits || []);
      const runLimitIndex = findIndex(runLimits, rl => isEqual(rl, visibleRunLimits[index]));

      if (runLimits && runLimits[runLimitIndex]) {
        runLimits.splice(runLimitIndex, 1, {
          ...runLimits[runLimitIndex],
          ...val,
        });
      }

      setFields({runLimits});
    },
    500,
    {leading: false, trailing: true},
  );

  const updateMultiple = () => {
    const runLimits = cloneDeep(model.runLimits || []);

    selected.forEach(val => {
      if (!val) {
        return;
      }

      const runLimitIndex = findIndex(runLimits, rl => isEqual(rl, val));

      if (runLimits && runLimits[runLimitIndex]) {
        runLimits.splice(runLimitIndex, 1, {
          ...val,
          licenseFee: editModel.licenseFee ?? val.licenseFee,
          maxRunsPeriod: editModel.maxRunsPeriod ?? val.maxRunsPeriod,
        });
      }
    });

    setFields({runLimits});
    setIsEditSelectedOpen(false);
    setSelected([]);
    setSelectedNum(0);
  };

  if (isFetching || !item || !item.id || isRunLimitsLoading) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} size='xlarge' />
      </Box>
    );
  }

  if (!ableTo('LICENSED_TITLES_VIEW') || !canViewRegion) {
    return <NotAuthorized />;
  }

  if (isError || isRunLimitsError) {
    return <CrudError error={error} />;
  }

  return (
    <>
      <Cover
        scrolling={true}
        gutter='large'
        coverTemplateHeight='100%'
        paddingX={{mobile: 'medium', wide: 'large'}}
        paddingTop={{mobile: 'medium', wide: 'large'}}
      >
        <Template label='header'>
          <Heading level='h1' truncate={true} truncateBackgroundHover='shadow'>
            {item.title || item.titleClipData?.[0].title}
          </Heading>
        </Template>
        <Template label='cover'>
          <Box background='pewter' borderTop={true} borderSize='0.125rem' borderColor='cavern'>
            <Box
              borderBottom={true}
              borderSize='0.125rem'
              borderColor='shadow'
              paddingY={{mobile: 'medium', wide: 'large'}}
              paddingX={{mobile: 'medium', wide: 'xlarge'}}
            >
              <Stack space='xlarge'>
                <Heading level='h3' color='secondary'>
                  Details
                </Heading>
                <Cluster space='xxxxxlarge'>
                  <FormItem label='Partner' child='Copy'>
                    <Copy
                      text={item.partnerName || item.titleClipData?.[0].partner?.name}
                      toCopy={item.partnerName || item.titleClipData?.[0].partner?.name}
                    />
                  </FormItem>
                  <FormItem label='Active Region' child='Copy'>
                    <Copy text={item.activeRegion?.toUpperCase()} toCopy={item.activeRegion?.toUpperCase()} />
                  </FormItem>
                  <FormItem label='Pluto Avails ID' child='Copy'>
                    <Copy text={item.plutoAvailsId} toCopy={item.plutoAvailsId} />
                  </FormItem>
                  <FormItem label='UUID' child='Copy'>
                    <Copy text={item.contentUuid} toCopy={item.contentUuid} />
                  </FormItem>
                  <FormItem label='Total Run Limits For All Years' child='Paragraph'>
                    <Paragraph>{(model.runLimits || []).length}</Paragraph>
                  </FormItem>
                </Cluster>
              </Stack>
            </Box>
            <Box
              borderTop={true}
              borderSize='0.125rem'
              borderColor='shadow'
              paddingY={{mobile: 'medium', wide: 'large'}}
              paddingX={{mobile: 'medium', wide: 'xlarge'}}
            >
              <Stack space='xlarge'>
                <Cluster justify='space-between' align='center'>
                  <Cluster align='center' space='large'>
                    <Heading level='h3' color='secondary'>
                      Run Limits For
                    </Heading>
                    <Select
                      value={{label: selectedYear}}
                      options={VALID_YEARS.map(year => ({label: year}))}
                      onChange={val => setSelectedYear(val.label)}
                    />
                  </Cluster>
                  <Button
                    onClick={() => setIsEditSelectedOpen(true)}
                    state={selectedNum === 0 || !canModify ? 'disabled' : ''}
                    permission={permissions.LICENSED_TITLES_EDIT}
                  >
                    Edit Selected{selectedNum > 0 && canModify ? ` (${selectedNum})` : ''}
                  </Button>
                </Cluster>
                <Stack space='small'>
                  {!formState.isValid ? (
                    <Help state='error'>
                      There are errors in your some of your Run Limits. Some of them may be on other years.
                    </Help>
                  ) : (
                    <Help state='normal'></Help>
                  )}
                  <Grid gap='xlarge'>
                    {visibleRunLimits.map((runLimit, i) => (
                      <RunLimit
                        onSelect={val => runLimitSelected(val, i)}
                        selected={selected[i] ? true : false}
                        key={runLimit.startDate.seconds}
                        runLimit={runLimit}
                        onChange={val => updateFromCard(val, i)}
                      />
                    ))}
                    {canModify && <AddBox title='Run Limit' height='18.75rem' onClick={() => setIsAddOpen(true)} />}
                  </Grid>
                </Stack>
              </Stack>
            </Box>
          </Box>
        </Template>
        <Template label='footer'>
          <Box background='onyx' paddingX='large' paddingY='small' marginX={{mobile: 'none', wide: 'largeNegative'}}>
            <Cluster justify='space-between'>
              <div></div>
              <Cluster space='xxxsmall'>
                <Button ghost={true} onClick={() => cancelHandler()} id='discard'>
                  Discard
                </Button>
                <Button
                  type='primary'
                  state={
                    !formState.isValid || !formState.isDirty
                      ? 'disabled'
                      : isRunLimitsFetching || isSaving
                      ? 'thinking'
                      : ''
                  }
                  onClick={saveHandler}
                  id='save'
                  permission={permissions.LICENSED_TITLES_EDIT}
                >
                  Save Changes
                </Button>
              </Cluster>
            </Cluster>
          </Box>
        </Template>
      </Cover>
      <ConfirmRouteChange
        when={!isLeaving ? formState.isDirty : false}
        onSave={saveHandler}
        isValid={formState.isValid}
      />
      <Dialog isOpen={isAddOpen} onClose={() => setIsAddOpen(false)} width='37.5rem'>
        <Template label='header'>
          <Heading level='h2'>Add Run Limit</Heading>
        </Template>
        <Template label='body'>
          <Grid gap='large'>
            <FormItem {...createForm.activeMonth} permission={permissions.LICENSED_TITLES_EDIT}>
              <Select
                options={MONTHS.map(month => ({label: month}))}
                appendToBody={isAddOpen}
                value={{label: createModel.activeMonth || ''}}
                placeholder='Active Month'
                onChange={val => createSetFields({activeMonth: val.label})}
              />
            </FormItem>
            <FormItem {...createForm.activeYear} permission={permissions.LICENSED_TITLES_EDIT}>
              <Select
                options={VALID_YEARS.map(year => ({label: year}))}
                appendToBody={isAddOpen}
                value={{label: `${createModel.activeYear}` || ''}}
                placeholder='Active Year'
                onChange={val => createSetFields({activeYear: parseInt(val.label, 10)})}
              />
            </FormItem>
            <FormItem label='Active Region' child='Paragraph'>
              <Paragraph>{item.activeRegion.toUpperCase()}</Paragraph>
            </FormItem>
            <FormItem
              {...createForm.maxRunsPeriod}
              permission={permissions.LICENSED_TITLES_EDIT}
              onBlur={() => createOnBlur('maxRunsPeriod')}
            >
              <TextInput
                type='number'
                placeholder='Max Number of Runs Allowed'
                value={createModel.maxRunsPeriod}
                onChange={val => createOnChange('maxRunsPeriod', val)}
              />
            </FormItem>
            <FormItem
              {...createForm.licenseFee}
              permission={permissions.LICENSED_TITLES_EDIT}
              onBlur={() => createOnBlur('licenseFee')}
            >
              <TextInput
                type='number'
                value={createModel.licenseFee}
                placeholder='License Fee'
                onChange={val => createOnChange('licenseFee', val)}
              />
            </FormItem>
          </Grid>
        </Template>
        <Template label='footer'>
          <Cluster justify='space-between'>
            <div></div>
            <Cluster space='small'>
              <Button ghost={true} size='small' onClick={() => setIsAddOpen(false)}>
                Cancel
              </Button>
              <Button
                type='primary'
                size='small'
                state={!createFormState.isValid || !createFormState.isDirty ? 'disabled' : ''}
                onClick={() => {
                  if (
                    some(
                      model.runLimits || [],
                      rl =>
                        luxon.fromSeconds(rl.startDate.seconds).setZone('America/Los_Angeles').toFormat('MMM yyyy') ===
                        `${createModel.activeMonth} ${createModel.activeYear}`,
                    )
                  ) {
                    Toast.error(
                      'Duplicate Entry',
                      `Run Limit for ${createModel.activeMonth} ${createModel.activeYear} already exists.`,
                    );
                    return;
                  }

                  setIsAddOpen(false);

                  const parsedDate = luxon
                    .fromFormat(`${createModel.activeMonth} 15 ${createModel.activeYear}`, 'MMM dd yyyy')
                    .setZone('America/Los_Angeles')
                    .startOf('month');

                  setFields({
                    runLimits: orderBy(
                      [
                        ...(model.runLimits || []),
                        {
                          licenseFee: createModel.licenseFee,
                          maxDailyRuns: createModel.maxDailyRuns,
                          maxRunsPeriod: createModel.maxRunsPeriod,
                          startDate: {
                            seconds: +parsedDate.toFormat('X'),
                          },
                          endDate: {
                            seconds: +parsedDate.endOf('month').toFormat('X'),
                          },
                          activeRegion: item.activeRegion,
                          licensedTitleId: item.id,
                        },
                      ],
                      a => a.startDate.seconds,
                    ),
                  });
                }}
              >
                + Add
              </Button>
            </Cluster>
          </Cluster>
        </Template>
      </Dialog>
      <Dialog isOpen={isEditSelectedOpen} onClose={() => setIsEditSelectedOpen(false)} width='37.5rem'>
        <Template label='header'>
          <Heading level='h2'>Edit Multiple Run Limits</Heading>
        </Template>
        <Template label='body'>
          <Grid gap='large'>
            <FormItem
              {...editForm.maxRunsPeriod}
              permission={permissions.LICENSED_TITLES_EDIT}
              onBlur={() => editOnBlur('maxRunsPeriod')}
            >
              <TextInput
                type='number'
                placeholder='Max Number of Runs Allowed'
                value={editModel.maxRunsPeriod}
                onChange={val => editOnChange('maxRunsPeriod', val)}
              />
            </FormItem>
            <FormItem
              {...editForm.licenseFee}
              permission={permissions.LICENSED_TITLES_EDIT}
              onBlur={() => editOnBlur('licenseFee')}
            >
              <TextInput
                placeholder='License Fee'
                type='number'
                value={editModel.licenseFee}
                onChange={val => editOnChange('licenseFee', val)}
              />
            </FormItem>
          </Grid>
        </Template>
        <Template label='footer'>
          <Cluster justify='space-between'>
            <div></div>
            <Cluster space='small'>
              <Button ghost={true} size='small' onClick={() => setIsEditSelectedOpen(false)}>
                Cancel
              </Button>
              <Button
                type='primary'
                size='small'
                state={!editFormState.isValid ? 'disabled' : ''}
                onClick={updateMultiple}
              >
                Edit
              </Button>
            </Cluster>
          </Cluster>
        </Template>
      </Dialog>
    </>
  );
};
