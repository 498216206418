export const getSeriesIdFromUrlPath = (): string => {
  let seriesId = '';

  if (location.pathname.includes('/series/')) {
    const seriePath = location.pathname.slice(0 + '/series/'.length, location.pathname.length).split('/');
    seriesId = seriePath[0] || '';
  }

  return seriesId;
};
