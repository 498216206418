import * as React from 'react';
import {Box, Notification} from '@pluto-tv/assemble';

export default (): JSX.Element => {
  return (
    <Box fullHeight={true} padding={{mobile: 'medium', wide: 'xlarge'}}>
      <Notification type='warning'>You are not authorized to perform this operation</Notification>
    </Box>
  );
};
