export const rewriteImageUrl = (url = ''): string => {
  const regex1 = /^http:\/\/pluto\.tv\.s3\.amazonaws\.com/i;
  const regex2 = /^http:\/\/silo\.pluto\.tv/i;
  const regex3 = /^http:\/\/s3\.amazonaws\.com\/pluto\.tv/i;
  return (
    url
      ?.replace(regex1, 'https://s3.amazonaws.com/pluto.tv')
      ?.replace(regex2, 'https://silo.pluto.tv')
      ?.replace(regex3, 'https://s3.amazonaws.com/pluto.tv') || ''
  );
};
