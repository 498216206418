import {ITimeline} from 'features/channelTimelines/channelTimelinesApi';
import {getLuxonObject, areDatesInDifferentDays} from '../../utils/dateUtils';
import {ISimpleScheduleEntry, worker} from '@pluto-tv/assemble';
import {IChannelCatalogItem} from 'models/channelCatalog';
import {IStateMsgList, StateColorType, getItemState} from '../../utils';
import {isSimpleScheduleEntry} from 'views/programming/channel/edit/program/components/EpisodesDetails';
import {v4 as uuidv4} from 'uuid';
import {SelectMode} from '.';

export const SAFE_PUBLISHED_WINDOW_IN_SECONDS = 1800;

export const getMinStart = (): Date => {
  const now = new Date();
  return new Date(now.setSeconds(now.getSeconds() + SAFE_PUBLISHED_WINDOW_IN_SECONDS));
};

export const getDraftTimeline = (
  drafts: ITimeline[],
  published: ITimeline[],
): {
  events: ITimeline[];
  borderEvent: ITimeline | null;
} => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const currentPoint = getLuxonObject(new Date() as Date)
    ?.plus({minutes: 30})
    .toJSDate()!;
  const pubEvents = published.filter(p => new Date(p.start) <= currentPoint);
  const lastPubEvent = pubEvents.length > 0 ? new Date(pubEvents[pubEvents.length - 1].stop) : null;
  const draEvents = lastPubEvent !== null ? drafts.filter(p => new Date(p.start) >= lastPubEvent) : drafts;

  return {
    // Doing the Sort here, cos sometimes the endpoint doesn't respond with the drafts sorted by start date
    events: pubEvents.concat(draEvents).sort((a, b) => (new Date(a.start) > new Date(b.start) ? 1 : -1)),
    borderEvent: pubEvents.length >= 1 ? pubEvents[pubEvents.length - 1] : null,
  };
};

export interface ITimeframe {
  start: Date;
  stop: Date;
}

const mapToSimpleScheduleEntry = (episode: IChannelCatalogItem): ISimpleScheduleEntry => {
  return {
    title: episode.series.name,
    subTitle: episode.name,
    episodeId: episode.id,
    duration: episode.duration,
    id: episode.id,
    minHeight: '8.15rem',
    start: new Date(),
    ...getTimelineState(episode),
  };
};

export const mapToSimpleScheduleEntries = (
  episodes: (IChannelCatalogItem | ISimpleScheduleEntry)[],
): ISimpleScheduleEntry[] => {
  return episodes.map(episode => {
    return isSimpleScheduleEntry(episode) ? episode : mapToSimpleScheduleEntry(episode);
  });
};

export const isFirstTimeAired = (timeline: ITimeline): boolean => {
  const today = new Date();
  const {
    start,
    episode: {firstAired},
  } = timeline;

  if (!start || firstAired === '') return false;

  const startTime = new Date(start);
  const firstAiredTime = new Date(firstAired);

  return startTime.getTime() === firstAiredTime.getTime() && startTime > today;
};

export const mapTimelinesIntoTimelinesEntry = (
  timelines: ITimeline[],
  isGracenoteReady: boolean,
  draggablePermission = false,
): ISimpleScheduleEntry[] => {
  return timelines.map(timeline => {
    const draft: ISimpleScheduleEntry = {
      title: timeline.episode.series.name,
      subTitle: timeline.episode.name,
      episodeId: timeline.episode.id,
      ...timeline,
      ...timeline.episode,
      id: timeline.id,
      state: timeline.state as StateColorType,
      minHeight: '8.15rem',
      draggable: draggablePermission,
    };

    if (timeline.locked) {
      delete draft.stateMsgList;
      delete draft.state;
    } else {
      const firstTime = isFirstTimeAired(timeline);

      if (timeline.stateMsgList && timeline.stateMsgList?.length > 0) {
        draft.errorMessagesWithState = timeline.stateMsgList.map(state => ({
          state: state.color as StateColorType,
          message: state.label,
        }));
      } else if (firstTime) {
        draft.state = 'success' as StateColorType;
      }

      if (firstTime) {
        draft.errorMessagesWithState = [
          ...(draft.errorMessagesWithState || []),
          {
            state: 'info',
            message: 'Episode first time airing on a channel.',
          },
        ];
      }
    }

    if (isGracenoteReady) {
      draft.manualOverrideMarker = new Date();
    } else if (draft.manualOverrideMarker) {
      delete draft.manualOverrideMarker;
    }
    return draft;
  });
};

export const canUseEpisodeForTimeline = (episodeToAssign: IChannelCatalogItem, start: Date): boolean =>
  episodeToAssign.hasLinearAvailabilityWindows &&
  episodeToAssign.allClipsReady &&
  episodeToAssign.isAvailableNow &&
  !!episodeToAssign.availabilityWindows.linear.find(
    currentWindow => start >= new Date(currentWindow.startDate) && start <= new Date(currentWindow.endDate),
  );

export const getTimelineState = (
  item: IChannelCatalogItem | ITimeline,
): {state: any; stateMsgList: IStateMsgList[]} => {
  const obj: IChannelCatalogItem = {...(item as IChannelCatalogItem)};

  if (
    ('providerIsJwMixed' in item && !('hasMixedProviderWith' in item)) ||
    ('providerIsJwOnly' in item && !('hasUniqueProvider' in item))
  ) {
    obj.hasMixedProviderWith = !!item.providerIsJwMixed;
    obj.hasUniqueProvider = !!item.providerIsJwOnly;
  }

  if ('hasEmptySources' in item && !('hasClipWithEmptySources' in item)) {
    obj.hasClipWithEmptySources = !!item.hasEmptySources;
  }

  // warnings or errors
  const stateResult = getItemState(obj);
  return stateResult;
};

export const getTimeLineEpisode = (
  episode: ISimpleScheduleEntry | IChannelCatalogItem,
  timeline: ITimeline[],
  start: Date,
): ITimeline | null => {
  const uniqueId = uuidv4();

  if (!isSimpleScheduleEntry(episode)) {
    return {
      id: `${episode.id}-${uniqueId}`,
      start,
      stop: new Date(start.getTime() + episode.allotment * 1000),
      episode: episode,
      ...getTimelineState(episode),
    } as ITimeline;
  } else {
    const timelineEpisode = timeline.find(t => t.id === episode.id);
    if (!timelineEpisode) return null;

    const episodeId = timelineEpisode.episode.id;
    const newId = `${episodeId}-${uniqueId}`;

    return {
      ...timelineEpisode,
      id: newId,
      locked: false,
      start,
      stop: new Date(start.getTime() + timelineEpisode.stop.getTime() - timelineEpisode.start.getTime()),
    };
  }
};

export const addDays = (date: Date, days: number): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const recalculateEventTimes = (
  episodes: (IChannelCatalogItem | ISimpleScheduleEntry)[],
  timeline: ITimeline[],
  startDate: Date,
  mode: SelectMode = 'vertical-select',
): ITimeline[] => {
  return episodes.reduce((acc, episode, index) => {
    const start: Date = acc[index - 1]
      ? mode === 'vertical-select'
        ? acc[index - 1].stop
        : addDays(acc[index - 1].start, index === 0 ? 0 : 1)
      : startDate;
    const timelineEpisode = getTimeLineEpisode(episode, timeline, start);

    return timelineEpisode ? [...acc, timelineEpisode] : acc;
  }, [] as ITimeline[]);
};

/**
 * This function moves the start and stop times of the events in the timeline to the new start time.
 * @param timeline - The timeline to update
 * @param start - The new start time
 * @returns The updated timeline
 */
const moveTimes = (timeline: ITimeline[], start: Date): ITimeline[] => {
  let newStart = start;

  return timeline.map(timelineEvent => {
    // If the event's start time is later than the new start time, return the event as is
    if (timelineEvent.start > newStart) {
      newStart = timelineEvent.stop;
      return timelineEvent;
    }

    // Calculate the new stop time for the event, maintaining the original duration
    const newStop = new Date(newStart.getTime() + timelineEvent.episode.allotment * 1000);

    // Create a new event with the updated start and stop times
    const newTimeline = {
      ...timelineEvent,
      start: newStart,
      stop: newStop,
    };

    // Update the new start time for the next event to be the stop time of the current event
    newStart = newStop;

    return newTimeline;
  });
};

/**
 * This function moves the start and stop times of the events up in the calendar to the new start time.
 * @param timeline - The timeline to update
 * @param start - The new start time
 * @returns The updated timeline
 */
export const moveTimesUp = (timeline: ITimeline[], start: Date): ITimeline[] => {
  let newStart = start;

  return timeline
    .sort((a, b) => (a.start.getTime() > b.start.getTime() ? 1 : -1))
    .map(timelineEvent => {
      // If the event's start time is later than the new start time + 30min return the event as is
      if (timelineEvent.start.getTime() > newStart.getTime() + 30 * 60 * 1000) {
        newStart = timelineEvent.stop;
        return timelineEvent;
      }

      // Calculate the new stop time for the event, maintaining the original duration
      const newStop = new Date(newStart.getTime() + timelineEvent.episode.allotment * 1000);

      // Create a new event with the updated start and stop times
      const newTimeline = {
        ...timelineEvent,
        start: newStart,
        stop: newStop,
      };

      // Update the new start time for the next event to be the stop time of the current event
      newStart = newStop;

      return newTimeline;
    });
};

/**
 * This function adds new episodes to the timeline and returns the updated timeline.
 * @param newEpisodes - The new episodes to add to the timeline
 * @param draftTimeline - The current timeline
 * @returns The updated timeline
 */
export const addEpisodes = (newEpisodes: ITimeline[], draftTimeline: ITimeline[]): ITimeline[] => {
  if (!newEpisodes.length) return draftTimeline;

  let newTimeline = [...draftTimeline].sort((a, b) => a.start.getTime() - b.start.getTime());

  newEpisodes.forEach(item => {
    // Find the index where the new episode should be inserted
    const indexToInsert = newTimeline.findIndex(
      draft => draft.start.setUTCMilliseconds(0) >= item.start.setUTCMilliseconds(0),
    );

    // If the new episode should be inserted at the end of the timeline
    if (indexToInsert === -1) {
      const startDateItem = newTimeline.length ? newTimeline[newTimeline.length - 1].stop : item.start;

      newTimeline = [...newTimeline, ...moveTimes([item], startDateItem)];
    } else {
      // Split the timeline into two parts at the insertion index
      const firstPart = newTimeline.slice(0, indexToInsert);
      const lastFirstPartEvent = firstPart.length ? firstPart[firstPart.length - 1] : null;

      const startDateItem =
        // If the new episode starts on a different day than the last episode in the first part,
        // use the start time of the new episode. Otherwise, use the stop time of the last episode in the first part.
        !lastFirstPartEvent || areDatesInDifferentDays(lastFirstPartEvent.stop, item.start)
          ? item.start
          : lastFirstPartEvent.stop;

      const secondPart = moveTimes([item, ...newTimeline.slice(indexToInsert)], startDateItem);

      newTimeline = [...firstPart, ...secondPart];
    }
  });

  return newTimeline;
};

export const getStartTimeToDrop = (items: ISimpleScheduleEntry[], timeToDrop?: number): number | null => {
  if (timeToDrop) return timeToDrop;

  const lastItem = items[items.length - 1];
  const nowPlus30 = new Date().getTime() + 30 * 60 * 1000;
  const newStartTime: number | null = lastItem.stop >= nowPlus30 ? lastItem.stop : null;
  return newStartTime;
};

/**
 * This function snap up the episodes when they are <= 30 minutes apart
 * @param draftTimeline - The current timeline
 * @param startDate
 * @param isSnapOn
 * @returns The updated timeline
 */
export const sanitizeDraftEventList = (
  draftTimelines: ITimeline[],
  startDate: Date,
  isSnapOn: boolean,
): ITimeline[] => {
  if (!isSnapOn) return draftTimelines;

  // Filter the draft list with the events of the current week
  const currentWeekDraftTimelines: ITimeline[] = draftTimelines
    .filter(draft => startDate && draft.stop >= startDate)
    .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());

  if (currentWeekDraftTimelines.length <= 1) {
    return draftTimelines;
  }

  // Keep the above filtered out items
  let firstPart: ITimeline[] = [];

  if (draftTimelines.length > currentWeekDraftTimelines.length) {
    const indexOfFirstEntry = draftTimelines.indexOf(currentWeekDraftTimelines[0]);

    // Used to splice the array and avoid modifying the draftTimelines one
    const tempDraftList = [...draftTimelines];
    firstPart = tempDraftList.splice(0, indexOfFirstEntry);
  }

  let secondPart: ITimeline[] = [];

  // Current entry to evaluate
  let currDraft: ITimeline | undefined = currentWeekDraftTimelines.pop();
  if (!!currDraft) {
    // Keep track the entry with its original start value before change
    let currOriginalStartdate = currDraft.start;
    while (currentWeekDraftTimelines.length > 0 && !!currDraft) {
      const prevDraft = currentWeekDraftTimelines.pop();
      if (prevDraft) {
        // Setting 0 milisec but not modify the values
        const draftDiff =
          new Date(currOriginalStartdate).setMilliseconds(0) - new Date(prevDraft.stop).setMilliseconds(0);

        // Set the original start date before it changes
        currOriginalStartdate = prevDraft.start;

        secondPart.push({...currDraft});
        // Check if gap duration is less than 30min
        if (draftDiff > 0 && draftDiff <= 30 * 60 * 1000) {
          secondPart = moveTimesUp(secondPart, prevDraft.stop).reverse();
        }

        currDraft = {...prevDraft};
      }
    }

    secondPart.push({...currDraft});
  }

  const allDraftTimelines = [...(firstPart || []), ...secondPart].sort((a, b) =>
    a.start.getTime() > b.start.getTime() ? 1 : -1,
  );
  return allDraftTimelines;
};

export const createTimelinesWorker = (
  timeframes: ITimeframe[],
  lastDraftTimeline: ITimeline | undefined,
  queue: (IChannelCatalogItem & {
    state: string;
    stateMsgList: IStateMsgList[];
  })[],
): Promise<ITimeline[]> =>
  worker(
    (timeframes: ITimeframe[], lastDraftTimeline: ITimeline | undefined): ITimeline[] => {
      const incrementIndex = (index: number) => {
        return index === queue.length - 1 ? 0 : index + 1;
      };

      const newTimelines: ITimeline[] = [];
      let index = 0;
      let triedToInsertEpisodeIndex: number | null = null;
      let episodeIndex = Math.abs(Math.random() * 1000);

      if (!queue.length) {
        return [] as ITimeline[];
      }

      if (lastDraftTimeline) {
        const currentEpisodeId = lastDraftTimeline.episode.id;
        index = queue.findIndex(i => i.id === currentEpisodeId) + 1;

        if (index === queue.length) {
          index = 0;
        }
      }

      // loop through our timeframes and try to create as many timeline as possible
      timeframes.forEach(timeframe => {
        let start = timeframe.start;
        const stop = timeframe.stop;

        while (start.getTime() < stop.getTime()) {
          const episodeToAssign = queue[index];

          const timeline: ITimeline = {
            id: `${episodeToAssign.id}-${episodeIndex}`,
            start: start,
            stop: new Date(start.getTime() + episodeToAssign.allotment * 1000),
            episode: episodeToAssign,
            state: episodeToAssign.state,
            stateMsgList: episodeToAssign.stateMsgList,
            isRecurrenceEvent: false,
          };

          // We are looping through the episode list and creating timelines for a timeframe.
          const canUseEpisode = canUseEpisodeForTimeline(episodeToAssign, start);

          if (canUseEpisode && timeline.stop.getTime() <= stop.getTime()) {
            // timeline fits in the timeframe, so pushes it to our list and set start to the end of timeline
            newTimelines.push(timeline);
            episodeIndex = episodeIndex + 1;

            start = timeline.stop;
            triedToInsertEpisodeIndex = null;
          } else {
            // it means that no available episode fits in the timeframe
            if (triedToInsertEpisodeIndex === incrementIndex(index)) break;

            if (triedToInsertEpisodeIndex === null) {
              // keep record of when we start to rotate episodes so we know when to stop it
              triedToInsertEpisodeIndex = index;
            }
          }
          // increment episodes index to try next available episode
          index = incrementIndex(index);
        }

        /*
         * Ensure we move to next episode before starting
         * processing next timeframe (in case fit was not found for the last space)
         */
        if (triedToInsertEpisodeIndex !== null) {
          index = incrementIndex(index);
        }
      });

      return newTimelines;
    },
    timeframes,
    lastDraftTimeline,
    queue,
  );
