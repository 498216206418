import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {RootState} from 'app/store';
import {ITerritory} from 'models/territory';

export const territoriesApi = createApi({
  reducerPath: 'territoriesApi',
  tagTypes: ['Territories'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/v1/',
    prepareHeaders(headers, {getState}) {
      const token = (getState() as RootState).user.jwt;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints(builder) {
    return {
      find: builder.query<ITerritory[], void>({
        query: () => ({
          url: `territories`,
          method: 'GET',
        }),
        providesTags: ['Territories'],
      }),
    };
  },
});

export const {useFindQuery} = territoriesApi;
