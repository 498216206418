import * as React from 'react';
import {GoogleOAuthProvider, localStorageHelper, Icons, summon, googleLogout} from '@pluto-tv/assemble';

import {useAppDispatch} from 'app/store/hooks';
import {useAppSelector} from 'app/store/hooks';

import {login, logout} from 'features/user/userSlice';
import {clientId} from 'views/login/config/googleClientId';

import PrivateLayout from './Private';
import PublicLayout from './Public';

summon.setBaseUrl('/v1/');

export default (): JSX.Element => {
  const dispatch = useAppDispatch();
  const {isAuthenticated} = useAppSelector(store => store.user);

  const handleAuthChange = React.useMemo(
    () => (jwt?: string) => {
      // Clean up dangling quotes on the JWT token string
      const cleanedJwt = jwt?.replace(/^\"/, '').replace(/\"$/, '');

      if (!isAuthenticated && cleanedJwt) {
        dispatch(login({jwt: cleanedJwt}));
        summon.setAuthToken(`Bearer ${cleanedJwt}`);
      }

      if (isAuthenticated && cleanedJwt) {
        summon.setAuthToken(`Bearer ${cleanedJwt}`);
      }

      if (isAuthenticated && !cleanedJwt) {
        googleLogout();
        dispatch(logout());
      }

      if (!isAuthenticated && !cleanedJwt) {
        dispatch(logout());
      }
    },
    [isAuthenticated, dispatch],
  );

  const onFocus = React.useMemo(
    () => () => {
      const existingSessionToken = localStorageHelper.loadOrDefault<string | undefined>('Jwt-Token', undefined);
      handleAuthChange(existingSessionToken);
    },
    [handleAuthChange],
  );

  const onStorageChanged = React.useMemo(
    () => (e: StorageEvent) => {
      // Don't listen to changes not relavent to the JWT token
      if (e.key !== 'Jwt-Token') {
        return;
      }

      // Don't pay attention to the token when it already exists
      // Without this there is a chain reaction
      if (e.oldValue && e.newValue) {
        return;
      }

      const jwt = e.newValue ? e.newValue : undefined;
      handleAuthChange(jwt);
    },
    [handleAuthChange],
  );

  React.useEffect(() => {
    // Check the auth when the tab is focused
    window.addEventListener('focus', onFocus);
    // Check the auth when local storage is changed
    window.addEventListener('storage', onStorageChanged);

    // Check the auth when the page first loads
    onFocus();

    // Unload event listeners
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('storage', onStorageChanged);
    };
  }, [onFocus, onStorageChanged]);

  return (
    <GoogleOAuthProvider clientId={clientId}>
      {isAuthenticated ? <PrivateLayout /> : <PublicLayout />}
      <Icons />
    </GoogleOAuthProvider>
  );
};
