import * as React from 'react';
import {DateTime as luxon} from 'luxon';
import {orderBy, cloneDeep, startCase} from 'lodash-es';

import {
  Box,
  Button,
  Cluster,
  ContentBoxes,
  ContentBox,
  ContentBoxColumn,
  FormItem,
  Help,
  Icon,
  ITableCol,
  Paragraph,
  Popover,
  Select,
  Spinner,
  Stack,
  Table,
  TagList,
  Template,
  TextInput,
  TIcons,
  Toggle,
  Toast,
} from '@pluto-tv/assemble';

import {TableActions} from 'components/tableActions';
import CrudError from 'components/crudError';
import CustomReferenceForm from 'components/custom-reference-form';

import {useFindQuery as useFindPartnersQuery} from 'features/partners/partnersApi';

import {useUserRegions} from 'helpers/useUserRegions';
import {IPopover} from 'helpers/popoverInterface';

import {INestedEpisodeProps} from '../nestedPropsInterface';
import {useEpisodePermissions} from '../../permissions/useEpisodePermissions';
import {ICustomReference} from 'models/episodes';

const hasCustomReferenceType = (customReferenceTypes: ICustomReference[], type: string) => {
  return Boolean(customReferenceTypes.find(customReferenceType => customReferenceType.type === type));
};

export default ({
  form,
  model,
  setFields,
  onBlur,
  onChange,
  dirtyFields,
  pristineModel,
}: INestedEpisodeProps): JSX.Element => {
  const {CAN_EDIT, editPermission} = useEpisodePermissions(pristineModel);

  const {territories, isFetching: isUserRegionsFetching, isError: isUserRegionsError} = useUserRegions();
  const {data: partners, isFetching: isFetchingPartners, isError: isErrorPartners} = useFindPartnersQuery();

  const [customReferencePopoverOpen, setCustomReferencePopoverOpen] = React.useState<IPopover>({});

  const hasDeliveryPartnerCustomRefSaved = hasCustomReferenceType(
    pristineModel.customReferences || [],
    'deliveryPartner',
  );

  const handleCustomReferenceClick = (icon: TIcons, index: number) => {
    if (icon === 'delete') {
      const cloned = cloneDeep(model.customReferences);

      if (!cloned) {
        return;
      }

      cloned.splice(index, 1);

      setFields({
        customReferences: cloned,
      });
    } else if (icon === 'edit') {
      setCustomReferencePopoverOpen({[index]: true});
    }
  };

  const handleCustomReferencesUpdate = (customReference: ICustomReference, index = -1) => {
    const cloned = cloneDeep(model.customReferences || []);

    const typeExists = cloned.find((ref, i) => ref.type === customReference.type && i !== index);

    if (!typeExists) {
      if (index >= 0) {
        cloned.splice(index, 1, customReference);
      } else {
        cloned.push(customReference);
      }
    } else {
      Toast.warning('The same Custom Reference type cannot be used more than once. Please update.');
    }

    setFields({
      customReferences: cloned,
    });

    setCustomReferencePopoverOpen({});
  };

  if (isUserRegionsError || isErrorPartners) {
    return <CrudError error='Error loading page data' />;
  }

  if (isUserRegionsFetching || isFetchingPartners) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} size='xlarge' />
      </Box>
    );
  }

  return (
    <ContentBoxes layout='columns'>
      <ContentBoxColumn>
        <ContentBox title='Territories'>
          <Stack space='medium'>
            <FormItem {...form.regionFilter} permission={editPermission} state='disabled'>
              <Select
                placeholder='Please Select Territories'
                onChange={value =>
                  setFields({
                    regionFilter: (value || [])?.map(v => v.value),
                  })
                }
                value={model.regionFilter?.map(d => ({label: d, value: d}))}
                id='territories'
                multiselect={true}
                predicate='value'
                options={
                  orderBy(
                    (territories || []).map(t => ({label: t.name, value: t.id.toLowerCase()})),
                    'label',
                  ) || []
                }
              />
            </FormItem>
          </Stack>
        </ContentBox>
        <ContentBox title='Availability Windows'>
          <Stack space='medium'>
            <Box marginTop='xsmallNegative'>
              <Icon icon='info' size='medium' iconAlign='center' space='xxsmall' color='info'>
                <Help state='info'>
                  All dates are on PDT. Start date will be set at 00:00:00, and end date at 23:59:59.
                </Help>
              </Icon>
            </Box>
            {form.availabilityWindows?.helpText && (
              <Box marginTop='xsmallNegative'>
                <Icon icon='warning' size='medium' iconAlign='center' space='xxsmall' color='error'>
                  <Help state='error'>{form.availabilityWindows?.helpText}</Help>
                </Icon>
              </Box>
            )}
            <Stack space='xxxxlarge'>
              <Stack space='small'>
                <Cluster align='center' space='medium'>
                  <Paragraph>Linear</Paragraph>
                  <Toggle
                    label='Yes'
                    value={model.distributeAs?.linear}
                    onChange={val => setFields({distributeAs: {...model.distributeAs!, linear: val}})}
                    permission={editPermission}
                  />
                </Cluster>
                <Table
                  cols={[
                    {
                      label: 'Start Date',
                      transform: row =>
                        luxon
                          .fromISO(row.startDate as string)
                          .setZone('America/Los_Angeles')
                          .toFormat('yyyy-LL-dd'),
                    },
                    {
                      label: 'End Date',
                      transform: row =>
                        luxon
                          .fromISO(row.endDate as string)
                          .setZone('America/Los_Angeles')
                          .toFormat('yyyy-LL-dd'),
                    },
                    {
                      label: 'Screenings',
                      field: 'screenings',
                    },
                  ]}
                  rows={model.availabilityWindows?.linear || []}
                  emptyMsg='No Linear Availability Windows'
                ></Table>
              </Stack>
              <Stack space='small'>
                <Cluster align='center' space='medium'>
                  <Paragraph>AVOD</Paragraph>
                  <Toggle
                    label='Yes'
                    value={model.distributeAs?.AVOD}
                    onChange={val => setFields({distributeAs: {...model.distributeAs!, AVOD: val}})}
                    permission={editPermission}
                  />
                </Cluster>
                <Table
                  cols={[
                    {
                      label: 'Start Date',
                      transform: row =>
                        luxon
                          .fromISO(row.startDate as string)
                          .setZone('America/Los_Angeles')
                          .toFormat('yyyy-LL-dd'),
                    },
                    {
                      label: 'End Date',
                      transform: row =>
                        luxon
                          .fromISO(row.endDate as string)
                          .setZone('America/Los_Angeles')
                          .toFormat('yyyy-LL-dd'),
                    },
                    {
                      label: 'Screenings',
                      field: 'screenings',
                    },
                  ]}
                  rows={model.availabilityWindows?.AVOD || []}
                  emptyMsg='No AVOD Availability Windows'
                ></Table>
              </Stack>
            </Stack>
          </Stack>
        </ContentBox>
      </ContentBoxColumn>
      <ContentBoxColumn>
        <ContentBox title='Identification'>
          <Stack space='medium'>
            <FormItem
              {...form.slug}
              onBlur={() => onBlur('slug')}
              permission={editPermission}
              state={form.slug?.state ? form.slug.state : dirtyFields?.slug ? 'warning' : ''}
              helpText={
                form.slug?.helpText
                  ? form.slug.helpText
                  : dirtyFields?.slug
                  ? 'Changing the slug can break old references to this series. Proceed with caution.'
                  : ''
              }
            >
              <TextInput onChange={value => onChange('slug', value)} value={model.slug} id='slug' />
            </FormItem>
            <FormItem {...form.tmsid} onBlur={() => onBlur('tmsid')} permission={editPermission}>
              <TextInput onChange={value => onChange('tmsid', value)} value={model.tmsid} id='tmsID' />
            </FormItem>
          </Stack>
        </ContentBox>
        <ContentBox title='External Partner IDs'>
          <Table
            cols={[
              {
                label: 'Key',
                field: 'key',
              },
              {
                label: 'ID',
                field: 'value',
              },
            ]}
            rows={model.externalIds || []}
            emptyMsg='No IDs Available for this item'
          ></Table>
        </ContentBox>
        <ContentBox title='Configuration'>
          <Stack space='xlarge'>
            <Stack space='small'>
              <Cluster space='xxlarge'>
                <FormItem label='DASH Enabled' child='Paragraph'>
                  <Paragraph>{model.dashEnabled ? 'Yes' : 'No'}</Paragraph>
                </FormItem>
                <FormItem label='Auto-Fill Feed Episode' permission={editPermission} child='Toggle'>
                  <Toggle label='Yes' value={model.isFeed} onChange={val => setFields({isFeed: val})} />
                </FormItem>
              </Cluster>
              <Table
                cols={[
                  {
                    label: 'Custom Reference Type',
                    transform: row => startCase(row.type),
                  },
                  {
                    label: 'Partner',
                    transform: row =>
                      (partners?.find(partner => partner.id === row.value.id) || {name: row.value}).name,
                  },
                  ...(CAN_EDIT
                    ? [
                        {
                          label: 'Actions',
                          colWidth: '6.25rem',
                          transform: (row, _col, index) => {
                            const canEditCustomRef =
                              row.type !== 'deliveryPartner' || !hasDeliveryPartnerCustomRefSaved;
                            return (
                              <Cluster>
                                <TableActions
                                  row={row}
                                  icons={[]}
                                  deleteOption={canEditCustomRef}
                                  displayField='name'
                                  altTitle='custom reference'
                                  onClick={(_row, icon) => handleCustomReferenceClick(icon, index)}
                                >
                                  <Popover
                                    appendToBody={true}
                                    manualTrigger={true}
                                    visible={customReferencePopoverOpen[index]}
                                    onClickOutside={() => setCustomReferencePopoverOpen({})}
                                  >
                                    {canEditCustomRef && (
                                      <Template label='trigger'>
                                        <Icon
                                          space='small'
                                          icon='edit'
                                          onClick={() => handleCustomReferenceClick('edit', index)}
                                        />
                                      </Template>
                                    )}
                                    <Template label='popover'>
                                      <CustomReferenceForm
                                        partners={partners || []}
                                        visible={customReferencePopoverOpen[index]}
                                        onCancel={() => setCustomReferencePopoverOpen({})}
                                        value={{
                                          id: row.id,
                                          type: row.type,
                                          value: row.value.id,
                                        }}
                                        onSave={val =>
                                          handleCustomReferencesUpdate(
                                            {
                                              id: val.id,
                                              type: val.type,
                                              value: {
                                                id: val.value,
                                                name:
                                                  partners?.find(partner => partner.id === val.value)?.name ||
                                                  val.value,
                                              },
                                            },
                                            index,
                                          )
                                        }
                                      />
                                    </Template>
                                  </Popover>
                                </TableActions>
                              </Cluster>
                            );
                          },
                        } as ITableCol<ICustomReference>,
                      ]
                    : []),
                ]}
                rows={model.customReferences || []}
              ></Table>
              <Cluster justify='space-between'>
                <div></div>
                {CAN_EDIT && (
                  <Popover
                    manualTrigger={true}
                    visible={customReferencePopoverOpen.add}
                    onClickOutside={() => setCustomReferencePopoverOpen({})}
                  >
                    <Template label='trigger'>
                      <Button type='primary' onClick={() => setCustomReferencePopoverOpen({add: true})}>
                        + Add
                      </Button>
                    </Template>
                    <Template label='popover'>
                      <CustomReferenceForm
                        partners={partners || []}
                        isNew={true}
                        visible={customReferencePopoverOpen.add}
                        onCancel={() => setCustomReferencePopoverOpen({})}
                        onSave={val =>
                          handleCustomReferencesUpdate({
                            id: val.id,
                            type: val.type,
                            value: {
                              id: val.value,
                              name: partners?.find(partner => partner.id === val.value)?.name || val.value,
                            },
                          })
                        }
                      />
                    </Template>
                  </Popover>
                )}
              </Cluster>
            </Stack>
          </Stack>
        </ContentBox>
        <ContentBox title='Tags'>
          <Stack space='medium'>
            <FormItem {...form.tags} permission={editPermission}>
              <TagList
                value={model.tags}
                onChange={value =>
                  setFields({tags: orderBy([...new Set(value || [])] as string[], tag => tag.toLowerCase())})
                }
                id='tags'
              />
            </FormItem>
          </Stack>
        </ContentBox>
      </ContentBoxColumn>
    </ContentBoxes>
  );
};
