import * as React from 'react';
import {
  Button,
  Cluster,
  DateTime,
  Dialog,
  FormItem,
  Grid,
  Heading,
  Select,
  Stack,
  TFormFields,
  Template,
  TextInput,
  disablePastDatesBehavior,
} from '@pluto-tv/assemble';
import {IFeaturedGroup} from 'models/featuredGroup';
import {IActiveRegion} from 'models/activeRegions';

interface IFeaturedGroupCreateDialogProps {
  createOpen: boolean;
  handleCancel: () => void;
  onBlur: (fieldName: keyof IFeaturedGroup, setVal?: boolean | undefined) => void;
  onChange: (fieldName: keyof IFeaturedGroup, value: string) => void;
  form: TFormFields<IFeaturedGroup>;
  setFields: (partialModel: Partial<IFeaturedGroup>) => void;
  model: Partial<IFeaturedGroup>;
  activeRegions: IActiveRegion[];
  formState: {
    /** Is form dirty? */
    isDirty: boolean;
    /** Is form valid? */
    isValid: boolean;
  };
  handleCreate: (navigateTo?: boolean) => Promise<string | undefined>;
  handleCreateAndEdit: () => Promise<void>;
  isCreating: boolean;
}

const FeaturedGroupCreateDialog = ({
  createOpen,
  handleCancel,
  onBlur,
  onChange,
  form,
  setFields,
  model,
  activeRegions,
  formState,
  handleCreate,
  handleCreateAndEdit,
  isCreating,
}: IFeaturedGroupCreateDialogProps): React.ReactElement => {
  return (
    <Dialog isOpen={createOpen} onClose={handleCancel} width='42.8125rem'>
      <Template label='header'>
        <Heading level='h2'>Create Featured Group</Heading>
      </Template>
      <Template label='body'>
        <Stack space='small'>
          <FormItem {...form.name} onBlur={() => onBlur('name')}>
            <TextInput
              onChange={value => {
                onChange('name', value);
              }}
              id='title'
            />
          </FormItem>
          <Grid gap='small' columnGap='xlarge'>
            <FormItem {...form?.start} onBlur={() => onBlur('start')}>
              <DateTime
                id='startDate'
                appendToBody={true}
                time={true}
                onBeforeDateRender={disablePastDatesBehavior}
                onChange={value => setFields({start: (value as Date) || undefined})}
              />
            </FormItem>
            <FormItem {...form?.activeRegion} onBlur={() => onBlur('activeRegion')}>
              <Select
                appendToBody={true}
                onChange={value => {
                  const activeRegion = value.value;
                  setFields({
                    activeRegion,
                  });
                }}
                value={{label: model.activeRegion || '', value: model.activeRegion}}
                id='activeRegion'
                predicate='value'
                options={activeRegions.map(ar => ({
                  label: `${ar.name} (${ar.code})`,
                  value: ar.code.toLowerCase(),
                }))}
              />
            </FormItem>
          </Grid>
        </Stack>
      </Template>
      <Template label='footer'>
        <Cluster justify='space-between'>
          <div></div>
          <Cluster space='small'>
            <Button ghost={true} onClick={handleCancel} id='cancelButton'>
              Cancel
            </Button>
            <Button
              type='primary'
              state={!formState.isValid || !formState.isDirty ? 'disabled' : isCreating ? 'thinking' : ''}
              onClick={() => handleCreate()}
              id='createButton'
            >
              Create
            </Button>
            <Button
              type='primary'
              state={!formState.isValid || !formState.isDirty ? 'disabled' : isCreating ? 'thinking' : ''}
              onClick={handleCreateAndEdit}
              id='createEditButton'
            >
              Create and Edit
            </Button>
          </Cluster>
        </Cluster>
      </Template>
    </Dialog>
  );
};

export default FeaturedGroupCreateDialog;
