import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

import {RootState} from 'app/store';
import {IChannelCatalogItem} from 'models/channelCatalog';
import {IStateMsgList} from 'views/programming/channel/utils';

export interface ITimeline {
  allClipsReady?: boolean;
  author?: string;
  episode: IChannelCatalogItem;
  fulfilled?: boolean;
  hasAdPods?: boolean;
  hasEmptySources?: boolean;
  hasLinearAvailabilityWindowForDate?: boolean;
  hasLinearAvailabilityWindows?: boolean;
  hasRegionAvailability?: boolean;
  id: string;
  isAvailableNow?: boolean;
  manualOverrideMarker?: string;
  providerIsJwMixed?: boolean;
  providerIsJwOnly?: boolean;
  start: Date;
  stop: Date;
  locked?: boolean;
  state?: string | number | symbol;
  stateMsgList?: IStateMsgList[];
  isRecurrenceEvent: boolean;
}

export const channelTimelinesApi = createApi({
  reducerPath: 'channelTimelinesApi',
  tagTypes: ['ChannelTimelineDraft', 'ChannelTimelines'],

  baseQuery: fetchBaseQuery({
    baseUrl: '/v1/',
    prepareHeaders(headers, {getState}) {
      const token = (getState() as RootState).user?.jwt || '';
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    findDraftTimelines: builder.query<
      ITimeline[],
      {channelId: string; startDate: number; endDate: number; lastUpdate: number; draftDiscarded?: number}
    >({
      query: args => {
        const {channelId, startDate, endDate, lastUpdate, draftDiscarded} = args;
        const searchParams = new URLSearchParams(
          `start=${startDate}&stop=${endDate}&lastUpdate=${lastUpdate}&draftDiscarded=${draftDiscarded}`,
        );

        return {
          url: `channels/${channelId}/timeline-drafts?${searchParams.toString()}`,
          method: 'GET',
        };
      },
      keepUnusedDataFor: Infinity,
      providesTags: ['ChannelTimelineDraft'],
    }),
    findTimelines: builder.query<
      ITimeline[],
      {channelId: string; startDate: number; endDate: number; lastUpdate: number; draftDiscarded?: number}
    >({
      query: args => {
        const {channelId, startDate, endDate, lastUpdate} = args;
        const searchParams = new URLSearchParams(
          `start=${startDate}&stop=${endDate}&lastUpdate=${lastUpdate}&draftDiscarded=0`,
        );

        return {
          url: `channels/${channelId}/timelines?${searchParams.toString()}`,
          method: 'GET',
        };
      },
      keepUnusedDataFor: Infinity,
      providesTags: ['ChannelTimelines'],
    }),
    discardTimelineDrafts: builder.mutation<void, {id: string}>({
      query: params => ({
        url: `channels/${params.id}/discard-draft`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['ChannelTimelineDraft'],
    }),
  }),
});

export const {useFindDraftTimelinesQuery, useFindTimelinesQuery, useDiscardTimelineDraftsMutation} =
  channelTimelinesApi;
