import {IChannelCatalogItem} from 'models/channelCatalog';

export const useTotalQueueCount = ({
  isSearchActive,
  isQueueFiltered,
  totalLength,
  movedToLibrary,
  movedToQueue,
  movedToQueueDuringFilter,
  movedToLibraryDuringFilter,
}: {
  isSearchActive: boolean;
  isQueueFiltered: boolean;
  totalLength: number;
  movedToLibrary: IChannelCatalogItem[];
  movedToQueue: IChannelCatalogItem[];
  movedToQueueDuringFilter: IChannelCatalogItem[];
  movedToLibraryDuringFilter: IChannelCatalogItem[];
}): number => {
  return isSearchActive && isQueueFiltered
    ? totalLength + movedToQueueDuringFilter.length - movedToLibraryDuringFilter.length
    : totalLength + movedToQueue.length - movedToLibrary.length;
};
