import {Box, ISimpleScheduleEntry, Spinner, Toast} from '@pluto-tv/assemble';
import {useBulkQuery} from 'features/episodes/episodesApi';
import sortBy from 'lodash/sortBy';
import {IChannelCatalogItem} from 'models/channelCatalog';
import {IEpisodeProgramDetails} from 'models/episodes';
import * as React from 'react';
import MultiSelectDetails from 'views/programming/channel/edit/program/components/MultiSelectDetails';
import SingleSelectDetails from 'views/programming/channel/edit/program/components/SingleSelectDetails';

export const isSimpleScheduleEntry = (
  item: ISimpleScheduleEntry | IChannelCatalogItem,
): item is ISimpleScheduleEntry => {
  return item && 'start' in item;
};

export const getRepeatStartDate = (episode: ISimpleScheduleEntry | IChannelCatalogItem): string => {
  // check if episode is of type ISimpleScheduleEntry, then return its start
  if (isSimpleScheduleEntry(episode)) {
    return episode.start.toString();
  }
  return new Date().toString();
};

interface EpisodesDetailsProps {
  items: (ISimpleScheduleEntry | IChannelCatalogItem)[];
  onRemove?: (id: string) => void;
  onError?: () => void;
}

function sortObjectsByIds<T extends {id: string}>(ids: string[], objects: T[]): T[] {
  return sortBy(objects, object => ids.indexOf(object.id));
}

export default ({items, onRemove, onError}: EpisodesDetailsProps): React.ReactElement | null => {
  const {data, isFetching, isError} = useBulkQuery(
    items.map(item => (isSimpleScheduleEntry(item) ? item.episodeId : item.id)),
  );

  React.useEffect(() => {
    if (isError) {
      Toast.error('Error', `There was an error fetching ${items.length > 1 ? 'episodes' : 'episode'} details`);
      onError?.();
    }
  }, [items, isError, onError]);

  if (isFetching) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} minHeight='9.375rem' size='xlarge' />
      </Box>
    );
  }

  if (data?.length) {
    if (items.length === 1) {
      const [episode] = data;
      const [item] = items;
      return <SingleSelectDetails timelineEpisode={item} episode={episode} onRemove={onRemove} />;
    }

    const episodesDetails =
      items.length > data.length
        ? items.reduce((acc, episode) => {
            const episodeId = isSimpleScheduleEntry(episode) ? episode.episodeId : episode.id;
            const found = data.find(d => d.id === episodeId);
            if (found) {
              acc.push(found);
            }
            return acc;
          }, [] as IEpisodeProgramDetails[])
        : data;

    return (
      <MultiSelectDetails
        timelineEpisodes={items}
        episodes={sortObjectsByIds<IEpisodeProgramDetails>(
          items.map(episode => (isSimpleScheduleEntry(episode) ? episode.episodeId : episode.id)),
          episodesDetails,
        )}
        onRemove={onRemove}
      />
    );
  }

  return null;
};
