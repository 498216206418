import * as React from 'react';
import {useLocation} from 'react-router-dom';

interface ReadOnlyURLSearchParams extends URLSearchParams {
  append: never;
  set: never;
  delete: never;
  sort: never;
}

export const useSearchParams = (): ReadOnlyURLSearchParams => {
  const {search} = useLocation();

  return React.useMemo(() => new URLSearchParams(search) as ReadOnlyURLSearchParams, [search]);
};
