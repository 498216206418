import * as React from 'react';

export type Modifiers = 'ctrl' | 'command' | 'shift';

export const useKeyboardShortcut = (shortcuts: string, callback: (event: KeyboardEvent) => void): void => {
  const handleKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      const shortcutList = shortcuts.split(',').map(s => s.trim());

      for (const shortcut of shortcutList) {
        const keys = shortcut.replace(/\s+/g, '').split('+');
        const key = keys.pop() as string;
        const isModifierKeys = keys.every(
          modifier =>
            (modifier === 'ctrl' && event.ctrlKey) ||
            (modifier === 'command' && event.metaKey) ||
            (modifier === 'shift' && event.shiftKey),
        );
        const noExtraModifiers =
          (keys.includes('ctrl') || !event.ctrlKey) &&
          (keys.includes('command') || !event.metaKey) &&
          (keys.includes('shift') || !event.shiftKey);
        if (isModifierKeys && noExtraModifiers && event.key.toLowerCase() === key) {
          callback(event);
          break;
        }
      }
    },
    [callback, shortcuts],
  );

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
};
