import * as React from 'react';
import {ISelectOption} from '@pluto-tv/assemble';

import {useFindQuery as useFindContentRatingsQuery} from 'features/contentRatings/contentRatingsApi';
import {useFindQuery as useFindAgeRangesQuery} from 'features/ageRanges/ageRangesApi';

import {useUserRegions} from './useUserRegions';

const NOT_RATED = 'Not Rated';

interface IUserRatings {
  ageRanges: ISelectOption[];
  contentRatings: ISelectOption[];
  isFetching: boolean;
  isError: boolean;
}

export const useUserModelRatings = <T>(model?: Partial<T>): IUserRatings => {
  const {ageRanges, contentRatings, isFetching, isError} = useUserRatings();

  const [ratingsOptions, setRatingsOptions] = React.useState<ISelectOption[]>([]);

  React.useEffect(() => {
    if (model) {
      setRatingsOptions(
        (contentRatings || []).filter(
          cr => cr.group?.toLowerCase() === (model as any).activeRegion || cr.label === NOT_RATED,
        ),
      );
    }
  }, [contentRatings, model]);

  return {
    ageRanges,
    isFetching,
    isError,
    contentRatings: ratingsOptions,
  };
};

export const useUserRatings = (): IUserRatings => {
  const [ratingsOptions, setRatingsOptions] = React.useState<ISelectOption[]>([]);
  const [ageRangesOptions, setAgeRangesOptions] = React.useState<ISelectOption[]>([]);

  const {data: ageRanges, isFetching: isFetchingAgeRanges, isError: isErrorAgeRanges} = useFindAgeRangesQuery();

  const {
    data: contentRatings,
    isFetching: isFetchingContentRatings,
    isError: isErrorContentRatings,
  } = useFindContentRatingsQuery();

  const {activeRegions} = useUserRegions();

  React.useEffect(() => {
    if (isErrorAgeRanges) {
      setAgeRangesOptions([]);
    }

    if (isErrorContentRatings) {
      setRatingsOptions([]);
    }
  }, [isErrorAgeRanges, isErrorContentRatings]);

  React.useEffect(() => {
    if (!isFetchingAgeRanges && ageRanges && activeRegions.length > 0) {
      const availableRanges = (ageRanges || [])
        .filter(r => activeRegions.find(ar => ar.code.toLowerCase() === r.countryCode))
        .map(a => ({
          label: a.displayName,
          value: a.id,
        }));

      setAgeRangesOptions(availableRanges);
    }
  }, [isFetchingAgeRanges, ageRanges, activeRegions]);

  React.useEffect(() => {
    if (!isFetchingContentRatings && contentRatings && activeRegions.length > 0) {
      const availableRatings = (contentRatings || [])
        .filter(cr => activeRegions.find(ar => ar.code === cr.region) || cr.region === '' || cr.value === NOT_RATED)
        .map(r => ({
          label: r.value,
          group: r.region,
          weight: +r.weight,
        }));

      setRatingsOptions(availableRatings);
    }
  }, [isFetchingContentRatings, contentRatings, activeRegions]);

  return {
    contentRatings: ratingsOptions,
    ageRanges: ageRangesOptions,
    isFetching: isFetchingAgeRanges || isFetchingContentRatings,
    isError: isErrorAgeRanges || isErrorContentRatings,
  };
};
