import {IEpisode} from 'models/episodes';

export const mapPopulatedEpisodeToEpisode = (pep: Partial<IEpisode>): any => {
  delete pep.nextTimeline;

  return {
    ...pep,
    series: pep.series && pep.series.id,
    parent: pep.parent && pep.parent.id,
    ageRange: pep.ageRange && pep.ageRange.map(ar => ar.id),
    channels: pep.channels && pep.channels.map(ch => ch.id),
    vodCategoryEntries: pep.vodCategoryEntries && pep.vodCategoryEntries.map(vc => vc.id),
    content:
      pep.content &&
      pep.content &&
      pep.content.map(ct => ({
        clip: ct.clip.id || ct.clip._id,
        inPoint: Math.ceil(ct.inPoint),
        outPoint: Math.ceil(ct.outPoint),
        adPods: ct.adPods,
      })),
    sources:
      pep.sources &&
      pep.sources.map(sc => ({
        clip: sc.clip.id || sc.clip._id,
        inPoint: Math.floor(sc.inPoint),
        outPoint: Math.floor(sc.outPoint),
        adPods: sc.adPods,
        alternativeAdPods: sc.alternativeAdPods,
      })),
    backups:
      pep.backups &&
      pep.backups.map(bk => ({
        clip: bk.clip.id,
        inPoint: bk.inPoint,
        outPoint: bk.outPoint,
      })),
    customReferences:
      pep.customReferences &&
      pep.customReferences.map(cr => ({
        id: cr.id,
        type: cr.type,
        value: cr.value.id,
      })),
  };
};
