import * as React from 'react';
import {
  Box,
  Cluster,
  Cover,
  Heading,
  Icon,
  Notification,
  Pagination,
  Paragraph,
  Sidebar,
  Spinner,
  Table,
  TdLink,
  TdTextStatus,
  Template,
} from '@pluto-tv/assemble';
import {DateTime as luxon} from 'luxon';

import {useAppPermissions} from 'app/permissions';

import NotAuthorized from 'components/notAuthorized';
import CrudError from 'components/crudError';

import {useLazyFindQuery} from 'features/runLimitTrackers/runLimitTrackersApi';

import {useUserRegions} from 'helpers/useUserRegions';
import runLimitsRoutes from 'routes/runLimits.routes';
import RunLimitTrackerSearchBar from './runLimitTrackerSearchBar';
import {IUserRunLimitTrackerSearch} from 'models/users';
import {IListPayload, TSortDirection} from 'models/generic';
import {IRunLimit} from 'models/licensedTitles';
import {withThousandsSeparator} from 'utils/thousands-separator';

const TABLE_COLUMN_NAME = {
  Title: 1,
  LF: 2,
  Partner: 3,
  'Active Region(s)': 4,
  Max: 5,
  Scheduled: 6,
} as const;

const defaultSearch: IUserRunLimitTrackerSearch = {
  name: '',
  model: {
    month: luxon.now().toFormat('MMM'),
    year: luxon.now().toFormat('yyyy'),
  },
  sortCol: 'Title' as keyof typeof TABLE_COLUMN_NAME,
  sortDir: 'asc',
};

export default (): JSX.Element => {
  const {ableTo} = useAppPermissions();

  const {
    activeRegions,
    isFetching: isFetchingRegions,
    isError: isErrorRegions,
    isSuccess: isSuccessRegions,
  } = useUserRegions();

  const [searchRunLimits, runLimitsResponse] = useLazyFindQuery();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [isSearchExpanded, setIsSearchExpanded] = React.useState(false);
  const [currentSearch, setCurrentSearch] = React.useState<IUserRunLimitTrackerSearch | undefined>(defaultSearch);

  const [runLimits, setRunLimits] = React.useState<IListPayload<IRunLimit>>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState<boolean>();

  const [sortCol, setSortCol] = React.useState<keyof typeof TABLE_COLUMN_NAME>('Title');
  const [sortDir, setSortDir] = React.useState<TSortDirection>('asc');

  const handleSearch = React.useCallback((searchParams: IUserRunLimitTrackerSearch) => {
    setCurrentSearch(searchParams);
    setPage(0);
  }, []);

  const handleClear = () => {
    setPage(0);
    setRowsPerPage(25);
    setSortCol(defaultSearch.sortCol as keyof typeof TABLE_COLUMN_NAME);
    setSortDir(defaultSearch.sortDir);
    setCurrentSearch(defaultSearch);
  };

  const performSearch = React.useCallback(
    (searchParams: IUserRunLimitTrackerSearch) => {
      searchRunLimits({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        sortfield: TABLE_COLUMN_NAME[searchParams.sortCol],
        sortdirection: searchParams.sortDir === 'asc' ? 1 : 2,
        year: searchParams.model.year!,
        month: luxon.fromFormat(searchParams.model.month!, 'MMM').toFormat('M'),
        title: searchParams.model.title,
        partnerId: searchParams.model.partnerId,
        activeRegions: (searchParams.model.activeRegions || []).map(region => region.toLowerCase()),
        licenseFee: searchParams.model.licenseFee,
        programmingType: searchParams.model.programmingType,
      });
    },
    [page, rowsPerPage, searchRunLimits],
  );

  const transformSearch = React.useCallback(() => {
    if (currentSearch && !isFetchingRegions && !isErrorRegions) {
      performSearch({
        ...currentSearch,
        model: {
          ...currentSearch.model,
          activeRegions:
            (currentSearch.model.activeRegions || []).length === 0
              ? activeRegions.map(region => region.code.toLowerCase())
              : currentSearch.model.activeRegions,
        },
      });
    }
  }, [activeRegions, currentSearch, isErrorRegions, isFetchingRegions, performSearch]);

  const changeSort = (columnName: keyof typeof TABLE_COLUMN_NAME) => {
    const newSortDir: TSortDirection = columnName === sortCol ? (sortDir === 'asc' ? 'dsc' : 'asc') : 'dsc';
    setSortCol(columnName);
    setSortDir(newSortDir);
    setCurrentSearch({
      ...currentSearch,
      sortCol: columnName,
      sortDir: newSortDir,
    } as IUserRunLimitTrackerSearch);
    setPage(0);
  };

  React.useEffect(() => {
    setRunLimits(runLimitsResponse?.data);
    setIsError(runLimitsResponse?.isError);

    if (runLimitsResponse?.isFetching || runLimitsResponse?.isLoading || runLimitsResponse?.isUninitialized) {
      setIsLoading(true);
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    }
  }, [runLimitsResponse]);

  React.useEffect(() => {
    if (isSuccessRegions) {
      transformSearch();
    }
  }, [isSuccessRegions, transformSearch]);

  if (!ableTo('RUN_LIMIT_TRACKER_VIEW')) {
    return <NotAuthorized />;
  }

  if (isError || isErrorRegions) {
    return <CrudError error='Error loading page data' />;
  }

  return (
    <Sidebar fullHeight={true}>
      <RunLimitTrackerSearchBar
        sortDir={sortDir}
        sortCol={sortCol}
        isFetching={isLoading}
        isSearchExpanded={isSearchExpanded}
        setIsSearchExpanded={setIsSearchExpanded}
        showFavoriteSearch={true}
        search={currentSearch}
        onSearch={handleSearch}
        onClear={handleClear}
      />
      <Cover
        scrolling={true}
        gutter='large'
        coverTemplateHeight='100%'
        overflow='auto'
        padding={{mobile: 'medium', wide: 'large'}}
      >
        <Template label='header'>
          <Cluster justify='space-between' align='center' space='medium'>
            <Cluster align='end' space='small'>
              <Heading level='h1'>
                Run Limit Tracker - {currentSearch?.model?.month}. {currentSearch?.model?.year}
              </Heading>
              <Cluster space='xxsmall' align='center'>
                <Icon
                  icon='tune'
                  space='xxxsmall'
                  verticalAlign='bottom'
                  lineHeight='0px'
                  onClick={() => setIsSearchExpanded(!isSearchExpanded)}
                >
                  {withThousandsSeparator(runLimits?.metadata.totalCount || 0)} Items
                </Icon>
              </Cluster>
            </Cluster>
          </Cluster>
        </Template>
        <Template label='cover'>
          <Box
            background='pewter'
            borderTop={true}
            borderSize='0.125rem'
            borderColor='cavern'
            paddingTop={((runLimits?.data?.length || 0) as number) > 0 ? 'xsmall' : 'medium'}
            paddingBottom='none'
            paddingX='large'
            fullHeight={true}
          >
            <Table
              loading={isLoading}
              fixedHeader={true}
              fixedFirstCol={true}
              id='rlt-table'
              wrapContent={true}
              onSort={column => changeSort(column as keyof typeof TABLE_COLUMN_NAME)}
              sortDir={sortDir}
              sortCol={sortCol}
              titleCols={[
                {
                  colSpan: 4,
                  label: '',
                },
                {
                  colSpan: 3,
                  label: 'RUNS',
                },
              ]}
              cols={[
                {
                  fixed: true,
                  label: 'Title',
                  sortable: true,
                  colMinWidth: '25rem',
                  transform: row => (
                    <TdLink
                      title={row.title}
                      row={row}
                      url={runLimitsRoutes.paths.licensedTitlesEditPage.replace(':id', row.licensedTitleId)}
                    />
                  ),
                },
                {
                  label: 'Active Region(s)',
                  transform: row => row.activeRegion.toUpperCase(),
                  sortable: true,
                },
                {
                  label: 'LF',
                  sortable: true,
                  field: 'licenseFee',
                },
                {
                  label: 'Partner',
                  field: 'partnerName',
                  sortable: true,
                },
                {
                  label: 'Max',
                  transform: row => `${row.runs?.max}` || 'N/A',
                  sortable: true,
                },
                {
                  label: 'Scheduled',
                  transform: row => (
                    <TdTextStatus
                      state={
                        row.runs.max > -1 ? (row.runs?.scheduled > row.runs?.max ? 'error' : 'success') : undefined
                      }
                      title={`${row.runs?.scheduled}` || 'N/A'}
                    />
                  ),
                  sortable: true,
                },
                {
                  label: 'Aired',
                  transform: row => (
                    <TdTextStatus
                      state={row.runs.max > -1 ? (row.runs?.aired > row.runs?.max ? 'error' : 'success') : undefined}
                      title={`${row.runs?.aired}` || 'N/A'}
                    />
                  ),
                },
              ]}
              rows={runLimits?.data || []}
            >
              <Template label='loading'>
                <Cluster space='small' align='center'>
                  <Spinner />
                  <Paragraph>Loading Run Limit Data</Paragraph>
                </Cluster>
              </Template>
              <Template label='empty'>
                <Notification type='warning'>There is no Run Limit Data currently available.</Notification>
              </Template>
            </Table>
          </Box>
        </Template>
        <Template label='footer'>
          <Cluster justify='space-between'>
            <div></div>
            {((runLimits?.data?.length || 0) as number) > 0 && (
              <Pagination
                perPage={rowsPerPage as 25 | 50 | 75 | 100}
                currentPage={page}
                total={runLimits?.metadata.totalCount || 0}
                onPageChange={page => {
                  setPage(page);
                  document.getElementById('rlt-table')?.parentElement?.scrollTo(0, 0);
                }}
                onPerPageChange={perPage => {
                  setRowsPerPage(perPage);
                  setPage(0);
                }}
              />
            )}
          </Cluster>
        </Template>
      </Cover>
    </Sidebar>
  );
};
