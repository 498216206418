export const activeRegionPermissions = {
  ACTIVE_REGION_VIEW: {
    enabled: ['regions_view', 'regions_edit'],
  },
  ACTIVE_REGION_CREATE: {
    enabled: ['regions_create'],
  },
  ACTIVE_REGION_EDIT: {
    enabled: ['regions_edit'],
    disabled: ['regions_view'],
  },
  ACTIVE_REGION_DELETE: {
    enabled: ['regions_delete'],
  },
};
