import * as React from 'react';

export default (): JSX.Element => {
  return (
    <svg height='56' viewBox='0 0 96 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M80.902 12C80.902 5.57591 81.497 3.9552 81.497 3.9552L79.6212 3.99719C75.1753 4.08956 71.5723 7.49054 71.5723 12.0084C71.5723 16.5262 75.1753 19.9272 79.6212 20.0196L81.497 20.0616C81.497 20.0448 80.902 18.4325 80.902 12Z'
        fill='#FF2CD2'
      ></path>
      <path
        d='M80.9021 12C80.9021 6.85233 83.6457 3.91321 83.6457 3.91321L81.4971 3.9552C77.0512 4.04757 73.4482 7.48214 73.4482 12C73.4482 16.5178 77.0512 19.9524 81.4971 20.0448L83.6457 20.0868C83.6457 20.0952 80.9021 17.1476 80.9021 12Z'
        fill='#5E00FF'
      ></path>
      <path
        d='M80.902 12C80.902 6.1889 85.7941 3.86279 85.7941 3.86279L83.6456 3.90478C79.1997 3.99715 75.5967 7.47371 75.5967 11.9916C75.5967 16.5094 79.1997 19.986 83.6456 20.0783L85.7941 20.1203C85.7941 20.1371 80.902 17.811 80.902 12Z'
        fill='#FFDE2B'
      ></path>
      <path
        d='M80.9021 12C80.9021 8.21271 83.3234 4.28269 87.6371 3.82922L85.7943 3.87121C81.3484 3.96358 77.7454 7.49053 77.7454 12.0084C77.7454 16.5262 81.3484 20.0532 85.7943 20.1455L87.6453 20.1875C83.3399 19.7173 80.9021 15.7872 80.9021 12Z'
        fill='#FF6700'
      ></path>
      <path
        d='M87.9429 3.82086C83.497 3.82086 79.894 7.48216 79.894 12C79.894 16.5179 83.497 20.1792 87.9429 20.1792C92.3888 20.1792 95.9918 16.5179 95.9918 12C96.0001 7.48216 92.3888 3.82086 87.9429 3.82086ZM91.1245 15.9972H89.6205L87.4719 10.7152H85.3151V13.436C85.3151 14.0238 85.596 14.3849 86.2489 14.3849H87.1992V15.9888H86.0257C84.4391 15.9888 83.5879 15.0735 83.5879 13.5451V10.7068H82.398V9.17006H83.5879V8.07838L85.3068 7.373V9.17006H88.6949L90.3559 13.5787L92.0335 9.12807H93.8928L91.1245 15.9972Z'
        fill='white'
      ></path>
      <path
        d='M61.2592 3.82085C56.8133 3.82085 53.2103 7.48215 53.2103 12C53.2103 16.5178 56.8133 20.1791 61.2592 20.1791C65.7051 20.1791 69.3081 16.5178 69.3081 12C69.3163 7.48215 65.7051 3.82085 61.2592 3.82085ZM61.2592 15.7537C59.218 15.7537 57.5653 14.0742 57.5653 12C57.5653 9.92582 59.218 8.24633 61.2592 8.24633C63.3003 8.24633 64.9531 9.92582 64.9531 12C64.9531 14.0742 63.3003 15.7537 61.2592 15.7537ZM48.3595 8.3471V13.4108C48.3595 14.9643 49.0371 15.6529 50.5659 15.6529H52.103V19.8684H49.8635C47.9959 19.8684 46.4671 19.3142 45.4258 18.2645C44.3846 17.2148 43.8392 15.6613 43.8392 13.7803V8.3471H41.0708V4.13156H43.8475V1.85584L48.3595 0V4.13156H52.1112V8.3471H48.3595ZM39.3024 4.13156V12.739C39.3024 16.8453 36.03 20.1791 31.9807 20.1791C27.9315 20.1791 24.659 16.8537 24.659 12.739V6.14696L29.138 4.30791V12.8817C29.138 14.4689 30.4106 15.7621 31.9725 15.7621C33.5343 15.7621 34.8069 14.4689 34.8069 12.8817V5.97061L39.3024 4.13156ZM22.7253 0V19.8684H18.2133V1.85584L22.7253 0ZM8.94964 3.82085C7.06551 3.82085 5.46234 4.46746 4.27236 5.70189L3.65258 4.17355L0 5.6683V24H4.51201V18.5836C5.68546 19.6585 7.20599 20.1791 8.94964 20.1791C11.0156 20.1791 12.9162 19.331 14.3046 17.7859C15.6763 16.2659 16.4283 14.2169 16.4283 12.0168C16.4366 7.42337 13.1476 3.82085 8.94964 3.82085ZM8.25549 15.7537C6.18129 15.7537 4.49548 14.0742 4.49548 12C4.49548 9.92582 6.17302 8.24633 8.25549 8.24633C10.3297 8.24633 12.0155 9.92582 12.0155 12C12.0155 14.0742 10.3297 15.7537 8.25549 15.7537Z'
        fill='white'
      ></path>
    </svg>
  );
};
