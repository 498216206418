import * as React from 'react';
import {some} from 'lodash-es';

import {IActiveRegion} from 'models/activeRegions';
import {ITerritory} from 'models/territory';

import {useFindQuery as useFindActiveRegionsQuery} from 'features/activeRegions/activeRegionsApi';
import {useFindQuery as useFindTerritoriesQuery} from 'features/territories/territoriesApi';

import {useAppSelector} from 'app/store/hooks';

import {getTerritoriesByRegion} from './getTerritoriesByRegion';

interface IUseUserRegions {
  activeRegions: IActiveRegion[];
  territoriesByRegion: {[id: string]: ITerritory[]};
  territories?: ITerritory[];
  isFetching: boolean;
  isError: boolean;
  isSuccess: boolean;
  allRegions: IActiveRegion[] | undefined;
}

export const useUserRegionModel = <T>(model?: T): boolean => {
  const {regions: userRegions = []} = useAppSelector(store => store.user);

  const [canViewModel, setCanViewModel] = React.useState(true);

  React.useEffect(() => {
    if (model) {
      setCanViewModel(some(userRegions || [], ur => ur.toLowerCase() === (model as any).activeRegion));
    } else {
      // Default to true for safety if the model hasn't loaded yet
      setCanViewModel(true);
    }
  }, [model, userRegions]);

  return canViewModel;
};

export const useUserRegions = (): IUseUserRegions => {
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [activeRegions, setActiveRegions] = React.useState<IActiveRegion[]>([]);
  const [territoriesByRegion, setTerritoriesByRegion] = React.useState<{[id: string]: ITerritory[]}>({});

  const {regions: userRegions = []} = useAppSelector(store => store.user);
  const {
    data: territories,
    isFetching: isTerritoriesFetching,
    isError: isTerritoriesError,
    isSuccess: isTerritoriesSuccess,
  } = useFindTerritoriesQuery();
  const {
    data: allRegions,
    isFetching: isRegionsFetching,
    isError: isRegionsError,
    isSuccess: isRegionsSuccess,
  } = useFindActiveRegionsQuery({offset: 0, limit: 100, sort: 'name:asc'});

  React.useEffect(() => {
    if (isTerritoriesSuccess && isRegionsSuccess) {
      const activeReg = allRegions?.data.filter(r => (userRegions || []).includes(r.code)) || [];
      const terrByReg = getTerritoriesByRegion(activeReg || [], territories === undefined ? [] : territories) || {};

      setActiveRegions(activeReg);
      setTerritoriesByRegion(terrByReg);
      setLoaded(true);
    }
  }, [territories, allRegions, userRegions, isRegionsSuccess, isTerritoriesSuccess]);

  return {
    activeRegions,
    territoriesByRegion,
    territories,
    isFetching: isTerritoriesFetching || isRegionsFetching,
    isError: isRegionsError || isTerritoriesError,
    isSuccess: loaded,
    allRegions: allRegions?.data,
  };
};
