import {DateTime as luxon} from 'luxon';
import {ITimeline} from 'features/channelTimelines/channelTimelinesApi';

export function hasOverlap(recurrentEventStart: luxon, recurrentEventEnd: luxon, allEvents: ITimeline[]): boolean {
  for (let i = 0; i < allEvents.length; i++) {
    const existingEventStart = luxon.fromISO(allEvents[i].start.toISOString());
    const existingEventEnd = luxon.fromISO(allEvents[i].stop.toISOString());
    const startCoincides = recurrentEventStart >= existingEventStart && recurrentEventStart < existingEventEnd;
    const endCoincides = recurrentEventEnd > existingEventStart && recurrentEventEnd <= existingEventEnd;

    // the scenario when the recurrentEvent has larger duration
    // and completely overshadows the existingEvent
    const completeOverlapCoincides = recurrentEventStart <= existingEventStart && recurrentEventEnd >= existingEventEnd;

    return startCoincides || endCoincides || completeOverlapCoincides;
  }
  return false;
}
