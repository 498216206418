import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {RootState} from 'app/store';
import {IDevice} from 'models/devices';
import {IListPayload, IListQuery} from 'models/generic';

export const devicesApi = createApi({
  reducerPath: 'devicesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/v1/',
    prepareHeaders(headers, {getState}) {
      const token = (getState() as RootState).user.jwt;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints(builder) {
    return {
      find: builder.query<IListPayload<IDevice>, IListQuery>({
        query: ({offset = 0, limit = 10}) => ({
          url: `devicetypes?offset=${offset}&limit=${limit}`,
          method: 'GET',
        }),
      }),
    };
  },
});

export const {useFindQuery} = devicesApi;
