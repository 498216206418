export const episodePermissions = {
  EPISODE_VIEW: {
    enabled: [
      'episode_view_oo',
      'episode_edit_oo',
      'episode_create_oo',
      'episode_view_partner',
      'episode_edit_partner',
      'episode_create_partner',
    ],
  },
  EPISODE_CREATE: {
    enabled: ['episode_create_oo', 'episode_create_partner'],
  },
  EPISODE_EDIT: {
    enabled: ['episode_edit_oo', 'episode_edit_partner'],
    disabled: [
      'episode_view_oo',
      'episode_delete_oo',
      'episode_create_oo',
      'episode_view_partner',
      'episode_delete_partner',
      'episode_create_partner',
    ],
  },
  EPISODE_DELETE: {
    enabled: ['episode_delete_oo', 'episode_delete_partner'],
  },
};
