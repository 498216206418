import {IChannelCatalogQuery} from 'models/channelCatalog';
import {IChannelQueueQuery} from 'models/channelQueue';
import {IChannelCatalogItemWithState} from 'views/programming/channel/contexts/memoryQueueApiProvider';

export const filterQueue = (
  queue: IChannelCatalogItemWithState[],
  searchParams?: Partial<IChannelCatalogQuery | IChannelQueueQuery>,
): IChannelCatalogItemWithState[] => {
  const {name, genre, updatedAt, rating, season, tags, genres} = searchParams || {};
  if (!name && !genre && !updatedAt && !rating && !season && !tags?.length && !genres?.length) return queue;

  return queue.filter(item => {
    const itemUpdatedAt = new Date(item.updatedAt).getTime();
    return (
      (name ? item.name.toLowerCase().includes(name.toLowerCase()) : true) &&
      (genre ? item.genre.includes(genre) : true) &&
      (genres?.length ? genres.includes(item.genre) : true) &&
      (season ? item.season === season : true) &&
      (rating ? item.rating === rating : true) &&
      (tags?.length && item.tags ? tags.some(itemModel => item.tags?.includes(itemModel)) : true) &&
      (updatedAt ? itemUpdatedAt >= updatedAt.start && itemUpdatedAt <= updatedAt.stop : true)
    );
  });
};
