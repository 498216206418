import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {RootState} from 'app/store';
import {IPermission} from 'models/permissions';
import {IListPayload, IFormPayload, IListQuery} from 'models/generic';

export const permissionsApi = createApi({
  reducerPath: 'permissionsApi',
  tagTypes: ['Permissions', 'User'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/v1/',
    prepareHeaders(headers, {getState}) {
      const token = (getState() as RootState).user.jwt;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    find: builder.query<IListPayload<IPermission>, IListQuery>({
      query: ({offset = 0, limit = 10, sort = '_id:dsc'}) => ({
        url: `permissions?offset=${offset}&limit=${limit}&sort=${sort}`,
        method: 'GET',
      }),
      providesTags: ['Permissions'],
    }),
    findById: builder.query<IPermission, string>({
      query: (id: string) => ({
        url: `permissions/${id}`,
        method: 'GET',
      }),
      providesTags: ['Permissions'],
    }),
    insert: builder.mutation<IFormPayload<IPermission>, Partial<IPermission>>({
      query: role => ({
        url: `permissions`,
        method: 'POST',
        body: role,
      }),
      invalidatesTags: ['Permissions', 'User'],
    }),
    update: builder.mutation<IFormPayload<IPermission>, {id: string; role: Partial<IPermission>}>({
      query: ({id, role}) => ({
        url: `permissions/${id}`,
        method: 'PUT',
        body: role,
      }),
      invalidatesTags: ['Permissions', 'User'],
    }),
    delete: builder.mutation<IFormPayload<IPermission>, string>({
      query: (id: string) => ({
        url: `permissions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Permissions', 'User'],
    }),
  }),
});

export const {
  useFindQuery,
  useFindByIdQuery,
  useLazyFindByIdQuery,
  useInsertMutation,
  useUpdateMutation,
  useDeleteMutation,
} = permissionsApi;
