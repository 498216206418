import {Box, Cluster, Spinner, Paragraph} from '@pluto-tv/assemble';
import * as React from 'react';

export interface ILoadingBoxProps {
  loadingText?: string;
  size?: 'small' | 'medium' | 'smallAtMobile' | 'smallAtTabletAndDown' | 'smallAtDesktopAndDown';
}

export const LoadingBox = React.memo(({loadingText = 'Loading...', size = 'medium'}: ILoadingBoxProps) => {
  return (
    <Box
      background='mist'
      paddingX='medium'
      paddingY={
        size === 'medium'
          ? 'small'
          : size === 'small'
          ? 'xxsmall'
          : size === 'smallAtMobile'
          ? {mobile: 'xxsmall', wide: 'small'}
          : size === 'smallAtTabletAndDown'
          ? {tablet: 'xxsmall', wide: 'small'}
          : size === 'smallAtDesktopAndDown'
          ? {desktop: 'xxsmall', wide: 'small'}
          : 'small'
      }
    >
      <Cluster space='small' fullWidth={true} align='center' justify='center'>
        <Spinner
          size={
            size === 'small'
              ? 'xsmall'
              : size === 'medium'
              ? 'medium'
              : size === 'smallAtMobile'
              ? {mobile: 'xsmall', wide: 'medium'}
              : size === 'smallAtTabletAndDown'
              ? {tablet: 'xsmall', wide: 'medium'}
              : size === 'smallAtDesktopAndDown'
              ? {desktop: 'xsmall', wide: 'medium'}
              : 'medium'
          }
        />
        <Paragraph
          size={
            size === 'small'
              ? 'small'
              : size === 'medium'
              ? 'medium'
              : size === 'smallAtMobile'
              ? {mobile: 'small', wide: 'medium'}
              : size === 'smallAtTabletAndDown'
              ? {tablet: 'small', wide: 'medium'}
              : size === 'smallAtDesktopAndDown'
              ? {desktop: 'small', wide: 'medium'}
              : 'medium'
          }
        >
          {loadingText}
        </Paragraph>
      </Cluster>
    </Box>
  );
});

LoadingBox.displayName = 'LoadingBox';
