import LicensedTitleListPage from 'views/runLimits/licensedTitles/list';
import LicensedTitleEditPage from 'views/runLimits/licensedTitles/edit';
import RunLimitPage from 'views/runLimits/runLimitTracker';
import {licensedTitlesPermissions} from 'views/runLimits/licensedTitles/permissions';
import {runLimitTrackerPermissions} from 'views/runLimits/runLimitTracker/permissions';
import {IRouteItem} from 'routes';

const paths = {
  licensedTitlesListPage: '/licensed-titles',
  licensedTitlesEditPage: '/licensed-titles/:id',
  runLimitTrackerPage: '/run-limit-tracker',
};

const routes: IRouteItem[] = [
  {
    name: 'Run Limits',
    icon: 'summarize',
    showInSideBar: true,
    requiredPermissions: [
      ...licensedTitlesPermissions.LICENSED_TITLES_VIEW.enabled,
      ...runLimitTrackerPermissions.RUN_LIMIT_TRACKER_VIEW.enabled,
    ],
    children: [
      {
        name: 'Run Limit Tracker',
        path: paths.runLimitTrackerPage,
        component: RunLimitPage,
        breadcrumb: {items: [{text: 'Run Limit Tracker', route: paths.runLimitTrackerPage}]},
        requiredPermissions: runLimitTrackerPermissions.RUN_LIMIT_TRACKER_VIEW.enabled,
      },
      {
        name: 'Licensed Titles',
        path: paths.licensedTitlesListPage,
        component: LicensedTitleListPage,
        breadcrumb: {items: [{text: 'Licensed Titles', route: paths.licensedTitlesListPage}]},
        requiredPermissions: licensedTitlesPermissions.LICENSED_TITLES_VIEW.enabled,
      },
    ],
  },
  {
    name: 'Licensed Title Single',
    path: paths.licensedTitlesEditPage,
    component: LicensedTitleEditPage,
    breadcrumb: {
      items: [
        {text: 'Licensed Titles', route: paths.licensedTitlesListPage},
        {text: 'Edit Licensed Title', route: paths.licensedTitlesEditPage},
      ],
    },
    requiredPermissions: licensedTitlesPermissions.LICENSED_TITLES_VIEW.enabled,
  },
];

export default {
  paths,
  routes,
};
