import * as React from 'react';
import {Box, Cover, Template, Center, Stack, Heading} from '@pluto-tv/assemble';
import PlutoTvLogo from 'assets/plutoTVLogo';

export default (): JSX.Element => {
  return (
    <>
      <Box background='slate'>
        <Cover center={true}>
          <Template label='cover'>
            <Center textCenter={true}>
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  width: '37.5rem',
                  transform: 'translate(-50%, -50%)',
                  height: '25rem',
                }}
              >
                <Stack space='xxlarge'>
                  <PlutoTvLogo />
                  <Heading level='h2'>404: Not found</Heading>
                </Stack>
              </div>
            </Center>
          </Template>
        </Cover>
      </Box>
    </>
  );
};
