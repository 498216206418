import {IActiveRegion} from 'models/activeRegions';
import {ITerritory} from 'models/territory';

export const getTerritoriesByRegion = (
  regions: IActiveRegion[],
  territories: ITerritory[],
): {[id: string]: ITerritory[]} => ({
  ['']: [],
  ...regions.reduce(
    (acum, region) => ({
      ...acum,
      [region.code]: territories?.filter(t => (region?.territories || []).includes(t.id)),
    }),
    {},
  ),
});
