import * as React from 'react';
import {debounce} from 'lodash-es';
import {
  Box,
  Cluster,
  Heading,
  Icon,
  IDraggable,
  IDroppable,
  Label,
  Paragraph,
  Popover,
  Select,
  Stack,
  Template,
  Textarea,
  TextInput,
  TSize,
  ImageWrapper,
} from '@pluto-tv/assemble';

import {ISlotBadge} from 'models/campaigns';
import DeleteConfirmation from 'components/deleteConfirmation';

import './index.css';

export interface IBillboardProps extends IDraggable, Omit<IDroppable, 'onDragOver'> {
  title: string;
  badgeData: ISlotBadge;
  tileImg: string;
  backgroundImg: string;
  height?: TSize;
  id?: string;
  state?: 'error' | 'info' | 'warning' | 'success';
  onRemove?(): void;
  onReplace?(): void;
  onReplaceImages?(): void;
  changeHandler(data: ISlotBadge): void;
  readonly: boolean;
}

const Billboard = React.memo(
  ({
    title,
    badgeData,
    tileImg,
    backgroundImg,
    height,
    onRemove,
    onReplace,
    onReplaceImages,
    changeHandler,
    onDrag,
    onDragStart,
    onDrop,
    state,
    readonly = false,
  }: IBillboardProps) => {
    const [dragOver, setDragOver] = React.useState(false);
    const [popoverVisible, setPopoverVisible] = React.useState(false);
    const togglePopoverVisible = () => {
      setPopoverVisible(!popoverVisible);
    };
    const [badgeText, setBadgeText] = React.useState('');
    const [badgeSponsoredText, setBadgeSpondoredText] = React.useState('');
    const [billboardDeleteOpen, setBillboardDeleteOpen] = React.useState(false);

    const toggleBillboardDeleteOpen = () => {
      setBillboardDeleteOpen(!billboardDeleteOpen);
    };

    const closePopoverAndExecute = (executeFn?: () => void) => {
      setPopoverVisible(false);
      if (executeFn) executeFn();
    };

    const textChanged = (val: string) => {
      setBadgeText(val);
      debouncedText(val);
    };

    const sponsorChanged = (val: string) => {
      setBadgeSpondoredText(val);
      debouncedSponsor(val);
    };

    const onBlurText = () => changeHandler({...badgeData, text: badgeText});
    const onBlurSponsored = () => changeHandler({...badgeData, sponsoredText: badgeSponsoredText});

    const debouncedText = React.useMemo(
      () => debounce(val => changeHandler({...badgeData, text: val}), 500),
      [badgeData, changeHandler],
    );
    const debouncedSponsor = React.useMemo(
      () => debounce(val => changeHandler({...badgeData, sponsoredText: val}), 500),
      [badgeData, changeHandler],
    );

    React.useEffect(() => {
      if (badgeData) {
        setBadgeText(badgeData.text || '');
        setBadgeSpondoredText(badgeData.sponsoredText || '');
      }
    }, [badgeData]);

    return (
      <div
        className={dragOver ? 'drag-over billboard' : 'billboard'}
        {...(onDrop && {
          onDrop: ev => {
            setDragOver(false);
            onDrop(ev);
          },
        })}
        onDragOver={ev => {
          ev.preventDefault();
          setDragOver(true);
        }}
        onDragLeave={() => setDragOver(false)}
        onDragEnd={() => setDragOver(false)}
      >
        <Box
          padding='small'
          borderRadius='0.5rem'
          height={height}
          borderColor={state ? state : 'transparent'}
          borderSize='0.1rem'
          borderStyle='solid'
          borderBottom={true}
          borderTop={true}
          borderRight={true}
          borderLeft={true}
          background='onyx'
          backgroundHover='cavern'
        >
          <Stack space='small'>
            <Cluster justify='space-between' wrap={false}>
              <Cluster space='small' align='flex-start' wrap={false} fullWidth={true}>
                {!readonly && <Icon icon='drag' onDrag={onDrag} onDragStart={onDragStart} draggable />}
                <Heading level='h4' truncate={true} truncateBackgroundHover='onyx'>
                  {title}
                </Heading>
              </Cluster>
              {!readonly && (
                <Popover manualTrigger={true} visible={popoverVisible} onClickOutside={() => setPopoverVisible(false)}>
                  <Template label='trigger'>
                    <Icon
                      icon='more'
                      onClick={() => {
                        togglePopoverVisible();
                        popoverVisible && billboardDeleteOpen ? toggleBillboardDeleteOpen() : null;
                      }}
                    />
                  </Template>
                  <Template label='popover'>
                    <Box padding='xxsmall' height='auto'>
                      <Stack space='medium'>
                        <Paragraph>
                          <Icon icon='findreplace' space='xxsmall' onClick={() => closePopoverAndExecute(onReplace)}>
                            Replace Content
                          </Icon>
                        </Paragraph>
                        <Paragraph>
                          <Icon icon='image' space='xxsmall' onClick={() => closePopoverAndExecute(onReplaceImages)}>
                            Replace Images
                          </Icon>
                        </Paragraph>
                        <Popover
                          manualTrigger={true}
                          visible={billboardDeleteOpen}
                          appendToBody={true}
                          onClickOutside={() => {
                            setPopoverVisible(false);
                            setBillboardDeleteOpen(false);
                          }}
                        >
                          <Template label='trigger'>
                            <Icon
                              icon='delete'
                              space='xxsmall'
                              onClick={() => toggleBillboardDeleteOpen()}
                              hoverColor='deleteLight'
                              textColor={billboardDeleteOpen ? 'deleteLight' : 'inherit'}
                            >
                              Remove
                            </Icon>
                          </Template>
                          <Template label='popover'>
                            <DeleteConfirmation
                              message={'Are you sure you want to delete ' + title + '?'}
                              cancelButtonFunction={() => {
                                closePopoverAndExecute(toggleBillboardDeleteOpen);
                              }}
                              proceedButtonFunction={() => {
                                toggleBillboardDeleteOpen();
                                closePopoverAndExecute(onRemove);
                              }}
                            />
                          </Template>
                        </Popover>
                      </Stack>
                    </Box>
                  </Template>
                </Popover>
              )}
            </Cluster>
            <Stack space='xxxsmall'>
              <Label>Tile</Label>
              <ImageWrapper src={tileImg} height='7.8125rem' />
            </Stack>
            <Stack space='xxxsmall'>
              <Label>Background</Label>
              <ImageWrapper src={backgroundImg} height='7.8125rem' />
            </Stack>
            <TextInput
              value={badgeData.text}
              onChange={value => textChanged(value)}
              onBlur={() => onBlurText()}
              placeholder='Enter Text for Badge'
              state={readonly ? 'disabled' : ''}
            />
            <Select
              clearable={true}
              state={readonly ? 'disabled' : ''}
              valueAsPill={true}
              placeholder='Enter Badge Color'
              value={{label: badgeData.backgroundColor || '', value: badgeData.backgroundColor || ''}}
              options={[
                {label: 'Pluto Blue', value: '#5E00FF', backgroundColor: '#5E00FF', textColor: '#FFFFFF'},
                {label: 'Alert Red', value: '#DE1C22', backgroundColor: '#DE1C22', textColor: '#FFFFFF'},
                {label: 'Gray', value: '#3D3D3D', backgroundColor: '#3D3D3D', textColor: '#FFFFFF'},
                {label: 'Pluto Pink', value: '#FF2CD2', backgroundColor: '#FF2CD2', textColor: '#FFFFFF'},
                {label: 'Pluto Orange', value: '#FF6700', backgroundColor: '#FF6700', textColor: '#FFFFFF'},
              ]}
              predicate='value'
              onChange={value => changeHandler({...badgeData, backgroundColor: value?.value || ''})}
            />
            <Textarea
              state={readonly ? 'disabled' : ''}
              value={badgeData.sponsoredText}
              onChange={value => sponsorChanged(value)}
              onBlur={() => onBlurSponsored()}
              placeholder='Enter Additional Text (Optional)'
              minHeight='6.25rem'
            />
          </Stack>
        </Box>
      </div>
    );
  },
);

Billboard.displayName = 'Billboard';
export default Billboard;
