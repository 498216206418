import {DateTime as luxon} from 'luxon';

export const tableDateTimeFormat = 'MM/dd/yyyy, h:mm a';
export const lauchCardsDateTimeFormat = 'dddd, M/D/YYYY, h:mm A';
export const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const generateYears = (startYear: number, endYear: number): string[] => {
  if (endYear < startYear) throw new Error('End year must be greater than start year');
  const years: string[] = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push(year.toString());
  }
  return years;
};

export const createDate = (
  month: typeof monthNames[number],
  year: string,
  startOrEnd: 'start' | 'end' = 'start',
): Date => {
  const yearNumber = parseInt(year, 10);
  const monthIndex = monthNames.indexOf(month) + 1;

  if (startOrEnd === 'start') {
    return luxon.local(yearNumber, monthIndex).startOf('month').startOf('day').toJSDate();
  }
  return luxon.local(yearNumber, monthIndex).endOf('month').endOf('day').toJSDate();
};

export const getCurrentYear = (): string => {
  return new Date().getFullYear().toString();
};

export const getCurrentMonth = (): string => {
  return monthNames[new Date().getMonth()];
};
