export const rolePermissions = {
  ROLE_VIEW: {
    enabled: ['role_view', 'role_edit', 'role_create'],
  },
  ROLE_CREATE: {
    enabled: ['role_create'],
  },
  ROLE_EDIT: {
    enabled: ['role_edit'],
    disabled: ['role_view', 'role_delete', 'role_create'],
  },
  ROLE_DELETE: {
    enabled: ['role_delete'],
  },
};
