import {intersection} from 'lodash-es';

// sorted by name using natural sort order
const dmaDistributionCodes = [
  {
    id: 662,
    name: 'Abilene-Sweetwater',
    label: 'Abilene-Sweetwater: 662',
    value: 662,
  },
  {
    id: 532,
    name: 'Albany-Schenectady-Troy',
    label: 'Albany-Schenectady-Troy: 532',
    value: 532,
  },
  {
    id: 525,
    name: 'Albany, GA',
    label: 'Albany, GA: 525',
    value: 525,
  },
  {
    id: 790,
    name: 'Albuquerque-santa Fe',
    label: 'Albuquerque-santa Fe: 790',
    value: 790,
  },
  {
    id: 644,
    name: 'Alexandria, LA',
    label: 'Alexandria, LA: 644',
    value: 644,
  },
  {
    id: 583,
    name: 'Alpena',
    label: 'Alpena: 583',
    value: 583,
  },
  {
    id: 634,
    name: 'Amarillo',
    label: 'Amarillo: 634',
    value: 634,
  },
  {
    id: 743,
    name: 'Anchorage',
    label: 'Anchorage: 743',
    value: 743,
  },
  {
    id: 524,
    name: 'Atlanta',
    label: 'Atlanta: 524',
    value: 524,
  },
  {
    id: 520,
    name: 'Augusta-Aiken',
    label: 'Augusta-Aiken: 520',
    value: 520,
  },
  {
    id: 635,
    name: 'Austin',
    label: 'Austin: 635',
    value: 635,
  },
  {
    id: 800,
    name: 'Bakersfield',
    label: 'Bakersfield: 800',
    value: 800,
  },
  {
    id: 512,
    name: 'Baltimore',
    label: 'Baltimore: 512',
    value: 512,
  },
  {
    id: 537,
    name: 'Bangor',
    label: 'Bangor: 537',
    value: 537,
  },
  {
    id: 716,
    name: 'Baton Rouge',
    label: 'Baton Rouge: 716',
    value: 716,
  },
  {
    id: 692,
    name: 'Beaumont-Port Arthur',
    label: 'Beaumont-Port Arthur: 692',
    value: 692,
  },
  {
    id: 821,
    name: 'Bend, OR',
    label: 'Bend, OR: 821',
    value: 821,
  },
  {
    id: 756,
    name: 'Billings',
    label: 'Billings: 756',
    value: 756,
  },
  {
    id: 746,
    name: 'Biloxi-Gulfport',
    label: 'Biloxi-Gulfport: 746',
    value: 746,
  },
  {
    id: 502,
    name: 'Binghamton',
    label: 'Binghamton: 502',
    value: 502,
  },
  {
    id: 630,
    name: 'Birmingham (Ann and Tusc)',
    label: 'Birmingham (Ann and Tusc): 630',
    value: 630,
  },
  {
    id: 559,
    name: 'Bluefield-Beckley-Oak Hill',
    label: 'Bluefield-Beckley-Oak Hill: 559',
    value: 559,
  },
  {
    id: 757,
    name: 'Boise',
    label: 'Boise: 757',
    value: 757,
  },
  {
    id: 506,
    name: 'Boston (Manchester)',
    label: 'Boston (Manchester): 506',
    value: 506,
  },
  {
    id: 736,
    name: 'Bowling Green',
    label: 'Bowling Green: 736',
    value: 736,
  },
  {
    id: 514,
    name: 'Buffalo',
    label: 'Buffalo: 514',
    value: 514,
  },
  {
    id: 523,
    name: 'Burlington-Plattsburgh',
    label: 'Burlington-Plattsburgh: 523',
    value: 523,
  },
  {
    id: 754,
    name: 'Butte-Bozeman',
    label: 'Butte-Bozeman: 754',
    value: 754,
  },
  {
    id: 767,
    name: 'Casper-Riverton',
    label: 'Casper-Riverton: 767',
    value: 767,
  },
  {
    id: 637,
    name: 'Cedar Rapids-Wtrlo-IWC&DUB',
    label: 'Cedar Rapids-Wtrlo-IWC&DUB: 637',
    value: 637,
  },
  {
    id: 648,
    name: 'Champaign&Sprngfld-Decatur',
    label: 'Champaign&Sprngfld-Decatur: 648',
    value: 648,
  },
  {
    id: 564,
    name: 'Charleston-huntington',
    label: 'Charleston-huntington: 564',
    value: 564,
  },
  {
    id: 519,
    name: 'Charleston, SC',
    label: 'Charleston, SC: 519',
    value: 519,
  },
  {
    id: 517,
    name: 'Charlotte',
    label: 'Charlotte: 517',
    value: 517,
  },
  {
    id: 584,
    name: 'Charlottesville',
    label: 'Charlottesville: 584',
    value: 584,
  },
  {
    id: 575,
    name: 'Chattanooga',
    label: 'Chattanooga: 575',
    value: 575,
  },
  {
    id: 759,
    name: 'Cheyenne-Scottsbluff',
    label: 'Cheyenne-Scottsbluff: 759',
    value: 759,
  },
  {
    id: 602,
    name: 'Chicago',
    label: 'Chicago: 602',
    value: 602,
  },
  {
    id: 868,
    name: 'Chico-Redding',
    label: 'Chico-Redding: 868',
    value: 868,
  },
  {
    id: 515,
    name: 'Cincinnati',
    label: 'Cincinnati: 515',
    value: 515,
  },
  {
    id: 598,
    name: 'Clarksburg-Weston',
    label: 'Clarksburg-Weston: 598',
    value: 598,
  },
  {
    id: 510,
    name: 'Cleveland-Akron (Canton)',
    label: 'Cleveland-Akron (Canton): 510',
    value: 510,
  },
  {
    id: 752,
    name: 'Colorado Springs-Pueblo',
    label: 'Colorado Springs-Pueblo: 752',
    value: 752,
  },
  {
    id: 604,
    name: 'Columbia-Jefferson City',
    label: 'Columbia-Jefferson City: 604',
    value: 604,
  },
  {
    id: 546,
    name: 'Columbia, SC',
    label: 'Columbia, SC: 546',
    value: 546,
  },
  {
    id: 673,
    name: 'Columbus-Tupelo-W Pnt-Hstn',
    label: 'Columbus-Tupelo-W Pnt-Hstn: 673',
    value: 673,
  },
  {
    id: 522,
    name: 'Columbus, GA (Opelika, AL)',
    label: 'Columbus, GA (Opelika, AL): 522',
    value: 522,
  },
  {
    id: 535,
    name: 'Columbus, Oh',
    label: 'Columbus, Oh: 535',
    value: 535,
  },
  {
    id: 600,
    name: 'Corpus Christi',
    label: 'Corpus Christi: 600',
    value: 600,
  },
  {
    id: 623,
    name: 'Dallas-Ft. Worth',
    label: 'Dallas-Ft. Worth: 623',
    value: 623,
  },
  {
    id: 682,
    name: 'Davenport-R.Island-Moline',
    label: 'Davenport-R.Island-Moline: 682',
    value: 682,
  },
  {
    id: 542,
    name: 'Dayton',
    label: 'Dayton: 542',
    value: 542,
  },
  {
    id: 751,
    name: 'Denver',
    label: 'Denver: 751',
    value: 751,
  },
  {
    id: 679,
    name: 'Des Moines-Ames',
    label: 'Des Moines-Ames: 679',
    value: 679,
  },
  {
    id: 505,
    name: 'Detroit',
    label: 'Detroit: 505',
    value: 505,
  },
  {
    id: 606,
    name: 'Dothan',
    label: 'Dothan: 606',
    value: 606,
  },
  {
    id: 676,
    name: 'Duluth-Superior',
    label: 'Duluth-Superior: 676',
    value: 676,
  },
  {
    id: 765,
    name: 'El Paso (Las Cruces)',
    label: 'El Paso (Las Cruces): 765',
    value: 765,
  },
  {
    id: 565,
    name: 'Elmira (Corning)',
    label: 'Elmira (Corning): 565',
    value: 565,
  },
  {
    id: 516,
    name: 'Erie',
    label: 'Erie: 516',
    value: 516,
  },
  {
    id: 801,
    name: 'Eugene',
    label: 'Eugene: 801',
    value: 801,
  },
  {
    id: 802,
    name: 'Eureka',
    label: 'Eureka: 802',
    value: 802,
  },
  {
    id: 649,
    name: 'Evansville',
    label: 'Evansville: 649',
    value: 649,
  },
  {
    id: 745,
    name: 'Fairbanks',
    label: 'Fairbanks: 745',
    value: 745,
  },
  {
    id: 724,
    name: 'Fargo-Valley City',
    label: 'Fargo-Valley City: 724',
    value: 724,
  },
  {
    id: 513,
    name: 'Flint-Saginaw-Bay City',
    label: 'Flint-Saginaw-Bay City: 513',
    value: 513,
  },
  {
    id: 866,
    name: 'Fresno-Visalia',
    label: 'Fresno-Visalia: 866',
    value: 866,
  },
  {
    id: 571,
    name: 'Ft. Myers-Naples',
    label: 'Ft. Myers-Naples: 571',
    value: 571,
  },
  {
    id: 670,
    name: 'Ft. Smith-Fay-Sprngdl-Rgrs',
    label: 'Ft. Smith-Fay-Sprngdl-Rgrs: 670',
    value: 670,
  },
  {
    id: 509,
    name: 'Ft. Wayne',
    label: 'Ft. Wayne: 509',
    value: 509,
  },
  {
    id: 592,
    name: 'Gainesville',
    label: 'Gainesville: 592',
    value: 592,
  },
  {
    id: 798,
    name: 'Glendive',
    label: 'Glendive: 798',
    value: 798,
  },
  {
    id: 773,
    name: 'Grand Junction-Montrose',
    label: 'Grand Junction-Montrose: 773',
    value: 773,
  },
  {
    id: 563,
    name: 'Grand Rapids-Kalmzoo-B.CRK',
    label: 'Grand Rapids-Kalmzoo-B.CRK: 563',
    value: 563,
  },
  {
    id: 755,
    name: 'Great Falls',
    label: 'Great Falls: 755',
    value: 755,
  },
  {
    id: 658,
    name: 'Green Bay-Appleton',
    label: 'Green Bay-Appleton: 658',
    value: 658,
  },
  {
    id: 518,
    name: 'Greensboro-H.Point-W.Salem',
    label: 'Greensboro-H.Point-W.Salem: 518',
    value: 518,
  },
  {
    id: 545,
    name: 'Greenville-N. Bern-Washngtn',
    label: 'Greenville-N. Bern-Washngtn: 545',
    value: 545,
  },
  {
    id: 567,
    name: 'Greenvll-Spart-Ashevll-And',
    label: 'Greenvll-Spart-Ashevll-And: 567',
    value: 567,
  },
  {
    id: 647,
    name: 'Greenwood-Greenville',
    label: 'Greenwood-Greenville: 647',
    value: 647,
  },
  {
    id: 636,
    name: 'Harlingen-Wslco-Brnsvl-MCA',
    label: 'Harlingen-Wslco-Brnsvl-MCA: 636',
    value: 636,
  },
  {
    id: 566,
    name: 'Harrisburg-Lncstr-Leb-York',
    label: 'Harrisburg-Lncstr-Leb-York: 566',
    value: 566,
  },
  {
    id: 569,
    name: 'Harrisonburg',
    label: 'Harrisonburg: 569',
    value: 569,
  },
  {
    id: 533,
    name: 'Hartford & New Haven',
    label: 'Hartford & New Haven: 533',
    value: 533,
  },
  {
    id: 710,
    name: 'Hattiesburg-Laurel',
    label: 'Hattiesburg-Laurel: 710',
    value: 710,
  },
  {
    id: 766,
    name: 'Helena',
    label: 'Helena: 766',
    value: 766,
  },
  {
    id: 744,
    name: 'Honolulu',
    label: 'Honolulu: 744',
    value: 744,
  },
  {
    id: 618,
    name: 'Houston',
    label: 'Houston: 618',
    value: 618,
  },
  {
    id: 691,
    name: 'Huntsville-Decatur (Flor)',
    label: 'Huntsville-Decatur (Flor): 691',
    value: 691,
  },
  {
    id: 758,
    name: 'Idaho Falls-Pocatllo(Jcksn)',
    label: 'Idaho Falls-Pocatllo(Jcksn): 758',
    value: 758,
  },
  {
    id: 527,
    name: 'Indianapolis',
    label: 'Indianapolis: 527',
    value: 527,
  },
  {
    id: 718,
    name: 'Jackson, MS',
    label: 'Jackson, MS: 718',
    value: 718,
  },
  {
    id: 639,
    name: 'Jackson, TN',
    label: 'Jackson, TN: 639',
    value: 639,
  },
  {
    id: 561,
    name: 'Jacksonville',
    label: 'Jacksonville: 561',
    value: 561,
  },
  {
    id: 574,
    name: 'Johnstown-Altoona-St Colge',
    label: 'Johnstown-Altoona-St Colge: 574',
    value: 574,
  },
  {
    id: 734,
    name: 'Jonesboro',
    label: 'Jonesboro: 734',
    value: 734,
  },
  {
    id: 603,
    name: 'Joplin-Pittsburg',
    label: 'Joplin-Pittsburg: 603',
    value: 603,
  },
  {
    id: 747,
    name: 'Juneau',
    label: 'Juneau: 747',
    value: 747,
  },
  {
    id: 616,
    name: 'Kansas City',
    label: 'Kansas City: 616',
    value: 616,
  },
  {
    id: 557,
    name: 'Knoxville',
    label: 'Knoxville: 557',
    value: 557,
  },
  {
    id: 702,
    name: 'La Crosse-Eau Claire',
    label: 'La Crosse-Eau Claire: 702',
    value: 702,
  },
  {
    id: 582,
    name: 'Lafayette, IN',
    label: 'Lafayette, IN: 582',
    value: 582,
  },
  {
    id: 642,
    name: 'Lafayette, LA',
    label: 'Lafayette, LA: 642',
    value: 642,
  },
  {
    id: 643,
    name: 'Lake Charles',
    label: 'Lake Charles: 643',
    value: 643,
  },
  {
    id: 551,
    name: 'Lansing',
    label: 'Lansing: 551',
    value: 551,
  },
  {
    id: 749,
    name: 'Laredo',
    label: 'Laredo: 749',
    value: 749,
  },
  {
    id: 839,
    name: 'Las Vegas',
    label: 'Las Vegas: 839',
    value: 839,
  },
  {
    id: 541,
    name: 'Lexington',
    label: 'Lexington: 541',
    value: 541,
  },
  {
    id: 558,
    name: 'Lima',
    label: 'Lima: 558',
    value: 558,
  },
  {
    id: 722,
    name: 'Lincoln & Hastings-Krny',
    label: 'Lincoln & Hastings-Krny: 722',
    value: 722,
  },
  {
    id: 693,
    name: 'Little Rock-Pine Bluff',
    label: 'Little Rock-Pine Bluff: 693',
    value: 693,
  },
  {
    id: 803,
    name: 'Los Angeles',
    label: 'Los Angeles: 803',
    value: 803,
  },
  {
    id: 529,
    name: 'Louisville',
    label: 'Louisville: 529',
    value: 529,
  },
  {
    id: 651,
    name: 'Lubbock',
    label: 'Lubbock: 651',
    value: 651,
  },
  {
    id: 503,
    name: 'Macon',
    label: 'Macon: 503',
    value: 503,
  },
  {
    id: 669,
    name: 'Madison',
    label: 'Madison: 669',
    value: 669,
  },
  {
    id: 737,
    name: 'Mankato',
    label: 'Mankato: 737',
    value: 737,
  },
  {
    id: 553,
    name: 'Marquette',
    label: 'Marquette: 553',
    value: 553,
  },
  {
    id: 813,
    name: 'Medford-Klamath Falls',
    label: 'Medford-Klamath Falls: 813',
    value: 813,
  },
  {
    id: 640,
    name: 'Memphis',
    label: 'Memphis: 640',
    value: 640,
  },
  {
    id: 711,
    name: 'Meridian',
    label: 'Meridian: 711',
    value: 711,
  },
  {
    id: 528,
    name: 'Miami-Ft. Lauderdale',
    label: 'Miami-Ft. Lauderdale: 528',
    value: 528,
  },
  {
    id: 617,
    name: 'Milwaukee',
    label: 'Milwaukee: 617',
    value: 617,
  },
  {
    id: 613,
    name: 'Minneapolis-St. Paul',
    label: 'Minneapolis-St. Paul: 613',
    value: 613,
  },
  {
    id: 687,
    name: 'Minot-Bsmrck-Dcknsn(Wlstn)',
    label: 'Minot-Bsmrck-Dcknsn(Wlstn): 687',
    value: 687,
  },
  {
    id: 762,
    name: 'Missoula',
    label: 'Missoula: 762',
    value: 762,
  },
  {
    id: 686,
    name: 'Mobile-Pensacola (Ft Walt)',
    label: 'Mobile-Pensacola (Ft Walt): 686',
    value: 686,
  },
  {
    id: 628,
    name: 'Monroe-El Dorado',
    label: 'Monroe-El Dorado: 628',
    value: 628,
  },
  {
    id: 828,
    name: 'Monterey-Salinas',
    label: 'Monterey-Salinas: 828',
    value: 828,
  },
  {
    id: 698,
    name: 'Montgomery-Selma',
    label: 'Montgomery-Selma: 698',
    value: 698,
  },
  {
    id: 570,
    name: 'Myrtle Beach-Florence',
    label: 'Myrtle Beach-Florence: 570',
    value: 570,
  },
  {
    id: 659,
    name: 'Nashville',
    label: 'Nashville: 659',
    value: 659,
  },
  {
    id: 622,
    name: 'New Orleans',
    label: 'New Orleans: 622',
    value: 622,
  },
  {
    id: 501,
    name: 'New York',
    label: 'New York: 501',
    value: 501,
  },
  {
    id: 544,
    name: 'Norfolk-Portsmth-Newpt NWS',
    label: 'Norfolk-Portsmth-Newpt NWS: 544',
    value: 544,
  },
  {
    id: 740,
    name: 'North Platte',
    label: 'North Platte: 740',
    value: 740,
  },
  {
    id: 633,
    name: 'Odessa-Midland',
    label: 'Odessa-Midland: 633',
    value: 633,
  },
  {
    id: 650,
    name: 'Oklahoma City',
    label: 'Oklahoma City: 650',
    value: 650,
  },
  {
    id: 652,
    name: 'Omaha',
    label: 'Omaha: 652',
    value: 652,
  },
  {
    id: 534,
    name: 'Orlando-Daytona Bch-Melbrn',
    label: 'Orlando-Daytona Bch-Melbrn: 534',
    value: 534,
  },
  {
    id: 631,
    name: 'Ottumwa-Kirksville',
    label: 'Ottumwa-Kirksville: 631',
    value: 631,
  },
  {
    id: 632,
    name: 'Paducah-Cape Girard-Harsbg',
    label: 'Paducah-Cape Girard-Harsbg: 632',
    value: 632,
  },
  {
    id: 804,
    name: 'Palm Springs',
    label: 'Palm Springs: 804',
    value: 804,
  },
  {
    id: 656,
    name: 'Panama City',
    label: 'Panama City: 656',
    value: 656,
  },
  {
    id: 597,
    name: 'Parkersburg',
    label: 'Parkersburg: 597',
    value: 597,
  },
  {
    id: 675,
    name: 'Peoria-Bloomington',
    label: 'Peoria-Bloomington: 675',
    value: 675,
  },
  {
    id: 504,
    name: 'Philadelphia',
    label: 'Philadelphia: 504',
    value: 504,
  },
  {
    id: 753,
    name: 'Phoenix (Prescott)',
    label: 'Phoenix (Prescott): 753',
    value: 753,
  },
  {
    id: 508,
    name: 'Pittsburgh',
    label: 'Pittsburgh: 508',
    value: 508,
  },
  {
    id: 500,
    name: 'Portland-Auburn',
    label: 'Portland-Auburn: 500',
    value: 500,
  },
  {
    id: 820,
    name: 'Portland, OR',
    label: 'Portland, OR: 820',
    value: 820,
  },
  {
    id: 552,
    name: 'Presque Isle',
    label: 'Presque Isle: 552',
    value: 552,
  },
  {
    id: 521,
    name: 'Providence-New Bedford',
    label: 'Providence-New Bedford: 521',
    value: 521,
  },
  {
    id: 717,
    name: 'Quincy-Hannibal-Keokuk',
    label: 'Quincy-Hannibal-Keokuk: 717',
    value: 717,
  },
  {
    id: 560,
    name: 'Raleigh-Durham (Fayetvlle)',
    label: 'Raleigh-Durham (Fayetvlle): 560',
    value: 560,
  },
  {
    id: 764,
    name: 'Rapid City',
    label: 'Rapid City: 764',
    value: 764,
  },
  {
    id: 811,
    name: 'Reno',
    label: 'Reno: 811',
    value: 811,
  },
  {
    id: 556,
    name: 'Richmond-Petersburg',
    label: 'Richmond-Petersburg: 556',
    value: 556,
  },
  {
    id: 573,
    name: 'Roanoke-Lynchburg',
    label: 'Roanoke-Lynchburg: 573',
    value: 573,
  },
  {
    id: 538,
    name: 'Rochester, NY',
    label: 'Rochester, NY: 538',
    value: 538,
  },
  {
    id: 611,
    name: 'Rochestr-Mason City-Austin',
    label: 'Rochestr-Mason City-Austin: 611',
    value: 611,
  },
  {
    id: 610,
    name: 'Rockford',
    label: 'Rockford: 610',
    value: 610,
  },
  {
    id: 862,
    name: 'Sacramnto-Stkton-Modesto',
    label: 'Sacramnto-Stkton-Modesto: 862',
    value: 862,
  },
  {
    id: 576,
    name: 'Salisbury',
    label: 'Salisbury: 576',
    value: 576,
  },
  {
    id: 770,
    name: 'Salt Lake City',
    label: 'Salt Lake City: 770',
    value: 770,
  },
  {
    id: 661,
    name: 'San Angelo',
    label: 'San Angelo: 661',
    value: 661,
  },
  {
    id: 641,
    name: 'San Antonio',
    label: 'San Antonio: 641',
    value: 641,
  },
  {
    id: 825,
    name: 'San Diego',
    label: 'San Diego: 825',
    value: 825,
  },
  {
    id: 807,
    name: 'San Francisco-Oak-San Jose',
    label: 'San Francisco-Oak-San Jose: 807',
    value: 807,
  },
  {
    id: 855,
    name: 'Santabarbra-Sanmar-Sanluob',
    label: 'Santabarbra-Sanmar-Sanluob: 855',
    value: 855,
  },
  {
    id: 507,
    name: 'Savannah',
    label: 'Savannah: 507',
    value: 507,
  },
  {
    id: 819,
    name: 'Seattle-Tacoma',
    label: 'Seattle-Tacoma: 819',
    value: 819,
  },
  {
    id: 657,
    name: 'Sherman-Ada',
    label: 'Sherman-Ada: 657',
    value: 657,
  },
  {
    id: 612,
    name: 'Shreveport',
    label: 'Shreveport: 612',
    value: 612,
  },
  {
    id: 624,
    name: 'Sioux City',
    label: 'Sioux City: 624',
    value: 624,
  },
  {
    id: 725,
    name: 'Sioux Falls (Mitchell)',
    label: 'Sioux Falls (Mitchell): 725',
    value: 725,
  },
  {
    id: 588,
    name: 'South Bend-Elkhart',
    label: 'South Bend-Elkhart: 588',
    value: 588,
  },
  {
    id: 881,
    name: 'Spokane',
    label: 'Spokane: 881',
    value: 881,
  },
  {
    id: 543,
    name: 'Springfield-Holyoke',
    label: 'Springfield-Holyoke: 543',
    value: 543,
  },
  {
    id: 619,
    name: 'Springfield, MO',
    label: 'Springfield, MO: 619',
    value: 619,
  },
  {
    id: 638,
    name: 'St. Joseph',
    label: 'St. Joseph: 638',
    value: 638,
  },
  {
    id: 609,
    name: 'St. Louis',
    label: 'St. Louis: 609',
    value: 609,
  },
  {
    id: 555,
    name: 'Syracuse',
    label: 'Syracuse: 555',
    value: 555,
  },
  {
    id: 530,
    name: 'Tallahassee-Thomasville',
    label: 'Tallahassee-Thomasville: 530',
    value: 530,
  },
  {
    id: 539,
    name: 'Tampa-St. Pete (Sarasota)',
    label: 'Tampa-St. Pete (Sarasota): 539',
    value: 539,
  },
  {
    id: 581,
    name: 'Terre Haute',
    label: 'Terre Haute: 581',
    value: 581,
  },
  {
    id: 547,
    name: 'Toledo',
    label: 'Toledo: 547',
    value: 547,
  },
  {
    id: 605,
    name: 'Topeka',
    label: 'Topeka: 605',
    value: 605,
  },
  {
    id: 540,
    name: 'Traverse City-Cadillac',
    label: 'Traverse City-Cadillac: 540',
    value: 540,
  },
  {
    id: 531,
    name: 'Tri-Cities, TN-VA',
    label: 'Tri-Cities, TN-VA: 531',
    value: 531,
  },
  {
    id: 789,
    name: 'Tucson (Sierra Vista)',
    label: 'Tucson (Sierra Vista): 789',
    value: 789,
  },
  {
    id: 671,
    name: 'Tulsa',
    label: 'Tulsa: 671',
    value: 671,
  },
  {
    id: 760,
    name: 'Twin Falls',
    label: 'Twin Falls: 760',
    value: 760,
  },
  {
    id: 709,
    name: 'Tyler-Longview (Lfkn&ncgd)',
    label: 'Tyler-Longview (Lfkn&ncgd): 709',
    value: 709,
  },
  {
    id: 0,
    name: 'Unknown DMA',
    label: 'Unknown DMA: 0',
    value: 0,
  },
  {
    id: 526,
    name: 'Utica',
    label: 'Utica: 526',
    value: 526,
  },
  {
    id: 626,
    name: 'Victoria',
    label: 'Victoria: 626',
    value: 626,
  },
  {
    id: 625,
    name: 'Waco-Temple-Bryan',
    label: 'Waco-Temple-Bryan: 625',
    value: 625,
  },
  {
    id: 511,
    name: 'Washington, DC (Hagrstwn)',
    label: 'Washington, DC (Hagrstwn): 511',
    value: 511,
  },
  {
    id: 549,
    name: 'Watertown',
    label: 'Watertown: 549',
    value: 549,
  },
  {
    id: 705,
    name: 'Wausau-Rhinelander',
    label: 'Wausau-Rhinelander: 705',
    value: 705,
  },
  {
    id: 548,
    name: 'West Palm Beach-Ft. Pierce',
    label: 'West Palm Beach-Ft. Pierce: 548',
    value: 548,
  },
  {
    id: 554,
    name: 'Wheeling-Steubenville',
    label: 'Wheeling-Steubenville: 554',
    value: 554,
  },
  {
    id: 627,
    name: 'Wichita Falls & Lawton',
    label: 'Wichita Falls & Lawton: 627',
    value: 627,
  },
  {
    id: 678,
    name: 'Wichita-Hutchinson Plus',
    label: 'Wichita-Hutchinson Plus: 678',
    value: 678,
  },
  {
    id: 577,
    name: 'Wilkes Barre-Scranton-Hztn',
    label: 'Wilkes Barre-Scranton-Hztn: 577',
    value: 577,
  },
  {
    id: 550,
    name: 'Wilmington',
    label: 'Wilmington: 550',
    value: 550,
  },
  {
    id: 810,
    name: 'Yakima-Pasco-Rchlnd-Knnwck',
    label: 'Yakima-Pasco-Rchlnd-Knnwck: 810',
    value: 810,
  },
  {
    id: 536,
    name: 'Youngstown',
    label: 'Youngstown: 536',
    value: 536,
  },
  {
    id: 771,
    name: 'Yuma-El Centro',
    label: 'Yuma-El Centro: 771',
    value: 771,
  },
  {
    id: 596,
    name: 'Zanesville',
    label: 'Zanesville: 596',
    value: 596,
  },
];

export const sortCodes = (codes: number[], asc = true): number[] => {
  const sortedCodes =
    intersection(
      dmaDistributionCodes.map(e => e.id),
      codes,
    ) || [];
  if (!asc) {
    return sortedCodes.reverse();
  }
  return sortedCodes;
};

export default dmaDistributionCodes;
