export const campaignPermissions = {
  CAMPAIGN_VIEW: {
    enabled: ['campaign_view', 'campaign_edit', 'campaign_create'],
  },
  CAMPAIGN_CREATE: {
    enabled: ['campaign_create'],
  },
  CAMPAIGN_EDIT: {
    enabled: ['campaign_edit'],
    disabled: ['campaign_view', 'campaign_delete', 'campaign_create'],
  },
  CAMPAIGN_DELETE: {
    enabled: ['campaign_delete'],
  },
};
