import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

import {RootState} from 'app/store';
import {ILicensedTitle} from 'models/licensedTitles';
import {IListPayload, IListQuery} from 'models/generic';
import {TProgrammingTypes} from 'helpers/programmingTypes';

interface IListLicensedTitleQuery extends Omit<IListQuery, 'search' | 'sort'> {
  title?: string;
  regions?: string[];
  partner?: string;
  sortfield?: number;
  sortdirection?: number;
  programmingType?: TProgrammingTypes;
}

export const licensedTitlesApi = createApi({
  reducerPath: 'licensedTitlesApi',
  tagTypes: ['LicensedTitles'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/v1/',
    prepareHeaders(headers, {getState}) {
      const token = (getState() as RootState).user.jwt;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    find: builder.query<IListPayload<ILicensedTitle>, IListLicensedTitleQuery>({
      query: ({
        offset = 0,
        limit = 10,
        sortfield = 1,
        sortdirection = 1,
        title,
        partner,
        regions = [],
        programmingType,
      }) => {
        let url = `rlt/licensed-titles?offset=${offset}&limit=${limit}&sortfield=${sortfield}&sortdirection=${sortdirection}`;

        if (title) {
          url = `${url}&title=${title}`;
        }

        if (partner) {
          url = `${url}&partner=${partner}`;
        }

        if (regions && regions.length > 0) {
          regions.forEach(region => {
            url = `${url}&region=${region}`;
          });
        }

        if (programmingType) {
          url = `${url}&programmingtype=${programmingType as string}`;
        }

        return {
          url,
          method: 'GET',
        };
      },
      providesTags: ['LicensedTitles'],
    }),
    findById: builder.query<ILicensedTitle, string>({
      query: (id: string) => ({
        url: `rlt/licensed-titles/${id}`,
        method: 'GET',
      }),
      providesTags: ['LicensedTitles'],
    }),
  }),
});

export const {useFindQuery, useLazyFindQuery, useFindByIdQuery, useLazyFindByIdQuery} = licensedTitlesApi;
