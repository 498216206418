import {Heading, Icon, Stack} from '@pluto-tv/assemble';
import * as React from 'react';
import {IStateMsgList} from 'views/programming/channel/utils';

interface IWarningsProps {
  title?: string;
  warnings: IStateMsgList[];
}

export default ({title, warnings}: IWarningsProps): React.ReactElement | null => {
  return (
    <Stack space='xsmall'>
      {title && (
        <Heading color='warning' level='h4'>
          {title}
        </Heading>
      )}
      <Stack space='xxxsmall'>
        {warnings.map((warning, index) => (
          <Icon
            key={index}
            icon={warning.icon as 'warning' | 'error'}
            space='small'
            color={warning.color as 'error' | 'warning'}
          >
            {warning.label}
          </Icon>
        ))}
      </Stack>
    </Stack>
  );
};
