import {
  Box,
  Button,
  Click,
  Cluster,
  Cover,
  Divider,
  Grid,
  Heading,
  Paragraph,
  Sidebar,
  Stack,
  Template,
} from '@pluto-tv/assemble';
import * as React from 'react';

import AssetForm from 'components/assetForm';

import {useAppPermissions} from 'app/permissions';
import {cloneDeep} from 'lodash-es';
import {INestedChannelProps} from '../nestedPropsInterface';

export default function ArtworkPage({model, setFields}: INestedChannelProps): JSX.Element {
  const {permissions} = useAppPermissions();

  const cloneExistingArtwork = React.useCallback(() => {
    setFields({
      featuredImageAlt: cloneDeep(model.featuredImage),
      thumbnailAlt: cloneDeep(model.thumbnail),
      logoAlt: cloneDeep(model.logo),
      solidLogoPNGAlt: cloneDeep(model.solidLogoPNG),
      colorLogoPNGAlt: cloneDeep(model.colorLogoPNG),
      solidLogoSVGAlt: cloneDeep(model.solidLogoSVG),
      colorLogoSVGAlt: cloneDeep(model.colorLogoSVG),
    });
  }, [model, setFields]);

  return (
    <Box borderTop={true} borderSize='0.125rem' borderColor='cavern' background='pewter' fullHeight={true}>
      <Sidebar side='right' fullHeight={true} gap='large' backgroundSidebar='slate'>
        <Cover scrolling={true} paddingTop='large'>
          <Template label='cover'>
            <Stack space='xxxxxxlarge'>
              <Stack space='large'>
                <Box paddingX={{mobile: 'medium', wide: 'xlarge'}}>
                  <Heading level='h2'>Channel Artwork</Heading>
                </Box>
                <Grid maxCols={2} minimum='25rem' gap='xxxxxsmall' background='shadow'>
                  <Box
                    paddingY={{mobile: 'medium', wide: 'large'}}
                    paddingX={{mobile: 'medium', wide: 'xlarge'}}
                    background='pewter'
                  >
                    <AssetForm
                      assetType='featured16_9'
                      asset={model.featuredImage}
                      contentType='channels'
                      contentId={model.id || ''}
                      name='Channel Featured'
                      width='30.75rem'
                      height='17.375rem'
                      id='channelFeatured'
                      maxFileSizeKB={2048}
                      minResolutionWidth={1560}
                      minResolutionHeight={878}
                      aspectRatioWidth={16}
                      aspectRatioHeight={9}
                      fileTypes={['image/jpeg']}
                      permission={permissions.CHANNEL_EDIT}
                      defaultUrl='http://pluto.tv.s3.amazonaws.com/assets/images/default/channel.id-featured-default.jpg'
                      onChange={(name, url) => {
                        setFields({featuredImage: {title: name, path: url}});
                      }}
                    />
                  </Box>
                  <Box
                    paddingY={{mobile: 'medium', wide: 'large'}}
                    paddingX={{mobile: 'medium', wide: 'xlarge'}}
                    background='pewter'
                  >
                    <AssetForm
                      assetType='hero1_1'
                      asset={model.thumbnail}
                      contentType='channels'
                      contentId={model.id || ''}
                      name='Channel Hero'
                      width='17.375rem'
                      height='17.375rem'
                      id='channelHero'
                      maxFileSizeKB={2048}
                      minResolutionWidth={660}
                      minResolutionHeight={660}
                      aspectRatioWidth={1}
                      aspectRatioHeight={1}
                      permission={permissions.CHANNEL_EDIT}
                      defaultUrl='http://pluto.tv.s3.amazonaws.com/assets/images/default/channel.id-thumbnail-default.jpg'
                      onChange={(name, url) => {
                        setFields({thumbnail: {title: name, path: url}});
                      }}
                    />
                  </Box>
                  <Box
                    paddingY={{mobile: 'medium', wide: 'large'}}
                    paddingX={{mobile: 'medium', wide: 'xlarge'}}
                    background='pewter'
                  >
                    <Stack space='xxxlarge'>
                      <AssetForm
                        assetType='logo7_2'
                        asset={model.logo}
                        contentType='channels'
                        contentId={model.id || ''}
                        fileTypes={['image/jpeg', 'image/png']}
                        name='Channel Logo'
                        width='17.5rem'
                        height='15rem'
                        id='channelLogo'
                        maxFileSizeKB={2048}
                        minResolutionWidth={280}
                        minResolutionHeight={80}
                        aspectRatioWidth={7}
                        aspectRatioHeight={2}
                        permission={permissions.CHANNEL_EDIT}
                        onChange={(name, url) => {
                          setFields({logo: {title: name, path: url}});
                        }}
                      />
                      <Divider color='graphite' />
                      <AssetForm
                        assetType='logo7_2'
                        asset={model.solidLogoPNG}
                        contentType='channels'
                        contentId={model.id || ''}
                        name='Channel Logo PNG Solid'
                        width='17.5rem'
                        height='15rem'
                        id='channelLogoPngSolid'
                        maxFileSizeKB={2048}
                        fileTypes={['image/png']}
                        minResolutionWidth={288}
                        minResolutionHeight={144}
                        aspectRatioWidth={2}
                        aspectRatioHeight={1}
                        permission={permissions.CHANNEL_EDIT}
                        onChange={(name, url) => {
                          setFields({solidLogoPNG: {title: name, path: url}});
                        }}
                      />
                      <Divider color='graphite' />
                      <AssetForm
                        assetType='logo7_2'
                        asset={model.colorLogoPNG}
                        contentType='channels'
                        contentId={model.id || ''}
                        name='Channel Logo PNG Colored'
                        width='17.5rem'
                        height='15rem'
                        id='channelLogoPngColored'
                        maxFileSizeKB={2048}
                        fileTypes={['image/png']}
                        minResolutionWidth={288}
                        minResolutionHeight={144}
                        aspectRatioWidth={2}
                        aspectRatioHeight={1}
                        permission={permissions.CHANNEL_EDIT}
                        onChange={(name, url) => {
                          setFields({colorLogoPNG: {title: name, path: url}});
                        }}
                      />
                      <Divider color='graphite' />
                      <AssetForm
                        assetType='logo7_2'
                        asset={model.solidLogoSVG}
                        contentType='channels'
                        contentId={model.id || ''}
                        name='Channel Logo | SVG Solid'
                        width='17.5rem'
                        height='15rem'
                        id='channelLogoSvgSolid'
                        maxFileSizeKB={2048}
                        fileTypes={['image/svg+xml']}
                        minResolutionWidth={288}
                        minResolutionHeight={144}
                        aspectRatioWidth={2}
                        aspectRatioHeight={1}
                        permission={permissions.CHANNEL_EDIT}
                        onChange={(name, url) => {
                          setFields({solidLogoSVG: {title: name, path: url}});
                        }}
                      />
                      <Divider color='graphite' />
                      <AssetForm
                        assetType='logo7_2'
                        asset={model.colorLogoSVG}
                        contentType='channels'
                        contentId={model.id || ''}
                        name='Channel Logo | SVG Colored'
                        width='17.5rem'
                        height='15rem'
                        id='channelLogoSvgColored'
                        maxFileSizeKB={2048}
                        fileTypes={['image/svg+xml']}
                        minResolutionWidth={288}
                        minResolutionHeight={144}
                        aspectRatioWidth={2}
                        aspectRatioHeight={1}
                        permission={permissions.CHANNEL_EDIT}
                        onChange={(name, url) => {
                          setFields({colorLogoSVG: {title: name, path: url}});
                        }}
                      />
                    </Stack>
                  </Box>
                  <Box
                    paddingY={{mobile: 'medium', wide: 'large'}}
                    paddingX={{mobile: 'medium', wide: 'xlarge'}}
                    background='pewter'
                  >
                    <Stack space='xxxlarge'>
                      <AssetForm
                        assetType='background4_1'
                        asset={model.tile}
                        contentType='channels'
                        contentId={model.id || ''}
                        name='Channel Tile Background'
                        width='31.25rem'
                        height='15rem'
                        id='channelTileBackground'
                        maxFileSizeKB={2048}
                        fileTypes={['image/jpeg']}
                        minResolutionWidth={600}
                        minResolutionHeight={150}
                        aspectRatioWidth={4}
                        aspectRatioHeight={1}
                        permission={permissions.CHANNEL_EDIT}
                        defaultUrl='http://pluto.tv.s3.amazonaws.com/assets/images/default/channel.id-background-default.jpg'
                        onChange={(name, url) => {
                          setFields({tile: {title: name, path: url}});
                        }}
                      />
                      <Divider color='graphite' />
                      <AssetForm
                        assetType='background4_1'
                        asset={model.tileGrayscale}
                        contentType='channels'
                        contentId={model.id || ''}
                        name='Channel Tile Grayscale'
                        width='31.25rem'
                        height='15rem'
                        id='channelTileGrayscale'
                        maxFileSizeKB={2048}
                        fileTypes={['image/jpeg']}
                        minResolutionWidth={600}
                        minResolutionHeight={150}
                        aspectRatioWidth={4}
                        aspectRatioHeight={1}
                        permission={permissions.CHANNEL_EDIT}
                        onChange={(name, url) => {
                          setFields({tileGrayscale: {title: name, path: url}});
                        }}
                      />
                      <Divider color='graphite' />
                      <AssetForm
                        assetType='browse128_15'
                        asset={model.browse}
                        contentType='channels'
                        contentId={model.id || ''}
                        name='Channel Browse'
                        width='31.25rem'
                        height='15rem'
                        fileTypes={['image/png']}
                        id='channelBrowse'
                        maxFileSizeKB={2048}
                        minResolutionWidth={2560}
                        minResolutionHeight={300}
                        aspectRatioWidth={128}
                        aspectRatioHeight={15}
                        permission={permissions.CHANNEL_EDIT}
                        onChange={(name, url) => {
                          setFields({browse: {title: name, path: url}});
                        }}
                      />
                    </Stack>
                  </Box>
                </Grid>
              </Stack>
              <Stack space='large'>
                <Box paddingX={{mobile: 'medium', wide: 'xlarge'}}>
                  <Stack space='medium'>
                    <Heading level='h2'>Distribution Partners Non-Sponsored Artwork</Heading>
                    <Cluster justify='space-between' align='center' space='medium'>
                      <Paragraph color='secondary'>
                        Artwork uploaded in these areas are only utilized in content feeds, and should not include any
                        sponsored logos.
                      </Paragraph>
                      <Button permission={permissions.CHANNEL_EDIT} onClick={cloneExistingArtwork}>
                        Add Existing Artwork
                      </Button>
                    </Cluster>
                  </Stack>
                </Box>
                <Grid maxCols={2} minimum='25rem' gap='xxxxxsmall' background='shadow'>
                  <Box
                    paddingY={{mobile: 'medium', wide: 'large'}}
                    paddingX={{mobile: 'medium', wide: 'xlarge'}}
                    background='pewter'
                  >
                    <AssetForm
                      assetType='featured16_9'
                      asset={model.featuredImageAlt}
                      contentType='channels'
                      contentId={model.id || ''}
                      name='Channel Featured'
                      width='30.75rem'
                      height='17.375rem'
                      id='channelFeaturedNonSponsored'
                      maxFileSizeKB={2048}
                      fileTypes={['image/jpeg']}
                      minResolutionWidth={1560}
                      minResolutionHeight={878}
                      aspectRatioWidth={16}
                      aspectRatioHeight={9}
                      permission={permissions.CHANNEL_EDIT}
                      onChange={(name, url) => {
                        setFields({featuredImageAlt: {title: name, path: url}});
                      }}
                    />
                  </Box>
                  <Box
                    paddingY={{mobile: 'medium', wide: 'large'}}
                    paddingX={{mobile: 'medium', wide: 'xlarge'}}
                    background='pewter'
                  >
                    <AssetForm
                      assetType='hero1_1'
                      asset={model.thumbnailAlt}
                      contentType='channels'
                      contentId={model.id || ''}
                      name='Channel Hero'
                      width='17.375rem'
                      height='17.375rem'
                      id='channelHeroNonSponsored'
                      maxFileSizeKB={2048}
                      minResolutionWidth={660}
                      minResolutionHeight={660}
                      aspectRatioWidth={1}
                      aspectRatioHeight={1}
                      permission={permissions.CHANNEL_EDIT}
                      onChange={(name, url) => {
                        setFields({thumbnailAlt: {title: name, path: url}});
                      }}
                    />
                  </Box>
                  <Box
                    paddingY={{mobile: 'medium', wide: 'large'}}
                    paddingX={{mobile: 'medium', wide: 'xlarge'}}
                    background='pewter'
                  >
                    <Stack space='xxxlarge'>
                      <AssetForm
                        assetType='logo7_2'
                        asset={model.logoAlt}
                        contentType='channels'
                        contentId={model.id || ''}
                        name='Channel Logo'
                        width='17.5rem'
                        height='15rem'
                        fileTypes={['image/jpeg', 'image/png']}
                        id='channelLogoNonSponsored'
                        maxFileSizeKB={2048}
                        minResolutionWidth={280}
                        minResolutionHeight={80}
                        aspectRatioWidth={7}
                        aspectRatioHeight={2}
                        permission={permissions.CHANNEL_EDIT}
                        onChange={(name, url) => {
                          setFields({logoAlt: {title: name, path: url}});
                        }}
                      />
                      <Divider color='graphite' />
                      <AssetForm
                        assetType='logo7_2'
                        asset={model.solidLogoPNGAlt}
                        contentType='channels'
                        contentId={model.id || ''}
                        name='Channel Logo PNG Solid'
                        width='17.5rem'
                        height='15rem'
                        id='channelLogoPngSolidNonSponsored'
                        maxFileSizeKB={2048}
                        fileTypes={['image/png']}
                        minResolutionWidth={288}
                        minResolutionHeight={144}
                        aspectRatioWidth={2}
                        aspectRatioHeight={1}
                        permission={permissions.CHANNEL_EDIT}
                        onChange={(name, url) => {
                          setFields({solidLogoPNGAlt: {title: name, path: url}});
                        }}
                      />
                      <Divider color='graphite' />
                      <AssetForm
                        assetType='logo7_2'
                        asset={model.colorLogoPNGAlt}
                        contentType='channels'
                        contentId={model.id || ''}
                        name='Channel Logo PNG Colored'
                        width='17.5rem'
                        height='15rem'
                        id='channelLogoPngColoredNonSponsored'
                        maxFileSizeKB={2048}
                        fileTypes={['image/png']}
                        minResolutionWidth={288}
                        minResolutionHeight={144}
                        aspectRatioWidth={2}
                        aspectRatioHeight={1}
                        permission={permissions.CHANNEL_EDIT}
                        onChange={(name, url) => {
                          setFields({colorLogoPNGAlt: {title: name, path: url}});
                        }}
                      />
                      <Divider color='graphite' />
                      <AssetForm
                        assetType='logo7_2'
                        asset={model.solidLogoSVGAlt}
                        contentType='channels'
                        contentId={model.id || ''}
                        name='Channel Logo | SVG Solid'
                        width='17.5rem'
                        height='15rem'
                        id='channelLogoSvgSolidNonSponsored'
                        maxFileSizeKB={2048}
                        fileTypes={['image/svg+xml']}
                        minResolutionWidth={288}
                        minResolutionHeight={144}
                        aspectRatioWidth={2}
                        aspectRatioHeight={1}
                        permission={permissions.CHANNEL_EDIT}
                        onChange={(name, url) => {
                          setFields({solidLogoSVGAlt: {title: name, path: url}});
                        }}
                      />
                      <Divider color='graphite' />
                      <AssetForm
                        assetType='logo7_2'
                        asset={model.colorLogoSVGAlt}
                        contentType='channels'
                        contentId={model.id || ''}
                        name='Channel Logo | SVG Colored'
                        width='17.5rem'
                        height='15rem'
                        id='channelLogoSvgColoredNonSponsored'
                        maxFileSizeKB={2048}
                        fileTypes={['image/svg+xml']}
                        minResolutionWidth={288}
                        minResolutionHeight={144}
                        aspectRatioWidth={2}
                        aspectRatioHeight={1}
                        permission={permissions.CHANNEL_EDIT}
                        onChange={(name, url) => {
                          setFields({colorLogoSVGAlt: {title: name, path: url}});
                        }}
                      />
                    </Stack>
                  </Box>
                </Grid>
              </Stack>
            </Stack>
          </Template>
        </Cover>
        <Cover scrolling={true} paddingTop='large'>
          <Template label='cover'>
            <Box paddingY='large' paddingX='xlarge'>
              <Stack space='xxxxlarge'>
                <Stack space='large'>
                  <Heading level='h3' color='highlight'>
                    Channel Artwork
                  </Heading>
                  <Click display='block' href='#channelFeatured'>
                    Channel Featured
                  </Click>
                  <Click display='block' href='#channelHero'>
                    Channel Hero
                  </Click>
                  <Click display='block' href='#channelLogo'>
                    Channel Logo
                  </Click>
                  <Click display='block' href='#channelLogoPngSolid'>
                    Channel Logo (PNG Solid)
                  </Click>
                  <Click display='block' href='#channelLogoPngColored'>
                    Channel Logo (PNG Colored)
                  </Click>
                  <Click display='block' href='#channelLogoSvgSolid'>
                    Channel Logo (SVG Solid)
                  </Click>
                  <Click display='block' href='#channelLogoSvgColored'>
                    Channel Logo (SVG Colored)
                  </Click>
                  <Click display='block' href='#channelTileBackground'>
                    Channel Tile Background
                  </Click>
                  <Click display='block' href='#channelTileGrayscale'>
                    Channel Tile Grayscale
                  </Click>
                  <Click display='block' href='#channelBrowse'>
                    Channel Browse
                  </Click>
                </Stack>
                <Stack space='large'>
                  <Heading level='h3' color='highlight'>
                    Non-Sponsored Artwork
                  </Heading>
                  <Click display='block' href='#channelFeaturedNonSponsored'>
                    Channel Featured
                  </Click>
                  <Click display='block' href='#channelHeroNonSponsored'>
                    Channel Hero
                  </Click>
                  <Click display='block' href='#channelLogoNonSponsored'>
                    Channel Logo
                  </Click>
                  <Click display='block' href='#channelLogoPngSolidNonSponsored'>
                    Channel Logo (PNG Solid)
                  </Click>
                  <Click display='block' href='#channelLogoPngColoredNonSponsored'>
                    Channel Logo (PNG Colored)
                  </Click>
                  <Click display='block' href='#channelLogoSvgSolidNonSponsored'>
                    Channel Logo (SVG Solid)
                  </Click>
                  <Click display='block' href='#channelLogoSvgColoredNonSponsored'>
                    Channel Logo (SVG Colored)
                  </Click>
                </Stack>
              </Stack>
            </Box>
          </Template>
        </Cover>
      </Sidebar>
    </Box>
  );
}
