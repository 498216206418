export const appNamePermissions = {
  APPNAME_VIEW: {
    enabled: ['appname_view', 'appname_edit', 'appname_create'],
  },
  APPNAME_CREATE: {
    enabled: ['appname_create'],
  },
  APPNAME_EDIT: {
    enabled: ['appname_edit'],
    disabled: ['appname_view', 'appname_delete', 'appname_create'],
  },
  APPNAME_DELETE: {
    enabled: ['appname_delete'],
  },
};
