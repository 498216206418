import * as React from 'react';
import {useFindQuery as useFindLanguagesQuery} from 'features/languages/languagesApi';
import {orderBy} from 'lodash-es';

// Keep compatibility with CMS Classic.
// See https://plutotv.atlassian.net/browse/CMS-2275
import {default as isoLanguages} from 'iso-639-1';
import {ISelectOption} from '@pluto-tv/assemble';
import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';
import {SerializedError} from '@reduxjs/toolkit';

interface IUseLanguages {
  languageList: ISelectOption[];
  languagesError: FetchBaseQueryError | SerializedError | undefined;
  searchLanguages: (searchStr: string) => ISelectOption[];
  isLanguagesFetching: boolean;
  isLanguagesError: boolean;
}

function searchLanguages(searchStr: string, languages: ISelectOption[]): ISelectOption[] {
  return orderBy(
    languages.filter(language => language.label.toLowerCase().indexOf(searchStr.toLowerCase()) > -1),
    'label',
  );
}

export const useLanguageList = (): IUseLanguages => {
  const {
    data: languages,
    isError: isLanguagesError,
    error: languagesError,
    isFetching: isLanguagesFetching,
  } = useFindLanguagesQuery();

  const languageList = orderBy(
    (languages || []).map(language => {
      return {
        label: isoLanguages.getName(language.iso639_1) || language.name,
        value: language.iso639_1,
      };
    }),
    'label',
  );

  return {
    languageList,
    isLanguagesError,
    languagesError,
    isLanguagesFetching,
    searchLanguages: React.useCallback(searchString => searchLanguages(searchString, languageList), [languageList]),
  };
};
