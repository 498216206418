import * as React from 'react';
import {IDateRange, isValEmpty, useCancellableSummon} from '@pluto-tv/assemble';
import {cloneDeep, throttle} from 'lodash-es';
import {IVodCollection} from 'models/vodCollections';
import {IActiveRegion} from 'models/activeRegions';
import {IElasticSearchRes, IListPayload, IListQuery} from 'models/generic';

import {useUserRegions} from 'helpers/useUserRegions';
import {IVodCategoryTitle, IVodEntryAvodAvailWindow} from 'models/vodCategoryEntry';

interface IVodCollectionSearch extends Partial<Omit<IVodCollection, 'published' | 'activeRegion'>>, IListQuery {
  // Author needs to be simplified from an object to a string
  author?: string;
  // Certain fields are expected to be a string representation of a boolean in the API
  isUsed?: string;
  promotional?: string;
  liveBroadcast?: string;
  published?: string;
  // Active Region needs to be an array
  activeRegion?: string[];
  // regionFilter -> territories
  territories?: string[];
}

export interface IVodCollectionMetaSearch {
  uploadDate?: IDateRange;
  duration?: IVodCollection;
  window?: 'day' | 'week' | 'month';
}

interface IUseVodCollectionSearch {
  vodCollection?: IListPayload<IVodCollection>;
  isError: boolean;
  isFetching: boolean;
  rowsPerPage: 25 | 50 | 75 | 100;
  setRowsPerPage;
  page: number;
  setPage;
  sort: string;
  setSort;
  clearSearch: (doSearch?: boolean) => void;
  search: (searchModel?: Partial<IVodCollection>, searchMeta?: IVodCollectionMetaSearch, sort?: string) => void;
}

const transformReq = (
  model: Partial<IVodCollection>,
  page: number,
  perPage: 25 | 50 | 75 | 100,
  sort: string,
  searchMeta?: IVodCollectionMetaSearch,
  activeRegions?: IActiveRegion[],
): IVodCollectionSearch => {
  const searchObj = cloneDeep(model) as IVodCollectionSearch;

  if (model.activeRegion) {
    searchObj.activeRegion = [model.activeRegion];
  } else {
    searchObj.activeRegion = activeRegions?.map(ar => ar.code.toLowerCase());
  }

  return {
    ...searchObj,
    sort,
    limit: perPage,
    offset: page * perPage,
  };
};

export const defaultSearch: Partial<IVodCollection> = {
  provider: 'jwplatform',
} as const;

const defaultSort = 'createdAt:desc';

export const useVodCollectionSearch = (): IUseVodCollectionSearch => {
  const [isFetching, setIsFetching] = React.useState(true);
  const [isError, setIsError] = React.useState(false);

  const [vodCollection, setVodCollection] = React.useState<IListPayload<IVodCollection>>();

  const [rowsPerPage, setRowsPerPage] = React.useState<25 | 50 | 75 | 100>(25);
  const [page, setPage] = React.useState<number>(0);
  const [sort, setSort] = React.useState<string>(defaultSort);

  const {activeRegions} = useUserRegions();
  const [summon] = useCancellableSummon();

  const clearSearch = async (doSearch = false): Promise<void> => {
    setPage(0);
    setSort(defaultSort);

    if (doSearch) {
      await search({}, {}, defaultSort);
    }
  };

  const search = throttle(
    async (searchModel?: Partial<IVodCollection>, searchMeta?: IVodCollectionMetaSearch, customSort?: string) => {
      setIsFetching(true);

      const mySort = customSort ? customSort : sort;

      let cleanedModel: Partial<IVodCollection> = {};

      if (!searchModel || isValEmpty(searchModel)) {
        cleanedModel = cloneDeep(defaultSearch);
      } else {
        Object.keys(searchModel).forEach(key => {
          const fieldName: keyof IVodCollection = key as keyof IVodCollection;

          if (!isValEmpty(searchModel[fieldName])) {
            cleanedModel[fieldName] = cloneDeep(searchModel[fieldName]) as any;
          }
        });
      }

      const fullReq = transformReq(cleanedModel, page, rowsPerPage, mySort, searchMeta, activeRegions);

      try {
        const res = await summon.post<IVodCollectionSearch, IElasticSearchRes<IVodCollection>>(
          'vodCollections',
          fullReq,
        );
        const data = res.hits.hits.map(hit => hit._source);

        setVodCollection({
          data,
          metadata: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            totalCount: res.hits.total,
          },
        });
      } catch (e) {
        setIsError(true);
      } finally {
        setIsFetching(false);
      }
    },
    150,
    {leading: false, trailing: true},
  );

  return {
    vodCollection,
    isError,
    isFetching,
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    sort,
    setSort,
    search,
    clearSearch,
  };
};

export type rowState = 'error' | 'info' | 'warning' | 'success' | 'disabled' | 'highlight' | '';

export interface IRowStateAndMessage {
  message: string;
  state: rowState;
}

export const isAvailWindowWithinRange = ({startDate, endDate}: IVodEntryAvodAvailWindow): boolean => {
  const {currentDate, start, end} = {
    currentDate: new Date(),
    start: new Date(startDate),
    end: new Date(endDate),
  };
  return start <= currentDate && end >= currentDate;
};

export const getVodEntriesItemState = (item: IVodCategoryTitle): IRowStateAndMessage[] => {
  const ifNoAvailWindows = !item['availabilityWindows'] || !item.availabilityWindows.length;
  const outsideWindow = !item?.availabilityWindows?.find(av => isAvailWindowWithinRange(av));

  const contentIconAndMessage = [] as IRowStateAndMessage[];

  if (item.contentType === 'episode') {
    if (item.published === false || item.published === undefined) {
      contentIconAndMessage.push({
        state: 'warning',
        message: 'The film is not published.',
      });
    }
    if (item.has_unique_provider === false) {
      contentIconAndMessage.push({
        state: 'warning',
        message: 'The film uses clips with mixed providers.',
      });
    }
    if (!item.promotional_adpods && (item.ad_pods === undefined || item.ad_pods === 0)) {
      contentIconAndMessage.push({
        state: 'warning',
        message: 'The film has no adPods.',
      });
    }
    if (ifNoAvailWindows || outsideWindow) {
      contentIconAndMessage.push({
        state: 'warning',
        message: 'The film has no availability, or is being used outside of its availability window.',
      });
    }
    if (item.all_clips_ready === undefined || item.all_clips_ready === false) {
      contentIconAndMessage.push({
        state: 'error',
        message: 'At least one clip in the film is not ready.',
      });
    }
  }

  if (item.contentType === 'series') {
    if (item.published === undefined || item.published === false) {
      contentIconAndMessage.push({
        state: 'warning',
        message: 'The series is not published.',
      });
    }

    if (ifNoAvailWindows || outsideWindow) {
      contentIconAndMessage.push({
        state: 'warning',
        message: 'The series has no availability, or is being used outside of its availability window.',
      });
    }
  }

  return contentIconAndMessage;
};
