import * as React from 'react';
import {IFeaturedGroup} from 'models/featuredGroup';
import {useSearchQuery} from 'features/featuredGroups/featuredGroupsApi';

interface IUseLaunchCardsReturnType {
  cards: IFeaturedGroup[];
}

const useLaunchCards = (activeRegion: string, publishedUpcomingData: IFeaturedGroup[]): IUseLaunchCardsReturnType => {
  const [cards, setCards] = React.useState<IFeaturedGroup[]>([]);

  const params = React.useMemo(() => {
    return {
      offset: 0,
      limit: 1,
      sort: 'start:asc',
      published: true,
      activeRegion: activeRegion,
    };
  }, [activeRegion]);

  const publishedGroupUpcomingData = React.useMemo(
    () =>
      publishedUpcomingData?.filter(
        data => data.published && data.activeRegion.toUpperCase() === activeRegion.toUpperCase(),
      ),
    [activeRegion, publishedUpcomingData],
  );

  const {data: publishedFeaturedGroup} = useSearchQuery(params, {
    refetchOnMountOrArgChange: true,
    skip: !activeRegion || publishedGroupUpcomingData.length > 0,
  });

  React.useEffect(() => {
    const published = publishedFeaturedGroup?.data?.length ? publishedFeaturedGroup.data : publishedGroupUpcomingData;

    const cards = [
      ...published,
      ...publishedUpcomingData.filter(
        data => !data.published && new Date(data.start).getTime() >= new Date().getTime(),
      ),
    ].splice(0, 2);

    setCards(cards);
  }, [activeRegion, publishedFeaturedGroup?.data, publishedGroupUpcomingData, publishedUpcomingData]);

  return {cards};
};

export default useLaunchCards;
