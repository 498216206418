import {IValidatorField} from '@pluto-tv/assemble';

import {ICampaign, ICampaignSlot} from 'models/campaigns';

export const campaignDetailsValidator: IValidatorField<ICampaign>[] = [
  {
    name: 'name',
    label: 'Campaign Title',
    required: true,
    validators: [
      (title: string): string | undefined => {
        if (!title || !title.trim().length) {
          return 'Campaign Title is required';
        } else if (title && title.trim().length > 100) {
          return 'Campaign Title must be less than 100 characters';
        }
      },
    ],
  },
  {
    name: 'displayName',
    label: 'Campaign Display Name',
    validators: [
      (name: string): string | undefined => {
        if (name && name.trim().length > 40) {
          return 'Campaign Display Name must be less than 40 characters';
        }
      },
    ],
  },
  {
    name: 'description',
    label: 'Campaign Description',
    required: false,
    validators: [
      (desc: string): string | undefined => {
        if (desc && desc.trim().length > 255) {
          return 'Campaign Description must be less than 255 characters';
        }
      },
    ],
  },
];

export const distributionTargetValidator: IValidatorField<ICampaign>[] = [
  {
    name: 'distribution',
    required: true,
    validators: [
      (targets: any[], campaign: Partial<ICampaign>): string | undefined => {
        if (campaign.published && (!targets || !targets.length)) {
          return 'Select at least one Distribution Target';
        }
      },
    ],
  },
];

export const targetRegionValidator: IValidatorField<ICampaign>[] = [
  {
    name: 'activeRegion',
    label: 'Target Region',
    required: true,
    validators: [
      (region: string): string | undefined => {
        if (!region) {
          return 'Target Region is required';
        }
      },
    ],
  },
  {
    name: 'regionFilter',
    label: 'Territories',
    required: true,
    validators: [
      (territories: string[]): string | undefined => {
        if (!territories || !territories.length) {
          return 'Territories is required';
        }
      },
    ],
  },
];

export const miscValidator: IValidatorField<ICampaign>[] = [
  {
    name: 'published',
    validators: [
      (published: boolean, model: Partial<ICampaign>): string | undefined => {
        if (published) {
          if (
            !model?.primetimeBillboards ||
            !model?.primetimeBillboards.length ||
            model?.primetimeBillboards.length < 5
          ) {
            return 'Must have at least 5 billboards to publish';
          }
        }
      },
    ],
  },
  {
    name: 'plutoOfficeOnly',
  },
];

export const publicationDateValidator: IValidatorField<ICampaign>[] = [
  {
    name: 'start',
    label: 'Publication Date Range',
    required: true,
    validators: [
      (date: Date, model: Partial<ICampaign>): string | undefined => {
        if (!date) {
          return 'Publication date is required';
        }

        if (!model.start || !model.end) {
          return 'Must have a start and end date';
        }

        const start = model.start.valueOf();
        const end = model.end.valueOf();
        const minDate = new Date('2021-01-01').valueOf();
        const maxDate = new Date('2035-12-31').valueOf();

        if (start >= end) {
          return 'Start date must be before end date';
        }

        if (start < minDate) {
          return 'Start date must be after 2021';
        }

        if (start > maxDate || end > maxDate) {
          return 'Date cannot end after 2035';
        }
      },
    ],
  },
  {
    name: 'end',
    required: true,
  },
];

export const billboardsValidator: IValidatorField<ICampaign>[] = [
  {
    name: 'primetimeBillboards',
    validators: [
      (billboards: ICampaignSlot[], model: Partial<ICampaign>): string | undefined => {
        if ((!billboards || !billboards.length) && !model.published) {
          return;
        }

        let errorMsg = '';

        billboards.forEach(billboard => {
          if (billboard.badge) {
            if (billboard.badge.text && !billboard.badge.backgroundColor) {
              errorMsg = "A carousel's badge must have a background color";
              return;
            } else if (billboard.badge.backgroundColor && !billboard.badge.text) {
              errorMsg = "A carousel's badge text has to be filled out";
              return;
            }

            if (billboard.badge.text && billboard.badge.text.length > 25) {
              errorMsg = "A carousel's badge text can't be more than 25 characters";
              return;
            }

            if (billboard.badge.sponsoredText && billboard.badge.sponsoredText.length > 50) {
              errorMsg = "A carousel's badge sponsored text can't be more than 50 characters";
              return;
            }
          }
        });

        if (errorMsg.length) {
          return errorMsg;
        }

        if (!model.published) {
          return;
        }

        if (billboards.length < 5) {
          return 'In order to publish, a minimum of 5 carousels is required';
        }

        if (billboards.length > 12) {
          return 'A maximum of 12 carousels is allowed';
        }
      },
    ],
  },
];
