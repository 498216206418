import * as React from 'react';
import {useFindLibraryDurationQuery} from 'features/channelCatalog/channelCatalogApi';
import {IChannelCatalogItem, IChannelCatalogQuery} from 'models/channelCatalog';

export const useChannelCatalogLibraryDuration = (
  channelId?: string,
  movedToLibrary: IChannelCatalogItem[] = [],
  movedToQueue: IChannelCatalogItem[] = [],
): {
  duration: number;
  fetch: (searchParams?: Partial<IChannelCatalogQuery>) => void;
  refetch: () => void;
  isFetching: boolean;
} => {
  const [newItemsTotalDuration, setNewItemsTotalDuration] = React.useState<number>(0);
  const [params, setParams] = React.useState<Partial<IChannelCatalogQuery>>({});

  const {data, isError, error, refetch, isFetching} = useFindLibraryDurationQuery(
    {channelId: channelId || '', ...params},
    {
      skip: !channelId,
    },
  );

  React.useEffect(() => {
    const totalMovedToLibraryDuration = movedToLibrary.reduce((acc, item) => acc + item.duration, 0);
    const totalMovedToQueueDuration = movedToQueue.reduce((acc, item) => acc + item.duration, 0);
    setNewItemsTotalDuration(totalMovedToLibraryDuration - totalMovedToQueueDuration);
  }, [movedToLibrary, movedToQueue]);

  const fetch = React.useCallback((searchParams?: Partial<IChannelCatalogQuery>) => {
    const {updatedAt, ...restParams} = searchParams || {};
    setParams({...updatedAt, ...restParams} || {});
  }, []);

  return {
    duration:
      isError && (error as {status: number})?.status !== 404 ? 0 : (data?.duration || 0) + newItemsTotalDuration,
    fetch,
    isFetching,
    refetch,
  };
};
