import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {orderBy, filter} from 'lodash-es';

import {RootState} from 'app/store';
import {IPartner} from 'models/partners';

export const partnersApi = createApi({
  reducerPath: 'partnersApi',
  tagTypes: ['Partners'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/v1/',
    prepareHeaders(headers, {getState}) {
      const token = (getState() as RootState).user.jwt;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    find: builder.query<IPartner[], void>({
      query: () => ({
        url: `partners`,
        method: 'GET',
      }),
      providesTags: ['Partners'],
      transformResponse: (data: IPartner[]) =>
        orderBy(
          filter(data, partner => !!partner.name),
          partner => partner.name.toLowerCase(),
        ),
    }),
  }),
});

export const {useFindQuery} = partnersApi;
