import * as React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {some} from 'lodash-es';
import {Accordion, Box, Click, Cover, Cluster, Expand, Icon, Popover, Stack, Template} from '@pluto-tv/assemble';

import {IRouteItem, TRouteCollection} from 'routes';

import Logo from 'components/logo';
import User from 'components/user';

interface IRoutePopover {
  [key: string]: boolean;
}

export interface IAppSidebarProps {
  getRoutes: () => TRouteCollection;
  hasRoles: boolean;
  picture?: string;
  name?: string;
  email?: string;
  isExpanded?: boolean;
}

const AppSidebar = React.memo(({getRoutes, picture, name, email, isExpanded = false, hasRoles}: IAppSidebarProps) => {
  const history = useHistory();
  const location = useLocation();
  const [popoverVisible, setPopoverVisible] = React.useState<IRoutePopover>({});

  const menuItemClickHandler = (targetPath: string) => {
    history.push(targetPath);
    setPopoverVisible({});
  };

  const [expanded, setExpanded] = React.useState<boolean>(isExpanded);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const getNavActiveColor = (route: IRouteItem, includeChildren = false) => {
    if (
      route.path === location.pathname ||
      location.pathname.indexOf(route.path!) === 0 ||
      (includeChildren &&
        route.children &&
        some(route.children, child => child.path === location.pathname || location.pathname.indexOf(child.path!) > -1))
    ) {
      return 'primary';
    }

    return undefined;
  };

  return (
    <Cover>
      <Template label='header'>
        <Box
          paddingX='xxxsmall'
          paddingTop='none'
          paddingBottom='none'
          background='black'
          onClick={() => menuItemClickHandler('/')}
        >
          <Logo expanded={expanded} />
        </Box>
      </Template>
      <Template label='cover'>
        {hasRoles ? (
          <Box padding='medium'>
            <Cluster space='large'>
              {getRoutes()
                .filter(r => r.showInSideBar && r.icon)
                .map(r => (
                  <Expand isExpanded={expanded} width='12.5rem' key={r.name}>
                    <Template label='fixed'>
                      {expanded && <Icon icon={r.icon!} size='xlarge' color={getNavActiveColor(r, true)} />}
                      {!expanded && r.children && (
                        <Popover
                          manualTrigger={true}
                          visible={popoverVisible[r.name]}
                          onClickOutside={() => setPopoverVisible(prev => ({...prev, [r.name]: false}))}
                        >
                          <Template label='trigger'>
                            <Icon
                              icon={r.icon!}
                              size='xlarge'
                              color={getNavActiveColor(r, true)}
                              onClick={() => setPopoverVisible(prev => ({...prev, [r.name]: !prev[r.name]}))}
                            />
                          </Template>
                          <Template label='popover'>
                            <Box paddingX='xxsmall' paddingY='xxxxsmall'>
                              <Stack space='xxsmall'>
                                {r.children.map(childRoute => (
                                  <Click
                                    key={childRoute.name}
                                    color={getNavActiveColor(childRoute)}
                                    onClick={() => menuItemClickHandler(childRoute.path!)}
                                  >
                                    {childRoute.name}
                                  </Click>
                                ))}
                              </Stack>
                            </Box>
                          </Template>
                        </Popover>
                      )}
                      {!expanded && !r.children && (
                        <Icon
                          icon={r.icon!}
                          size='xlarge'
                          color={getNavActiveColor(r, true)}
                          onClick={() => menuItemClickHandler(r.path!)}
                        />
                      )}
                    </Template>
                    <Template label='expandable'>
                      <Box paddingTop='xxxxxxsmall'>
                        {!r.children && (
                          <Click
                            display='block'
                            color={getNavActiveColor(r)}
                            onClick={() => menuItemClickHandler(r.path!)}
                          >
                            {r.name}
                          </Click>
                        )}
                        {r.children && (
                          <Accordion isOpen={false}>
                            <Template label='trigger'>
                              <Click display='block'>{r.name!}</Click>
                            </Template>
                            <Template label='body'>
                              <Box paddingLeft='medium' paddingTop='small'>
                                <Stack space='xxsmall'>
                                  {r.children.map(childRoute => (
                                    <Click
                                      key={childRoute.name}
                                      size='small'
                                      color={getNavActiveColor(childRoute)}
                                      onClick={() => menuItemClickHandler(childRoute.path!)}
                                    >
                                      {childRoute.name}
                                    </Click>
                                  ))}
                                </Stack>
                              </Box>
                            </Template>
                          </Accordion>
                        )}
                      </Box>
                    </Template>
                  </Expand>
                ))}
            </Cluster>
          </Box>
        ) : (
          <></>
        )}
      </Template>
      <Template label='footer'>
        <Box paddingY={{mobile: 'xsmall', wide: 'medium'}} paddingX={{mobile: 'xsmall', wide: 'xsmall'}}>
          <Cluster space='large'>
            <User email={email!} isExpanded={expanded} name={name!} avatar={picture} />
            <Box paddingLeft='xxxxsmall'>
              <Icon
                icon='chevronright'
                size='xlarge'
                rotate={expanded ? '-180deg' : '0deg'}
                onClick={toggleExpanded}
                id='multiButtonIcon'
              />
            </Box>
          </Cluster>
        </Box>
      </Template>
    </Cover>
  );
});

AppSidebar.displayName = 'AppSidebar';
export default AppSidebar;
