export const programmingTypes = [
  'Behind the Scenes',
  'Commentary',
  'Concert',
  'Condensed Game',
  'Current Preview',
  'Event',
  'Excerpt',
  'Full Episode',
  'Full Game',
  'Full Movie',
  'Gameplay',
  'Highlights',
  'Interview',
  'Music Video',
  'Outake',
  'Performance',
  'Pluto TV Systems',
  'Recap',
  'Short Film',
  'Sneak Peak',
  'Special',
  'Teaser Trailer',
  'Trailer',
  'Web Exclusive',
] as const;

export type TProgrammingTypes = keyof typeof programmingTypes;
