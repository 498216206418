import RoleListPage from 'views/admin/role/list';
import RoleEditPage from 'views/admin/role/edit';
import UserListPage from 'views/admin/user/list';
import UserEditPage from 'views/admin/user/edit';
import activeRegionsListPage from 'views/admin/activeRegions/list';
import activeRegionsEditPage from 'views/admin/activeRegions/edit';
import AppNameListPage from 'views/admin/appName/list';
import AppNameEditPage from 'views/admin/appName/edit';
import {rolePermissions} from 'views/admin/role/permissions';
import {userPermissions} from 'views/admin/user/permissions';
import {appNamePermissions} from 'views/admin/appName/permissions';
import {activeRegionPermissions} from 'views/admin/activeRegions/permissions';
import {IRouteItem} from 'routes';

const paths = {
  roleListPage: '/roles',
  roleEditPage: '/roles/:id',
  userListPage: '/users',
  userEditPage: '/users/:id',
  activeRegionsListPage: '/active-regions',
  activeRegionsEditPage: '/active-regions/:id',
  appNameListPage: '/app-names',
  appNameCreatePage: '/app-names/new',
  appNameEditPage: '/app-names/:id',
};

const routes: IRouteItem[] = [
  {
    name: 'Admin',
    icon: 'gear',
    showInSideBar: true,
    requiredPermissions: [
      ...rolePermissions.ROLE_VIEW.enabled,
      ...userPermissions.USER_VIEW.enabled,
      ...appNamePermissions.APPNAME_VIEW.enabled,
    ],
    children: [
      {
        name: 'Roles',
        path: paths.roleListPage,
        component: RoleListPage,
        breadcrumb: {items: [{text: 'Roles', route: paths.roleListPage}]},
        requiredPermissions: rolePermissions.ROLE_VIEW.enabled,
      },
      {
        name: 'Users',
        path: paths.userListPage,
        component: UserListPage,
        breadcrumb: {items: [{text: 'Users', route: paths.userListPage}]},
        requiredPermissions: userPermissions.USER_VIEW.enabled,
      },
      {
        name: 'Active Regions',
        path: paths.activeRegionsListPage,
        component: activeRegionsListPage,
        breadcrumb: {items: [{text: 'Active Regions', route: paths.activeRegionsListPage}]},
        requiredPermissions: activeRegionPermissions.ACTIVE_REGION_VIEW.enabled,
      },
      {
        name: 'App Names',
        path: paths.appNameListPage,
        component: AppNameListPage,
        breadcrumb: {items: [{text: 'App Names', route: paths.appNameListPage}]},
        requiredPermissions: appNamePermissions.APPNAME_VIEW.enabled,
      },
    ],
  },
  {
    name: 'Role Single',
    path: paths.roleEditPage,
    component: RoleEditPage,
    breadcrumb: {
      items: [
        {text: 'Roles', route: paths.roleListPage},
        {text: 'Edit Role', route: paths.roleEditPage},
      ],
    },
    requiredPermissions: rolePermissions.ROLE_VIEW.enabled,
  },
  {
    name: 'User Single',
    path: paths.userEditPage,
    component: UserEditPage,
    breadcrumb: {
      items: [
        {text: 'Users', route: paths.userListPage},
        {text: 'Edit User', route: paths.userEditPage},
      ],
    },
    requiredPermissions: userPermissions.USER_VIEW.enabled,
  },
  {
    name: 'Active Region Single',
    path: paths.activeRegionsEditPage,
    component: activeRegionsEditPage,
    breadcrumb: {
      items: [
        {text: 'Active Region', route: paths.activeRegionsListPage},
        {text: 'Edit Active Region', route: paths.activeRegionsEditPage},
      ],
    },
    requiredPermissions: activeRegionPermissions.ACTIVE_REGION_VIEW.enabled,
  },
  {
    name: 'App Name Single',
    path: paths.appNameEditPage,
    component: AppNameEditPage,
    breadcrumb: {
      items: [{text: 'App Names', route: paths.appNameListPage}, {text: 'Edit App Name'}],
    },
    requiredPermissions: appNamePermissions.APPNAME_VIEW.enabled,
  },
];

export default {
  paths,
  routes,
};
