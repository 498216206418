import * as React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {IBreadcrumbProps, TIcons} from '@pluto-tv/assemble';
import Landing from '../views/landing/Landing';

import programmingRoutes from './programming.routes';
import contentRoutes from './content.routes';
import campaignRoutes from './campaign.routes';
import runLimitsRoutes from './runLimits.routes';
import adminRoutes from './admin.routes';

export interface IRouteItem {
  name: string;
  path?: string;
  showInSideBar?: boolean;
  icon?: TIcons;
  exact?: boolean;
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any> | undefined;
  requiredPermissions?: string[];
  breadcrumb?: IBreadcrumbProps;
  children?: Omit<IRouteItem, 'children' | 'icon' | 'showInSideBar'>[];
}

export type TRouteCollection = IRouteItem[];

const routesPaths = {
  landing: '/',
  ...programmingRoutes.paths,
  ...contentRoutes.paths,
  ...campaignRoutes.paths,
  ...runLimitsRoutes.paths,
  ...adminRoutes.paths,
};

const getChildrenRoutesForUserPermissions = (children: TRouteCollection, permissions: string[]): TRouteCollection => {
  return children.filter(
    route =>
      !route.requiredPermissions ||
      (route.requiredPermissions && route.requiredPermissions.some(r => permissions.includes(r))),
  );
};

const getRoutesForUserPermissions = (permissions: string[]): TRouteCollection => {
  const routes: TRouteCollection = [
    {
      name: 'Landing',
      path: routesPaths.landing,
      component: Landing,
      breadcrumb: {items: [{text: 'Landing', route: routesPaths.landing}]},
    },
    ...programmingRoutes.routes,
    ...contentRoutes.getRoutes(),
    ...campaignRoutes.routes,
    ...runLimitsRoutes.routes,
    ...adminRoutes.routes,
  ];

  return routes
    .filter(
      route =>
        !route.requiredPermissions ||
        (route.requiredPermissions && route.requiredPermissions.some(r => permissions.includes(r))),
    )
    .map(route =>
      !route.children ? route : {...route, children: getChildrenRoutesForUserPermissions(route.children, permissions)},
    );
};

export {getRoutesForUserPermissions};
