import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

import {RootState} from 'app/store';
import {IRunLimit, IRunLimitObj} from 'models/licensedTitles';
import {IListPayload, IListQuery} from 'models/generic';
import {isUndefined} from 'lodash-es';

interface IRLTDashboardQuery extends Omit<IListQuery, 'search' | 'sort'> {
  title?: string;
  activeRegions?: string[];
  partnerId?: string;
  sortfield?: number;
  sortdirection?: number;
  month: string;
  year: string;
  licenseFee?: number;
  programmingType?: string;
}

export const runLimitTrackersApi = createApi({
  reducerPath: 'runLimitTrackersApi',
  tagTypes: ['RunLimitTrackers', 'LicensedTitleRunLimitTrackers'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/v1/',
    prepareHeaders(headers, {getState}) {
      const token = (getState() as RootState).user.jwt;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    find: builder.query<IListPayload<IRunLimit>, IRLTDashboardQuery>({
      query: ({
        offset = 0,
        limit = 10,
        sortfield = 1,
        sortdirection = 1,
        month,
        year,
        activeRegions = [],
        title,
        partnerId,
        licenseFee,
        programmingType,
      }) => {
        let url = `rlt/run-limit-dashboard?offset=${offset}&limit=${limit}&sortfield=${sortfield}&sortdirection=${sortdirection}&month=${month}&year=${year}`;

        if (title) {
          url = `${url}&title=${title}`;
        }

        if (partnerId) {
          url = `${url}&partnerId=${partnerId}`;
        }

        if (activeRegions && activeRegions.length > 0) {
          activeRegions.forEach(region => {
            url = `${url}&activeRegions=${region}`;
          });
        }

        if (programmingType) {
          url = `${url}&programmingType=${programmingType}`;
        }

        if (!isUndefined(licenseFee)) {
          url = `${url}&licenseFee=${licenseFee}`;
        }

        return {
          url,
          method: 'GET',
        };
      },
      providesTags: ['RunLimitTrackers'],
    }),
    findByLicensedTitleId: builder.query<IListPayload<IRunLimitObj>, string>({
      query: id => ({
        url: `rlt/run-limit?licensedTitleId=${id}&offset=0&limit=100&startDate=1999-01-01&endDate=2043-12-31`,
        method: 'GET',
      }),
      providesTags: ['LicensedTitleRunLimitTrackers'],
    }),
    insert: builder.mutation<IRunLimit, Partial<IRunLimitObj>>({
      query: runLimit => ({
        url: 'rlt/run-limit',
        method: 'POST',
        body: {runLimit},
      }),
      invalidatesTags: ['RunLimitTrackers', 'LicensedTitleRunLimitTrackers'],
    }),
    update: builder.mutation<IRunLimit, Partial<IRunLimitObj>[]>({
      query: runLimits => ({
        url: 'rlt/run-limit',
        method: 'PUT',
        body: {runLimits},
      }),
      invalidatesTags: ['RunLimitTrackers', 'LicensedTitleRunLimitTrackers'],
    }),
  }),
});

export const {useFindQuery, useLazyFindQuery, useFindByLicensedTitleIdQuery, useInsertMutation, useUpdateMutation} =
  runLimitTrackersApi;
