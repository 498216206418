import * as React from 'react';
import {Popover, Template, Icon, Box, Stack, TIcons} from '@pluto-tv/assemble';
import {IFeaturedGroup} from 'models/featuredGroup';

export const RowState = ({row}: {row: IFeaturedGroup}): React.ReactElement | null => {
  if (!row?.state?.messageList?.length) return null;
  const {state, messageList} = row.state;

  return (
    <Popover trigger='mouseenter'>
      <Template label='trigger'>
        <Icon icon={state as TIcons} verticalAlign='text-bottom' color={state as any} />
      </Template>
      <Template label='popover'>
        <Box paddingX='small' paddingY='xxsmall' background='charcoal' minWidth='18.75rem'>
          <Stack space='xxxsmall'>
            {messageList.map((validatorMessage, index) => (
              <Icon
                icon={validatorMessage.icon as TIcons}
                iconAlign='baseline'
                space='small'
                key={index}
                color={validatorMessage.icon as any}
              >
                {validatorMessage.message}
              </Icon>
            ))}
          </Stack>
        </Box>
      </Template>
    </Popover>
  );
};
