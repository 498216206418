import * as React from 'react';

import {useMeQuery} from 'features/users/usersApi';
import {IEpisode} from 'models/episodes';

interface IEpisodeListPermissions {
  canViewOO: boolean;
  canViewPartner: boolean;
}

export const useEpisodeListPermissions = (): IEpisodeListPermissions => {
  const {data: userProfile, isFetching, isError} = useMeQuery();

  const [canViewOO, setCanViewOO] = React.useState<boolean>(false);
  const [canViewPartner, setCanViewPartner] = React.useState<boolean>(false);

  const reset = () => {
    setCanViewOO(false);
    setCanViewPartner(false);
  };

  const checkPermissions = () => {
    if (!userProfile) {
      reset();
      return;
    }

    setCanViewOO(
      (userProfile.permissions || []).find(perm => perm === 'episode_view_oo' || perm === 'series_view_oo')
        ? true
        : false,
    );

    setCanViewPartner(
      (userProfile.permissions || []).find(perm => perm === 'episode_view_partner' || perm === 'series_view_partner')
        ? true
        : false,
    );
  };

  React.useEffect(() => {
    if (isError) {
      reset();
      return;
    }

    checkPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  React.useEffect(() => {
    if (!isFetching && userProfile) {
      checkPermissions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile, isFetching]);

  return {
    canViewOO,
    canViewPartner,
  };
};

interface IEpisodePermissions {
  CAN_VIEW: boolean;
  CAN_EDIT: boolean;
  CAN_DELETE: boolean;
  editPermission: 'enabled' | 'disabled';
}

export const useEpisodePermissions = (episode?: Partial<IEpisode>): IEpisodePermissions => {
  const {data: userProfile, isFetching, isError} = useMeQuery();

  const [canView, setCanView] = React.useState<boolean>(true);
  const [canEdit, setCanEdit] = React.useState<boolean>(false);
  const [canDelete, setCanDelete] = React.useState<boolean>(false);

  const editPermission = React.useMemo((): 'enabled' | 'disabled' => (canEdit ? 'enabled' : 'disabled'), [canEdit]);

  const reset = () => {
    setCanView(true);
    setCanEdit(false);
    setCanDelete(false);
  };

  const checkPermissions = () => {
    if (!episode || !userProfile) {
      reset();
      return;
    }

    const isOO = episode.plutoTvOO;

    setCanView(
      (userProfile.permissions || []).find(perm => perm === (isOO ? 'episode_view_oo' : 'episode_view_partner'))
        ? true
        : false,
    );
    setCanEdit(
      (userProfile.permissions || []).find(perm => perm === (isOO ? 'episode_edit_oo' : 'episode_edit_partner'))
        ? true
        : false,
    );
    setCanDelete(
      (userProfile.permissions || []).find(perm => perm === (isOO ? 'episode_delete_oo' : 'episode_delete_partner'))
        ? true
        : false,
    );
  };

  React.useEffect(() => {
    if (isError) {
      reset();
      return;
    }

    checkPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  React.useEffect(() => {
    if (!isFetching && userProfile) {
      checkPermissions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile, isFetching, episode]);

  return {
    CAN_EDIT: canEdit,
    CAN_VIEW: canView,
    CAN_DELETE: canDelete,
    editPermission,
  };
};
