import * as React from 'react';
import {ContentBoxes, ContentBox} from '@pluto-tv/assemble';

import AssetForm from 'components/assetForm';

import {INestedEpisodeProps} from '../nestedPropsInterface';
import {useEpisodePermissions} from '../../permissions/useEpisodePermissions';

export default ({model, setFields, pristineModel}: INestedEpisodeProps): JSX.Element => {
  const {editPermission} = useEpisodePermissions(pristineModel);

  return (
    <ContentBoxes layout='grid' gridMaxCols={2}>
      <ContentBox title='Film/TV Episode Screenshot 16:9'>
        <AssetForm
          assetType='screenshot16_9'
          asset={model.screenshot16_9}
          contentType='episodes'
          contentId={model.id || ''}
          width='20rem'
          height='15rem'
          maxFileSizeKB={2048}
          minResolutionWidth={960}
          minResolutionHeight={540}
          aspectRatioWidth={16}
          aspectRatioHeight={9}
          permission={editPermission}
          defaultUrl='http://pluto.tv.s3.amazonaws.com/assets/images/default/vod.screenshot16_9-default.jpg'
          onChange={(name, url) => {
            setFields({screenshot16_9: {title: name, path: url}});
          }}
        />
      </ContentBox>
      <ContentBox title='Film/TV Episode Screenshot 4:3'>
        <AssetForm
          assetType='screenshot4_3'
          asset={model.screenshot4_3}
          contentType='episodes'
          contentId={model.id || ''}
          width='20rem'
          height='15rem'
          maxFileSizeKB={2048}
          minResolutionWidth={320}
          minResolutionHeight={240}
          aspectRatioWidth={4}
          aspectRatioHeight={3}
          permission={editPermission}
          defaultUrl='http://pluto.tv.s3.amazonaws.com/assets/images/default/vod.screenshot4_3-default.jpg'
          onChange={(name, url) => {
            setFields({screenshot4_3: {title: name, path: url}});
          }}
        />
      </ContentBox>
      <ContentBox title='Film Poster'>
        <AssetForm
          assetType='poster'
          asset={model.poster}
          contentType='episodes'
          contentId={model.id || ''}
          width='21.6875rem'
          height='31.25rem'
          maxFileSizeKB={2048}
          minResolutionWidth={347}
          minResolutionHeight={500}
          aspectRatioWidth={347}
          aspectRatioHeight={500}
          permission={editPermission}
          defaultUrl='http://pluto.tv.s3.amazonaws.com/assets/images/default/vod.poster-default.jpg'
          onChange={(name, url) => {
            setFields({poster: {title: name, path: url}});
          }}
        />
      </ContentBox>
      <ContentBox title='Film Poster (16:9 Title Treatment)'>
        <AssetForm
          assetType='poster16_9'
          asset={model.poster16_9}
          contentType='episodes'
          contentId={model.id || ''}
          width='20rem'
          height='15rem'
          maxFileSizeKB={2048}
          minResolutionWidth={1560}
          minResolutionHeight={540}
          aspectRatioWidth={16}
          aspectRatioHeight={9}
          permission={editPermission}
          onChange={(name, url) => {
            setFields({poster16_9: {title: name, path: url}});
          }}
        />
      </ContentBox>
    </ContentBoxes>
  );
};
