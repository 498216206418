import * as React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {forOwn, forEach} from 'lodash-es';

import adminRoutes from 'routes/admin.routes';
import NotAuthorized from 'components/notAuthorized';
import CrudError from 'components/crudError';
import {useLazyFindByIdQuery, useInsertMutation, useUpdateMutation, useDeleteMutation} from 'features/roles/rolesApi';
import {useFindQuery as useFindPermissionsQuery} from 'features/permissions/permissionsApi';

import {
  Box,
  Button,
  Cluster,
  Columns,
  Cover,
  FormItem,
  Heading,
  Popover,
  Sidebar,
  Spinner,
  Stack,
  Template,
  Textarea,
  TextInput,
  Toast,
  useValidateForm,
  trimModel,
} from '@pluto-tv/assemble';

import {roleDetailsValidator} from '../validators';
import {IRole} from 'models/roles';
import {buildPermissionsTree} from '../utils';
import PermissionsGroup from './permissionsGroup';
import {useAppPermissions} from 'app/permissions';
import DeleteConfirmation from 'components/deleteConfirmation';

export default (): JSX.Element => {
  const history = useHistory();
  const {id}: {id: string} = useParams();
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [deleteVisible, setDeleteVisible] = React.useState(false);
  const {ableTo, permissions: appPermissions} = useAppPermissions();

  if (!ableTo('ROLE_VIEW')) {
    return <NotAuthorized />;
  }
  const cantModify = (id === 'new' && !ableTo('ROLE_CREATE')) || (id !== 'new' && !ableTo('ROLE_EDIT'));

  const [searchRole, roleFound, lastRoleSearchInfo] = useLazyFindByIdQuery();
  const {data: permissions, isFetching: permissionsLoading} = useFindPermissionsQuery({
    offset: 0,
    limit: 100,
    sort: 'order:asc',
  });
  const [updateRole] = useUpdateMutation();
  const [insertRole] = useInsertMutation();
  const [deleteRole] = useDeleteMutation();

  const permissionsTree = buildPermissionsTree(permissions?.data);

  const dependencyMap = {};
  permissions?.data.forEach(p => {
    if (p.dependsOn && p.dependsOn.length) {
      p.dependsOn.forEach(d => {
        dependencyMap[d] = [...(dependencyMap[d] || []), p.name];
      });
    }
  });

  const {
    pristineModel: rolePristineModel,
    form: roleForm,
    model: roleModel,
    onBlur,
    onChange,
    state: formState,
    setModel,
    setFields,
  } = useValidateForm<IRole>([...roleDetailsValidator]);

  const cancelHandler = () => {
    history.push(adminRoutes.paths.roleListPage);
  };

  const saveHandler = async () => {
    if (isSaving || cantModify) {
      return;
    }

    setIsSaving(true);

    try {
      if (id === 'new') {
        await onRoleCreate({...roleModel, permissions: roleModel.permissions?.filter(p => p !== '')} as IRole);
      } else {
        await onRoleUpdate(id, roleModel as IRole);
      }
    } catch (e) {
      const duplicateError = (e as any).data?.error;
      if (duplicateError === 'Conflict') {
        Toast.error('Validation Error', `Role with name '${roleModel.name}' already exists.`);
      }

      const validationErrors = (e as any).data?.validationErrors;
      if (validationErrors) {
        forOwn(validationErrors, (val: string[]) => {
          forEach(val, errorMsg => Toast.error('Validation Error', errorMsg));
        });
      }
    } finally {
      setIsSaving(false);
    }
  };

  const onRoleCreate = async (entity: IRole) => {
    const trimmed = trimModel(entity, 'name', 'description');
    const result = await insertRole(trimmed).unwrap();
    Toast.success('Success', 'Role Created');
    history.push(adminRoutes.paths.roleEditPage.replace(':id', result._id));
  };

  const onRoleUpdate = async (id: string, entity: IRole) => {
    const trimmed = trimModel(entity, 'name', 'description');
    const result = await updateRole({id, role: trimmed}).unwrap();
    Toast.success('Success', 'Role Updated');
    history.push(adminRoutes.paths.roleEditPage.replace(':id', result._id));
  };

  const onRoleDelete = async () => {
    await deleteRole(id).unwrap();
    Toast.success('Success', 'Role Deleted');
    history.push(adminRoutes.paths.roleListPage);
  };

  React.useEffect(() => {
    if (roleFound.data && roleFound.isSuccess && roleFound.data._id === lastRoleSearchInfo.lastArg) {
      setModel(roleFound.data);
    }
  }, [roleFound, lastRoleSearchInfo, setModel]);

  React.useEffect(() => {
    if (id === 'new') {
      setModel({_id: '', permissions: []});
    } else {
      searchRole(id);
    }
  }, [id, searchRole, setModel]);

  const deletePermission = (permissionsSet: Set<string>, permission: string) => {
    // delete selected permission
    permissionsSet.delete(permission);

    // if any dependant permission is selected, must delete too
    dependencyMap[permission]?.forEach(d => deletePermission(permissionsSet, d));
  };

  const setRolePermission = (permissions: string[], enable: boolean) => {
    const newPermissions = new Set([...(roleModel.permissions || [])]);
    permissions.forEach(p => (enable ? newPermissions.add(p) : deletePermission(newPermissions, p)));
    setFields({permissions: [...newPermissions]});
  };

  if (roleFound.isError) {
    return <CrudError error={roleFound.error} />;
  }

  if (!roleModel || permissionsLoading) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} size='xlarge' />
      </Box>
    );
  }

  return (
    <>
      <Cover
        scrolling={true}
        gutterTop='medium'
        gutterBottom='large'
        coverTemplateHeight='100%'
        paddingX={{mobile: 'medium', wide: 'large'}}
        paddingTop={{mobile: 'medium', wide: 'large'}}
      >
        <Template label='header'>
          <Cluster justify='space-between' align='center' space='medium' wrap={false}>
            <Heading level='h1' truncate={true} truncateBackgroundHover='shadow'>
              {rolePristineModel.name || 'New Role'}
            </Heading>
            <Popover
              appendToBody={true}
              manualTrigger={true}
              visible={deleteVisible}
              onClickOutside={() => setDeleteVisible(false)}
            >
              <Template label='trigger'>
                <Button
                  icon='delete'
                  type='delete'
                  onClick={() => setDeleteVisible(true)}
                  state={id === 'new' ? 'hidden' : 'normal'}
                  permission={appPermissions.ROLE_DELETE}
                >
                  Delete Role
                </Button>
              </Template>
              <Template label='popover'>
                <DeleteConfirmation
                  message={'Are you sure you want to delete ' + rolePristineModel.name + '?'}
                  cancelButtonFunction={() => setDeleteVisible(false)}
                  proceedButtonFunction={() => onRoleDelete()}
                />
              </Template>
            </Popover>
          </Cluster>
        </Template>
        <Template label='cover'>
          <Box background='pewter' borderTop={true} borderSize='0.125rem' borderColor='cavern' fullHeight={true}>
            <Sidebar fullHeight={true} sideWidth='18.75rem'>
              <Box
                borderRight={true}
                borderSize='0.125rem'
                borderColor='shadow'
                paddingY={{mobile: 'medium', wide: 'large'}}
                paddingX={{mobile: 'medium', wide: 'xlarge'}}
              >
                <Stack space='xlarge'>
                  <Heading level='h3' color='secondary'>
                    Details
                  </Heading>
                  <Stack space='small'>
                    <FormItem
                      {...roleForm.name}
                      onBlur={() => onBlur('name')}
                      // Since we have no backend in place to support Role name changes, this field cannot be modified by users
                      permission={id !== 'new' ? 'disabled' : appPermissions.ROLE_EDIT}
                    >
                      <TextInput onChange={value => onChange('name', value)} value={roleModel.name} />
                    </FormItem>
                    <FormItem
                      {...roleForm.description}
                      onBlur={() => onBlur('description')}
                      permission={appPermissions.ROLE_EDIT}
                    >
                      <Textarea
                        onChange={value => onChange('description', value)}
                        value={roleModel.description}
                        minHeight='6.25rem'
                      />
                    </FormItem>
                  </Stack>
                </Stack>
              </Box>
              <Box
                borderLeft={true}
                borderSize='0.125rem'
                borderColor='shadow'
                paddingY={{mobile: 'medium', wide: 'large'}}
                paddingX={{mobile: 'medium', wide: 'xlarge'}}
                fullHeight={true}
              >
                <Cover scrolling={true} gutterTop='xlarge' coverTemplateHeight='100%'>
                  <Template label='header'>
                    <Heading level='h3' color='secondary'>
                      Permissions
                    </Heading>
                  </Template>
                  <Template label='cover'>
                    <Columns columns={3} gap='large'>
                      {permissionsTree &&
                        Object.keys(permissionsTree).map(name => (
                          <PermissionsGroup
                            key={name}
                            selectedPermissions={roleModel.permissions || []}
                            name={name}
                            content={permissionsTree}
                            onPermissionChange={(permissions: string[], enable: boolean) =>
                              setRolePermission(permissions, enable)
                            }
                            readonly={cantModify}
                          />
                        ))}
                    </Columns>
                  </Template>
                </Cover>
              </Box>
            </Sidebar>
          </Box>
        </Template>
        <Template label='footer'>
          <Box background='onyx' paddingX='large' paddingY='small' marginX={{mobile: 'none', wide: 'largeNegative'}}>
            <Cluster justify='space-between'>
              <div></div>
              <Cluster space='small'>
                <Button ghost={true} onClick={() => cancelHandler()} id='discard'>
                  Discard
                </Button>
                <Button
                  type='primary'
                  state={!formState.isValid || !formState.isDirty ? 'disabled' : isSaving ? 'thinking' : ''}
                  id='save'
                  onClick={() => saveHandler()}
                  permission={id === 'new' ? appPermissions.ROLE_CREATE : appPermissions.ROLE_EDIT}
                >
                  Save Changes
                </Button>
              </Cluster>
            </Cluster>
          </Box>
        </Template>
      </Cover>
    </>
  );
};
