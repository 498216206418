import * as React from 'react';
import {Button, Cluster, Cover, Dialog, Heading, Icon, Notification, Stack, Table, Template} from '@pluto-tv/assemble';
import {IGapOrOverlap} from 'views/programming/channel/utils/models';
import {formatDateWithTimezone, miliSecsToStr} from 'views/programming/channel/utils/dateUtils';

interface Props {
  isOpen: boolean;
  reviewChanges: () => void;
  saveAndProceed: () => void;
  gaps: IGapOrOverlap[];
  overlaps: IGapOrOverlap[];
  pushingFuture: number;
}

const renderGapsMessage = (gaps: IGapOrOverlap[]) => {
  if (!gaps.length) return null;

  return (
    <Notification type='warning'>Gaps have been detected in the following areas of the draft schedule.</Notification>
  );
};

const renderOverlapsMessage = (overlaps: IGapOrOverlap[]) => {
  if (!overlaps.length) return null;

  return (
    <Notification type='warning'>
      Overlapping timelines have been detected in the following areas of the draft schedule.
    </Notification>
  );
};

const renderPushing = (pushingFuture: number) => {
  if (pushingFuture <= 0) return null;

  return (
    <Notification type='warning'>
      {'By saving these changes to the schedule, existing entries will be pushed into the future by ' +
        miliSecsToStr(pushingFuture) +
        '.'}
    </Notification>
  );
};

const renderIssuesTable = (gaps: IGapOrOverlap[], overlaps: IGapOrOverlap[]) => {
  if (!gaps.length && !overlaps.length) return null;

  const rows = gaps
    .map(g => ({...g, type: 'Gap'}))
    .concat(overlaps.map(ov => ({...ov, type: 'Overlap'})))
    .sort((a, b) => a.date.getTime() - b.date.getTime());

  return (
    <Table
      wrapContent={true}
      fixedHeader={true}
      cols={[
        {
          label: 'Type',
          field: 'type',
        },
        {
          label: 'Date',
          transform: row => formatDateWithTimezone(row.date, 'LLL dd, yyyy'),
        },
        {
          label: 'Time',
          transform: row => formatDateWithTimezone(row.date, 'h:mm a'),
        },
        {
          label: 'Duration',
          transform: row => miliSecsToStr(row.duration),
        },
      ]}
      rows={rows}
    ></Table>
  );
};

const ConfirmDraftSaveModal = React.memo(
  ({isOpen = false, reviewChanges, saveAndProceed, gaps, overlaps, pushingFuture}: Props) => {
    const handleOnClose = () => {
      reviewChanges();
    };

    const handleSave = () => {
      saveAndProceed();
    };

    const handleReview = () => {
      reviewChanges();
    };

    return (
      <Dialog isOpen={isOpen} onClose={handleOnClose} width='54rem' height='48rem'>
        <Template label='header'>
          <Heading level='h2'>
            <Icon icon='warning' color='warning' space='xxsmall'>
              Are you sure?
            </Icon>
          </Heading>
        </Template>
        <Template label='body'>
          <Cover gutter='medium' coverTemplateHeight='100%' overflow='auto' scrolling={true}>
            <Template label='header'>
              <Stack space='medium'>
                {renderGapsMessage(gaps)}
                {renderOverlapsMessage(overlaps)}
              </Stack>
            </Template>

            <Template label='cover'>{renderIssuesTable(gaps, overlaps)}</Template>

            <Template label='footer'>{renderPushing(pushingFuture)}</Template>
          </Cover>
        </Template>
        <Template label='footer'>
          <Cluster justify='space-between' align='end'>
            <div></div>
            <Cluster space='small'>
              <Button ghost={true} size='small' onClick={handleReview}>
                Review Changes
              </Button>
              <Button size='small' type='primary' onClick={handleSave}>
                Save and Proceed
              </Button>
            </Cluster>
          </Cluster>
        </Template>
      </Dialog>
    );
  },
);

export default ConfirmDraftSaveModal;
