import * as React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {
  Box,
  Button,
  Cluster,
  ContentBoxes,
  ContentBox,
  Cover,
  FormItem,
  Grid,
  Heading,
  Select,
  Spinner,
  Stack,
  Template,
  TextInput,
  Toggle,
  Notification,
  useValidateForm,
  Toast,
  trimModel,
} from '@pluto-tv/assemble';

import adminRoutes from 'routes/admin.routes';
import NotAuthorized from 'components/notAuthorized';

import {useAppPermissions} from 'app/permissions';

import {useFindByIdQuery, useUpdateMutation} from 'features/users/usersApi';
import {useFindQuery as useFindActiveRegionsQuery} from 'features/activeRegions/activeRegionsApi';
import {useFindQuery as useFindRolesQuery} from 'features/roles/rolesApi';

import {SimpleAvatar} from 'components/simpleAvatar';

import {IUser} from 'models/users';

import {userValidator} from '../validators';

export default (): JSX.Element => {
  const {id}: {id: string} = useParams();
  const history = useHistory();
  const {ableTo, permissions} = useAppPermissions();

  if (!ableTo('USER_VIEW')) {
    return <NotAuthorized />;
  }
  const cantModify = !ableTo('USER_EDIT');

  const [updateUser] = useUpdateMutation();

  const [isSaving, setIsSaving] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const {
    data: item,
    isError: userIsError,
    isLoading: userIsLoading,
  } = useFindByIdQuery(id, {refetchOnMountOrArgChange: true});
  const {
    data: activeRegions,
    isError: isErrorRegions,
    isLoading: isFetchingRegions,
  } = useFindActiveRegionsQuery({offset: 0, limit: 100, sort: 'name:asc'});
  const {data: roles, isError: isErrorRoles, isFetching: isFetchingRoles} = useFindRolesQuery({limit: 100});

  const {
    model: user,
    form: userForm,
    onBlur,
    onChange,
    pristineModel,
    setFields,
    setModel,
    state: formState,
  } = useValidateForm<IUser>(userValidator);

  const cancelHandler = () => {
    history.push(adminRoutes.paths.userListPage);
  };

  React.useEffect(() => {
    if (item) {
      setModel(item);
    }
  }, [item, setModel]);

  React.useEffect(() => {
    if (isErrorRegions || userIsError || isErrorRoles) {
      setIsError(true);
    }
  }, [isErrorRegions, userIsError, isErrorRoles]);

  React.useEffect(() => {
    if (isFetchingRegions || userIsLoading || isFetchingRoles) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isFetchingRegions, userIsLoading, isFetchingRoles]);

  const saveUser = async () => {
    if (isSaving || cantModify) {
      return;
    }

    setIsSaving(true);

    const trimmed = trimModel(user, 'displayName', 'email');
    const {_id: id, ...props} = trimmed;

    try {
      const res = await updateUser({
        id: id!,
        user: {
          ...props,
        },
      });

      if ((res as any).error) {
        throw Error((res as any).error);
      }

      setModel(trimmed as IUser);
      Toast.success('Success', 'User Updated');
    } catch (e) {
      Toast.error('Error', 'Error Updating User');
    } finally {
      setIsSaving(false);
    }
  };

  if (isError) {
    return (
      <Box fullHeight={true} padding={{mobile: 'medium', wide: 'xlarge'}}>
        <Notification type='warning'>Error Message Here</Notification>
      </Box>
    );
  }

  if (isLoading) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} size='xlarge' />
      </Box>
    );
  }

  return (
    <>
      <Cover
        scrolling={true}
        gutterTop='medium'
        gutterBottom='large'
        coverTemplateHeight='100%'
        paddingX={{mobile: 'medium', wide: 'large'}}
        paddingTop={{mobile: 'medium', wide: 'large'}}
      >
        <Template label='header'>
          <Grid maxCols={2}>
            <Cluster justify='space-between' align='center' space='medium' wrap={false}>
              <Heading level='h1' truncate={true} truncateBackgroundHover='shadow'>
                {pristineModel.displayName}
              </Heading>
              <Cluster align='center' space='medium' wrap={false}>
                <Toggle
                  value={pristineModel.active}
                  onChange={val => setFields({active: val})}
                  label={user.active ? 'Active' : 'Inactive'}
                  permission={permissions.USER_EDIT}
                ></Toggle>
                <SimpleAvatar name={pristineModel.displayName} src={pristineModel.image}></SimpleAvatar>
              </Cluster>
            </Cluster>
            <div></div>
          </Grid>
        </Template>
        <Template label='cover'>
          <Grid maxCols={2}>
            <ContentBoxes layout='stack'>
              <ContentBox title='Details'>
                <Stack space='medium'>
                  <FormItem
                    {...userForm.displayName}
                    onBlur={() => onBlur('displayName')}
                    permission={permissions.USER_EDIT}
                  >
                    <TextInput onChange={val => onChange('displayName', val)} value={user.displayName} />
                  </FormItem>
                  <FormItem {...userForm.email} state='disabled'>
                    <TextInput value={user.email} />
                  </FormItem>
                  <FormItem {...userForm.roles} permission={permissions.USER_EDIT}>
                    <Select
                      clearable={true}
                      options={(roles?.data || []).map(ar => ({label: ar.name, value: ar.name}))}
                      onChange={val => setFields({roles: val ? [val.label] : undefined})}
                      value={user.roles && user.roles.length > 0 ? {label: user.roles[0]} : undefined}
                    />
                  </FormItem>
                  <FormItem {...userForm.regions} permission={permissions.USER_EDIT}>
                    <Select
                      predicate='value'
                      multiselect={true}
                      addAll={true}
                      clearable={true}
                      options={(activeRegions?.data || []).map(ar => ({
                        label: `${ar.name} (${ar.code})`,
                        value: ar.code,
                      }))}
                      onChange={val => setFields({regions: (val || []).map(a => a.value)})}
                      value={user.regions?.map(a => ({label: a, value: a}))}
                    />
                  </FormItem>
                </Stack>
              </ContentBox>
            </ContentBoxes>
            <div></div>
          </Grid>
        </Template>
        <Template label='footer'>
          <Box background='onyx' paddingX='large' paddingY='small' marginX={{mobile: 'none', wide: 'largeNegative'}}>
            <Cluster justify='space-between'>
              <div></div>
              <Cluster space='small'>
                <Button ghost={true} onClick={() => cancelHandler()} id='discard'>
                  Discard
                </Button>
                <Button
                  type='primary'
                  id='save'
                  onClick={() => saveUser()}
                  state={!formState.isValid || !formState.isDirty ? 'disabled' : isSaving ? 'thinking' : ''}
                  permission={permissions.USER_EDIT}
                >
                  Save Changes
                </Button>
              </Cluster>
            </Cluster>
          </Box>
        </Template>
      </Cover>
    </>
  );
};
