import * as React from 'react';
import {
  Box,
  Copy,
  FilePicker,
  FormItem,
  Heading,
  Help,
  IFilePickerVerify,
  Icon,
  MainStates,
  Sidebar,
  Stack,
  TSize,
  TextInput,
} from '@pluto-tv/assemble';
import {useCreateAssetUploadUrlMutation} from 'features/assets/assetsApi';
import {AssetType, ContentType} from 'models/assets';
import {rewriteImageUrl} from 'utils/imageUtils';

export type FileType = 'image/jpeg' | 'image/png' | 'image/svg+xml';

export interface IAssetProps {
  asset?: IAsset;
  contentType: ContentType;
  contentId: string;
  assetType: AssetType;
  width: TSize;
  height: TSize;
  id?: string;
  name?: string;
  maxFileSizeKB: number;
  fileTypes?: [FileType, ...FileType[]]; // at least one file type should be provided
  minResolutionWidth: number;
  minResolutionHeight: number;
  aspectRatioWidth: number;
  aspectRatioHeight: number;
  defaultUrl?: string;
  permission?: MainStates;
  onChange: (name: string, url: string) => void;
  showAltText?: boolean;
}

export interface IAsset {
  title?: string;
  altText?: string;
  path?: string;
}

export default function AssetForm({
  asset,
  contentType,
  contentId,
  assetType,
  width,
  height,
  id,
  name,
  maxFileSizeKB: maxFileSize,
  fileTypes = ['image/jpeg'],
  minResolutionWidth,
  minResolutionHeight,
  aspectRatioWidth,
  aspectRatioHeight,
  defaultUrl,
  onChange,
  permission,
  showAltText = true,
}: IAssetProps): JSX.Element {
  const [createAssetUploadUrl] = useCreateAssetUploadUrlMutation();

  const handleTitleChange = (newTitle: string) => {
    onChange(newTitle, asset?.path || '');
  };

  const handleImageRemove = () => {
    const isNotDefaultImage = !asset?.path?.includes('/assets/images/default/');
    const imageUrl = isNotDefaultImage ? defaultUrl : '';
    onChange(asset?.title || '', imageUrl || '');
  };

  const handleImageChange = async (file: File) => {
    try {
      const imageUrl = await uploadAssetImage(file);
      onChange(asset?.title || '', imageUrl);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error uploading file', e);
    }
  };

  // Remove image/ prefix and +xml suffix leaving only the file extensions
  const validFormats = fileTypes
    .map(v => v.split('/')[1].split('+')[0].toUpperCase().replace(/JPEG/g, 'JPG'))
    .join(' or ');

  const uploadAssetImage = async (image: File): Promise<string> => {
    const result = await createAssetUploadUrl({
      contentType: contentType,
      contentId: contentId,
      assetType: assetType,
      mimeType: image.type,
      size: image.size,
    }).unwrap();

    await fetch(result.uploadUrl, {
      method: 'PUT',
      headers: {
        'x-amz-acl': 'public-read',
      },
      body: image,
    });

    return result.imageUrl;
  };

  const validateAssetImage = async ([image]: File[]): Promise<IFilePickerVerify> => {
    const resolutionValidationPromise = new Promise<IFilePickerVerify>(resolve => {
      if (!image) {
        return resolve({error: true, errorMsg: {header: 'Please select an image'}});
      }
      const extension = image.name.split('.').pop()?.toLowerCase();

      if (!extension || !fileTypes.includes(image.type as FileType)) {
        return resolve({
          error: true,
          errorMsg: {
            header: 'Error',
            msgOne: `${aspectRatioWidth}:${aspectRatioHeight} images and ${validFormats} format only.`,
            msgTwo: `'${extension}' extension is invalid.`,
          },
        });
      }

      // Max file size: maxFileSizeKB
      const imageSize = image.size / 1024;
      if (imageSize > maxFileSize) {
        return resolve({
          error: true,
          errorMsg: {
            header: 'Error',
            msgOne: `Max allowed file size is ${maxFileSize} KB.`,
            msgTwo: `${Math.ceil(imageSize)} KB is invalid.`,
          },
        });
      }

      // Image must be aspectRatioWidth:aspectRatioHeight
      const imageInstance = new Image();
      imageInstance.src = URL.createObjectURL(image);
      imageInstance.onload = () => {
        const {width, height} = imageInstance;

        // we should not compare floating point numbers so instead of it
        // we have to round them to the same factor and use the floor function to
        // turn them into integers that way comparisons can be properly done

        // using 2 decimals to compare
        const roundFactor = 100;
        const imageRatio = Math.floor((width / height) * roundFactor);
        const expectedRatio = Math.floor((aspectRatioWidth / aspectRatioHeight) * roundFactor);

        if (imageRatio !== expectedRatio) {
          return resolve({
            error: true,
            errorMsg: {
              header: 'Error',
              msgOne: `Image must be ${aspectRatioWidth}:${aspectRatioHeight} Aspect Ratio`,
              msgTwo: `${width}px (w) x ${height}px (h) is invalid.`,
            },
          });
        }

        // Min resolution: 960px (w) x 540px
        if (width < minResolutionWidth) {
          return resolve({
            error: true,
            errorMsg: {
              header: 'Error',
              msgOne: `Minimum resolution is ${minResolutionWidth}px (w) x ${minResolutionHeight}px (h).`,
              msgTwo: `${width}px (w) x ${height}px (h) is invalid.`,
            },
          });
        }

        return resolve({
          error: false,
        });
      };
    });

    return resolutionValidationPromise;
  };

  return (
    <Stack space='xlarge'>
      {name && (
        <Heading id={id} level='h3' color='secondary'>
          {name}
        </Heading>
      )}
      <Sidebar gap='medium' sideWidth={width}>
        <Box>
          <FilePicker
            permission={permission}
            type='advanced'
            msgOne='Drag and drop, or click here to browse and upload an image.'
            width={width}
            height={height}
            previewImage={rewriteImageUrl(asset?.path)}
            verifyUpload={validateAssetImage}
            onChange={files => {
              if (files.length) {
                handleImageChange(files[0]);
              }
            }}
            onRemove={handleImageRemove}
          />
        </Box>
        <Stack space='small'>
          {showAltText && (
            <FormItem label='Alt-Text' permission={permission}>
              <TextInput value={asset?.title} onChange={handleTitleChange} />
            </FormItem>
          )}
          <FormItem label='URL' permission={permission} child='Copy'>
            <Copy text={asset?.path ? asset?.path : 'No URL yet. Upload a file.'} toCopy={asset?.path} />
          </FormItem>
          <Icon icon='info' size='medium' iconAlign='center' space='xxsmall' color='info'>
            <Stack space='xxsmall'>
              <Help state='info'>
                Image Type: <span style={{color: '#60B2F3'}}>{validFormats}</span>
              </Help>
              <Help state='info'>
                Min Resolution:{' '}
                <span style={{color: '#60B2F3'}}>
                  {minResolutionWidth}px (w) x {minResolutionHeight}px (h)
                </span>
              </Help>
              <Help state='info'>
                Max File Size: <span style={{color: '#60B2F3'}}>{maxFileSize} KB</span>
              </Help>
            </Stack>
          </Icon>
        </Stack>
      </Sidebar>
    </Stack>
  );
}
