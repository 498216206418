import {IValidatorField} from '@pluto-tv/assemble';

import {IFeaturedGroup, IFeaturedGroupsSearchValues} from 'models/featuredGroup';

export const featuredGroupSearchValidator: IValidatorField<IFeaturedGroupsSearchValues>[] = [
  {
    name: 'monthYear',
    label: 'Month/Year',
  },
  {
    name: 'groupName',
    label: 'Group Name',
  },
  {
    name: 'activeRegion',
    label: 'Active Region',
  },
  {
    name: 'channels',
    label: 'Includes Channel',
  },
  {
    name: 'published',
    label: 'Published',
  },
];

const featuredGroupSimpleDetails: IValidatorField<IFeaturedGroup>[] = [
  {
    name: 'published',
    label: 'Published',
  },
  {
    name: 'name',
    label: 'Featured Group Name',
    required: true,
    validators: [
      (name: string): string | undefined => {
        if (!name || !name.trim().length) {
          return 'Featured Group Name is required';
        } else if (name && name.trim().length > 100) {
          return 'Featured Group Name must be less than 100 characters';
        }
      },
    ],
  },
  {
    name: 'start',
    label: 'Start Date',
    required: true,
    validators: [
      (startDate: string): string | undefined => {
        if (!startDate) {
          return 'Start Date is required';
        }
      },
    ],
  },
  {
    name: 'activeRegion',
    label: 'Active Region',
    required: true,
    validators: [
      (region: string): string | undefined => {
        if (!region) {
          return 'Active Region is required';
        }
      },
    ],
  },
  {
    name: 'contentItems',
    label: 'Content Items',
  },
];

const featuredGroupCreateFieldsValidator: IValidatorField<IFeaturedGroup>[] = [
  {
    name: 'name',
    label: 'Featured Group Name',
    required: true,
    validators: [
      (name: string): string | undefined => {
        if (!name || !name.trim().length) {
          return 'Featured Group Name is required';
        } else if (name && name.trim().length > 100) {
          return 'Featured Group Name must be less than 100 characters';
        }
      },
    ],
  },
  {
    name: 'start',
    label: 'Start Date',
    required: true,
    validators: [
      (startDate: string): string | undefined => {
        if (!startDate) {
          return 'Start Date is required';
        }
      },
    ],
  },
  {
    name: 'activeRegion',
    label: 'Active Region',
    required: true,
    validators: [
      (region: string): string | undefined => {
        if (!region) {
          return 'Active Region is required';
        }
      },
    ],
  },
];

export const featuredGroupDetailsValidator: IValidatorField<IFeaturedGroup>[] = [...featuredGroupSimpleDetails];

export const featuredGroupCreateValidator: IValidatorField<IFeaturedGroup>[] = [...featuredGroupCreateFieldsValidator];
