import {ISimpleEntry, TSize, worker} from '@pluto-tv/assemble';

import {IClip} from 'models/clips';
import {IEpisodeSource} from 'models/episodes';

export const transformClips = (clips: IEpisodeSource[] = []): Promise<ISimpleEntry[]> =>
  worker(
    (clips: IEpisodeSource[]): ISimpleEntry[] =>
      clips.map(source => {
        const entry: ISimpleEntry = {
          createdAt: source.clip.createdAt,
          author: source.clip.author?.name,
          duration:
            Number.isFinite(source.inPoint) && Number.isFinite(source.outPoint)
              ? source.outPoint - source.inPoint
              : source.clip.duration,
          realDuration:
            (Number.isFinite(source.clip.inPoint) && Number.isFinite(source.clip.outPoint)) ||
            (Number.isFinite(source.inPoint) && Number.isFinite(source.outPoint))
              ? Math.max(
                  source.clip.outPoint - source.clip.inPoint,
                  source.outPoint - source.inPoint,
                  source.clip.duration,
                )
              : source.clip.duration,
          id: source.clip.id!,
          title: source.clip.name,
          inPoint: source.inPoint,
          outPoint: source.outPoint,
          adPods: (source.adPods || [])
            .map(pod =>
              Object.assign({}, pod, {
                startAt: pod.startAt / 1000,
                duration: pod.duration / 1000,
              }),
            )
            .sort((a, b) => a.startAt - b.startAt),
          minHeight: Number.isFinite(source.inPoint) || Number.isFinite(source.outPoint) ? '7.75rem' : '6.25rem',
          meta: source,
        };

        const errorMessages: string[] = [];

        if (source.clip.contentStatus !== 'ready') {
          errorMessages.push('Clip is not in a Ready state. Please update.');
        }

        if (!source.clip.published) {
          errorMessages.push('Clip is not published. Please update.');
        }

        if (!source.clip.sources?.length) {
          errorMessages.push('Clip contains empty sources. Please update.');
        }

        // If the clip is live or promotional, don't get check Availability windows
        if (!source.clip.promotional && !source.clip.liveBroadcast) {
          const availabilityWindowMsg =
            'Clip has no availability windows, or is outside of its linear and/or AVOD availability window. Please update.';
          if (source.clip.availabilityWindows) {
            const now = new Date();

            const hasAVOD = (source.clip.availabilityWindows?.AVOD || []).some(
              w => now >= new Date(w.startDate) && now < new Date(w.endDate),
            );
            const hasLinear = (source.clip.availabilityWindows?.linear || []).some(
              w => now >= new Date(w.startDate) && now < new Date(w.endDate),
            );

            if (!hasAVOD && !hasLinear) {
              errorMessages.push(availabilityWindowMsg);
            }
          } else {
            errorMessages.push(availabilityWindowMsg);
          }
        }

        if (errorMessages.length > 0) {
          entry.state = 'error';
          entry.errorMessagesWithState = errorMessages.map(msg => ({
            state: 'error',
            message: msg,
          }));
        }

        return entry;
      }),
    clips,
  );

export const transformClipToEntry = (clip: IClip, createAdPods = false): Promise<ISimpleEntry> =>
  worker(
    (clip: IClip, createAdPods = false): ISimpleEntry => {
      const entry: ISimpleEntry = {
        createdAt: clip.createdAt,
        duration:
          Number.isFinite(clip.inPoint) && Number.isFinite(clip.outPoint)
            ? clip.outPoint - clip.inPoint
            : clip.duration,
        realDuration:
          Number.isFinite(clip.inPoint) && Number.isFinite(clip.outPoint)
            ? Math.max(clip.outPoint - clip.inPoint, clip.duration)
            : clip.duration,
        author: clip.author?.name,
        id: clip._id,
        title: clip.name,
        inPoint: clip.inPoint,
        outPoint: clip.outPoint,
        adPods: createAdPods
          ? (clip.breakpoints || []).map(bp => ({
              duration: 60,
              startAt: bp / 1000,
            }))
          : [],
        minHeight: Number.isFinite(clip.inPoint) || Number.isFinite(clip.outPoint) ? '7.75rem' : '6.25rem',
        meta: {clip},
      };

      const errorMessages: string[] = [];

      if (clip.contentStatus !== 'ready') {
        errorMessages.push('Clip is not in a Ready state. Please update.');
      }

      if (!clip.published) {
        errorMessages.push('Clip is not published. Please update.');
      }

      if (!clip.sources?.length) {
        errorMessages.push('Clip contains empty sources. Please update.');
      }

      // If the clip is live or promotional, don't get check Availability windows
      if (!clip.promotional && !clip.liveBroadcast) {
        const availabilityWindowMsg =
          'Clip has no availability windows, or is outside of its linear and/or AVOD availability window. Please update.';

        if (clip.availabilityWindows) {
          const now = new Date();

          const hasAVOD = (clip.availabilityWindows?.AVOD || []).some(
            w => now >= new Date(w.startDate) && now < new Date(w.endDate),
          );
          const hasLinear = (clip.availabilityWindows?.linear || []).some(
            w => now >= new Date(w.startDate) && now < new Date(w.endDate),
          );

          if (!hasAVOD && !hasLinear) {
            errorMessages.push(availabilityWindowMsg);
          }
        } else {
          errorMessages.push(availabilityWindowMsg);
        }
      }

      if (errorMessages.length > 0) {
        entry.state = 'error';
        entry.errorMessagesWithState = errorMessages.map(msg => ({
          state: 'error',
          message: msg,
        }));
      }

      return entry;
    },
    clip,
    createAdPods,
  );

export const setActive = (clips: ISimpleEntry[] = [], activeIndex?: number): Promise<ISimpleEntry[]> =>
  worker(
    (clips: ISimpleEntry[] = [], activeIndex?: number): ISimpleEntry[] =>
      clips.map((clip, index) =>
        Object.assign({}, clip, {
          active: Number.isFinite(activeIndex) && index === activeIndex,
        }),
      ),
    clips,
    activeIndex,
  );

export const findActive = (clips: ISimpleEntry[] = [], activeIndex: number): Promise<ISimpleEntry | undefined> =>
  worker(
    (clips: ISimpleEntry[], activeIndex?: number): ISimpleEntry | undefined =>
      clips.find((source, index) => Number.isFinite(activeIndex) && index === activeIndex),
    clips,
    activeIndex,
  );

export const onDropClip = (entries: ISimpleEntry[] = [], from: string, to: string): Promise<ISimpleEntry[]> =>
  worker(
    (entries: ISimpleEntry[], from: string, to: string): ISimpleEntry[] => {
      const fromIndex = entries.findIndex(entry => entry.id === from);
      const toIndex = entries.findIndex(entry => entry.id === to);

      const updatedEntries = Array.from(entries);
      updatedEntries.splice(toIndex, 0, updatedEntries.splice(fromIndex, 1)[0]);

      return updatedEntries;
    },
    entries,
    from,
    to,
  );

export const calcMarkerOffset = (
  clips: ISimpleEntry[] = [],
  activeIndex?: number,
  timestamp?: number,
  remPerMinute?: number,
): Promise<TSize> =>
  worker(
    (clips: ISimpleEntry[], activeIndex: number, timestamp = 0, remPerMinute?: number): TSize => {
      if (!Number.isFinite(activeIndex) || !Number.isFinite(timestamp) || !remPerMinute || clips.length === 0) {
        return '-1rem';
      }

      let offset = 0;

      clips.every((clip, index) => {
        if (index !== activeIndex) {
          let totalDuration = clip.duration;

          (clip.adPods || []).forEach(pod => {
            if (pod.startAt <= clip.duration) {
              totalDuration += pod.duration;
            }
          });

          offset += totalDuration;
          return true;
        }

        // Check to see if it timestamp falls inside of where we are representing an ad pod
        // If it is, just skip that section
        if (
          (clip.adPods || []).every(pod => {
            if (timestamp >= pod.startAt && timestamp < pod.startAt + pod.duration) {
              offset += pod.duration + timestamp;
              return false;
            }

            if (timestamp >= pod.startAt + pod.duration) {
              offset += pod.duration;
            }

            return true;
          })
        ) {
          // Check to see if timestamp is outside of the In/Out Points
          // If it is, just settle for those
          if (Number.isFinite(clip.inPoint) && timestamp < clip.inPoint!) {
            offset += 0;
          } else if (Number.isFinite(clip.outPoint) && timestamp > clip.outPoint!) {
            offset += clip.outPoint!;
          } else {
            offset += timestamp;
          }
        }

        return false;
      });

      return `${(offset / 60) * remPerMinute}rem`;
    },
    clips,
    activeIndex,
    timestamp,
    remPerMinute,
  );
