import * as React from 'react';
import {summon} from '@pluto-tv/assemble';
import {IPlayerSubtitle} from '@pluto-tv/assemble-player';

import {IClip} from 'models/clips';

import {isDropFrame} from './msToTimecode';

// ES and DAL handle the clip ID differently
export const getClipId = (clip: IClip): string => clip.id || clip._id;
const isMp4 = (url?: string) => url?.toLowerCase().endsWith('mp4');
const isM3u8 = (url?: string) => url?.toLowerCase().endsWith('m3u8');

const getSignedVideoUrl = async (clipId: string): Promise<{downloadUrl: string; streamUrl: string; query: string}> =>
  await summon.get<void, {downloadUrl: string; streamUrl: string; query: string}>(`clips/${clipId}/resource/video`);

const CC_LANGUAGES = {
  en: 'English',
  es: 'Spanish',
  de: 'German',
} as const;

export interface IClipVideoData {
  clipId: string;
  cc?: IPlayerSubtitle[];
  playerType: 'progressive' | 'hls';
  dropFrame: boolean;
  streamUrl?: string;
  signedUrl?: string;
  signedQuery?: string;
}

export const getClipVideo = async (clip: IClip): Promise<IClipVideoData> => {
  const cc: IPlayerSubtitle[] = [];
  let playerType: 'progressive' | 'hls' = 'progressive';
  let streamUrl: string | undefined = undefined;
  let signedUrl: string | undefined = undefined;
  let signedQuery: string | undefined = undefined;

  const dropFrame = isDropFrame(clip.framerate);

  let hasSignedUrl = false;

  const clipId = getClipId(clip);

  /** This is a pretty safe failback */
  if (clip.origin?.type === 'HLS') {
    playerType = 'hls';
  } else if (clip.origin?.url && isMp4(clip.origin?.url)) {
    playerType = 'progressive';
  }

  try {
    const signed = await getSignedVideoUrl(clipId);
    streamUrl = signed.streamUrl;
    signedUrl = signed.downloadUrl;
    signedQuery = signed.query;
    hasSignedUrl = true;

    // Change Player Type based on signed URL
    if (isM3u8(streamUrl)) {
      playerType = 'hls';
    } else if (isMp4(streamUrl)) {
      playerType = 'progressive';
    }
  } catch (e) {
    streamUrl = clip.origin?.url;
  }

  /**
   * This is commented out until we can figure out
   * the issue with HLS playback with DRM clips...
   */
  // Clip hasn't been ingested yet so use the origin
  // if (!clip.url) {
  //   if (clip.origin?.type === 'HLS') {
  //     playerType = 'hls';
  //     streamUrl = clip.origin.url;
  //   } else {
  //     playerType = 'progressive';

  //     try {
  //       const clipStreamUrl = await getSignedVideoUrl(clipId);
  //       streamUrl = clipStreamUrl;
  //       signedUrl = clipStreamUrl;
  //       hasSignedUrl = true;
  //     } catch (e) {}
  //   }
  // } else {
  //   if (isMp4(clip.url)) {
  //     playerType = 'progressive';

  //     try {
  //       const clipStreamUrl = await getSignedVideoUrl(clipId);
  //       streamUrl = clipStreamUrl;
  //       signedUrl = clipStreamUrl;
  //     } catch (e) {
  //       streamUrl = clip.url;
  //     }
  //   } else {
  //     playerType = 'hls';
  //     streamUrl = clip.url;
  //   }
  // }

  const ccPromises: Promise<void>[] = [];

  (clip?.cc || []).forEach(ccObj => {
    const ccId = ccObj.id || ccObj._id;
    ccPromises.push(
      summon
        .get<void, {downloadUrl: string}>(`clips/${clipId}/resource/cc/${ccId}`)
        .then(ccData => {
          const label = CC_LANGUAGES[ccObj.language] ? CC_LANGUAGES[ccObj.language] : ccObj.language;

          cc.push({
            label,
            enabled: false,
            language: ccObj.language,
            source: ccData.downloadUrl,
          });
        })
        .catch(),
    );
  });

  await Promise.allSettled(ccPromises);

  // Get Signed URL
  if (isMp4(clip.origin?.type) && !hasSignedUrl) {
    try {
      const clipStreamUrl = (await getSignedVideoUrl(clipId)).downloadUrl;
      signedUrl = clipStreamUrl;
    } catch (e) {}
  }

  return {
    cc,
    playerType,
    streamUrl,
    signedUrl,
    signedQuery,
    clipId,
    dropFrame,
  };
};

interface IuseGetClipVideo {
  cc?: IPlayerSubtitle[];
  isLoading: boolean;
  playerType: 'progressive' | 'hls';
  setActiveClip: (clip?: IClip) => void;
  signedUrl?: string;
  signedQuery?: string;
  streamUrl?: string;
  dropFrame?: boolean;
}

export const useGetClipVideo = (): IuseGetClipVideo => {
  const [clip, setClip] = React.useState<IClip>();
  const [cc, setCC] = React.useState<IPlayerSubtitle[]>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [playerType, setPlayerType] = React.useState<'progressive' | 'hls'>('hls');
  const [streamUrl, setStreamUrl] = React.useState<string>();
  const [signedUrl, setSignedUrl] = React.useState<string>();
  const [signedQuery, setSignedQuery] = React.useState<string>();
  const [dropFrame, setDropFrame] = React.useState<boolean>();

  const setActiveClip = (clip?: IClip) => setClip(clip);

  const reset = () => {
    setIsLoading(true);
    setCC(undefined);
    setPlayerType('hls');
    setStreamUrl(undefined);
    setSignedUrl(undefined);
    setSignedQuery(undefined);
    setDropFrame(undefined);
  };

  React.useEffect(() => {
    if (!clip || (!clip._id && !clip.id)) {
      return;
    }

    reset();

    (async () => {
      const {playerType, cc, signedUrl, streamUrl, signedQuery, dropFrame} = await getClipVideo(clip);

      setPlayerType(playerType);
      setSignedUrl(signedUrl);
      setSignedQuery(signedQuery);
      setStreamUrl(streamUrl);
      setCC(cc);
      setDropFrame(dropFrame);

      setIsLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clip]);

  return {
    cc,
    isLoading,
    playerType,
    setActiveClip,
    signedUrl,
    streamUrl,
    signedQuery,
    dropFrame,
  };
};
