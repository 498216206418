import * as React from 'react';
import {get} from 'lodash-es';
import {Box, Notification} from '@pluto-tv/assemble';

const extractErrorMessage = error => {
  const message = get(error, 'error.data.message', null) || get(error, 'error.error.data.message', null);
  return message ? message.charAt(0).toUpperCase() + message.slice(1) : 'Unknown server error';
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (error: any): JSX.Element => {
  return (
    <Box fullHeight={true} padding={{mobile: 'medium', wide: 'xlarge'}}>
      <Notification type='warning'>{extractErrorMessage(error)}</Notification>
    </Box>
  );
};
