import {IValidatorField} from '@pluto-tv/assemble';
import {isNumber} from 'lodash-es';

interface IRunLimitSearch {
  month: string;
  year: string;
  partnerId?: string;
  activeRegions?: string[];
  title?: string;
  programmingType?: string;
  licenseFee?: number;
}

export const searchRunLimitsValidator: IValidatorField<IRunLimitSearch>[] = [
  {
    name: 'activeRegions',
    label: 'Active Region(s)',
  },
  {
    name: 'partnerId',
    label: 'Partner',
  },
  {
    name: 'title',
    label: 'Title',
  },
  {
    name: 'month',
    label: 'Month',
  },
  {
    name: 'year',
    label: 'Year',
  },
  {
    name: 'programmingType',
    label: 'Programming Type',
  },
  {
    name: 'licenseFee',
    label: 'License Fee',
    validators: [
      (licenseFee: number): string | undefined => {
        if (isNumber(licenseFee) && (licenseFee < -1 || licenseFee > 5)) {
          return 'License Fee must be between -1 and 5';
        }
      },
    ],
  },
];
