import * as React from 'react';
import {Box, Button, Cluster, FormItem, IValidatorField, Stack, TextInput, useValidateForm} from '@pluto-tv/assemble';

const emptyModel = {
  order: 0,
};

export interface IOrder {
  order: number;
  maxOrder?: number;
}

export interface IOrderFormProps {
  value?: Partial<IOrder>;
  onCancel(): void;
  onSave(savedObj: IOrder): void;
  visible?: boolean;
  maxValue?: number;
}

const OrderForm = React.memo(({onCancel, value, onSave, visible, maxValue}: IOrderFormProps) => {
  const orderValidator: IValidatorField<IOrder>[] = [
    {
      name: 'order',
      label: 'Order',
      required: true,
      validators: [
        (order: number, localModel): string | undefined => {
          if (order === undefined) {
            return 'Order is required';
          } else if (order <= 0) {
            return 'Order should be positive';
          } else if (localModel.maxOrder && order > localModel.maxOrder) {
            return `Order should be at most ${localModel.maxOrder}`;
          }
        },
      ],
    },
    {
      name: 'maxOrder',
    },
  ];

  const {
    model,
    form,
    setModel,
    state: formState,
    onBlur,
    onChange: searchOnChange,
  } = useValidateForm<IOrder>(orderValidator, 'immediate');

  React.useEffect(() => {
    if (visible) {
      setModel(
        {
          order: value?.order,
          maxOrder: maxValue,
        } || emptyModel,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, value]);

  return (
    <Box padding='small' background='charcoal'>
      <Stack space='small'>
        <FormItem {...form.order} onBlur={() => onBlur('order', true)}>
          <TextInput
            type='number'
            value={model?.order ? model.order : 0}
            onChange={val => {
              searchOnChange('order', val);
            }}
          />
        </FormItem>
        <Cluster justify='space-between'>
          <div></div>
          <Cluster space='small'>
            <Button ghost={true} onClick={onCancel}>
              Cancel
            </Button>
            <Button
              type='primary'
              onClick={() => onSave(model as IOrder)}
              state={!formState.isValid || !formState.isDirty ? 'disabled' : ''}
            >
              Apply
            </Button>
          </Cluster>
        </Cluster>
      </Stack>
    </Box>
  );
});

OrderForm.displayName = 'OrderForm';
export default OrderForm;
