import React from 'react';

interface IUseHandleKeyAction {
  keyPressed: boolean;
  setKeyPressed: React.Dispatch<React.SetStateAction<boolean>>;
}

const useHandleKeyAction = (key: string): IUseHandleKeyAction => {
  const [keyPressed, setKeyPressed] = React.useState(false);
  React.useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === key) {
        event.preventDefault();

        setKeyPressed(true);
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [key]);

  return {keyPressed, setKeyPressed};
};

export default useHandleKeyAction;
