import {IFeaturedGroup} from 'models/featuredGroup';
import {IRegionDateGroups} from 'components/featuredGroupList/hooks/useAllGroupsData';

export interface IValidatorMessage {
  message: string;
  icon?: string;
}

export interface IValidatorState {
  state: 'error' | 'info' | 'warning' | 'success' | 'disabled' | 'highlight' | '';
  validatorMessage?: IValidatorMessage;
  stop?: boolean; // If true, stops the validation process, subsequent validators will not be called.
}

export type IValidator = ({
  row,
  mappedGroups,
  message,
}: {
  row: IFeaturedGroup;
  mappedGroups?: IRegionDateGroups;
  message?: string;
}) => IValidatorState | null;

const getStateMessage = (row: IFeaturedGroup, mappedGroups: IRegionDateGroups): string => {
  const conflictingGroups = mappedGroups?.[row.activeRegion.toUpperCase()]?.[row.start as string];

  if (!conflictingGroups || conflictingGroups.length < 2) return '';

  const remainingConflictingGoups = conflictingGroups.filter(group => group.id !== row.id);

  let message = `${row.name} has the same start date and time as ${remainingConflictingGoups[0].name}`;

  message +=
    conflictingGroups.length > 2
      ? ` and ${remainingConflictingGoups.length - 1} other ${
          remainingConflictingGoups.length - 1 === 1 ? 'group' : 'groups'
        }.`
      : '.';

  return message;
};

export const validatePublished: IValidator = ({row}) => {
  if (row.published) {
    return {
      state: 'success',
    };
  }
  return null;
};

export const validateHasUnpublishedChannels: IValidator = ({
  row,
  message = `${row.name} references an unpublished channel(s).`,
}) => {
  const hasUnpublishedGroups = row.contentItems?.some(group => !group.content.published);

  if (hasUnpublishedGroups) {
    return {
      state: 'warning',
      validatorMessage: {
        message,
        icon: 'warning',
      },
    };
  }
  return null;
};

export const validateConflictingGroups: IValidator = ({row, mappedGroups}) => {
  if (!mappedGroups) return null;
  const message = getStateMessage(row, mappedGroups);

  if (message.length) {
    return {
      state: 'warning',
      validatorMessage: {
        message,
        icon: 'warning',
      },
    };
  }
  return null;
};

export const validateGroupHasNoChannel: IValidator = ({
  row,
  message = 'This group currently has no channels assigned. Please update.',
}) => {
  const groupHasChannels = row.contentItems?.length > 0;

  if (groupHasChannels) {
    return null;
  }
  return {
    state: 'warning',
    validatorMessage: {
      message,
      icon: 'warning',
    },
  };
};
