import * as React from 'react';

export const useHighlightItems = <T extends {id: string} | string>(
  timeout = 4000,
): {
  items: string[];
  add: (itemsToAdd: T[], shouldEmpty?: boolean) => void;
  remove: (itemsToRemove: T[]) => void;
} => {
  const [items, setItems] = React.useState<string[]>([]);

  const add = React.useCallback(
    (itemsToAdd: T[], shouldEmpty = false) => {
      if (shouldEmpty) {
        setItems([]);
      } else {
        setItems(prev => [...prev, ...itemsToAdd.map(item => (typeof item === 'string' ? item : item.id))]);
        setTimeout(() => {
          setItems(prev =>
            prev.filter(
              item =>
                !itemsToAdd.some(itemToAdd =>
                  typeof itemToAdd === 'string' ? itemToAdd === item : itemToAdd.id === item,
                ),
            ),
          );
        }, timeout);
      }
    },
    [timeout],
  );

  const remove = React.useCallback((itemsToRemove: T[]) => {
    setItems(prev =>
      prev.filter(
        item =>
          !itemsToRemove.some(itemToRemove =>
            typeof itemToRemove === 'string' ? itemToRemove === item : itemToRemove.id === item,
          ),
      ),
    );
  }, []);

  return {
    items,
    add,
    remove,
  };
};
