import {IValidatorField} from '@pluto-tv/assemble';
import {IActiveRegion} from 'models/activeRegions';

export const activeRegionValidator: IValidatorField<IActiveRegion>[] = [
  {
    name: 'name',
    label: 'Region Name',
    required: true,
    validators: [
      (name: string): string | undefined => {
        if (!name || !name.trim().length) {
          return 'Name is required';
        } else if (name && name.trim().length > 100) {
          return 'Name must be less than 100 characters';
        }
      },
    ],
  },
  {
    name: 'code',
    label: 'Region Slug',
    required: true,
    validators: [
      (code: string): string | undefined => {
        if (!code || !code.trim().length) {
          return 'Region Slug is required';
        }
      },
    ],
  },
  {
    name: 'territories',
    label: 'Associated Territories',
  },
];
