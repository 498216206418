import * as React from 'react';

import FeaturedGroupsList from './FeaturedGroupsList';
import {FeaturedGroupsSearchQueryProvider} from './providers/FeaturedGroupsSearchQueryProvider';
import {FeaturedGroupsFiltersProvider} from './providers/FeaturedGroupsFiltersProvider';

const FeaturedGroupsListWrapper = (): React.ReactElement => {
  return (
    <FeaturedGroupsSearchQueryProvider>
      <FeaturedGroupsFiltersProvider>
        <FeaturedGroupsList />
      </FeaturedGroupsFiltersProvider>
    </FeaturedGroupsSearchQueryProvider>
  );
};

export default FeaturedGroupsListWrapper;
