export const formatDateRange = (startDate: string, endDate: string): {startDate: string; endDate: string} => {
  const formattedStartDate = new Date(startDate).toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    timeZone: 'UTC',
  });
  const formattedEndDate = new Date(endDate).toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    timeZone: 'UTC',
  });
  return {
    startDate: formattedStartDate,
    endDate: formattedEndDate,
  };
};
