import {ISimpleScheduleEntry, Toast} from '@pluto-tv/assemble';
import {useLazyRepeatScheduleQuery} from 'features/channelProgram/channelProgramApi';
import {IChannelCatalogItem} from 'models/channelCatalog';
import * as React from 'react';
import {
  isSimpleScheduleEntry,
  getRepeatStartDate,
} from 'views/programming/channel/edit/program/components/EpisodesDetails';
import {formatDateWithTimezone, getRepeatDays} from 'views/programming/channel/utils/dateUtils';

interface IUseRecurrenceValidationResponse {
  isValidRecurrence: boolean;
  recurrenceWarning: {
    [key in 'occurrences' | 'timeframe']: undefined | string;
  };
  repeatPeriod: number;
  endRepeatType: 'after' | 'date';
  endRepeatDate: Date | undefined;
  timeframe: 'day' | 'week' | 'month' | 'years';
  cycleOver: 'season' | 'series';
  positioning: 'week' | 'month';
  occurrences: number;
  sunRepeat: boolean;
  monRepeat: boolean;
  tueRepeat: boolean;
  wedRepeat: boolean;
  thuRepeat: boolean;
  friRepeat: boolean;
  satRepeat: boolean;

  setRepeatPeriod: (value: number) => void;
  setEndRepeatType: (value: 'after' | 'date') => void;
  setEndRepeatDate: (value: Date | undefined) => void;
  setTimeframe: (value: 'day' | 'week' | 'month' | 'years') => void;
  setCycleOver: (value: 'season' | 'series') => void;
  setPositioning: (value: 'week' | 'month') => void;
  setOccurrences: (value: number) => void;
  setSunRepeat: (value: boolean) => void;
  setMonRepeat: (value: boolean) => void;
  setTueRepeat: (value: boolean) => void;
  setWedRepeat: (value: boolean) => void;
  setThuRepeat: (value: boolean) => void;
  setFriRepeat: (value: boolean) => void;
  setSatRepeat: (value: boolean) => void;

  handleAddRepeatSchedule: (
    episodeToRepeat: IChannelCatalogItem | ISimpleScheduleEntry,
    lastRepeatRequest: number,
    timeToAddNewEntry?: number,
  ) => Promise<any>;
}

const useRecurrenceValidation = (channelId: string): IUseRecurrenceValidationResponse => {
  // 'repeat every' value
  const [repeatPeriod, setRepeatPeriod] = React.useState<number>(1);
  // endRepeatType Indicates if repeat schedule will finish after:
  //  - On given date ("date")
  //  - After N occurrences ("after")
  const [endRepeatType, setEndRepeatType] = React.useState<'after' | 'date'>('date');
  // Value store when endRepeatType is "date"
  const [endRepeatDate, setEndRepeatDate] = React.useState<Date | undefined>(undefined);
  const [timeframe, setTimeframe] = React.useState<'day' | 'week' | 'month' | 'years'>('day');
  // Indicates if repeat schedule will iterate over:
  //  - Episodes in this season ("season")
  //  - Episodes and seasons in the series ("series")
  const [cycleOver, setCycleOver] = React.useState<'season' | 'series'>('series');
  // End after how many occurrences
  const [occurrences, setOccurrences] = React.useState<number>(1);
  // flags for the days of the week to repeat
  const [sunRepeat, setSunRepeat] = React.useState(false);
  const [monRepeat, setMonRepeat] = React.useState(false);
  const [tueRepeat, setTueRepeat] = React.useState(false);
  const [wedRepeat, setWedRepeat] = React.useState(false);
  const [thuRepeat, setThuRepeat] = React.useState(false);
  const [friRepeat, setFriRepeat] = React.useState(false);
  const [satRepeat, setSatRepeat] = React.useState(false);

  const [positioning, setPositioning] = React.useState<'week' | 'month'>('week');

  const [recurrenceWarning, setRecurrenceWarning] = React.useState<
    {
      [key in 'occurrences' | 'timeframe']: undefined | string;
    }
  >({occurrences: undefined, timeframe: undefined});

  const isValidRecurrence = React.useMemo(() => {
    // check mandatory fields
    // - repeatPeriod not empty
    // - timeframe not empty
    // - endRepeatType not empty
    // - cycleOver not empty
    // - if endRepeatType is 'after', occurrences not empty
    // - if endRepeatType is 'date', endRepeatDate not empty
    // - if timeframe is weeks, at least one day selected
    const repeatDays = getRepeatDays(
      timeframe,
      sunRepeat,
      monRepeat,
      tueRepeat,
      wedRepeat,
      thuRepeat,
      friRepeat,
      satRepeat,
    );

    const hasErrors = Object.keys(recurrenceWarning).find(key => !!recurrenceWarning[key]);

    if (
      !repeatPeriod ||
      !timeframe ||
      !endRepeatType ||
      !cycleOver ||
      (endRepeatType === 'after' && !occurrences) ||
      (endRepeatType === 'date' && !endRepeatDate) ||
      (timeframe === 'week' && repeatDays.length === 0) ||
      hasErrors
    ) {
      return false;
    }
    return true;
  }, [
    cycleOver,
    endRepeatDate,
    endRepeatType,
    friRepeat,
    monRepeat,
    occurrences,
    recurrenceWarning,
    repeatPeriod,
    satRepeat,
    sunRepeat,
    thuRepeat,
    timeframe,
    tueRepeat,
    wedRepeat,
  ]);

  React.useEffect(() => {
    /*
      If timeframe is month
        - validate that repeatPeriod is between 1 and 24,
          different value will display an error
      If timeframe is day or week
        - value should be between 1 and 60, different value will display an error
    */
    if (timeframe === 'month' && (repeatPeriod < 1 || repeatPeriod > 24)) {
      setRecurrenceWarning({...recurrenceWarning, timeframe: 'Repeat every must be between 1 and 24'});
    } else if ((timeframe === 'day' || timeframe === 'week') && (repeatPeriod < 1 || repeatPeriod > 60)) {
      setRecurrenceWarning({...recurrenceWarning, timeframe: 'Repeat every must be between 1 and 60'});
    } else {
      setRecurrenceWarning({...recurrenceWarning, timeframe: undefined});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeframe, repeatPeriod]);

  React.useEffect(() => {
    if (endRepeatType === 'after') {
      if (occurrences > 100) {
        setRecurrenceWarning({...recurrenceWarning, occurrences: 'Value must be equal to or less than 100'});
      } else if (occurrences < 1) {
        setRecurrenceWarning({...recurrenceWarning, occurrences: 'Value must be equal to or greater than 1'});
      } else {
        setRecurrenceWarning({...recurrenceWarning, occurrences: undefined});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [occurrences, endRepeatType]);

  React.useEffect(() => {
    if (endRepeatType === 'date') {
      if (endRepeatDate && endRepeatDate.getTime() < new Date().setHours(0, 0, 0, 0)) {
        setRecurrenceWarning({...recurrenceWarning, occurrences: 'Date Value must be greater than today'});
      } else {
        setRecurrenceWarning({...recurrenceWarning, occurrences: undefined});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endRepeatType, endRepeatDate]);

  // Recurrence options
  const [searchRepeatSchedule] = useLazyRepeatScheduleQuery();

  const handleAddRepeatSchedule = async (
    episodeToRepeat: IChannelCatalogItem | ISimpleScheduleEntry,
    lastRepeatRequest: number,
    timeToAddNewEntry?: number,
  ) => {
    if (!isValidRecurrence) {
      Toast.error('Invalid input, please check the fields');
      return;
    }

    const repeatDays = getRepeatDays(
      timeframe,
      sunRepeat,
      monRepeat,
      tueRepeat,
      wedRepeat,
      thuRepeat,
      friRepeat,
      satRepeat,
    );

    // Get the first selected episode
    const selectedEp = episodeToRepeat;
    const isEpisodeTimeline = isSimpleScheduleEntry(selectedEp);
    const episodeId = isEpisodeTimeline ? selectedEp.episodeId : selectedEp.id;

    try {
      const response = await searchRepeatSchedule({
        lastUpdate: lastRepeatRequest,
        channelId,
        repeatPeriod: repeatPeriod,
        repeatInterval: timeframe,
        // if episode is already in the timeline use its start, otherwise use current date
        start: timeToAddNewEntry ? new Date(timeToAddNewEntry).toString() : getRepeatStartDate(selectedEp),
        series: selectedEp.series.id || '',
        episode: episodeId,
        season: selectedEp.season || 1,
        end: endRepeatType,
        endDate: endRepeatType === 'date' ? formatDateWithTimezone(endRepeatDate ?? '') : undefined,
        // occurrences: occurrences, // send only if endRepeatType is 'after'
        occurrences: endRepeatType === 'after' ? occurrences : undefined,
        // if will start on the same day of week or same day of month
        monthInterval: timeframe === 'month' ? positioning : 'week',
        cycle: cycleOver,
        localDay: new Date().getDay(),
        repeatDays: repeatDays,
      });

      if (response.isError) {
        return {data: null, error: response.error};
      }

      return {data: response.data, error: null, originalArgs: response.originalArgs};
    } catch (error) {
      return {error, data: null};
    }
  };

  return {
    isValidRecurrence,
    recurrenceWarning,
    repeatPeriod,
    endRepeatType,
    endRepeatDate,
    timeframe,
    cycleOver,
    occurrences,
    sunRepeat,
    monRepeat,
    tueRepeat,
    wedRepeat,
    thuRepeat,
    friRepeat,
    satRepeat,
    positioning,

    setPositioning,
    setRepeatPeriod,
    setEndRepeatType,
    setEndRepeatDate,
    setTimeframe,
    setCycleOver,
    setOccurrences,
    setSunRepeat,
    setMonRepeat,
    setTueRepeat,
    setWedRepeat,
    setThuRepeat,
    setFriRepeat,
    setSatRepeat,
    handleAddRepeatSchedule,
  };
};

export default useRecurrenceValidation;
