import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

import {RootState} from 'app/store';
import {IGenre} from 'models/genres';

export const genresApi = createApi({
  reducerPath: 'genresApi',
  tagTypes: ['Genres'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/v1/',
    prepareHeaders(headers, {getState}) {
      const token = (getState() as RootState).user.jwt;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    find: builder.query<IGenre[], void>({
      query: () => ({
        url: `genres`,
        method: 'GET',
      }),
      providesTags: ['Genres'],
    }),
  }),
});

export const {useFindQuery} = genresApi;
