import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {RootState} from 'app/store';
import {IRole} from 'models/roles';
import {IListPayload, IListQuery} from 'models/generic';

export const rolesApi = createApi({
  reducerPath: 'rolesApi',
  tagTypes: ['Roles', 'Users'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/v1/',
    prepareHeaders(headers, {getState}) {
      const token = (getState() as RootState).user.jwt;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    find: builder.query<IListPayload<IRole>, IListQuery>({
      query: ({offset = 0, limit = 10, sort = '_id:dsc'}) => ({
        url: `roles?offset=${offset}&limit=${limit}&sort=${sort}`,
        method: 'GET',
      }),
      providesTags: ['Roles'],
    }),
    findById: builder.query<IRole, string>({
      query: (id: string) => ({
        url: `roles/${id}`,
        method: 'GET',
      }),
      providesTags: ['Roles'],
    }),
    insert: builder.mutation<IRole, Partial<IRole>>({
      query: role => ({
        url: `roles`,
        method: 'POST',
        body: role,
      }),
      invalidatesTags: ['Roles'],
    }),
    update: builder.mutation<IRole, {id: string; role: Partial<IRole>}>({
      query: ({id, role}) => ({
        url: `roles/${id}`,
        method: 'PUT',
        body: role,
      }),
      invalidatesTags: ['Roles', 'Users'],
    }),
    delete: builder.mutation<IRole, string>({
      query: (id: string) => ({
        url: `roles/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Roles', 'Users'],
    }),
  }),
});

export const {
  useFindQuery,
  useFindByIdQuery,
  useLazyFindByIdQuery,
  useInsertMutation,
  useUpdateMutation,
  useDeleteMutation,
} = rolesApi;
