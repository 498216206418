import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {INotification, NotificationType} from 'models/notification';

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {type: 'info', text: '', isVisible: false} as INotification,
  reducers: {
    showNotification: (state, {payload: {type, text}}: PayloadAction<{type: NotificationType; text: string}>) => {
      state.type = type;
      state.text = text;
      state.isVisible = true;
    },
    hideNotification: state => {
      state.isVisible = false;
      state.text = '';
      state.type = 'info';
    },
  },
});

export const {showNotification, hideNotification} = notificationSlice.actions;

export default notificationSlice.reducer;
