import * as React from 'react';
import {Pagination} from '@pluto-tv/assemble';
import {SearchState} from 'components/featuredGroupList/FeaturedGroupsList';
import {useFeaturedGroupsSearchQuery} from 'components/featuredGroupList/providers/FeaturedGroupsSearchQueryProvider';

interface IFeaturedGroupsPaginationProps {
  onChangeState: (state: Partial<SearchState>) => void;
  onPageChange: () => void;
  searchState: SearchState;
}

const FeaturedGroupsPagination = ({
  onChangeState,
  searchState,
  onPageChange,
}: IFeaturedGroupsPaginationProps): React.ReactElement => {
  const {data, currentData, isFetching, error} = useFeaturedGroupsSearchQuery();
  const {rowsPerPage, page} = searchState;

  const totalCount = data?.metadata.totalCount || 0;
  const currentDataCount =
    (error as {status: number})?.status === 404 ? currentData?.metadata.totalCount || 0 : totalCount;
  const isDisabled = isFetching || !Boolean(totalCount);

  const handleOnPageChange = (page: number) => {
    onChangeState({
      page,
    });
    onPageChange();
  };

  const handleOnPerPageChange = (perPage: number) => {
    onChangeState({
      rowsPerPage: perPage as 25 | 50 | 75 | 100,
      page: 0,
    });
  };

  return (
    <Pagination
      perPage={rowsPerPage}
      currentPage={page}
      disabled={isDisabled}
      total={currentDataCount}
      onPageChange={handleOnPageChange}
      onPerPageChange={handleOnPerPageChange}
    />
  );
};

export default FeaturedGroupsPagination;
