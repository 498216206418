import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {RootState} from 'app/store';
import {IRatingDescriptor} from 'models/ratingDescriptors';

export const ratingDescriptorsApi = createApi({
  reducerPath: 'ratingDescriptorsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/v1/',
    prepareHeaders(headers, {getState}) {
      const token = (getState() as RootState).user.jwt;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints(builder) {
    return {
      find: builder.query<IRatingDescriptor[], void>({
        query: () => ({
          url: `rating-descriptors`,
          method: 'GET',
        }),
      }),
    };
  },
});

export const {useFindQuery} = ratingDescriptorsApi;
