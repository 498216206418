import * as React from 'react';
import {useSearchQuery} from 'features/featuredGroups/featuredGroupsApi';
import {useUserRegions} from 'helpers/useUserRegions';
import {IFeaturedGroup} from 'models/featuredGroup';

interface IUsePublishedUpcomingDataProps {
  activeRegion?: string | string[];
}
interface IUsePublishedUpcomingDataReturnType {
  featuredGroups?: IFeaturedGroup[];
  isError: boolean;
  isFetching: boolean;
}

const usePublishedUpcomingData = ({
  activeRegion,
}: IUsePublishedUpcomingDataProps): IUsePublishedUpcomingDataReturnType => {
  const {activeRegions} = useUserRegions();

  const params = React.useMemo(() => {
    const startDate = new Date();
    const endDate = new Date();
    // get data for the next 12 months
    endDate.setFullYear(endDate.getFullYear() + 1);

    return {
      offset: 0,
      limit: 250,
      sort: 'start:asc',
      activeRegion: activeRegion || (activeRegions || []).map(region => region.code),
      startDate: startDate.toISOString(),
      startEndDate: endDate.toISOString(),
    };
  }, [activeRegions, activeRegion]);

  const {
    data: featuredGroups,
    isError,
    isFetching,
  } = useSearchQuery(params, {
    refetchOnMountOrArgChange: true,
    skip: !activeRegions.length,
  });

  return {
    featuredGroups: featuredGroups?.data,
    isError,
    isFetching,
  };
};

export default usePublishedUpcomingData;
