import ClipList from 'components/clipList';
import ClipEditPage from 'views/content/clip/edit';
import EpisodeList from 'components/episodeList';
import EpisodeEditPage from 'views/content/episode/edit';
import SeriesList from 'components/seriesList';
import SeriesEditPage from 'views/content/series/edit';

import {clipPermissions} from 'views/content/clip/permissions';
import {episodePermissions} from 'views/content/episode/permissions';
import {seriesPermissions} from 'views/content/series/permissions';

import {IRouteItem} from 'routes';
import {getSeriesIdFromUrlPath} from 'helpers/seriesIdFromPath';

const paths = {
  clipListPage: '/clips',
  clipEditDetailsPageAlt: '/clips/:id',
  clipEditDetailsPage: '/clips/:id/details',
  clipEditSettingsPage: '/clips/:id/settings',
  clipEditMediaPage: '/clips/:id/media',
  clipEditCaptionsAudioPage: '/clips/:id/captions-audio',
  clipEditEpisodesPage: '/clips/:id/episodes',
  episodeListPage: '/episodes',
  episodeEditDetailsPageAlt: '/episodes/:id',
  episodeEditDetailsPage: '/episodes/:id/details',
  episodeEditSettingsPage: '/episodes/:id/settings',
  episodeEditSchedulingPage: '/episodes/:id/scheduling',
  episodeEditVideoPage: '/episodes/:id/video',
  episodeEditAssetsPage: '/episodes/:id/assets',
  seriesListPage: '/series',
  seriesEditDetailsPageAlt: '/series/:id',
  seriesEditDetailsPage: '/series/:id/details',
  seriesEditSettingsPage: '/series/:id/settings',
  seriesEditEpisodesPage: '/series/:id/episodes',
  seriesEditEpisodesDetailsPage: '/series/:id/episodes/:episodeId/details',
  seriesEditEpisodesSettingsPage: '/series/:id/episodes/:episodeId/settings',
  seriesEditEpisodesSchedulingPage: '/series/:id/episodes/:episodeId/scheduling',
  seriesEditEpisodesVideoPage: '/series/:id/episodes/:episodeId/video',
  seriesEditEpisodesAssetsPage: '/series/:id/episodes/:episodeId/assets',
  seriesEditSchedulingPage: '/series/:id/scheduling',
  seriesEditAssetsPage: '/series/:id/assets',
};

const getRoutes = (): IRouteItem[] => {
  const seriesId = getSeriesIdFromUrlPath();

  return [
    {
      name: 'Content',
      icon: 'content',
      showInSideBar: true,
      requiredPermissions: [
        ...clipPermissions.CLIP_VIEW.enabled,
        ...episodePermissions.EPISODE_VIEW.enabled,
        ...seriesPermissions.SERIES_VIEW.enabled,
      ],
      children: [
        {
          name: 'Series',
          path: paths.seriesListPage,
          component: SeriesList,
          breadcrumb: {items: [{text: 'Series', route: paths.seriesListPage}]},
          requiredPermissions: seriesPermissions.SERIES_VIEW.enabled,
        },
        {
          name: 'Episodes',
          path: paths.episodeListPage,
          component: EpisodeList,
          breadcrumb: {items: [{text: 'Episodes', route: paths.episodeListPage}]},
          requiredPermissions: episodePermissions.EPISODE_VIEW.enabled,
        },
        {
          name: 'Clips',
          path: paths.clipListPage,
          component: ClipList,
          breadcrumb: {items: [{text: 'Clips', route: paths.clipListPage}]},
          requiredPermissions: clipPermissions.CLIP_VIEW.enabled,
        },
      ],
    },
    {
      name: 'Clip Edit',
      children: [
        {
          name: 'Clip Edit Detail',
          path: paths.clipEditDetailsPage,
          component: ClipEditPage,
          breadcrumb: {
            items: [
              {text: 'Clips', route: paths.clipListPage},
              {text: 'Clip Details', route: paths.clipEditDetailsPage},
            ],
          },
          requiredPermissions: clipPermissions.CLIP_VIEW.enabled,
        },
        {
          name: 'Clip Edit Detail',
          path: paths.clipEditDetailsPageAlt,
          component: ClipEditPage,
          breadcrumb: {
            items: [
              {text: 'Clips', route: paths.clipListPage},
              {text: 'Clip Details', route: paths.clipEditDetailsPage},
            ],
          },
          requiredPermissions: clipPermissions.CLIP_VIEW.enabled,
        },
        {
          name: 'Clip Edit Settings',
          path: paths.clipEditSettingsPage,
          component: ClipEditPage,
          breadcrumb: {
            items: [
              {text: 'Clips', route: paths.clipListPage},
              {text: 'Clip Settings', route: paths.clipEditSettingsPage},
            ],
          },
          requiredPermissions: clipPermissions.CLIP_VIEW.enabled,
        },
        {
          name: 'Clip Edit Media',
          path: paths.clipEditMediaPage,
          component: ClipEditPage,
          breadcrumb: {
            items: [
              {text: 'Clips', route: paths.clipListPage},
              {text: 'Clip Media', route: paths.clipEditMediaPage},
            ],
          },
          requiredPermissions: clipPermissions.CLIP_VIEW.enabled,
        },
        {
          name: 'Clip Edit Captions/Audio',
          path: paths.clipEditCaptionsAudioPage,
          component: ClipEditPage,
          breadcrumb: {
            items: [
              {text: 'Clips', route: paths.clipListPage},
              {text: 'Clip Captions & Audio', route: paths.clipEditCaptionsAudioPage},
            ],
          },
          requiredPermissions: clipPermissions.CLIP_VIEW.enabled,
        },
        {
          name: 'Clip Edit Episodes',
          path: paths.clipEditEpisodesPage,
          component: ClipEditPage,
          breadcrumb: {
            items: [
              {text: 'Clips', route: paths.clipListPage},
              {text: 'Clip Episodes', route: paths.clipEditEpisodesPage},
            ],
          },
          requiredPermissions: clipPermissions.CLIP_VIEW.enabled,
        },
      ],
    },
    {
      name: 'Episode Edit',
      children: [
        {
          name: 'Episode Edit Detail',
          path: paths.episodeEditDetailsPage,
          component: EpisodeEditPage,
          breadcrumb: {
            items: [
              {text: 'Episodes', route: paths.episodeListPage},
              {text: 'Episode Details', route: paths.episodeEditDetailsPage},
            ],
          },
          requiredPermissions: episodePermissions.EPISODE_VIEW.enabled,
        },
        {
          name: 'Episode Edit Detail',
          path: paths.episodeEditDetailsPageAlt,
          component: EpisodeEditPage,
          breadcrumb: {
            items: [
              {text: 'Episodes', route: paths.episodeListPage},
              {text: 'Episode Details', route: paths.episodeEditDetailsPage},
            ],
          },
          requiredPermissions: episodePermissions.EPISODE_VIEW.enabled,
        },
        {
          name: 'Episode Edit Settings',
          path: paths.episodeEditSettingsPage,
          component: EpisodeEditPage,
          breadcrumb: {
            items: [
              {text: 'Episodes', route: paths.episodeListPage},
              {text: 'Episode Settings', route: paths.episodeEditSettingsPage},
            ],
          },
          requiredPermissions: episodePermissions.EPISODE_VIEW.enabled,
        },
        {
          name: 'Episode Edit Scheduling',
          path: paths.episodeEditSchedulingPage,
          component: EpisodeEditPage,
          breadcrumb: {
            items: [
              {text: 'Episodes', route: paths.episodeListPage},
              {text: 'Episode Scheduling', route: paths.episodeEditSchedulingPage},
            ],
          },
          requiredPermissions: episodePermissions.EPISODE_VIEW.enabled,
        },
        {
          name: 'Episode Edit Video',
          path: paths.episodeEditVideoPage,
          component: EpisodeEditPage,
          breadcrumb: {
            items: [
              {text: 'Episodes', route: paths.episodeListPage},
              {text: 'Episode Video', route: paths.episodeEditVideoPage},
            ],
          },
          requiredPermissions: episodePermissions.EPISODE_VIEW.enabled,
        },
        {
          name: 'Episode Edit Assets',
          path: paths.episodeEditAssetsPage,
          component: EpisodeEditPage,
          breadcrumb: {
            items: [
              {text: 'Episodes', route: paths.episodeListPage},
              {text: 'Episode Assets', route: paths.episodeEditAssetsPage},
            ],
          },
          requiredPermissions: episodePermissions.EPISODE_VIEW.enabled,
        },
      ],
    },
    {
      name: 'Series Edit',
      children: [
        {
          name: 'Series Edit Detail',
          path: paths.seriesEditDetailsPage,
          component: SeriesEditPage,
          breadcrumb: {
            items: [
              {text: 'Series', route: paths.seriesListPage},
              {text: 'Series Details', route: paths.seriesEditDetailsPage},
            ],
          },
          requiredPermissions: seriesPermissions.SERIES_VIEW.enabled,
        },
        {
          name: 'Series Edit Detail',
          path: paths.seriesEditDetailsPageAlt,
          component: SeriesEditPage,
          breadcrumb: {
            items: [
              {text: 'Series', route: paths.seriesListPage},
              {text: 'Series Details', route: paths.seriesEditDetailsPage},
            ],
          },
          requiredPermissions: seriesPermissions.SERIES_VIEW.enabled,
        },
        {
          name: 'Series Edit Settings',
          path: paths.seriesEditSettingsPage,
          component: SeriesEditPage,
          breadcrumb: {
            items: [
              {text: 'Series', route: paths.seriesListPage},
              {text: 'Series Settings', route: paths.seriesEditSettingsPage},
            ],
          },
          requiredPermissions: seriesPermissions.SERIES_VIEW.enabled,
        },
        {
          name: 'Series Edit Episodes',
          path: paths.seriesEditEpisodesPage,
          component: SeriesEditPage,
          breadcrumb: {
            items: [
              {text: 'Series', route: paths.seriesListPage},
              {text: 'Series Episodes', route: paths.seriesEditEpisodesPage},
            ],
          },
          requiredPermissions: seriesPermissions.SERIES_VIEW.enabled,
        },
        {
          name: 'Series Edit Episode Details',
          path: paths.seriesEditEpisodesDetailsPage,
          component: EpisodeEditPage,
          breadcrumb: {
            items: [
              {text: 'Series', route: paths.seriesListPage},
              {
                text: 'Series Episodes',
                route: paths.seriesEditEpisodesPage.replace(':id', seriesId),
              },
              {text: 'Episode Details', route: paths.seriesEditEpisodesDetailsPage},
            ],
          },
          requiredPermissions: [...seriesPermissions.SERIES_VIEW.enabled, ...episodePermissions.EPISODE_VIEW.enabled],
        },
        {
          name: 'Series Edit Episode Settings',
          path: paths.seriesEditEpisodesSettingsPage,
          component: EpisodeEditPage,
          breadcrumb: {
            items: [
              {text: 'Series', route: paths.seriesListPage},
              {
                text: 'Series Episodes',
                route: paths.seriesEditEpisodesPage.replace(':id', seriesId),
              },
              {text: 'Episode Settings', route: paths.seriesEditEpisodesSettingsPage},
            ],
          },
          requiredPermissions: [...seriesPermissions.SERIES_VIEW.enabled, ...episodePermissions.EPISODE_VIEW.enabled],
        },
        {
          name: 'Series Edit Episode Scheduling',
          path: paths.seriesEditEpisodesSchedulingPage,
          component: EpisodeEditPage,
          breadcrumb: {
            items: [
              {text: 'Series', route: paths.seriesListPage},
              {
                text: 'Series Episodes',
                route: paths.seriesEditEpisodesPage.replace(':id', seriesId),
              },
              {text: 'Episode Scheduling', route: paths.seriesEditEpisodesSchedulingPage},
            ],
          },
          requiredPermissions: [...seriesPermissions.SERIES_VIEW.enabled, ...episodePermissions.EPISODE_VIEW.enabled],
        },
        {
          name: 'Series Edit Episode Video',
          path: paths.seriesEditEpisodesVideoPage,
          component: EpisodeEditPage,
          breadcrumb: {
            items: [
              {text: 'Series', route: paths.seriesListPage},
              {
                text: 'Series Episodes',
                route: paths.seriesEditEpisodesPage.replace(':id', seriesId),
              },
              {text: 'Episode Video', route: paths.seriesEditEpisodesVideoPage},
            ],
          },
          requiredPermissions: [...seriesPermissions.SERIES_VIEW.enabled, ...episodePermissions.EPISODE_VIEW.enabled],
        },
        {
          name: 'Series Edit Episode Assets',
          path: paths.seriesEditEpisodesAssetsPage,
          component: EpisodeEditPage,
          breadcrumb: {
            items: [
              {text: 'Series', route: paths.seriesListPage},
              {
                text: 'Series Episodes',
                route: paths.seriesEditEpisodesPage.replace(':id', seriesId),
              },
              {text: 'Episode Assets', route: paths.seriesEditEpisodesAssetsPage},
            ],
          },
          requiredPermissions: [...seriesPermissions.SERIES_VIEW.enabled, ...episodePermissions.EPISODE_VIEW.enabled],
        },
        {
          name: 'Series Edit Scheduling',
          path: paths.seriesEditSchedulingPage,
          component: SeriesEditPage,
          breadcrumb: {
            items: [
              {text: 'Series', route: paths.seriesListPage},
              {text: 'Series Scheduling', route: paths.seriesEditSchedulingPage},
            ],
          },
          requiredPermissions: seriesPermissions.SERIES_VIEW.enabled,
        },
        {
          name: 'Series Edit Assets',
          path: paths.seriesEditAssetsPage,
          component: SeriesEditPage,
          breadcrumb: {
            items: [
              {text: 'Series', route: paths.seriesListPage},
              {text: 'Series Assets', route: paths.seriesEditAssetsPage},
            ],
          },
          requiredPermissions: seriesPermissions.SERIES_VIEW.enabled,
        },
      ],
    },
  ];
};

export default {
  paths,
  getRoutes,
};
