import * as React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {forEach, forOwn} from 'lodash-es';

import adminRoutes from 'routes/admin.routes';
import NotAuthorized from 'components/notAuthorized';
import CrudError from 'components/crudError';

import {useLazyFindByIdQuery, useInsertMutation, useUpdateMutation} from 'features/appnames/appnamesApi';
import {useFindQuery as useFindDevices} from 'features/devices/devicesApi';
import {appNameValidator} from '../validators';

import {
  Box,
  Button,
  Cluster,
  ContentBoxes,
  ContentBox,
  Cover,
  FormItem,
  Grid,
  Heading,
  Select,
  Spinner,
  Stack,
  Template,
  TextInput,
  Toast,
  useValidateForm,
  trimModel,
} from '@pluto-tv/assemble';

import {IAppName} from 'models/appNames';
import {useAppPermissions} from 'app/permissions';

export default (): JSX.Element => {
  const history = useHistory();
  const {id}: {id: string} = useParams();
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const {ableTo, permissions} = useAppPermissions();

  if (!ableTo('APPNAME_VIEW')) {
    return <NotAuthorized />;
  }
  const cantModify = (id === 'new' && !ableTo('APPNAME_CREATE')) || (id !== 'new' && !ableTo('APPNAME_EDIT'));

  const [searchAppName, appNameFound, lastAppNameSearchInfo] = useLazyFindByIdQuery();
  const {data: deviceList, isFetching: devicesLoading} = useFindDevices({
    offset: 0,
    limit: 250,
    sort: 'name:asc',
  });
  const [updateAppName] = useUpdateMutation();
  const [insertAppName] = useInsertMutation();

  const platforms = Array.from(new Set(deviceList?.data.map(d => d.platform)));

  const {
    pristineModel: appNamePristineModel,
    form: appNameForm,
    model: appNameModel,
    onBlur,
    onChange,
    state: formState,
    setModel,
    setFields,
  } = useValidateForm<IAppName>([...appNameValidator]);

  const cancelHandler = () => {
    history.push(adminRoutes.paths.appNameListPage);
  };

  const saveHandler = async () => {
    if (isSaving || cantModify) {
      return;
    }

    setIsSaving(true);

    try {
      if (id === 'new') {
        await onAppNameCreate(appNameModel as IAppName);
      } else {
        await onAppNameUpdate(id, appNameModel as IAppName);
      }
    } catch (e) {
      const duplicateError = (e as any).data?.error;
      if (duplicateError === 'Conflict') {
        Toast.error('Validation Error', `App name with name '${appNameModel.name}' already exists.`);
      }

      const validationErrors = (e as any).data?.validationErrors;
      if (validationErrors) {
        forOwn(validationErrors, (val: string[]) => {
          forEach(val, errorMsg => Toast.error('Validation Error', errorMsg));
        });
      }
    } finally {
      setIsSaving(false);
    }
  };

  const onAppNameCreate = async (entity: IAppName) => {
    const result = await insertAppName(trimModel(entity, 'name')).unwrap();
    Toast.success('Success', 'App name Created');
    history.push(adminRoutes.paths.appNameEditPage.replace(':id', result._id));
  };

  const onAppNameUpdate = async (id: string, entity: IAppName) => {
    const result = await updateAppName({id, appName: trimModel(entity, 'name')}).unwrap();
    Toast.success('Success', 'App name Updated');
    history.push(adminRoutes.paths.appNameEditPage.replace(':id', result._id));
  };

  React.useEffect(() => {
    if (appNameFound.data && appNameFound.isSuccess && appNameFound.data._id === lastAppNameSearchInfo.lastArg) {
      setModel(appNameFound.data);
    }
  }, [appNameFound, lastAppNameSearchInfo, setModel]);

  React.useEffect(() => {
    if (id === 'new') {
      setModel({_id: '', devices: []});
    } else {
      searchAppName(id);
    }
  }, [id, searchAppName, setModel]);

  if (appNameFound.isError) {
    return <CrudError error={appNameFound.error} />;
  }

  if (!appNameModel || devicesLoading) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} size='xlarge' />
      </Box>
    );
  }

  return (
    <>
      <Cover
        scrolling={true}
        gutterTop='medium'
        gutterBottom='large'
        coverTemplateHeight='100%'
        paddingX={{mobile: 'medium', wide: 'large'}}
        paddingTop={{mobile: 'medium', wide: 'large'}}
      >
        <Template label='header'>
          <Cluster justify='space-between' align='center' space='medium' wrap={false}>
            <Heading level='h1' truncate={true} truncateBackgroundHover='shadow'>
              {appNamePristineModel.name || 'New App Name'}
            </Heading>
          </Cluster>
        </Template>
        <Template label='cover'>
          <Grid maxCols={2}>
            <ContentBoxes layout='stack'>
              <ContentBox title='Details'>
                <Stack space='medium'>
                  <FormItem
                    {...appNameForm.name}
                    onBlur={() => onBlur('name')}
                    permission={id === 'new' ? permissions.APPNAME_CREATE : 'disabled'}
                  >
                    <TextInput onChange={value => onChange('name', value)} value={appNameModel.name} />
                  </FormItem>
                  <FormItem
                    {...appNameForm.devices}
                    onBlur={() => onBlur('devices')}
                    permission={id === 'new' ? permissions.APPNAME_CREATE : permissions.APPNAME_EDIT}
                  >
                    <Select
                      id='devices'
                      multiselect={true}
                      searchable={true}
                      searchPlaceholder='Search Associated Devices'
                      value={appNameModel?.devices?.map(p => ({label: p, value: p}))}
                      options={(platforms || []).map(p => ({label: p, value: p}))}
                      onChange={value => {
                        setFields({
                          devices: (value || []).map(v => v.value),
                        });
                      }}
                      predicate='value'
                    />
                  </FormItem>
                </Stack>
              </ContentBox>
            </ContentBoxes>
            <div></div>
          </Grid>
        </Template>
        <Template label='footer'>
          <Box background='onyx' paddingX='large' paddingY='small' marginX={{mobile: 'none', wide: 'largeNegative'}}>
            <Cluster justify='space-between'>
              <div></div>
              <Cluster space='small'>
                <Button ghost={true} onClick={() => cancelHandler()} id='discard'>
                  Discard
                </Button>
                <Button
                  type='primary'
                  state={!formState.isValid || !formState.isDirty ? 'disabled' : isSaving ? 'thinking' : ''}
                  id='save'
                  onClick={() => saveHandler()}
                  permission={id === 'new' ? permissions.APPNAME_CREATE : permissions.APPNAME_EDIT}
                >
                  Save Changes
                </Button>
              </Cluster>
            </Cluster>
          </Box>
        </Template>
      </Cover>
    </>
  );
};
