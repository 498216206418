import * as React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {skipToken} from '@reduxjs/toolkit/query/react';
import {forOwn, forEach} from 'lodash-es';

import adminRoutes from 'routes/admin.routes';
import NotAuthorized from 'components/notAuthorized';

import {
  useDeleteMutation,
  useFindByIdQuery,
  useInsertMutation,
  useUpdateMutation,
} from 'features/activeRegions/activeRegionsApi';
import {useFindQuery as useFindTerritoriesQuery} from 'features/territories/territoriesApi';

import {IActiveRegion} from 'models/activeRegions';

import {activeRegionValidator} from '../validators';

import {
  Box,
  Button,
  Cluster,
  ContentBoxes,
  ContentBox,
  Cover,
  FormItem,
  Grid,
  Heading,
  Select,
  Spinner,
  Stack,
  Template,
  TextInput,
  useValidateForm,
  Toast,
  trimModel,
  Popover,
} from '@pluto-tv/assemble';

import {useAppPermissions} from 'app/permissions';
import DeleteConfirmation from 'components/deleteConfirmation';
import CrudError from 'components/crudError';

export default (): JSX.Element => {
  const {id}: {id: string} = useParams();
  const history = useHistory();
  const {ableTo, permissions} = useAppPermissions();

  if (!ableTo('ACTIVE_REGION_VIEW')) {
    return <NotAuthorized />;
  }

  const cantModify =
    (id === 'new' && !ableTo('ACTIVE_REGION_CREATE')) || (id !== 'new' && !ableTo('ACTIVE_REGION_EDIT'));

  const [isSaving, setIsSaving] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [error, setError] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const [updateactiveRegion] = useUpdateMutation();
  const [insertactiveRegion] = useInsertMutation();
  const [removeRegion] = useDeleteMutation();

  const canDelete = ableTo('ACTIVE_REGION_DELETE') && id !== 'new';

  const {
    data: activeRegion,
    isError: activeRegionIsError,
    error: activeRegionError,
  } = useFindByIdQuery(id !== 'new' ? id : skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const {data: territories, isError: territoriesIsError, error: territoriesError} = useFindTerritoriesQuery();

  const {
    model: activeRegionModel,
    form: userForm,
    onChange,
    setFields,
    setModel,
    state: formState,
  } = useValidateForm<IActiveRegion>(activeRegionValidator);

  const cancelHandler = () => {
    history.push(adminRoutes.paths.activeRegionsListPage);
  };

  const toggleDeleteOpen = () => {
    setDeleteOpen(!deleteOpen);
  };

  const deleteHandler = async () => {
    if (!activeRegion || !activeRegion._id || !canDelete) {
      toggleDeleteOpen();
      return;
    }

    try {
      await removeRegion(activeRegion._id);
      toggleDeleteOpen();
      cancelHandler();
    } catch (e) {
      Toast.error('Error', `Failed to remove ${activeRegion.name} region`);
    }
  };

  React.useEffect(() => {
    if (activeRegion && territories) {
      setModel(activeRegion);
      setIsLoading(false);
    }

    if (id === 'new' && territories) {
      setIsLoading(false);
    }
  }, [activeRegion, territories, id, setModel]);

  React.useEffect(() => {
    if (activeRegionIsError || territoriesIsError) {
      setIsError(true);
      setError(activeRegionIsError ? activeRegionError : territoriesError);
    }
  }, [activeRegionIsError, territoriesIsError, activeRegionError, territoriesError]);

  const saveHandler = async () => {
    if (isSaving || cantModify) {
      return;
    }

    setIsSaving(true);

    try {
      if (id === 'new') {
        await onactiveRegionCreate({
          ...activeRegionModel,
        } as IActiveRegion);
      } else {
        await onactiveRegionUpdate(id, activeRegionModel as IActiveRegion);
      }
    } catch (e) {
      const duplicateError = (e as any).data?.error;
      if (duplicateError === 'Conflict') {
        const errorMessage = (e as any).data?.message;
        const message = errorMessage.startsWith('Active region')
          ? errorMessage
          : `Active region with slug '${activeRegionModel.code}' already exists.`;
        Toast.error('Validation Error', message);
      }

      const validationErrors = (e as any).data?.validationErrors;
      if (validationErrors) {
        forOwn(validationErrors, (val: string[]) => {
          forEach(val, errorMsg => Toast.error('Validation Error', errorMsg));
        });
      }
    } finally {
      setIsSaving(false);
    }
  };

  const onactiveRegionCreate = async (entity: IActiveRegion) => {
    const trimmed = trimModel(entity, 'name', 'code');
    const result = await insertactiveRegion(trimmed).unwrap();
    Toast.success('Success', 'Active Region Created');
    history.push(adminRoutes.paths.activeRegionsEditPage.replace(':id', result._id));
  };

  const onactiveRegionUpdate = async (id: string, entity: IActiveRegion) => {
    const trimmed = trimModel(entity, 'name', 'description');
    const result = await updateactiveRegion({id, activeRegion: trimmed}).unwrap();
    Toast.success('Success', 'Active Region Updated');
    history.push(adminRoutes.paths.activeRegionsEditPage.replace(':id', result._id));
  };

  if (isError) {
    return <CrudError error={error} />;
  }

  if (isLoading) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} size='xlarge' />
      </Box>
    );
  }

  return (
    <>
      <Cover
        scrolling={true}
        gutterTop='medium'
        gutterBottom='large'
        coverTemplateHeight='100%'
        paddingX={{mobile: 'medium', wide: 'large'}}
        paddingTop={{mobile: 'medium', wide: 'large'}}
      >
        <Template label='header'>
          <Grid minimum='100%'>
            <Cluster justify='space-between' align='center' space='medium' wrap={false}>
              <Heading level='h1' truncate={true} truncateBackgroundHover='shadow'>
                {id === 'new' ? 'New Active Region' : activeRegion?.name}
              </Heading>
              {canDelete && (
                <Popover manualTrigger={true} visible={deleteOpen} onClickOutside={() => setDeleteOpen(false)}>
                  <Template label='trigger'>
                    <Button type='delete' onClick={() => toggleDeleteOpen()}>
                      Delete
                    </Button>
                  </Template>
                  <Template label='popover'>
                    <DeleteConfirmation
                      message={'Are you sure you want to delete ' + activeRegion?.name + '?'}
                      cancelButtonFunction={() => toggleDeleteOpen()}
                      proceedButtonFunction={() => deleteHandler()}
                    />
                  </Template>
                </Popover>
              )}
            </Cluster>
          </Grid>
        </Template>
        <Template label='cover'>
          <Grid maxCols={2}>
            <ContentBoxes layout='stack'>
              <ContentBox title='Details'>
                <Stack space='medium'>
                  <FormItem
                    label='Region Name'
                    required={true}
                    state={id === 'new' ? userForm.name?.state : 'disabled'}
                    helpText={id === 'new' ? userForm.name?.helpText : undefined}
                  >
                    <TextInput onChange={val => onChange('name', val)} value={activeRegionModel.name} />
                  </FormItem>
                  <FormItem
                    label='Region Slug'
                    required={true}
                    state={id === 'new' ? userForm.code?.state : 'disabled'}
                    helpText={id === 'new' ? userForm.code?.helpText : undefined}
                  >
                    <Select
                      predicate='value'
                      searchable={true}
                      searchPlaceholder='Search Slugs'
                      options={territories?.map(t => ({label: `${t.id} - ${t.name}`, value: t.id})) || []}
                      value={{value: activeRegionModel.code, label: ''}}
                      onChange={value => {
                        const currentCode = value.value;
                        const [defaultTerritory] = territories?.filter(t => t.id === currentCode) || [];
                        const regionData: {code: string; territories?: string[]} = {
                          code: currentCode,
                        };
                        if (id === 'new' && defaultTerritory) {
                          regionData.territories = [defaultTerritory.id];
                        }
                        setFields(regionData);
                      }}
                    />
                  </FormItem>
                  <FormItem
                    label='Associated Territories'
                    permission={id === 'new' ? permissions.ACTIVE_REGION_CREATE : permissions.ACTIVE_REGION_EDIT}
                  >
                    <Select
                      multiselect={true}
                      clearable={true}
                      predicate='value'
                      searchable={true}
                      searchPlaceholder='Search Territories'
                      options={(territories || []).map(t => ({label: t.name, value: t.id}))}
                      value={activeRegionModel.territories?.map(d => ({label: d, value: d}))}
                      onChange={value =>
                        setFields({
                          territories: (value || []).map(v => v.value),
                        })
                      }
                    />
                  </FormItem>
                </Stack>
              </ContentBox>
            </ContentBoxes>
            <div></div>
          </Grid>
        </Template>
        <Template label='footer'>
          <Box background='onyx' paddingX='large' paddingY='small' marginX={{mobile: 'none', wide: 'largeNegative'}}>
            <Cluster justify='space-between'>
              <div></div>
              <Cluster space='small'>
                <Button ghost={true} onClick={() => cancelHandler()} id='discard'>
                  Discard
                </Button>
                <Button
                  type='primary'
                  id='save'
                  onClick={() => saveHandler()}
                  state={!formState.isValid || !formState.isDirty ? 'disabled' : isSaving ? 'thinking' : ''}
                  permission={id === 'new' ? permissions.ACTIVE_REGION_CREATE : permissions.ACTIVE_REGION_EDIT}
                >
                  Save Changes
                </Button>
              </Cluster>
            </Cluster>
          </Box>
        </Template>
      </Cover>
    </>
  );
};
