export const channelPermissions = {
  CHANNEL_VIEW: {
    enabled: ['channel_view', 'channel_edit', 'channel_create'],
  },
  CHANNEL_CREATE: {
    enabled: ['channel_create'],
  },
  CHANNEL_EDIT: {
    enabled: ['channel_edit'],
    disabled: ['channel_view', 'channel_create'],
  },
  CHANNEL_CATALOG_VIEW: {
    enabled: ['channel_catalog_view'],
  },
  CHANNEL_CATALOG_EDIT: {
    enabled: ['channel_catalog_edit'],
  },
  CHANNEL_EPG_VIEW: {
    enabled: ['epg_view'],
  },
  CHANNEL_EPG_EDIT: {
    enabled: ['epg_edit'],
  },
};
