import * as React from 'react';
import Layout from 'app/layout/Main';
import {Provider} from 'react-redux';
import {store} from './store';

import './App.css';
import '@pluto-tv/assemble/dist/style.css';

export default (): JSX.Element => {
  return (
    <Provider store={store}>
      <Layout />
    </Provider>
  );
};
