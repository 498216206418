import * as React from 'react';
import {IChannelCatalogItemWithState} from './memoryQueueApiProvider';

interface IMemoryQueueContext {
  setMemoryQueue: (queue: IChannelCatalogItemWithState[]) => void;
  getMemoryQueue(): IChannelCatalogItemWithState[];
  queueModel?: {episode: string; order: number}[];
  setQueueModelChange: () => void;
}

export const MemoryQueueContext = React.createContext<IMemoryQueueContext | null>(null);

interface IMemoryQueueProviderProps {
  children: React.ReactNode;
}

export const MemoryQueueProvider = ({children}: IMemoryQueueProviderProps): React.ReactElement => {
  const memoryQueueRef = React.useRef<IChannelCatalogItemWithState[]>([]);
  const [queueModel, setQueueModel] = React.useState<{episode: string; order: number}[]>();

  const getMemoryQueue = React.useCallback(() => memoryQueueRef.current, []);

  const setMemoryQueue = React.useCallback((newQueue: IChannelCatalogItemWithState[]) => {
    memoryQueueRef.current = newQueue;
  }, []);

  const setQueueModelChange = React.useCallback(() => {
    // remove duplicated items
    const temp = memoryQueueRef.current.filter((item, index, self) => index === self.findIndex(t => t.id === item.id));

    setQueueModel(
      temp.map((item, index) => ({
        episode: item.id,
        order: index + 1,
      })),
    );
  }, []);

  const value = React.useMemo(
    () => ({
      getMemoryQueue,
      setMemoryQueue,
      queueModel,
      setQueueModelChange,
    }),
    [getMemoryQueue, queueModel, setMemoryQueue, setQueueModelChange],
  );

  return <MemoryQueueContext.Provider value={value}>{children}</MemoryQueueContext.Provider>;
};

export const useMemoryQueueProvider = (): IMemoryQueueContext => {
  const context = React.useContext(MemoryQueueContext);

  if (!context) {
    throw new Error('useMemoryQueueProvider must be used within a MemoryQueueProvider');
  }

  return context;
};
