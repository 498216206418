import * as React from 'react';

import {Box, ContentBoxes, ContentBox, Spinner} from '@pluto-tv/assemble';

import AssetForm from 'components/assetForm';

import {INestedVodCollectionProps} from '../nestedPropsInterface';
import {useUserRegions} from 'helpers/useUserRegions';

import CrudError from 'components/crudError';
import {useAppPermissions} from 'app/permissions';

interface IVodCollectionProps extends INestedVodCollectionProps {
  dirtyFields: any;
}

export default ({model, setFields}: IVodCollectionProps): JSX.Element => {
  const {isFetching: isUserRegionsFetching, isError: isUserRegionsError} = useUserRegions();
  const {permissions} = useAppPermissions();

  if (isUserRegionsError) {
    return <CrudError error='Error loading page data' />;
  }

  if (isUserRegionsFetching) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} size='xlarge' />
      </Box>
    );
  }

  return (
    <ContentBoxes layout='grid' gridMaxCols={2}>
      <ContentBox>
        <AssetForm
          assetType='imageFeatured'
          asset={model.imageFeatured}
          contentType='vodcategories'
          contentId={model.id || ''}
          name='Collection Featured'
          width='20rem'
          height='15rem'
          maxFileSizeKB={2048}
          minResolutionWidth={1920}
          minResolutionHeight={1080}
          aspectRatioWidth={16}
          aspectRatioHeight={9}
          fileTypes={['image/jpeg']}
          permission={permissions.VOD_EDIT}
          defaultUrl='https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg'
          onChange={(name, url) => {
            setFields({imageFeatured: {path: url}});
          }}
          showAltText={false}
        />
      </ContentBox>
    </ContentBoxes>
  );
};
