import {IChannelCatalogItem} from 'models/channelCatalog';

export const useTotalLibraryCount = ({
  totalLength,
  movedToLibrary,
  movedToQueue,
}: {
  totalLength: number;
  movedToLibrary: IChannelCatalogItem[];
  movedToQueue: IChannelCatalogItem[];
}): number => {
  return totalLength + movedToLibrary.length - movedToQueue.length;
};
