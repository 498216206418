import * as React from 'react';
import {IFeaturedGroup} from 'models/featuredGroup';
import {IValidator, IValidatorMessage} from 'components/featuredGroupList/utils/validators';
import {IRegionDateGroups} from 'components/featuredGroupList/hooks/useAllGroupsData';

interface IReturnType {
  validate: (row: IFeaturedGroup) => {state: string; messageList: IValidatorMessage[]};
}

export interface IValidationResult {
  state: string;
  messageList: IValidatorMessage[];
}

const useValidator = (
  validators: IValidator[],
  validatorData: IRegionDateGroups,
  onCompleteValidations?: (validationResult: IValidationResult) => void,
): IReturnType => {
  const validate = React.useCallback(
    (row: IFeaturedGroup) => {
      const validationResult: {state: string; messageList: IValidatorMessage[]} = {
        state: '',
        messageList: [],
      };

      if (!Object.keys(validatorData).length) return validationResult;

      for (const rule of validators) {
        const result = rule({row, mappedGroups: validatorData});

        if (result) {
          validationResult.state = result.state;
          if (result.validatorMessage) {
            validationResult.messageList.push(result.validatorMessage);
          }
          if (result.stop) break;
        }
      }

      onCompleteValidations?.(validationResult);

      return validationResult;
    },
    [onCompleteValidations, validatorData, validators],
  );

  return {
    validate,
  };
};

export default useValidator;
