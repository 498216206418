import * as React from 'react';
import {IRegionDateGroups} from 'components/featuredGroupList/hooks/useAllGroupsData';
import {
  validatePublished,
  validateHasUnpublishedChannels,
  validateConflictingGroups,
  validateGroupHasNoChannel,
  IValidatorMessage,
  IValidator,
} from 'components/featuredGroupList/utils/validators';
import {IFeaturedGroup} from 'models/featuredGroup';
import useValidations from 'components/featuredGroupList/hooks/useValidations';
import {SearchState} from '../FeaturedGroupsList';

interface IReturnType {
  validate: (row: IFeaturedGroup) => {state: string; messageList: IValidatorMessage[]};
  reset: () => void;
}

const useValidateRows = (
  searchQuery: SearchState,
  validatorData: IRegionDateGroups,
  publishedUpcomingData: IFeaturedGroup[],
): IReturnType => {
  const upcomingDataRef = React.useRef<{[key: string]: IFeaturedGroup | null}>();

  const upcomingGroups = React.useMemo(() => {
    if (!publishedUpcomingData.length) return null;

    return publishedUpcomingData.reduce((acc, group) => {
      if (!acc[group.activeRegion.toUpperCase()] && !group.published && group.start >= new Date().toISOString()) {
        acc[group.activeRegion.toUpperCase()] = group.id;
      }
      return acc;
    }, {} as {[key: string]: string});
  }, [publishedUpcomingData]);

  const reset = React.useCallback(() => {
    upcomingDataRef.current = {};
  }, []);

  const validateUpcoming: IValidator = React.useCallback(
    ({row}) => {
      if (!Object.keys(upcomingGroups || {}).length) {
        return null;
      }

      if (upcomingGroups?.[row.activeRegion.toUpperCase()]?.includes(row.id)) {
        return {
          state: 'info',
          validatorMessage: {message: 'This group is planned to start next.', icon: 'info'},
        };
      }

      return null;
    },
    [upcomingGroups],
  );

  const {validate} = useValidations(
    [
      validatePublished,
      validateUpcoming,
      validateHasUnpublishedChannels,
      ({row}) =>
        validateGroupHasNoChannel({
          row,
          message: `${row.name} has no associated channels. Please add before publishing.`,
        }),
      validateConflictingGroups,
    ],
    validatorData,
  );

  return {
    validate,
    reset,
  };
};

export default useValidateRows;
