import * as React from 'react';
import {useHistory} from 'react-router-dom';
import {
  Box,
  Button,
  Click,
  Cluster,
  Cover,
  DateTime,
  Dialog,
  Divider,
  Expand,
  FormItem,
  Grid,
  Heading,
  Icon,
  ImageWrapper,
  Notification,
  Pagination,
  Paragraph,
  Select,
  Sidebar,
  Spinner,
  Stack,
  Table,
  TagList,
  TdLink,
  Template,
  Textarea,
  TextInput,
  Toast,
  useValidateForm,
  ITableCol,
  trimModel,
  IDateRange,
  secondsToHms,
  useCancellableSummon,
  Toggle,
  hmsToSeconds,
} from '@pluto-tv/assemble';
import {Player} from '@pluto-tv/assemble-player';
import {capitalize, cloneDeep, orderBy, some, uniq} from 'lodash-es';

import {useAppPermissions} from 'app/permissions';

import clipRoutes from 'routes/content.routes';

import NotAuthorized from 'components/notAuthorized';
import {TableActions} from 'components/tableActions';
import CrudError from 'components/crudError';

import {useInsertMutation} from 'features/clips/clipsApi';
import {useFindQuery as useFindCategoriesQuery} from 'features/categories/categoriesApi';
import {useFindQuery as useFindPartnersQuery} from 'features/partners/partnersApi';
import {useFindQuery as useFindLanguagesQuery} from 'features/languages/languagesApi';

import {programmingTypes} from 'helpers/programmingTypes';
import {useUserRegions} from 'helpers/useUserRegions';
import {useGetClipVideo} from 'helpers/useGetClipVideo';
import {useUserRatings} from 'helpers/useUserRatings';

import {IClip} from 'models/clips';

import {clipCreateValidator, clipDurationValidator, clipSearchAllValidators} from '../../views/content/clip/validators';
import {
  IClipDuration,
  IClipMetaSearch,
  IClipSearch,
  checkOriginUrlFileValidation,
  useClipSearch,
} from '../../views/content/clip/utils';
import {IUserClipSearch} from 'models/users';
import {ClipFavoriteSearch} from 'components/favoriteSearch/favoriteSearch';
import useToggleSearchBarOnSlash from 'helpers/useToggleSearchBarOnSlash';
import {withThousandsSeparator} from 'utils/thousands-separator';
import useClipDelete, {ClipNotFoundError, ClipReferencesError} from 'views/content/clip/hooks/useClipDelete';

const TABLE_COLUMN_NAME = {
  'Upload Date': 'createdAt',
  'Clip Name': 'name',
  Duration: 'duration',
  'Active Region': 'activeRegion',
  'Clip Status': 'contentStatus',
} as const;

const DEFAULT_SEARCH = {
  provider: 'jwplatform',
};

const transformSavedSearch = (saved: IUserClipSearch): IUserClipSearch => {
  const cloned = cloneDeep(saved);

  if (cloned.meta?.uploadDate) {
    cloned.meta.uploadDate.start = new Date(cloned.meta.uploadDate.start);
    cloned.meta.uploadDate.end = new Date(cloned.meta.uploadDate.end!);
  }

  return cloned;
};

export interface IClipListProps {
  actionsCol?: boolean;
  addNewClips?: boolean;
  checkboxCol?: true | 'multiple';
  clipPreview?: boolean;
  inModal?: boolean;
  presetSearch?: Partial<IClipSearch>;
  onSelect?: (row?: IClip | IClip[]) => void;
  nameTarget?: React.HTMLAttributeAnchorTarget;
  existingClipsId?: string[];
  trackClipsSortList?: boolean;
  isSearchExpanded?: boolean;
  totalDuration?: string;
  totalAllotment?: string;
}

const ClipList = React.memo(
  ({
    actionsCol = true,
    addNewClips = true,
    clipPreview = true,
    checkboxCol,
    inModal = false,
    onSelect,
    presetSearch = {},
    isSearchExpanded = false,
    nameTarget,
    existingClipsId = [],
    trackClipsSortList = false,
    totalDuration,
    totalAllotment,
  }: IClipListProps) => {
    const history = useHistory();
    const {ableTo, permissions} = useAppPermissions();

    const [insertClip] = useInsertMutation();

    const [searchExpanded, setSearchExpanded] = React.useState(isSearchExpanded);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isInitialLoading, setIsInitialLoading] = React.useState(true);
    const [searchMeta, setSearchMeta] = React.useState<IClipMetaSearch>({});

    const [activeClip, setActiveClip] = React.useState<IClip>();
    const [clipPreviewOpen, setClipPreviewOpen] = React.useState(false);
    const [activeSearch, setActiveSearch] = React.useState<IUserClipSearch>();

    const [calculatedDuration, setCalculatedDuration] = React.useState<string | null>(null);

    const [summon] = useCancellableSummon();
    useToggleSearchBarOnSlash(setSearchExpanded, searchExpanded);

    const {
      form,
      model,
      onBlur,
      onChange,
      setFields,
      reset: resetCreate,
      state: formState,
    } = useValidateForm<IClip>(clipCreateValidator, 'onBlur');

    const {
      model: searchModel,
      onChange: searchOnChange,
      setFields: searchSetFields,
      setModel: setSearchModel,
      onBlur: searchOnBlur,
      reset: searchReset,
    } = useValidateForm<IClipSearch>(clipSearchAllValidators, 'immediate');

    const {
      form: durationForm,
      model: durationModel,
      onChange: durationOnChange,
      state: durationFormState,
      setModel: durationSetModel,
      onBlur: durationOnBlur,
    } = useValidateForm<IClipDuration>(clipDurationValidator, 'onBlur');

    const {
      isLoading: isStreamLoading,
      playerType,
      setActiveClip: setClipData,
      cc,
      streamUrl,
      signedQuery,
      dropFrame,
    } = useGetClipVideo();

    const {data: partners, isFetching: isFetchingPartners, isError: isErrorPartners} = useFindPartnersQuery();
    const {data: categories, isFetching: isFetchingCategories, isError: isErrorCategories} = useFindCategoriesQuery();
    const {data: languages, isFetching: isFetchingLanguages, isError: isErrorLanguages} = useFindLanguagesQuery();

    const {contentRatings} = useUserRatings();
    const clipNameInputId = 'clipName';

    const {
      activeRegions,
      territories,
      isFetching: isUserRegionsFetching,
      territoriesByRegion,
      isError: isUserRegionsError,
    } = useUserRegions();

    const [sortCol, setSortCol] = React.useState<keyof typeof TABLE_COLUMN_NAME>('Upload Date');
    const [sortDir, setSortDir] = React.useState<'asc' | 'dsc'>('dsc');

    const {clips, isError, isFetching, page, rowsPerPage, search, setPage, setRowsPerPage, sort, setSort, clearSearch} =
      useClipSearch();

    const onRowSelected = (rows?: IClip[]) => {
      const newTotalDuration = rows
        ? secondsToHms(
            rows.reduce(
              (accumulator, source) => accumulator + source.duration + source.breakpoints.length * 60,
              hmsToSeconds(totalDuration || '00:00:00'),
            ),
          )
        : '00:00:00';

      setCalculatedDuration(newTotalDuration);

      if (onSelect) {
        onSelect(rows);
      }
    };

    React.useEffect(() => {
      if (totalDuration) {
        setCalculatedDuration(totalDuration);
      }
    }, [totalDuration]);

    React.useEffect(() => {
      if (!isInitialLoading) {
        initialSearch();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInitialLoading]);

    React.useEffect(() => {
      if (!isInitialLoading) {
        search(searchModel, searchMeta);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage]);

    React.useEffect(() => {
      if (activeClip) {
        setClipData(activeClip);
        setClipPreviewOpen(true);
      } else {
        setClipPreviewOpen(false);
        setClipData(undefined);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeClip]);

    React.useEffect(() => {
      if (activeSearch) {
        const transformedActiveSearch = transformSavedSearch(activeSearch);

        setSearchModel(transformedActiveSearch.model || {});
        setSearchMeta(transformedActiveSearch.meta || {});

        if (transformedActiveSearch.meta?.duration) {
          durationSetModel(transformedActiveSearch.meta.duration);
        } else {
          durationSetModel({});
        }

        setSortCol(transformedActiveSearch.sortCol as any);
        setSortDir(transformedActiveSearch.sortDir);
        setPage(0);

        const sortStr = `${TABLE_COLUMN_NAME[transformedActiveSearch.sortCol]}:${
          transformedActiveSearch.sortDir === 'asc' ? 'asc' : 'desc'
        }`;

        setSort(sortStr);
        search(transformedActiveSearch.model, transformedActiveSearch.meta, sortStr);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSearch]);

    React.useEffect(() => {
      setIsLoading(
        isFetching || isFetchingPartners || isUserRegionsFetching || isFetchingCategories || isFetchingLanguages,
      );

      setIsInitialLoading(isFetchingPartners || isUserRegionsFetching || isFetchingCategories || isFetchingLanguages);
    }, [isFetching, isFetchingPartners, isUserRegionsFetching, isFetchingCategories, isFetchingLanguages]);

    React.useEffect(() => {
      if (!isFetching && searchExpanded) {
        const clipNameInput = document.getElementById(clipNameInputId);

        clipNameInput?.focus({
          preventScroll: true,
        });
      }
    }, [searchExpanded, isFetching]);

    const {handleDelete: deleteClip} = useClipDelete();

    const [createOpen, setCreateOpen] = React.useState(false);
    const openCreate = () => {
      resetCreate();
      setCreateOpen(true);
      setSearchExpanded(false);
    };

    const initialSearch = () => {
      const initialModel: Partial<IClipSearch> = {
        ...DEFAULT_SEARCH,
        ...presetSearch,
      };

      searchReset();
      setSearchModel(initialModel);
      // setIsEmptySearch(true);
      setSearchMeta({});
      durationSetModel({});

      setActiveSearch(undefined);

      setSortDir('dsc');
      setSortCol('Upload Date');

      clearSearch(initialModel, true);
    };

    const setDurationSecond = duration => {
      const durationInput = duration?.split(':');
      const hour = durationInput[0];
      const minute = durationInput[1];
      const second = durationInput[2];

      if (durationInput.length === 3 && (hour >= 1 || minute >= 1 || second >= 5)) {
        const seconds = +hour * 3600 + +minute * 60 + +second;
        return seconds;
      }

      return duration;
    };

    const handleCreate = async (navigateTo = false): Promise<string | undefined> => {
      let clipId: string | undefined;

      setIsCreating(true);
      const postModel: Partial<IClip> = {
        ...model,
        url: model.origin?.url,
        provider: 'jwplatform',
        contentStatus: model.liveBroadcast ? 'ready' : 'uploaded',
        duration: model.liveBroadcast ? 24 * 60 * 60 : model.duration,
        published: false,
      };

      try {
        const newClip = await insertClip(trimModel(postModel, 'name')).unwrap();

        clipId = newClip.id!;

        const toastMsg = navigateTo ? (
          'Clip Created'
        ) : (
          <Stack space='xxsmall'>
            <Paragraph>Clip Created</Paragraph>
            <Click
              underline={true}
              hoverColor='white'
              onClick={() => history.push(clipRoutes.paths.clipEditDetailsPage.replace(':id', clipId as string))}
            >
              View Clip: {postModel.name}
            </Click>
          </Stack>
        );

        Toast.success('Success', toastMsg, 8000);
        search(searchModel, searchMeta);
        setPage(0);
      } catch (e) {
        Toast.error('Error', (e as any).data.message);
      }

      if (!postModel.liveBroadcast) {
        try {
          await summon.post(`/clips/${clipId}/process`);

          Toast.success('Success', 'The Clip transcoding job has been requested.', 8000);
        } catch (e) {
          Toast.error('Error', 'Could not start transcode job. Please try again', 8000);
        }
      }

      setCreateOpen(false);
      setIsCreating(false);

      return clipId;
    };

    const handleCreateAndEdit = async () => {
      try {
        const clipId = await handleCreate(true);

        if (clipId) {
          history.push(clipRoutes.paths.clipEditDetailsPage.replace(':id', clipId as string));
        }
      } catch (e) {}
    };

    const handleCancel = () => {
      setCreateOpen(false);
    };

    const handleEdit = (clip: IClip) => {
      history.push(clipRoutes.paths.clipEditDetailsPage.replace(':id', clip._id));
    };

    const handleDelete = async (clip: IClip) => {
      if (!ableTo('CLIP_DELETE')) return;
      try {
        await deleteClip(clip._id);
        Toast.success('Success', 'Clip Removed');
      } catch (error: any) {
        let message = '';
        if (error instanceof ClipReferencesError) {
          message = 'This clip is referenced by one or more episodes. Please remove all episode references to delete.';
        } else if (error instanceof ClipNotFoundError) {
          message = 'Failed to remove clip. Clip not found.';
        }
        Toast.error('Error', message || `Failed to remove clip. Please try again.`);
      }
      search(searchModel, searchMeta);
    };

    React.useEffect(() => {
      const [col, dir] = sort.split(':');

      if (dir !== 'asc') {
        setSortDir('dsc');
      } else {
        setSortDir('asc');
      }

      some(Object.keys(TABLE_COLUMN_NAME), key => {
        if (TABLE_COLUMN_NAME[key] === col) {
          setSortCol(key as keyof typeof TABLE_COLUMN_NAME);
          return true;
        }
      });

      if (!isInitialLoading) {
        search(searchModel, searchMeta);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort]);

    const changeSort = (columnName: keyof typeof TABLE_COLUMN_NAME) => {
      if (columnName === sortCol) {
        if (sortDir === 'asc') {
          setSort(`${TABLE_COLUMN_NAME[columnName]}:desc`);
        } else {
          setSort(`${TABLE_COLUMN_NAME[columnName]}:asc`);
        }
      } else {
        setSort(`${TABLE_COLUMN_NAME[columnName]}:desc`);
      }
      setPage(0);
    };
    const [isCreating, setIsCreating] = React.useState(false);

    const searchSelectedHandler = (userSearch: IUserClipSearch) => {
      setPage(0);
      setActiveSearch(userSearch);
      setSearchModel(userSearch.model);
    };

    const ratingsForSelectedRegions = React.useMemo(() => {
      const selectedRegions = searchModel.activeRegion || [];

      if (selectedRegions.length === 0) {
        return contentRatings;
      }

      const newContentRatings = contentRatings.filter(
        r => r.group === '' || selectedRegions.includes(r.group?.toLocaleLowerCase() || ''),
      );

      if (searchModel.rating && !newContentRatings.some(c => c.label === searchModel.rating)) {
        searchSetFields({rating: undefined});
      }

      return newContentRatings;
    }, [searchModel.activeRegion, contentRatings, searchModel.rating, searchSetFields]);

    const territoriesForSelectedRegions = React.useMemo(() => {
      const selectedRegions = searchModel.activeRegion || [];

      if (selectedRegions.length === 0) {
        return territories;
      }

      return selectedRegions.flatMap(r => territoriesByRegion[r.toUpperCase()]);
    }, [searchModel.activeRegion, territoriesByRegion, territories]);

    if (isError || isErrorPartners || isUserRegionsError || isErrorCategories || isErrorLanguages) {
      return <CrudError error='Error loading page data' />;
    }

    if (!ableTo('CLIP_VIEW')) {
      return <NotAuthorized />;
    }

    return (
      <Sidebar fullHeight={true}>
        <Expand id='clipsSidebar' width='21rem' height='100%' fullHeightContainer={true} isExpanded={searchExpanded}>
          <Template label='expandable'>
            <Box
              background='pewter'
              paddingY={inModal ? 'none' : 'medium'}
              paddingRight='medium'
              paddingLeft={inModal ? 'none' : 'medium'}
              fullHeight={true}
            >
              <Cover scrolling={true} gutter='medium'>
                <Template label='header'>
                  <Stack space='medium'>
                    <Cluster align='center' justify='space-between'>
                      <Icon icon='tune' space='small' size='large' iconAlign='center'>
                        <Heading level='h4'>Search Filters</Heading>
                      </Icon>
                      <Icon icon='collapseleft' size='large' onClick={() => setSearchExpanded(!searchExpanded)} />
                    </Cluster>
                    {searchExpanded && (
                      <ClipFavoriteSearch
                        searchModel={{
                          name: '',
                          model: searchModel,
                          sortCol: sortCol,
                          sortDir: sortDir,
                          meta: searchMeta,
                        }}
                        searchSelected={activeSearch}
                        onSearchSelected={searchSelectedHandler}
                        onClearSelection={clearSearch}
                      />
                    )}

                    <Divider color='graphite' />
                  </Stack>
                </Template>
                <Template label='cover'>
                  {/* Need to force form re-render on open searchBar to make autoFocus field work properly */}
                  {searchExpanded && (
                    <form
                      onSubmit={ev => {
                        ev.preventDefault();
                        setPage(0);
                        setTimeout(() => search(searchModel, searchMeta));
                      }}
                    >
                      <Stack space='small'>
                        {/* Using this to allow pressing enter to submit form */}
                        <input type='submit' style={{display: 'none'}} />
                        {/* negative margin here to counter act the odd space from the input on the line above. */}
                        <Box marginTop='xsmallNegative'>
                          <FormItem
                            label='Clip Name'
                            onBlur={() => {
                              searchOnBlur('name');
                            }}
                          >
                            <TextInput
                              id={clipNameInputId}
                              onChange={value => {
                                searchOnChange('name', value);
                              }}
                              value={searchModel.name}
                              placeholder='Search by Clip Name'
                            />
                          </FormItem>
                        </Box>
                        <FormItem label='Active Region'>
                          <Select
                            multiselect={true}
                            placeholder='Select Active Region'
                            clearable={true}
                            id='targetRegion'
                            value={searchModel?.activeRegion?.map(v => ({label: v, value: v})) || []}
                            options={activeRegions.map(ar => ({
                              label: `${ar.name} (${ar.code})`,
                              value: ar.code.toLowerCase(),
                            }))}
                            onChange={value => {
                              if (!value) {
                                searchSetFields({activeRegion: [], regionFilter: []});
                              } else {
                                const lastSelectedRegion = value[value.length - 1].value.toUpperCase();
                                const allSelectedRegionTerritores = value.flatMap(v =>
                                  territoriesByRegion[v.value.toUpperCase()].map(t => t.id.toLocaleLowerCase()),
                                );
                                const lastSelectedRegionTerritories = territoriesByRegion[lastSelectedRegion].map(t =>
                                  t.id.toLocaleLowerCase(),
                                );
                                const newRegionFilter = uniq(
                                  [...(searchModel.regionFilter || []), ...lastSelectedRegionTerritories].filter(r =>
                                    allSelectedRegionTerritores.some(t => t === r),
                                  ),
                                );
                                searchSetFields({
                                  activeRegion: value.map(v => v.value),
                                  regionFilter: newRegionFilter,
                                });
                              }
                            }}
                            predicate='value'
                          />
                        </FormItem>
                        <FormItem
                          label='Author'
                          onBlur={() => {
                            searchOnBlur('author');
                          }}
                        >
                          <TextInput
                            onChange={value => {
                              searchOnChange('author', value);
                            }}
                            value={searchModel.author}
                            placeholder='Search by Author'
                          />
                        </FormItem>
                        <FormItem label='Tags'>
                          <TagList
                            value={searchModel.tags}
                            onChange={value =>
                              searchSetFields({
                                tags: orderBy([...new Set(value || [])] as string[], tag => tag.toLowerCase()),
                              })
                            }
                            placeholder='Search by tags'
                          />
                        </FormItem>
                        <FormItem label='Category'>
                          <Select
                            placeholder='Select category'
                            onChange={value => searchSetFields({category: value?.label})}
                            value={{label: searchModel.category || ''}}
                            clearable={true}
                            options={orderBy(
                              (categories || []).map(category => ({label: category.category})),
                              'label',
                            )}
                          />
                        </FormItem>
                        <FormItem label='Upload Date' state={searchMeta?.window ? 'disabled' : ''}>
                          <DateTime
                            id='uploadDate'
                            range={true}
                            placeholder='Search by upload date range'
                            value={searchMeta?.uploadDate}
                            clearable={true}
                            appendToBody={true}
                            onChange={value => {
                              setSearchMeta({
                                uploadDate: value as IDateRange,
                                ...(searchMeta?.duration && {
                                  duration: searchMeta.duration,
                                }),
                                window: undefined,
                              });
                            }}
                          />
                        </FormItem>
                        <Cluster wrap={false} space='xxxsmall' align='center'>
                          <FormItem
                            label='Duration'
                            helpText={durationForm.end?.helpText || durationForm.start?.helpText}
                            state={durationForm.end?.state || durationForm.start?.state}
                            onBlur={() => durationOnBlur('start')}
                          >
                            <TextInput
                              id='durationStart'
                              mask='NN:[0-5]N:[0-5]N'
                              fixedPlaceholder='00:00:00'
                              value={durationModel.start}
                              onChange={val => {
                                durationOnChange('start', val);
                                setSearchMeta({
                                  ...searchMeta,
                                  duration: {
                                    ...(searchMeta.duration?.end && {
                                      end: searchMeta.duration?.end,
                                    }),
                                    start: val,
                                  },
                                });
                              }}
                            />
                          </FormItem>
                          <Heading level='h5' color='secondary'>
                            to
                          </Heading>
                          <TextInput
                            id='durationEnd'
                            mask='NN:[0-5]N:[0-5]N'
                            fixedPlaceholder='00:00:00'
                            value={durationModel.end}
                            onBlur={() => durationOnBlur('end')}
                            onChange={val => {
                              durationOnChange('end', val);

                              setSearchMeta({
                                ...searchMeta,
                                duration: {
                                  ...(searchMeta.duration?.start && {
                                    start: searchMeta.duration?.start,
                                  }),
                                  end: val,
                                },
                              });
                            }}
                          />
                        </Cluster>
                        <FormItem label='Promotional'>
                          <Select
                            placeholder='Promotional?'
                            clearable={true}
                            options={[
                              {label: 'Yes', value: true},
                              {label: 'No', value: false},
                            ]}
                            predicate='value'
                            value={{label: '', value: searchModel.promotional}}
                            onChange={val => searchSetFields({promotional: val?.value})}
                          />
                        </FormItem>
                        <FormItem label='Time Window' state={searchMeta?.uploadDate ? 'disabled' : ''}>
                          <Select
                            placeholder='Time Window'
                            clearable={true}
                            options={[
                              {label: 'Daily (24-Hour Range)', value: 'day'},
                              {label: 'Weekly (7-Day Range)', value: 'week'},
                              {label: 'Monthly (30-Day Range)', value: 'month'},
                            ]}
                            predicate='value'
                            value={{label: '', value: searchMeta?.window}}
                            onChange={val =>
                              setSearchMeta({
                                window: val?.value,
                                ...(searchMeta?.duration && {
                                  duration: searchMeta.duration,
                                }),
                                uploadDate: undefined,
                              })
                            }
                          />
                        </FormItem>
                        <FormItem label='Language'>
                          <Select
                            clearable={true}
                            onChange={value => searchSetFields({language: value?.value})}
                            value={{label: searchModel.language || '', value: searchModel.language}}
                            id='language'
                            predicate='value'
                            searchable={true}
                            placeholder='Select language'
                            searchPlaceholder='Search for language'
                            onSearch={val =>
                              orderBy(
                                (languages || [])
                                  .filter(language => language.name.toLowerCase().indexOf(val.toLowerCase()) > -1)
                                  .map(language => ({label: language.name, value: language.iso639_1})),
                                'label',
                              ) || []
                            }
                            options={orderBy(
                              (languages || []).map(language => ({
                                label: language.name,
                                value: language.iso639_1,
                              })),
                              'label',
                            )}
                          />
                        </FormItem>
                        <FormItem label='Rating'>
                          <Select
                            clearable={true}
                            placeholder='Please select rating'
                            onChange={value => searchSetFields({rating: value?.label})}
                            value={{label: searchModel.rating || ''}}
                            id='rating'
                            sortField='weight'
                            options={ratingsForSelectedRegions}
                          />
                        </FormItem>
                        <FormItem
                          label='Pluto Avails ID'
                          onBlur={() => {
                            searchOnBlur('plutoAvailsID');
                          }}
                        >
                          <TextInput
                            value={searchModel.plutoAvailsID}
                            placeholder='Pluto Avails ID'
                            onChange={value => {
                              searchOnChange('plutoAvailsID', value);
                            }}
                          />
                        </FormItem>
                        <FormItem
                          label='URL'
                          onBlur={() => {
                            searchOnBlur('url');
                          }}
                        >
                          <TextInput
                            value={searchModel.url?.[0]}
                            placeholder='URL'
                            onChange={value => {
                              searchOnChange('url', [value]);
                            }}
                          />
                        </FormItem>
                        <FormItem label='Used'>
                          <Select
                            placeholder='Used?'
                            clearable={true}
                            options={[
                              {label: 'Yes', value: true},
                              {label: 'No', value: false},
                            ]}
                            value={{label: '', value: searchModel.isUsed}}
                            onChange={value => searchSetFields({isUsed: value?.value})}
                            predicate='value'
                          />
                        </FormItem>
                        <FormItem label='Programming Type'>
                          <Select
                            clearable={true}
                            placeholder='Select a Programming Type'
                            onChange={value => searchSetFields({programmingType: value?.label})}
                            value={{label: searchModel.programmingType || ''}}
                            options={programmingTypes.map(pT => ({label: pT}))}
                          />
                        </FormItem>
                        <FormItem label='Live Broadcast'>
                          <Select
                            clearable={true}
                            placeholder='Live Broadcast?'
                            options={[
                              {label: 'Yes', value: true},
                              {label: 'No', value: false},
                            ]}
                            predicate='value'
                            value={{label: '', value: searchModel.liveBroadcast}}
                            onChange={value => searchSetFields({liveBroadcast: value?.value})}
                          />
                        </FormItem>
                        <FormItem label='Published'>
                          <Select
                            clearable={true}
                            placeholder='Published?'
                            options={[
                              {label: 'Yes', value: true},
                              {label: 'No', value: false},
                            ]}
                            predicate='value'
                            value={{label: '', value: searchModel.published}}
                            onChange={value => searchSetFields({published: value?.value})}
                          />
                        </FormItem>
                        <FormItem
                          label='Summary'
                          onBlur={() => {
                            searchOnBlur('summary');
                          }}
                        >
                          <Textarea
                            value={searchModel.summary}
                            onChange={value => {
                              searchOnChange('summary', value);
                            }}
                            placeholder='Search by summary'
                            minHeight='6.25rem'
                          />
                        </FormItem>
                        <FormItem
                          label='Description'
                          onBlur={() => {
                            searchOnBlur('description');
                          }}
                        >
                          <Textarea
                            value={searchModel.description}
                            onChange={value => {
                              searchOnChange('description', value);
                            }}
                            placeholder='Search by description'
                            minHeight='6.25rem'
                          />
                        </FormItem>
                        <FormItem label='Directors'>
                          <TagList
                            placeholder='Search by directors'
                            value={searchModel.directors}
                            onChange={value =>
                              searchSetFields({
                                directors: orderBy([...new Set(value || [])] as string[], director =>
                                  director.toLowerCase(),
                                ),
                              })
                            }
                          />
                        </FormItem>
                        <FormItem label='Actors'>
                          <TagList
                            placeholder='Search by actors'
                            value={searchModel.actors}
                            onChange={value =>
                              searchSetFields({
                                actors: orderBy([...new Set(value || [])] as string[], actor => actor.toLowerCase()),
                              })
                            }
                          />
                        </FormItem>
                        <FormItem label='Writers'>
                          <TagList
                            value={searchModel.writers}
                            placeholder='Search by writers'
                            onChange={value =>
                              searchSetFields({
                                writers: orderBy([...new Set(value || [])] as string[], writer => writer.toLowerCase()),
                              })
                            }
                          />
                        </FormItem>
                        <FormItem label='Producers'>
                          <TagList
                            value={searchModel.producers}
                            placeholder='Search by producers'
                            onChange={value =>
                              searchSetFields({
                                producers: orderBy([...new Set(value || [])] as string[], producer =>
                                  producer.toLowerCase(),
                                ),
                              })
                            }
                          />
                        </FormItem>
                        <FormItem label='Territories'>
                          <Select
                            placeholder='Please select territories'
                            onChange={value =>
                              searchSetFields({
                                regionFilter: (value || [])?.map(v => v.value),
                              })
                            }
                            value={searchModel.regionFilter?.map(d => ({label: d, value: d}))}
                            clearable={true}
                            searchable={true}
                            addAll={true}
                            multiselect={true}
                            predicate='value'
                            options={
                              orderBy(
                                (territoriesForSelectedRegions || []).map(t => ({
                                  label: t.name,
                                  value: t.id.toLowerCase(),
                                })),
                                'label',
                              ) || []
                            }
                          />
                        </FormItem>
                        <FormItem label='AVOD'>
                          <Select
                            placeholder='AVOD?'
                            clearable={true}
                            options={[
                              {label: 'Yes', value: true},
                              {label: 'No', value: false},
                            ]}
                            predicate='value'
                            value={{label: '', value: searchModel.distributeAs?.AVOD}}
                            onChange={value =>
                              searchSetFields({
                                distributeAs: value ? {...searchModel.distributeAs!, AVOD: value?.value} : undefined,
                              })
                            }
                          />
                        </FormItem>
                      </Stack>
                    </form>
                  )}
                </Template>
                <Template label='footer'>
                  <Cluster justify='space-between'>
                    <div></div>
                    <Cluster space='small'>
                      <Button
                        id='clearSearchButton'
                        ghost={true}
                        state={isFetching ? 'disabled' : ''}
                        onClick={initialSearch}
                      >
                        Clear
                      </Button>
                      <Button
                        state={!durationFormState.isValid ? 'disabled' : isFetching ? 'thinking' : ''}
                        id='searchButton'
                        type='primary'
                        onClick={() => {
                          setPage(0);
                          search(searchModel, searchMeta);
                        }}
                      >
                        Search
                      </Button>
                    </Cluster>
                  </Cluster>
                </Template>
              </Cover>
            </Box>
          </Template>
        </Expand>
        <Cover
          scrolling={true}
          gutter='large'
          coverTemplateHeight='100%'
          overflow='auto'
          padding={inModal ? 'none' : {mobile: 'medium', wide: 'large'}}
        >
          <Template label='header'>
            <Cluster justify='space-between' align='center' space='medium'>
              <Cluster align='end' space='large' id='clipsCountHeader'>
                {!inModal && <Heading level='h1'>Clips</Heading>}
                {inModal && (
                  <Heading level='h2' color='secondary'>
                    Clips
                  </Heading>
                )}
                <Cluster space='xlarge' align='center'>
                  <Icon
                    icon='tune'
                    space='xxxsmall'
                    verticalAlign='bottom'
                    lineHeight='0px'
                    onClick={() => setSearchExpanded(!searchExpanded)}
                  >
                    {withThousandsSeparator(clips?.metadata.totalCount || 0)} Items
                  </Icon>
                  {/*
                  {!isEmptySearch && !isLoading && (
                    <Icon
                      icon='cancel'
                      space='xxxsmall'
                      verticalAlign='bottom'
                      lineHeight='0px'
                      onClick={initialSearch}
                    >
                      Clear Filter
                    </Icon>
                  )}
                  */}
                </Cluster>
              </Cluster>
              <Cluster space='medium' align='center'>
                {/*
                <form
                  onSubmit={ev => {
                    ev.preventDefault();

                    setTimeout(() => search(searchModel, searchMeta));
                    setTimeout(() => setIsEmptySearch(isEqual(cleanModel(searchModel), DEFAULT_SEARCH)), 200);
                  }}
                >
                  <input type='submit' style={{display: 'none'}} />
                  <TextInput
                    size='small'
                    iconLeft='search'
                    iconRight='tune'
                    onIconRightClick={() => searchExpanded(!isSearchExpanded)}
                    onChange={value => searchOnChange('name', value)}
                    onBlur={() => searchOnBlur('name')}
                    value={searchModel.name}
                    placeholder='Search by clip name'
                  />
                </form>
                */}
                {addNewClips ? (
                  <>
                    <Button type='primary' onClick={openCreate} permission={permissions.CLIP_CREATE} id='newClip'>
                      + New Clip
                    </Button>
                    <Dialog
                      id='createClipDialog'
                      isOpen={createOpen}
                      onClose={handleCancel}
                      width='32.8125rem'
                      height='39rem'
                    >
                      <Template label='header'>
                        <Heading level='h2'>Create Clip</Heading>
                      </Template>
                      <Template label='body'>
                        <Grid gap='small'>
                          <FormItem
                            {...form.name}
                            onBlur={() => {
                              onBlur('name');
                            }}
                          >
                            <TextInput
                              onChange={value => {
                                onChange('name', value);
                              }}
                              id='title'
                            ></TextInput>
                          </FormItem>
                          <FormItem {...form?.activeRegion}>
                            <Select
                              onChange={value => {
                                const activeRegion = value.value;
                                const regionFilter =
                                  territoriesByRegion[value.value.toUpperCase()]?.map(t => t.id.toLowerCase()) || [];

                                setFields({
                                  activeRegion,
                                  regionFilter,
                                });
                              }}
                              value={{label: model.activeRegion || '', value: model.activeRegion}}
                              id='activeRegtion'
                              predicate='value'
                              options={activeRegions.map(ar => ({
                                label: `${ar.name} (${ar.code})`,
                                value: ar.code.toLowerCase(),
                              }))}
                            />
                          </FormItem>
                          <FormItem {...form.regionFilter}>
                            <Select
                              id='territories'
                              value={model.regionFilter?.map(d => ({label: d, value: d}))}
                              multiselect={true}
                              searchable={true}
                              options={
                                orderBy(
                                  (territories || []).map(t => ({label: t.name, value: t.id.toLowerCase()})),
                                  'label',
                                ) || []
                              }
                              onChange={value =>
                                setFields({
                                  regionFilter: (value || [])?.map(v => v.value),
                                })
                              }
                              predicate='value'
                            />
                          </FormItem>
                          <FormItem label='Clip Duration' {...form.duration} onBlur={() => onBlur('duration')}>
                            <TextInput
                              onChange={v => onChange('duration', setDurationSecond(v))}
                              id='duration'
                              fixedPlaceholder='HH:MM:SS'
                              mask='NN:[0-5]N:[0-5]N[:|;]'
                            ></TextInput>
                          </FormItem>
                          <FormItem label='Clip Framerate' {...form?.framerate} onBlur={() => onBlur('framerate')}>
                            <Select
                              onChange={value => {
                                const rate = value.value;
                                setFields({framerate: rate});
                              }}
                              value={{value: model?.framerate || '', label: ''}}
                              id='framerate'
                              predicate='value'
                              options={[23.976, 23.98, 24, 25, 29.97, 30, 50, 59.94, 60].map(option => ({
                                label: `${option}`,
                                value: option as number,
                              }))}
                            />
                          </FormItem>
                          <FormItem {...form.origin} onBlur={() => onBlur('origin')}>
                            <Textarea
                              onChange={value => {
                                let originType = '';
                                if (checkOriginUrlFileValidation(value, '.mp4')) {
                                  originType = 'MP4';
                                } else if (checkOriginUrlFileValidation(value, '.m3u8')) {
                                  originType = 'HLS';
                                }

                                onChange('origin', {...model.origin, url: value, type: originType});
                              }}
                              value={model.origin?.url}
                              id='url'
                            />
                          </FormItem>
                          <FormItem {...form.partner}>
                            <Select
                              onChange={value =>
                                setFields({
                                  partner: value?.value,
                                  author: {
                                    name: value?.label || '',
                                  },
                                })
                              }
                              value={{value: model?.partner || '', label: ''}}
                              id='partner'
                              predicate='value'
                              searchable={true}
                              clearable={true}
                              onSearch={val =>
                                (partners || [])
                                  .filter(partner => partner.name.toLowerCase().indexOf(val.toLowerCase()) > -1)
                                  .map(partner => ({label: partner.name, value: partner.id})) || []
                              }
                              options={(partners || []).map(partner => ({label: partner.name, value: partner.id}))}
                            />
                          </FormItem>
                          <FormItem child='Toggle' label='Live Broadcast'>
                            <Toggle
                              label='Yes'
                              id='liveBroadcastToggle'
                              onChange={val => setFields({liveBroadcast: val})}
                            />
                          </FormItem>
                        </Grid>
                      </Template>
                      <Template label='footer'>
                        <Cluster justify='space-between'>
                          <div></div>
                          <Cluster space='small'>
                            <Button ghost={true} onClick={handleCancel} id='cancelButton'>
                              Cancel
                            </Button>
                            <Button
                              type='primary'
                              state={
                                !formState.isValid || !formState.isDirty ? 'disabled' : isCreating ? 'thinking' : ''
                              }
                              onClick={() => handleCreate()}
                              id='createButton'
                            >
                              Create
                            </Button>
                            <Button
                              type='primary'
                              state={
                                !formState.isValid || !formState.isDirty ? 'disabled' : isCreating ? 'thinking' : ''
                              }
                              onClick={handleCreateAndEdit}
                              id='createEditButton'
                            >
                              Create and Edit
                            </Button>
                          </Cluster>
                        </Cluster>
                      </Template>
                    </Dialog>
                  </>
                ) : (
                  <Box height='1.7rem'></Box>
                )}
              </Cluster>
              {totalDuration && (
                <Cluster space='large' align='center'>
                  <Heading level='h6'>Total Duration</Heading>
                  <Cluster space='xxxxsmall' align='end'>
                    <Heading level='h6' monospace={true} monospaceWeight='normal'>
                      {calculatedDuration}
                    </Heading>
                    <Heading level='h6' monospace={true} monospaceWeight='normal'>
                      /
                    </Heading>
                    <Heading level='h6' monospace={true} monospaceWeight='normal'>
                      {totalAllotment}
                    </Heading>
                  </Cluster>
                </Cluster>
              )}
            </Cluster>
          </Template>
          <Template label='cover'>
            <Box
              background='pewter'
              borderTop={true}
              borderSize='0.125rem'
              borderColor='cavern'
              paddingTop={inModal ? 'none' : (clips?.data.length as number) > 0 ? 'xsmall' : 'medium'}
              paddingBottom='none'
              paddingX={inModal ? 'none' : 'large'}
              fullHeight={true}
            >
              <Table
                predicate='_id'
                loading={isLoading}
                fixedHeader={true}
                wrapContent={true}
                onSort={colName => changeSort(colName as keyof typeof TABLE_COLUMN_NAME)}
                onSelect={clip => onRowSelected(clip as IClip[])}
                sortDir={sortDir}
                sortCol={sortCol}
                selectable={checkboxCol!}
                id='clips-search-table'
                rowStatus={row => (existingClipsId.find(clip => clip === row._id) ? 'highlight' : '') as any}
                trackSort={trackClipsSortList}
                cols={[
                  {
                    label: 'Clip Name',
                    sortable: true,
                    transform: row => {
                      const editDetailsUrl = clipRoutes.paths.clipEditDetailsPage.replace(':id', row._id);
                      return clipPreview ? (
                        <Cluster wrap={false} space='small' align='center'>
                          <Click
                            onClick={() => {
                              setActiveClip(row);
                            }}
                          >
                            <ImageWrapper
                              backgroundColor='black'
                              height='2.5rem'
                              width='4.4375rem'
                              icon='play'
                              iconSize='large'
                              src={row.thumbnail}
                              alt={row.name}
                            />
                          </Click>
                          <TdLink title={row.name} row={row} url={editDetailsUrl} />
                        </Cluster>
                      ) : (
                        <TdLink
                          title={row.name}
                          row={row}
                          url={editDetailsUrl}
                          {...(nameTarget && {target: nameTarget})}
                        />
                      );
                    },
                    colMinWidth: '12.5rem',
                  },
                  {
                    label: 'Active Region',
                    transform: row => row.activeRegion.toUpperCase(),
                    sortable: true,
                    colMinWidth: '9.6875rem',
                  },
                  {
                    label: 'Author',
                    colMinWidth: '12.5rem',
                    transform: row => row.author?.name,
                  },
                  {
                    label: 'Upload Date',
                    transform: row => new Date(row.createdAt).toLocaleString(),
                    sortable: true,
                    colMinWidth: '13.75rem',
                  },
                  {
                    label: 'Duration',
                    transform: row => secondsToHms(row.duration),
                    sortable: true,
                    colMinWidth: '7.5rem',
                  },
                  {
                    label: 'Clip Status',
                    sortable: true,
                    colMinWidth: '9.375rem',
                    transform: row => (
                      <Icon
                        space='xxxsmall'
                        pulse={row.contentStatus === 'processing' ? true : false}
                        color={
                          row.contentStatus === 'origin' ||
                          row.contentStatus === 'downloading' ||
                          row.contentStatus === 'processing' ||
                          row.contentStatus === 'uploaded'
                            ? 'infoLight'
                            : row.contentStatus === 'ready'
                            ? 'success'
                            : 'error'
                        }
                        icon={
                          row.contentStatus === 'origin'
                            ? 'origin'
                            : row.contentStatus === 'downloading'
                            ? 'downloading'
                            : row.contentStatus === 'uploaded'
                            ? 'uploading'
                            : row.contentStatus === 'processing'
                            ? 'inprogress'
                            : row.contentStatus === 'ready'
                            ? 'check'
                            : 'error'
                        }
                      >
                        {capitalize(row.contentStatus)}
                      </Icon>
                    ),
                  },
                  ...(ableTo('CLIP_EDIT') && actionsCol
                    ? [
                        {
                          label: 'Actions',
                          colWidth: '6.25rem',
                          transform: row => (
                            <TableActions
                              row={row}
                              icons={ableTo('CLIP_EDIT') ? ['edit'] : []}
                              deleteOption={ableTo('CLIP_DELETE')}
                              onClick={(row, icon) => {
                                switch (icon) {
                                  case 'edit':
                                    handleEdit(row);
                                    break;
                                  case 'delete':
                                    handleDelete(row);
                                    break;
                                  default:
                                }
                              }}
                            />
                          ),
                        } as ITableCol<IClip>,
                      ]
                    : []),
                ]}
                rows={clips?.data}
              >
                <Template label='loading'>
                  <Cluster space='small' align='center'>
                    <Spinner />
                    <Paragraph>Loading Clips</Paragraph>
                  </Cluster>
                </Template>
                <Template label='empty'>
                  <Notification type='warning'>There are no clips currently available.</Notification>
                </Template>
              </Table>
              <Dialog
                isOpen={clipPreviewOpen && activeClip ? true : false}
                onClose={() => setActiveClip(undefined)}
                width='50%'
                id='clip-preview-dialog'
              >
                <Template label='header'>
                  <Heading level='h3'>{activeClip?.name}</Heading>
                </Template>
                <Template label='body'>
                  {isStreamLoading ? (
                    <Box fullHeight={true}>
                      <Spinner center={true} minHeight='9.375rem' size='xlarge' />
                    </Box>
                  ) : (
                    activeClip &&
                    streamUrl && (
                      <Player
                        id='preview'
                        playerType={playerType}
                        src={streamUrl}
                        subtitles={cc}
                        license='9CE6F075527140BFF002A0E007D58114U9B800763DCE09ED4B3739AA5CF69B309'
                        hlsPlayerQueryString={signedQuery}
                        options={{
                          dropFrame,
                          frameRate: {
                            denominator: 1,
                            numerator: activeClip?.framerate || 30,
                          },
                        }}
                      />
                    )
                  )}
                </Template>
              </Dialog>
            </Box>
          </Template>
          <Template label='footer'>
            <Cluster justify='space-between'>
              <div></div>
              {(clips?.data.length as number) > 0 && (
                <Pagination
                  id='pagination'
                  perPage={rowsPerPage}
                  disabled={isFetching}
                  currentPage={page}
                  total={clips?.metadata.totalCount || 0}
                  onPageChange={page => {
                    setPage(page);
                    document.getElementById('clips-search-table')?.parentElement?.scrollTo(0, 0);
                  }}
                  onPerPageChange={perPage => {
                    setRowsPerPage(perPage as 25 | 50 | 75 | 1000);
                    setPage(0);
                  }}
                />
              )}
            </Cluster>
          </Template>
        </Cover>
      </Sidebar>
    );
  },
);

ClipList.displayName = 'ClipList';
export default ClipList;
