import {Grid, dayjs} from '@pluto-tv/assemble';
import FeaturedGroupBox from 'components/featuredGroupBox';
import {IRegionDateGroups} from 'components/featuredGroupList/hooks/useAllGroupsData';
import useLaunchCards from 'components/featuredGroupList/hooks/useLaunchCards';
import useValidations from 'components/featuredGroupList/hooks/useValidations';
import {lauchCardsDateTimeFormat} from 'components/featuredGroupList/utils/dateUtils';
import {
  validateConflictingGroups,
  validateGroupHasNoChannel,
  validateHasUnpublishedChannels,
} from 'components/featuredGroupList/utils/validators';
import {IFeaturedGroup} from 'models/featuredGroup';
import * as React from 'react';

interface ILaunchCardsComponentProps {
  activeRegionSelected: string;
  validatorData: IRegionDateGroups;
  handleDuplicate: (row: IFeaturedGroup) => Promise<void>;
  handleDelete: (ids: string | string[]) => Promise<void>;
  handleEdit: (featuredGroupId: string) => void;
  handlePublish: (row: IFeaturedGroup) => Promise<void>;
  publishedUpcomingData?: IFeaturedGroup[];
}

const LaunchCards = React.memo(
  ({
    validatorData,
    activeRegionSelected,
    handleDuplicate,
    handleDelete,
    handleEdit,
    handlePublish,
    publishedUpcomingData,
  }: ILaunchCardsComponentProps) => {
    const {validate: validateRows} = useValidations(
      [
        validateConflictingGroups,
        validateGroupHasNoChannel,
        ({row}) => validateHasUnpublishedChannels({row, message: 'This group references an unpublished channel(s).'}),
      ],
      validatorData,
    );

    const {cards: launchCardsToDisplay} = useLaunchCards(activeRegionSelected, publishedUpcomingData || []);

    return (
      <div id='featured-group-boxes'>
        <Grid gap='medium' maxCols={2}>
          {launchCardsToDisplay.map(featuredChannel => {
            const cardWithState = validateRows(featuredChannel);
            if (featuredChannel.published) {
              return (
                <FeaturedGroupBox
                  id={featuredChannel.id}
                  key={featuredChannel.id}
                  title={featuredChannel.name || ''}
                  published={featuredChannel.published}
                  channelCount={featuredChannel.contentItems?.length || 0}
                  channelWarnings={cardWithState}
                  startDate={dayjs(featuredChannel.start).format(lauchCardsDateTimeFormat)}
                  onDuplicate={() => handleDuplicate(featuredChannel)}
                  onEdit={() => handleEdit(featuredChannel.id || '')}
                />
              );
            } else {
              return (
                <FeaturedGroupBox
                  id={featuredChannel.id}
                  key={featuredChannel.id}
                  title={featuredChannel.name || ''}
                  published={!!featuredChannel.published}
                  channelCount={featuredChannel.contentItems?.length || 0}
                  startDate={dayjs(featuredChannel.start).format(lauchCardsDateTimeFormat)}
                  channelWarnings={cardWithState}
                  onDuplicate={() => handleDuplicate(featuredChannel)}
                  onDelete={() => handleDelete(featuredChannel.id || '')}
                  onEdit={() => handleEdit(featuredChannel.id || '')}
                  onPublish={() => handlePublish(featuredChannel)}
                />
              );
            }
          })}
        </Grid>
      </div>
    );
  },
);

LaunchCards.displayName = 'LaunchCards';
export default LaunchCards;
