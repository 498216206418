import * as React from 'react';
import {useCancellableSummon} from '@pluto-tv/assemble';

interface IUseRunLimitCache<T> {
  getPromise(keyId: string): Promise<T>;
}

export const useRunLimitCache = <T,>(): IUseRunLimitCache<T> => {
  const promiseMap = React.useRef<Map<string, Promise<T>>>();

  const [summon] = useCancellableSummon();

  React.useEffect(() => {
    promiseMap.current = new Map<string, Promise<T>>();
  }, []);

  const getPromise = React.useCallback(
    (url: string): Promise<T> => {
      const mappedPromse = promiseMap.current?.get(url);

      if (mappedPromse) {
        return mappedPromse;
      }

      const promise = summon.get<T, T>(url);
      promiseMap.current?.set(url, promise);

      return promise;
    },
    [summon],
  );

  return {
    getPromise,
  };
};

export const generateRunLimitUri = (id: string): string =>
  `rlt/run-limit?licensedTitleId=${id}&offset=0&limit=100&startDate=1999-01-01&endDate=2043-12-31`;
