export const featuredGroupsPermissions = {
  FEATURED_GROUPS_VIEW: {
    enabled: ['featured_groups_view', 'featured_groups_edit', 'featured_groups_create', 'featured_groups_delete'],
  },
  FEATURED_GROUPS_DELETE: {
    enabled: ['featured_groups_delete'],
  },
  FEATURED_GROUPS_CREATE: {
    enabled: ['featured_groups_create'],
  },
  FEATURED_GROUPS_EDIT: {
    enabled: ['featured_groups_edit'],
    disabled: ['featured_groups_view', 'featured_groups_create', 'featured_groups_delete'],
  },
};
