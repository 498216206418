import * as React from 'react';
import {IFeaturedGroup} from 'models/featuredGroup';
import {useUserRegions} from 'helpers/useUserRegions';
import {SearchState} from '../FeaturedGroupsList';
import {useSearchQuery} from 'features/featuredGroups/featuredGroupsApi';

interface IReturnType {
  mappedData: IRegionDateGroups;
}

export interface IRegionDateGroups {
  // first key is the active region, second key the date time string
  [key: string]: {[key: string]: IFeaturedGroup[]};
}

const mapData = (data: IFeaturedGroup[]): IRegionDateGroups => {
  const dateGroups: IRegionDateGroups = {};

  data.forEach(row => {
    const activeRegion = row.activeRegion?.toUpperCase() || 'UNKNOWN';
    const dateTimeString = row.start as string;

    if (!dateGroups[activeRegion]) {
      dateGroups[activeRegion] = {};
    }

    if (!dateGroups[activeRegion][dateTimeString]) {
      dateGroups[activeRegion][dateTimeString] = [];
    }

    dateGroups[activeRegion][dateTimeString].push(row);
  });

  return dateGroups;
};

const useAllGroupsData = (searchQuery: SearchState): IReturnType => {
  const {activeRegions} = useUserRegions();
  const [mappedData, setMappedData] = React.useState<IRegionDateGroups>({});

  const {data: featuredGroupsData} = useSearchQuery(
    {
      limit: 50000,
      offset: 0,
      sort: 'start:asc',
      activeRegion: activeRegions.map(region => region.code),
      startDate: searchQuery.searchObj.startDate,
      startEndDate: searchQuery.searchObj.startEndDate,
    },
    {skip: !activeRegions.length},
  );

  React.useEffect(() => {
    if (featuredGroupsData) {
      setMappedData(mapData(featuredGroupsData.data));
    }
  }, [featuredGroupsData]);

  return {
    mappedData,
  };
};

export default useAllGroupsData;
