import * as React from 'react';
import {
  Box,
  Button,
  Cluster,
  DateTime,
  Dialog,
  FormItem,
  Grid,
  Heading,
  ISimpleScheduleEntry,
  Label,
  Notification,
  Select,
  Stack,
  Template,
  TextInput,
} from '@pluto-tv/assemble';
import {disablePastDates} from 'views/programming/channel/utils/dateUtils';
import {IChannelCatalogItemWithState} from 'views/programming/channel/contexts/memoryQueueApiProvider';
import useRecurrenceValidation from 'views/programming/channel/edit/program/hooks/useRecurrenceValidation';

interface IEpisodeRecurrenceProps {
  channelId: string;
  startDate?: Date;
  searchSelected: IChannelCatalogItemWithState | ISimpleScheduleEntry;
  timeToAddNewEntry?: number;
  isRecurrenceOpen: boolean;
  setIsValidRecurrence?: (value: boolean) => void;
  setIsRecurrenceOpen: (value: boolean) => void;
  setRecurrenceResponse?: (value: any) => void;
  setLastRepeatSchedule: (value: number) => void;

  closeHandler?: () => void;
  backHandler?: () => void;
  addToCalendarHandler?: (recurrenceResponseFromModal?: any) => void;
}
const EpisodeRecurrence = React.memo(
  ({
    channelId,
    startDate,
    searchSelected,
    isRecurrenceOpen,
    timeToAddNewEntry,
    setIsValidRecurrence,
    setIsRecurrenceOpen,
    setRecurrenceResponse,
    setLastRepeatSchedule,
    closeHandler,
    backHandler,
    addToCalendarHandler,
  }: IEpisodeRecurrenceProps) => {
    const {
      isValidRecurrence,
      recurrenceWarning,
      repeatPeriod,
      endRepeatType,
      endRepeatDate,
      timeframe,
      cycleOver,
      occurrences,
      sunRepeat,
      monRepeat,
      tueRepeat,
      wedRepeat,
      thuRepeat,
      friRepeat,
      satRepeat,
      positioning,
      setRepeatPeriod,
      setEndRepeatType,
      setEndRepeatDate,
      setTimeframe,
      setCycleOver,
      setOccurrences,
      setSunRepeat,
      setMonRepeat,
      setTueRepeat,
      setWedRepeat,
      setThuRepeat,
      setFriRepeat,
      setSatRepeat,
      setPositioning,

      handleAddRepeatSchedule,
    } = useRecurrenceValidation(channelId);

    React.useEffect(() => {
      if (setIsValidRecurrence) {
        setIsValidRecurrence(isValidRecurrence);
      }
    }, [isValidRecurrence, setIsValidRecurrence]);

    const getEpNotificationMessage = (item: IChannelCatalogItemWithState | ISimpleScheduleEntry) => {
      return `The Episode '${item.name}' will be added and follow the repeat pattern set below.`;
    };

    return (
      <Dialog
        width='100%'
        height='100%'
        isOpen={isRecurrenceOpen}
        onClose={() => {
          setIsRecurrenceOpen(false);
        }}
      >
        <Template label='header'>
          <Heading level='h2'>{addToCalendarHandler ? 'Add Episode(s)' : 'Set Recurrence'}</Heading>
        </Template>
        <Template label='body'>
          <Stack space='xlarge'>
            <Notification type='info'>{getEpNotificationMessage(searchSelected)}</Notification>
            <Grid gap='xxsmall' maxCols={3}>
              <Box
                paddingY={{mobile: 'medium', wide: 'large'}}
                paddingX={{mobile: 'medium', wide: 'xlarge'}}
                background='shadow'
                borderTop={true}
                borderSize='0.125rem'
                borderColor='cavern'
              >
                <Stack space='xlarge'>
                  <Heading level='h3' color='secondary'>
                    Repeat
                  </Heading>

                  <Grid minimum='11.25rem' gap='medium'>
                    <FormItem
                      label='Repeat Every'
                      helpText={recurrenceWarning.timeframe}
                      state={recurrenceWarning.timeframe ? 'error' : ''}
                    >
                      <TextInput
                        type='number'
                        value={repeatPeriod}
                        onChange={value => {
                          setRepeatPeriod(value);
                        }}
                      />
                    </FormItem>
                    <FormItem label='Timeframe'>
                      <Select
                        predicate='value'
                        options={[
                          {label: 'Day(s)', value: 'day'},
                          {label: 'Week(s)', value: 'week'},
                          {label: 'Month(s)', value: 'month'},
                        ]}
                        value={{label: '', value: timeframe}}
                        onChange={value => {
                          setTimeframe(value.value);
                        }}
                      />
                    </FormItem>
                  </Grid>
                  {timeframe === 'week' && (
                    <Stack space='small'>
                      <Label>Repeat on which day(s) of the week</Label>
                      <Cluster buttonGroup={true}>
                        <Button active={sunRepeat} onClick={() => setSunRepeat(!sunRepeat)}>
                          Sun
                        </Button>
                        <Button active={monRepeat} onClick={() => setMonRepeat(!monRepeat)}>
                          Mon
                        </Button>
                        <Button active={tueRepeat} onClick={() => setTueRepeat(!tueRepeat)}>
                          Tue
                        </Button>
                        <Button active={wedRepeat} onClick={() => setWedRepeat(!wedRepeat)}>
                          Wed
                        </Button>
                        <Button active={thuRepeat} onClick={() => setThuRepeat(!thuRepeat)}>
                          Thu
                        </Button>
                        <Button active={friRepeat} onClick={() => setFriRepeat(!friRepeat)}>
                          Fri
                        </Button>
                        <Button active={satRepeat} onClick={() => setSatRepeat(!satRepeat)}>
                          Sat
                        </Button>
                      </Cluster>
                      {/** Provides some extra space */}
                      <Box height='10px'></Box>
                    </Stack>
                  )}
                  {timeframe === 'month' && (
                    <FormItem label='Positioning'>
                      <Select
                        predicate='value'
                        options={[
                          {label: 'Same Day of the Week', value: 'week'},
                          {label: 'Same Day of the Month', value: 'month'},
                        ]}
                        value={{label: '', value: positioning}}
                        onChange={opt => {
                          setPositioning(opt.value);
                        }}
                      />
                    </FormItem>
                  )}
                </Stack>
              </Box>
              <Box
                paddingY={{mobile: 'medium', wide: 'large'}}
                paddingX={{mobile: 'medium', wide: 'xlarge'}}
                background='shadow'
                borderTop={true}
                borderSize='0.125rem'
                borderColor='cavern'
              >
                <Stack space='xlarge'>
                  <Heading level='h3' color='secondary'>
                    End
                  </Heading>
                  <Stack space='medium'>
                    <FormItem label='End Type'>
                      <Select
                        predicate='value'
                        options={[
                          {label: 'End On Date', value: 'date'},
                          {label: 'End On Occurence', value: 'after'},
                        ]}
                        value={{label: '', value: endRepeatType}}
                        onChange={value => {
                          setEndRepeatType(value.value);
                        }}
                      />
                    </FormItem>
                    {endRepeatType === 'date' && (
                      <FormItem
                        label='Date'
                        helpText={recurrenceWarning.occurrences}
                        state={recurrenceWarning.occurrences ? 'error' : ''}
                      >
                        <DateTime
                          id='endRecurrenceDate'
                          onBeforeDateRender={date => disablePastDates(date, startDate)}
                          value={endRepeatDate}
                          appendToBody={true}
                          onChange={val => {
                            setEndRepeatDate(val as Date);
                          }}
                        />
                      </FormItem>
                    )}
                    {endRepeatType === 'after' && (
                      <FormItem
                        label='End after how many occurrences'
                        helpText={recurrenceWarning.occurrences}
                        state={recurrenceWarning.occurrences ? 'error' : ''}
                      >
                        <TextInput type='number' value={occurrences} onChange={value => setOccurrences(value)} />
                      </FormItem>
                    )}
                  </Stack>
                </Stack>
              </Box>
              <Box
                paddingY={{mobile: 'medium', wide: 'large'}}
                paddingX={{mobile: 'medium', wide: 'xlarge'}}
                background='shadow'
                borderTop={true}
                borderSize='0.125rem'
                borderColor='cavern'
              >
                <Stack space='xlarge'>
                  <Heading level='h3' color='secondary'>
                    Cycle
                  </Heading>
                  <Stack space='medium'>
                    <FormItem label='Cycle Over'>
                      <Select
                        predicate='value'
                        options={[
                          {label: 'Episodes and Seasons in the Series', value: 'series'},
                          {label: 'Episode in this Season', value: 'season'},
                        ]}
                        value={{label: '', value: cycleOver}}
                        onChange={value => {
                          setCycleOver(value.value);
                        }}
                      />
                    </FormItem>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          </Stack>
        </Template>
        <Template label='footer'>
          <Cluster justify='space-between'>
            <div></div>
            <Cluster space='small'>
              <Button
                ghost={true}
                onClick={() => {
                  setIsRecurrenceOpen(false);

                  if (closeHandler) {
                    closeHandler();
                  }
                }}
              >
                Cancel
              </Button>
              {backHandler && (
                <Button type='primary' onClick={backHandler}>
                  Back to Queue List
                </Button>
              )}
              <Button
                type='primary'
                onClick={async () => {
                  const lastRepeatSchedule = new Date().getTime();
                  const response = await handleAddRepeatSchedule(searchSelected, lastRepeatSchedule, timeToAddNewEntry);
                  setLastRepeatSchedule(lastRepeatSchedule);
                  setIsRecurrenceOpen(false);

                  if (addToCalendarHandler) {
                    addToCalendarHandler(response);
                  }

                  if (setRecurrenceResponse) {
                    setRecurrenceResponse(response);
                  }
                }}
                state={!isValidRecurrence ? 'disabled' : ''}
              >
                {addToCalendarHandler ? 'Add to Calendar' : 'Apply Recurrence'}
              </Button>
            </Cluster>
          </Cluster>
        </Template>
      </Dialog>
    );
  },
);

export default EpisodeRecurrence;
