import CampaignListPage from '../views/campaign/list';
import CampaignEditPage from '../views/campaign/edit';
import {campaignPermissions} from 'views/campaign/permissions';

import {IRouteItem} from 'routes';

const paths = {
  campaignListPage: '/campaign',
  campaignEditPage: '/campaign/:id',
};

const routes: IRouteItem[] = [
  {
    name: 'Campaigns',
    path: paths.campaignListPage,
    icon: 'campaign',
    component: CampaignListPage,
    showInSideBar: true,
    breadcrumb: {items: [{text: 'Campaigns', route: paths.campaignListPage}]},
    requiredPermissions: campaignPermissions.CAMPAIGN_VIEW.enabled,
  },
  {
    name: 'Campaign Single',
    path: paths.campaignEditPage,
    component: CampaignEditPage,
    breadcrumb: {
      items: [
        {text: 'Campaigns', route: paths.campaignListPage},
        {text: 'Edit Campaign', route: paths.campaignEditPage},
      ],
    },
    requiredPermissions: campaignPermissions.CAMPAIGN_VIEW.enabled,
  },
];

export default {
  paths,
  routes,
};
