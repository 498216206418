import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

import {RootState} from 'app/store';
import {IChannel, IPublishTimelineDraftsParams, ITimelineDraftsParams} from 'models/channels';

import {IListPayload} from 'models/generic';

export interface IChannelQuery {
  limit?: number;
  kidsMode?: boolean;
  activeRegion?: string;
  offset?: number;
  sort?: string;
  name?: string;
  number?: number;
  samsungServiceId?: string;
  activeRegions?: string[];
  published?: boolean;
  unpublished?: boolean;
  archived?: boolean;
  ignoreArchiveFlag?: boolean;
  deviceIncluded?: string[];
  deviceExcluded?: string[];
}

export const channelsApi = createApi({
  reducerPath: 'channelsApi',
  tagTypes: ['Channels', 'ChannelsSearch', 'Channel'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/v1/',
    prepareHeaders(headers, {getState}) {
      const token = (getState() as RootState).user.jwt;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    search: builder.query<IListPayload<IChannel>, IChannelQuery>({
      query: ({offset = 0, limit = 10, sort = 'number:asc'}) => ({
        url: `channels?offset=${offset}&limit=${limit}&sort=${sort}`,
        method: 'GET',
      }),
      providesTags: ['ChannelsSearch'],
    }),
    findById: builder.query<IChannel, string>({
      query: (id: string) => ({
        url: `channels/${id}`,
        method: 'GET',
      }),
      providesTags: ['Channels'],
    }),
    insert: builder.mutation<IChannel, Partial<IChannel>>({
      query: channel => ({
        url: `channels`,
        method: 'POST',
        body: channel,
      }),
      invalidatesTags: ['Channels', 'ChannelsSearch'],
    }),
    update: builder.mutation<IChannel, {id: string; channel: Partial<IChannel>; fields: string[]}>({
      query: ({id, channel, fields}) => ({
        url: `channels/${id}`,
        method: 'PUT',
        body: {
          id,
          channelParams: channel,
          updateFields: fields,
        },
      }),
      invalidatesTags: ['Channel', 'ChannelsSearch', 'Channels'],
    }),
    bulkUpdate: builder.mutation<
      IChannel,
      {devices: string[]; includeGroup: string[]; excludeGroup: string[]; removeGroup: string[]}
    >({
      query: ({devices, includeGroup, excludeGroup, removeGroup}) => ({
        url: `channels/bulk-update-devices`,
        method: 'POST',
        body: {
          devices,
          includeGroup,
          excludeGroup,
          removeGroup,
        },
      }),
      invalidatesTags: ['Channel', 'ChannelsSearch', 'Channels'],
    }),
    delete: builder.mutation<IChannel, string>({
      query: (id: string) => ({
        url: `channel/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Channels', 'ChannelsSearch'],
    }),
    find: builder.query<IListPayload<IChannel>, IChannelQuery>({
      query: (params: IChannelQuery) => {
        const search = new URLSearchParams();
        const queryParams = {
          sort: params.sort ?? 'name:asc',
          offset: params.offset ?? 0,
          limit: params.limit ?? 2000,
          activeRegion: params.activeRegion || params.activeRegions,
          channelName: params.name,
          channelNumber: params.number,
          samsungId: params.samsungServiceId,
          archived: params.archived,
          kidsMode: params.kidsMode,
          deviceIncluded: params.deviceIncluded,
          deviceExcluded: params.deviceExcluded,
          publishedState:
            params.published && params.unpublished
              ? undefined
              : params.published
              ? true
              : params.unpublished
              ? false
              : undefined,
        };

        Object.entries(queryParams).forEach(([key, value]) => {
          if (Array.isArray(value) && value.length > 0) {
            value.forEach(v => search.append(key, `${v}`));
          } else if (value != null && value !== '' && !Array.isArray(value)) {
            search.set(key, `${value}`);
          }
        });

        return {
          url: `channels?${search}`,
          method: 'GET',
        };
      },
      providesTags: ['Channels'],
    }),

    findLite: builder.query<IListPayload<IChannel>, IChannelQuery>({
      query: (params: IChannelQuery) => {
        const search = new URLSearchParams();
        const queryParams = {
          sort: params.sort ?? 'name:asc',
          offset: params.offset ?? 0,
          limit: params.limit ?? 2000,
          activeRegion: params.activeRegion || params.activeRegions,
          channelName: params.name,
          channelNumber: params.number,
          samsungId: params.samsungServiceId,
          archived: params.ignoreArchiveFlag ? undefined : params.archived || 'false',
          kidsMode: params.kidsMode,
          publishedState:
            params.published && params.unpublished
              ? undefined
              : params.published
              ? true
              : params.unpublished
              ? false
              : undefined,
        };
        Object.entries(queryParams).forEach(([key, value]) => {
          if (Array.isArray(value) && value.length > 0) {
            value.forEach(v => search.append(key, `${v}`));
          } else if (!!value && !Array.isArray(value)) {
            search.set(key, `${value}`);
          }
        });
        return {
          url: `channels/lookup?${search}`,
          method: 'GET',
        };
      },
      providesTags: ['Channels'],
    }),

    updateTimelineDrafts: builder.mutation<void, ITimelineDraftsParams>({
      query: params => ({
        url: `channels/${params.id}/timeline-drafts`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['Channel', 'ChannelsSearch', 'Channels'],
    }),

    publishTimelineDrafts: builder.mutation<void, IPublishTimelineDraftsParams>({
      query: params => ({
        url: `channels/${params.id}/timelines`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['Channel', 'ChannelsSearch', 'Channels'],
    }),
  }),
});

export const {
  useFindQuery,
  useSearchQuery,
  useFindByIdQuery,
  useInsertMutation,
  useUpdateMutation,
  useBulkUpdateMutation,
  useDeleteMutation,
  useLazyFindQuery,
  useLazyFindLiteQuery,
  useFindLiteQuery,
  useUpdateTimelineDraftsMutation,
  usePublishTimelineDraftsMutation,
} = channelsApi;
