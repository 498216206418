import * as React from 'react';
import {Box, Button, Cluster, FormItem, Select, Stack, TextInput, useValidateForm} from '@pluto-tv/assemble';

import {ADSPARX_AD_POLICIES, ISource, SOURCE_TYPES, TAdSparxAdPolicy, TSourceType} from 'models/clips';

import {clipSourceValidator} from '../../validators';

export interface IClipSourceFormProps {
  onCancel(): void;
  isNew?: boolean;
  value?: Partial<ISource>;
  onSave: (savedObj: ISource) => void;
}

const ClipSourceForm = React.memo(({isNew = false, onCancel, value, onSave}: IClipSourceFormProps) => {
  const {
    model,
    onBlur,
    setFields,
    form,
    onChange,
    setModel,
    state: formState,
  } = useValidateForm<ISource>(clipSourceValidator);

  React.useEffect(() => {
    if (isNew) {
      setModel({
        type: undefined,
        file: undefined,
        adSparxAdPolicy: undefined,
      });
    } else {
      setModel(value || {});
    }
  }, [value, isNew, setModel]);

  return (
    <Box padding='small' background='charcoal'>
      <Stack space='small'>
        <FormItem {...form.type} onBlur={() => onBlur('type', false)}>
          <Select
            id='clipSourceFormType'
            placeholder='Select a type'
            value={{label: model.type || ''}}
            options={SOURCE_TYPES.map(t => ({label: t}))}
            onChange={val =>
              setFields({
                type: val.label as TSourceType,
                adSparxAdPolicy: undefined,
              })
            }
          />
        </FormItem>
        <FormItem {...form.file} onBlur={() => onBlur('file')}>
          <TextInput
            id='clipSourceFormUrl'
            placeholder='Enter URL'
            value={model.file}
            onChange={val => onChange('file', val)}
          />
        </FormItem>
        {model.type === 'liveAdsparx' && (
          <FormItem {...form.adSparxAdPolicy} onBlur={() => onBlur('adSparxAdPolicy', false)}>
            <Select
              placeholder='Select a policy'
              value={{label: model.adSparxAdPolicy || ''}}
              options={ADSPARX_AD_POLICIES.map(t => ({label: t}))}
              onChange={val => setFields({adSparxAdPolicy: val.label as TAdSparxAdPolicy})}
            />
          </FormItem>
        )}
        <Cluster justify='space-between'>
          <div></div>
          <Cluster space='small'>
            <Button ghost={true} onClick={onCancel}>
              Cancel
            </Button>
            <Button
              id='clipSourceFormSubmitButton'
              type='primary'
              onClick={() => onSave(model as ISource)}
              state={!formState.isValid || !formState.isDirty ? 'disabled' : ''}
            >
              {isNew ? '+ Add' : 'Update'}
            </Button>
          </Cluster>
        </Cluster>
      </Stack>
    </Box>
  );
});

ClipSourceForm.displayName = 'ClipSourceForm';
export default ClipSourceForm;
