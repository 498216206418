import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

import {RootState} from 'app/store';
import {IAgeRange} from 'models/ageRanges';

export const ageRangesApi = createApi({
  reducerPath: 'ageRangesApi',
  tagTypes: ['AgeRanges'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/v1/',
    prepareHeaders(headers, {getState}) {
      const token = (getState() as RootState).user.jwt;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    find: builder.query<IAgeRange[], void>({
      query: () => ({
        url: `age-ranges`,
        method: 'GET',
      }),
      providesTags: ['AgeRanges'],
    }),
  }),
});

export const {useFindQuery} = ageRangesApi;
