import * as React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Button, Cluster, Dialog, Heading, Help, Icon, Paragraph, Stack, Template} from '@pluto-tv/assemble';

interface IConfirmProps {
  ignoreHashChanges?: boolean;
  when?: boolean;
  hasFlaggedFields?: boolean;
  onSave?(ev?: any): Promise<void> | void;
  isValid?: boolean;

  attemptToLeave?: (leavingThePage?: boolean) => void;
  extraMessage?: string;
  cancelAttemptToLeave?: () => void;
}

export interface IConfirmRef {
  doRouteChange(): void;
}

const ConfirmRouteChange = React.memo(
  React.forwardRef<IConfirmRef, IConfirmProps>(
    (
      {
        when,
        onSave,
        hasFlaggedFields,
        isValid,
        ignoreHashChanges = false,
        attemptToLeave,
        extraMessage,
        cancelAttemptToLeave,
      }: IConfirmProps,
      ref,
    ) => {
      const history = useHistory();
      const location = useLocation();

      const [isOpen, setIsOpen] = React.useState(false);
      const [isSaving, setIsSaving] = React.useState(false);
      const [currentPath, setCurrentPath] = React.useState('');

      const unblockRef = React.useRef<any>();

      React.useEffect(() => {
        window.onpopstate = (e: PopStateEvent) => {
          e.stopPropagation();
          e.stopImmediatePropagation();
          e.preventDefault();
        };

        return () => {
          window.onpopstate = null;
        };
      }, []);

      React.useEffect(() => {
        unblockRef.current = history.block(loc => {
          if (when) {
            const samePathname = loc.pathname === location.pathname;
            const sameHash = loc.hash === location.hash;

            if (ignoreHashChanges && samePathname && !sameHash) {
              return;
            }

            if (attemptToLeave) {
              attemptToLeave(true);
            }

            setCurrentPath(loc.pathname);
            setIsOpen(true);
            return false;
          }
        });
        return () => {
          unblockRef.current && unblockRef.current();
        };
      }, [location, ignoreHashChanges, when, history, attemptToLeave]);

      const doRouteChange = () => {
        if (unblockRef) {
          unblockRef.current();
        }

        history.push(currentPath);
        setIsOpen(false);
      };

      React.useImperativeHandle(ref, () => ({
        doRouteChange,
      }));

      const save = async () => {
        if (!onSave) {
          setIsOpen(false);
          return;
        }

        if (!hasFlaggedFields) {
          try {
            setIsSaving(true);
            await onSave();
            doRouteChange();
          } catch (e) {
            setIsOpen(false);
          } finally {
            setIsSaving(false);
          }
        } else {
          setIsOpen(false);
          onSave();
        }
      };

      const handleOnClose = () => {
        setIsOpen(false);
        if (cancelAttemptToLeave) {
          cancelAttemptToLeave();
        }
      };

      return (
        <Dialog isOpen={isOpen} onClose={handleOnClose} width='37.5rem'>
          <Template label='header'>
            <Heading level='h2'>
              <Icon icon='warning' color='warning' space='xxsmall'>
                Unsaved Changes
              </Icon>
            </Heading>
          </Template>
          <Template label='body'>
            <Stack space='medium'>
              <Paragraph color='secondary'>
                You have unsaved changes. Are you sure you want to leave this page without saving?
              </Paragraph>
              {!isValid && (
                <Icon icon='info' size='medium' iconAlign='center' space='xxsmall' color='info'>
                  <Help state='info'>
                    The `Save and Proceed` button may be disabled if required fields are not valid.
                  </Help>
                </Icon>
              )}
              {extraMessage && (
                <Icon icon='info' size='medium' iconAlign='start' space='small' color='info'>
                  <Help state='info'>{extraMessage}</Help>
                </Icon>
              )}
            </Stack>
          </Template>
          <Template label='footer'>
            <Cluster justify='space-between'>
              <Button
                type='solid'
                state={isSaving ? 'disabled' : ''}
                size='small'
                onClick={() => {
                  handleOnClose();
                  doRouteChange();
                }}
              >
                Leave Without Saving
              </Button>
              <Cluster space='small'>
                <Button ghost={true} state={isSaving ? 'disabled' : ''} size='small' onClick={handleOnClose}>
                  Review Changes
                </Button>
                {onSave && (
                  <Button
                    size='small'
                    state={!isValid ? 'disabled' : isSaving ? 'thinking' : ''}
                    type='primary'
                    onClick={save}
                  >
                    Save and Proceed
                  </Button>
                )}
              </Cluster>
            </Cluster>
          </Template>
        </Dialog>
      );
    },
  ),
);

ConfirmRouteChange.displayName = 'ConfirmRouteChange';

export default ConfirmRouteChange;
