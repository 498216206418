import {IValidatorField} from '@pluto-tv/assemble';
import {isNumber} from 'lodash-es';
import {DateTime as luxon} from 'luxon';

import {IEditRunLimits, ILicensedTitleSearch, IRunLimitLicensed, IRunLimitObj} from 'models/licensedTitles';

export const searchLicensedTitlesValidator: IValidatorField<ILicensedTitleSearch>[] = [
  {
    name: 'regions',
    label: 'Active Region(s)',
  },
  {
    name: 'partner',
    label: 'Partner',
  },
  {
    name: 'title',
    label: 'Title',
  },
  {
    name: 'programmingType',
    label: 'Programming Type',
  },
];

export const createRunLimitValidator: IValidatorField<IRunLimitLicensed>[] = [
  {
    name: 'activeMonth',
    label: 'Active Month',
    required: true,
    validators: [
      (activeMonth: string, model: Partial<IRunLimitLicensed>): string | undefined => {
        if (!activeMonth) {
          return 'Active Month is required';
        }

        if (model.activeYear) {
          const now = luxon.now();
          const selected = luxon.fromFormat(`${activeMonth} 1, ${model.activeYear}`, 'DD').endOf('month');

          if (now > selected) {
            return 'Cannot modify data in the past';
          }
        }
      },
    ],
  },
  {
    name: 'activeYear',
    label: 'Active year',
    required: true,
    validators: [
      (activeYear: number, model: Partial<IRunLimitLicensed>): string | undefined => {
        if (!isNumber(activeYear)) {
          return 'Active Year is required';
        }

        if (model.activeMonth) {
          const now = luxon.now();
          const selected = luxon.fromFormat(`${model.activeMonth} 1, ${activeYear}`, 'DD').endOf('month');

          if (now > selected) {
            return 'Cannot modify data in the past';
          }
        }
      },
    ],
  },
];

export const editRunLimitValidator: IValidatorField<IRunLimitLicensed | IRunLimitObj>[] = [
  {
    name: 'maxRunsPeriod',
    label: 'Max Number of Runs Allowed Per Month',
  },
  {
    name: 'licenseFee',
    label: 'License Fee',
    validators: [
      (licenseFee: number): string | undefined => {
        if (!isNumber(licenseFee)) {
          return;
        } else if (licenseFee < -1 || licenseFee > 5) {
          return 'License Fee must be between -1 and 5';
        }
      },
    ],
  },
];

export const licensedTitleValidator: IValidatorField<IEditRunLimits>[] = [
  {
    name: 'runLimits',
    validators: [
      (runLimits: IRunLimitObj[]): string | undefined => {
        let errorMsg = '';

        runLimits.forEach(runLimit => {
          const now = luxon.now();
          const card = luxon.fromSeconds(runLimit.endDate.seconds);

          // Don't validate issues from the past
          if (now > card) {
            return;
          }

          if (isNumber(runLimit.licenseFee) && (runLimit.licenseFee < -1 || runLimit.licenseFee > 5)) {
            errorMsg = 'License Fee must be between -1 and 5';
          }
        });

        if (errorMsg.length) {
          return errorMsg;
        }
      },
    ],
  },
];
