import * as React from 'react';
import {Box, Button, Cluster, Heading, Icon, Paragraph, Sidebar, Stack} from '@pluto-tv/assemble';

export interface IActionConfirmation {
  message?: string;
  cancelButtonFunction(): void;
  proceedButtonFunction(): void;
}

const ActionConfirmation = React.memo(
  ({
    message = 'Are you sure you want to do this?',
    cancelButtonFunction,
    proceedButtonFunction,
  }: IActionConfirmation) => {
    return (
      <Box id='areYouSureContent' padding='small' background='charcoal'>
        <Sidebar gap='large'>
          <Icon icon='warning' size='xxxlarge' />
          <Box paddingTop='xsmall'>
            <Stack space='large'>
              <Heading level='h3'>Are you sure?</Heading>
              <Paragraph>{message}</Paragraph>
              <Cluster justify='space-between'>
                <div></div>
                <Cluster space='small'>
                  <Button id='cancelButton' ghost={true} onClick={() => cancelButtonFunction()}>
                    Cancel
                  </Button>
                  <Button id='yesProceedButton' type='primary' onClick={() => proceedButtonFunction()}>
                    Yes, Proceed
                  </Button>
                </Cluster>
              </Cluster>
            </Stack>
          </Box>
        </Sidebar>
      </Box>
    );
  },
);

ActionConfirmation.displayName = 'ActionConfirmation';
export default ActionConfirmation;
