export const clipPermissions = {
  CLIP_VIEW: {
    enabled: ['clip_view', 'clip_edit', 'clip_create'],
  },
  CLIP_CREATE: {
    enabled: ['clip_create'],
  },
  CLIP_EDIT: {
    enabled: ['clip_edit'],
    disabled: ['clip_view', 'clip_delete', 'clip_create'],
  },
  CLIP_DELETE: {
    enabled: ['clip_delete'],
  },
};
