import {ISeriesListResult} from 'models/series';
import {IRowStateAndMessage, isAvailWindowWithinRange} from 'views/programming/vodCollections/utils';

export type StateColorType = 'success' | 'error' | 'warning' | 'info' | 'focus' | '';
export interface IStateMsgList {
  color: StateColorType;
  icon: StateColorType;
  label: string;
}

export const getSeriesListItemState = (item: ISeriesListResult): IRowStateAndMessage[] => {
  const ifNoAvailWindows = !item['availabilityWindows'] || !item.availabilityWindows.AVOD;
  const outsideWindow = !item.availabilityWindows?.AVOD?.find(av => isAvailWindowWithinRange(av));
  const contentIconAndMessage = [] as IRowStateAndMessage[];

  if (item.published === false) {
    contentIconAndMessage.push({
      state: 'warning',
      message: 'The series is not published.',
    });
  }

  if (item.type === 'film' && item.episodeCount > 1) {
    contentIconAndMessage.push({
      state: 'warning',
      message: 'The series (Film) has multiple episodes. Please review.',
    });
  }

  if (ifNoAvailWindows || outsideWindow) {
    contentIconAndMessage.push({
      state: 'warning',
      message: 'The series has no availability, or is being used outside of its availability window.',
    });
  }

  return contentIconAndMessage;
};
