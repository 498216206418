import {IValidatorField} from '@pluto-tv/assemble';

import {IChannelCatalogQuery} from 'models/channelCatalog';

import {ICfaasIntegration, IChannel, IGracenoteIntegration} from 'models/channels';

export const channelSearchValidator: IValidatorField<IChannel>[] = [
  {
    name: 'name',
    label: 'Channel Name',
  },
  {
    name: 'number',
    label: 'Channel Number',
  },
  {
    name: 'activeRegions',
    label: 'Active Region',
  },
  {
    name: 'deviceIncluded',
    label: 'Devices Included',
  },
  {
    name: 'deviceExcluded',
    label: 'Devices Excluded',
  },
  {
    name: 'samsungServiceId',
    label: 'Samsung Service ID',
  },
  {
    name: 'published',
    label: 'Published',
  },
  {
    name: 'unpublished',
    label: 'Unpublished',
  },
  {
    name: 'archived',
    label: 'Archived',
  },
  {
    name: 'kidsMode',
    label: 'Kids Mode',
  },
];

export const channelDeviceUpdateValidator: IValidatorField<IChannel>[] = [
  {
    name: 'distribution',
  },
  {
    name: 'regionFilter',
  },
];

export const channelCopyDistributionCreateValidator: IValidatorField<IChannel>[] = [
  {
    name: 'name',
    label: 'Channel Name',
    required: true,
    validators: [
      (name: string): string | undefined => {
        if (!name || !name.trim().length) {
          return 'Channel Name is required';
        } else if (name && name.trim().length > 100) {
          return 'Channel Name must be less than 100 characters';
        }
      },
    ],
  },
  {
    name: 'categories',
    label: 'Main Categories',
    required: true,
    validators: [
      (categories: string[]): string | undefined => {
        if (!categories || !categories.length) {
          return 'Main Categories is required';
        }
      },
    ],
  },
  {
    name: 'category',
    label: 'Legacy Category',
    required: true,
    validators: [
      (legacyCategory: string): string | undefined => {
        if (!legacyCategory) {
          return 'Legacy Category required';
        }
      },
    ],
  },
  {
    name: 'metadataLanguage',
    label: 'Metadata Language',
    required: true,
    validators: [
      (metadataLanguage: string): string | undefined => {
        if (!metadataLanguage) {
          return 'Metadata Language is required';
        }
      },
    ],
  },
  {
    name: 'summary',
    label: 'Short Description',
    required: true,
    validators: [
      (summary: string): string | undefined => {
        if (!summary || !summary.trim().length) {
          return 'Short Description is required';
        } else if (summary.length >= 255) {
          return 'Short Description must be less than 255 characters.';
        }
      },
    ],
  },
];

const channelSimpleDetails: IValidatorField<IChannel>[] = [
  ...channelCopyDistributionCreateValidator,
  {
    name: 'slug',
    label: 'Channel Slug',
    required: true,
    validators: [
      (slug: string): string | undefined => {
        if (!slug || !slug.trim().length) {
          return 'Channel Slug is required';
        } else if (slug && slug.trim().length > 100) {
          return 'Channel Slug must be less than 100 characters.';
        }
      },
    ],
  },
  {
    name: 'activeRegion',
    label: 'Active Region',
    required: true,
    validators: [
      (region: string): string | undefined => {
        if (!region) {
          return 'Active Region is required';
        }
      },
    ],
  },
];

const channelCreateFieldsValidator: IValidatorField<IChannel>[] = [
  {
    name: 'name',
    label: 'Channel Name',
    required: true,
    validators: [
      (name: string): string | undefined => {
        if (!name || !name.trim().length) {
          return 'Channel Name is required';
        } else if (name && name.trim().length > 100) {
          return 'Channel Name must be less than 100 characters';
        }
      },
    ],
  },
  {
    name: 'activeRegion',
    label: 'Active Region',
    required: true,
    validators: [
      (region: string): string | undefined => {
        if (!region) {
          return 'Active Region is required';
        }
      },
    ],
  },
  {
    name: 'categories',
    label: 'Main Categories',
    required: true,
    validators: [
      (categories: string[], model: Partial<IChannel>): string | undefined => {
        if (!model.activeRegion) {
          return 'Select an Active Region';
        } else if (!categories || !categories.length) {
          return 'Main Categories is required';
        }
      },
    ],
  },
  {
    name: 'category',
    label: 'Legacy Category',
    required: true,
    validators: [
      (legacyCategory: string): string | undefined => {
        if (!legacyCategory) {
          return 'Legacy Category required';
        }
      },
    ],
  },
  {
    name: 'metadataLanguage',
    label: 'Metadata Language',
    required: true,
    validators: [
      (metadataLanguage: string): string | undefined => {
        if (!metadataLanguage) {
          return 'Metadata Language is required';
        }
      },
    ],
  },
  {
    name: 'summary',
    label: 'Short Description',
    required: true,
    validators: [
      (summary: string): string | undefined => {
        if (!summary || !summary.trim().length) {
          return 'Short Description is required';
        } else if (summary.length >= 255) {
          return 'Short Description must be less than 255 characters.';
        }
      },
    ],
  },
];

export const channelDetailsValidator: IValidatorField<IChannel>[] = [
  ...channelSimpleDetails,
  {
    name: 'number',
    label: 'Channel Number',
    required: true,
    validators: [
      (number: string): string | undefined => {
        if (!Number.isInteger(number)) {
          return 'Number is required';
        }
      },
    ],
  },
  {
    name: 'description',
    label: 'Long Description',
    required: true,
    validators: [
      (longDescription: string): string | undefined => {
        if (!longDescription || !longDescription.trim().length) {
          return 'Long Description is required';
        }
      },
    ],
  },
  {
    name: 'visibility',
    label: 'Visibility',
  },
  {
    name: 'kidsMode',
    label: 'Kids Mode',
  },
  {
    name: 'plutoOfficeOnly',
    label: 'Office Only',
  },
  {
    name: 'supportedChannelCaptionLanguages',
    label: 'Supported Channel Caption Languages',
  },
  {
    name: 'tmsid',
    label: 'Gracenote Channel PID',
  },
  {
    name: 'notes',
    label: 'Notes',
  },
  {
    name: 'episodesQueue',
    label: 'Episodes Queue',
    validators: [
      (episodesQueue: [], model: Partial<IChannel>): string | undefined => {
        if (model.autoSchedule && !episodesQueue?.length) {
          // Not displaying an error in the form, because a Toast is displayed instead
          return ' ';
        }
      },
    ],
  },
];

export const channelDistributionValidator: IValidatorField<IChannel>[] = [
  ...channelSimpleDetails,
  {
    name: 'dmaDistribution',
    label: 'DMA',
  },
  {
    name: 'regionFilter',
    label: 'Territories',
    validators: [
      (newValues: string[], model: Partial<IChannel>): string | undefined => {
        if (!newValues?.length) return;
        const field = model.regionFilter;
        if ((!field?.exclude || !field?.exclude.length) && (!field?.include || !field?.include.length)) {
          return `Included Territories OR Excluded Territories is required`;
        }
      },
    ],
  },
  {
    name: 'distribution',
    label: 'Devices',
    validators: [
      (newValues: string[], model: Partial<IChannel>): string | undefined => {
        if (!newValues?.length) return;
        const field = model.distribution;
        if ((!field?.exclude || !field?.exclude.length) && (!field?.include || !field?.include.length)) {
          return `Included Devices OR Excluded Devices is required`;
        }
      },
    ],
  },
  {
    name: 'customReferences',
    label: 'Custom References',
  },
  {
    name: 'samsungChannelProperties',
    label: 'Samsung TV+',
  },
];

export const channelGracenoteIntegrationValidator: IValidatorField<IGracenoteIntegration>[] = [
  {
    name: 'enabled',
    label: 'Enabled',
  },
  {
    name: 'dynamicClip',
    label: 'Clip ID',
    validators: [
      (dynamicClip: string, model: Partial<IGracenoteIntegration>): string | undefined => {
        if (model.enabled && !dynamicClip) {
          return 'Clip ID is required';
        }
      },
    ],
  },
  {
    name: 'genre',
    label: 'Genre',
    validators: [
      (genre: string, model: Partial<IGracenoteIntegration>): string | undefined => {
        if (model.enabled && !genre) {
          return 'Genre is required';
        }
      },
    ],
  },
  {
    name: 'subGenre',
    label: 'Sub-Genre',
    validators: [
      (subgenre: string, model: Partial<IGracenoteIntegration>): string | undefined => {
        if (model.enabled && !subgenre) {
          return 'Sub-Genre is required';
        }
      },
    ],
  },
  {
    name: 'category',
    label: 'Category',
    validators: [
      (category: string, model: Partial<IGracenoteIntegration>): string | undefined => {
        if (model.enabled && !category) {
          return 'Category is required';
        }
      },
    ],
  },
  {
    name: 'subCategory',
    label: 'Sub-Category',
    validators: [
      (subCategory: string, model: Partial<IGracenoteIntegration>): string | undefined => {
        if (model.enabled && !subCategory) {
          return 'Sub-Category is required';
        }
      },
    ],
  },
];

export const channelCfaasIntegrationValidator: IValidatorField<ICfaasIntegration>[] = [
  {
    name: 'enabled',
    label: 'Enabled',
  },
  {
    name: 'sourceId',
    label: 'Source Id',
  },
  {
    name: 'clipId',
    label: 'Clip ID',
    validators: [
      (dynamicClip: string, model: Partial<IGracenoteIntegration>): string | undefined => {
        if (model.enabled && !dynamicClip) {
          return 'Clip ID is required';
        }
      },
    ],
  },
  {
    name: 'genre',
    label: 'Genre',
    validators: [
      (genre: string, model: Partial<IGracenoteIntegration>): string | undefined => {
        if (model.enabled && !genre) {
          return 'Genre is required';
        }
      },
    ],
  },
  {
    name: 'subGenre',
    label: 'Sub-Genre',
    validators: [
      (subgenre: string, model: Partial<IGracenoteIntegration>): string | undefined => {
        if (model.enabled && !subgenre) {
          return 'Sub-Genre is required';
        }
      },
    ],
  },
  {
    name: 'category',
    label: 'Category',
    validators: [
      (category: string, model: Partial<IGracenoteIntegration>): string | undefined => {
        if (model.enabled && !category) {
          return 'Category is required';
        }
      },
    ],
  },
  {
    name: 'subCategory',
    label: 'Sub-Category',
    validators: [
      (subCategory: string, model: Partial<IGracenoteIntegration>): string | undefined => {
        if (model.enabled && !subCategory) {
          return 'Sub-Category is required';
        }
      },
    ],
  },
];

export const channelSettingsValidator: IValidatorField<IChannel>[] = [
  ...channelSimpleDetails,
  {
    name: 'autoEpgSource',
    label: 'Integration Type',
  },
  {
    name: 'seoAliases',
    label: 'SEO Aliases',
  },
  {
    name: 'tags',
    label: 'Tags',
    validators: [
      (tags: string[]): string | undefined => {
        if (tags.length < 1) {
          return 'Must be at least 1 tag';
        }
      },
    ],
  },
  {
    name: 'logoOverlayEnable',
    label: 'Logo Overlay Enable',
  },
  {
    name: 'googleDai',
    label: 'Google DAI + AES 128 Enabled',
  },
  {
    name: 'autoSchedule',
    label: 'Enabled',
    validators: [
      (autoSchedule: boolean, model: Partial<IChannel>): string | undefined => {
        if (autoSchedule && !model.episodesQueue?.length) {
          // Not displaying an error in the form, because a Toast is displayed instead
          return '  ';
        }
      },
    ],
  },
  {
    name: 'autoScheduleDays',
    label: 'Auto Schedule Days',
    validators: [
      (number: number, model: Partial<IChannel>): string | undefined => {
        if (model.autoSchedule && !Number.isInteger(number)) {
          return 'Auto Schedule Days is required';
        } else if (Number.isInteger(number) && number < 1) {
          return 'Cannot be less than 1';
        } else if (Number.isInteger(number) && number > 30) {
          return 'Cannot be greater than 30';
        }
      },
    ],
  },
  {
    name: 'gracenoteIntegration',
  },
  {
    name: 'cfaasIntegration',
  },
];

export const channelEditValidator: IValidatorField<IChannel>[] = [
  {
    name: 'published',
    label: 'Published',
    required: true,
  },
  {
    name: 'archived',
    label: 'Archived',
    required: true,
  },
];

export const channelCreateValidator: IValidatorField<IChannel>[] = [...channelCreateFieldsValidator];

export const channelArtworksValidator: IValidatorField<IChannel>[] = [
  {name: 'background'},
  {name: 'browse'},
  {name: 'partnerLogo'},
  {name: 'tile'},
  {name: 'tileGrayscale'},
  {name: 'logo'},
  {name: 'logoAlt'},
  {name: 'thumbnail'},
  {name: 'thumbnailAlt'},
  {name: 'featuredImage'},
  {name: 'featuredImageAlt'},
  {name: 'colorLogoPNG'},
  {name: 'colorLogoPNGAlt'},
  {name: 'colorLogoSVG'},
  {name: 'colorLogoSVGAlt'},
  {name: 'solidLogoPNG'},
  {name: 'solidLogoPNGAlt'},
  {name: 'solidLogoSVG'},
  {name: 'solidLogoSVGAlt'},
];

export const channelCatalogValidator: IValidatorField<IChannelCatalogQuery>[] = [
  {
    name: 'name',
    label: 'Episode Name',
  },
  {
    name: 'updatedAt',
    label: 'Date Modified Range',
  },
  {
    name: 'rating',
    label: 'Rating',
  },
  {
    name: 'genre',
    label: 'Genre',
  },
  {
    name: 'season',
    label: 'Season Number',
    validators: [
      (season: number): string | undefined => {
        if (season === undefined) return;
        if (Number.isFinite(season) && season < 1) {
          return 'Season cannot be less than 1';
        } else if (!Number.isFinite(season)) {
          return 'Season must be a valid number';
        }
      },
    ],
  },
];

export const channelProgramValidator: IValidatorField<IChannel>[] = [
  {
    name: 'published',
    label: 'Published',
    required: true,
  },
];
