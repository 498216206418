import * as React from 'react';
import {IChannelCatalogItem} from 'models/channelCatalog';

export const useChannelCatalogQueueDuration = (
  queueDuration: number,
  movedToLibrary: IChannelCatalogItem[] = [],
  movedToQueue: IChannelCatalogItem[] = [],
  movedToLibraryDuringFilter: IChannelCatalogItem[] = [],
  movedToQueueDuringFilter: IChannelCatalogItem[] = [],
  isSearchActive: boolean,
): {duration: number} => {
  const duration = React.useMemo(() => {
    if (isSearchActive) {
      const totalMovedToLibraryDuringFilterDuration = movedToLibraryDuringFilter.reduce(
        (acc, item) => acc + item.duration,
        0,
      );
      const totalMovedToQueueDuringFilterDuration = movedToQueueDuringFilter.reduce(
        (acc, item) => acc + item.duration,
        0,
      );
      return queueDuration + totalMovedToQueueDuringFilterDuration - totalMovedToLibraryDuringFilterDuration;
    }
    const totalMovedToLibraryDuration = movedToLibrary.reduce((acc, item) => acc + item.duration, 0);
    const totalMovedToQueueDuration = movedToQueue.reduce((acc, item) => acc + item.duration, 0);
    return queueDuration + totalMovedToQueueDuration - totalMovedToLibraryDuration;
  }, [
    isSearchActive,
    movedToLibrary,
    movedToLibraryDuringFilter,
    movedToQueue,
    movedToQueueDuringFilter,
    queueDuration,
  ]);

  return {
    duration,
  };
};
