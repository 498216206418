import * as React from 'react';
import {capitalize, isArray} from 'lodash-es';
import {Box, Accordion, Template, Cluster, Stack, Heading, Checkbox} from '@pluto-tv/assemble';
import {TPermissionChangeCallback} from '../utils';
import PermissionsSubGroup from './permissionsSubGroup';
import PermissionItem from './permissionsItem';

export interface IPermissionGroupProps {
  selectedPermissions: string[];
  name: string;
  content: any;
  onPermissionChange: TPermissionChangeCallback;
  readonly: boolean;
}

export default ({
  selectedPermissions,
  name,
  content,
  onPermissionChange,
  readonly = false,
}: IPermissionGroupProps): JSX.Element => {
  const allGroupPermissions = Object.values(content[name])
    .map((p: any) => (Array.isArray(p) ? Object.values(p).map((p: any) => p.name) : p.name))
    .flat();
  const selectedGroupPermissions = selectedPermissions.filter(permission =>
    allGroupPermissions.find(p => p === permission),
  );

  const featureLength = React.useMemo((): number => {
    if (isArray(content[name])) {
      return content[name].length;
    }

    let features = 0;

    Object.keys(content[name]).forEach(sub => (features += content[name][sub].length));

    return features;
  }, [content, name]);

  return (
    <Box background='slate' key={name}>
      <Accordion triggerPadding='small'>
        <Template label='trigger'>
          <Cluster justify='space-between' align='center' fullWidth={true}>
            <Stack space='xxsmall'>
              <Cluster space='xxxsmall'>
                <Heading level='h4'>
                  {
                    // Capitalize if is not an acronym
                    (name.match(/[A-Z]/g) || []).length > 1 ? name : capitalize(name)
                  }
                </Heading>
              </Cluster>
              <Heading level='h5' color='secondary'>
                {featureLength} features
              </Heading>
            </Stack>
            <Checkbox
              label='Enable All'
              onChange={enable => onPermissionChange(allGroupPermissions, enable)}
              value={selectedGroupPermissions.length === allGroupPermissions.length}
              indeterminate={
                selectedGroupPermissions.length > 0 && selectedGroupPermissions.length !== allGroupPermissions.length
              }
              state={readonly ? 'disabled' : ''}
            />
          </Cluster>
        </Template>
        <Template label='body'>
          <Box background='onyx' paddingX='large' paddingY='xlarge'>
            <Stack space='large'>
              {Object.keys(content[name]).map(entryItem =>
                Array.isArray(content[name][entryItem]) ? (
                  <PermissionsSubGroup
                    key={entryItem}
                    selectedPermissions={selectedGroupPermissions}
                    name={entryItem}
                    content={content[name][entryItem]}
                    onPermissionChange={(permissions: string[], enable: boolean) =>
                      onPermissionChange(permissions, enable)
                    }
                    readonly={readonly}
                  />
                ) : (
                  <PermissionItem
                    key={content[name][entryItem].name}
                    selectedPermissions={selectedGroupPermissions}
                    name={content[name][entryItem].name}
                    displayName={content[name][entryItem].displayName}
                    dependsOn={content[name][entryItem].dependsOn}
                    onPermissionChange={(permissions: string[], enable: boolean) =>
                      onPermissionChange(permissions, enable)
                    }
                    readonly={readonly}
                  />
                ),
              )}
            </Stack>
          </Box>
        </Template>
      </Accordion>
    </Box>
  );
};
