import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {RootState} from 'app/store';
import {IActiveRegion} from 'models/activeRegions';
import {IListPayload, IListQuery} from 'models/generic';

export const activeRegionsApi = createApi({
  reducerPath: 'activeRegionsApi',
  tagTypes: ['Active Regions', 'Territories'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/v1/',
    prepareHeaders(headers, {getState}) {
      const token = (getState() as RootState).user.jwt;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    find: builder.query<IListPayload<IActiveRegion>, IListQuery>({
      query: ({offset = 0, limit = 10, sort = '_id:dsc'}) => ({
        url: `active-regions?offset=${offset}&limit=${limit}&sort=${sort}`,
        method: 'GET',
      }),
      providesTags: ['Active Regions'],
    }),
    findById: builder.query<IActiveRegion, string>({
      query: (id: string) => ({
        url: `active-regions/${id}`,
        method: 'GET',
      }),
      providesTags: ['Active Regions'],
    }),
    insert: builder.mutation<IActiveRegion, Partial<IActiveRegion>>({
      query: activeRegion => ({
        url: `active-regions`,
        method: 'POST',
        body: activeRegion,
      }),
      invalidatesTags: ['Active Regions', 'Territories'],
    }),
    update: builder.mutation<IActiveRegion, {id: string; activeRegion: Partial<IActiveRegion>}>({
      query: ({id, activeRegion}) => ({
        url: `active-regions/${id}`,
        method: 'PUT',
        body: activeRegion,
      }),
      invalidatesTags: ['Active Regions', 'Territories'],
    }),
    delete: builder.mutation<IActiveRegion, string>({
      query: (id: string) => ({
        url: `active-regions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Active Regions', 'Territories'],
    }),
  }),
});

export const {
  useFindQuery,
  useFindByIdQuery,
  useLazyFindByIdQuery,
  useInsertMutation,
  useUpdateMutation,
  useDeleteMutation,
} = activeRegionsApi;
