import * as React from 'react';
import {isEqual} from 'lodash-es';
import {useFindQueueDurationQuery} from 'features/channelQueue/channelQueueApi';
import {IChannelQueueQuery} from 'models/channelQueue';
import {IListPayload} from 'models/generic';
import {sortQueue} from 'views/programming/channel/edit/catalog/utils/catalogQueueSortHelper';
import {filterQueue} from 'views/programming/channel/edit/catalog/utils/catalogQueueFilterHelper';
import {
  useMemoryQueueApiProvider,
  defaultParams,
  IChannelCatalogItemWithState,
} from 'views/programming/channel/contexts/memoryQueueApiProvider';
import {useMemoryQueueProvider} from 'views/programming/channel/contexts/memoryQueueProvider';
import {shuffle} from 'views/programming/channel/edit/catalog/utils/queueShuffle';

interface IUseQueueApiReturn {
  data: IListPayload<IChannelCatalogItemWithState> | null;
  isError: boolean;
  isLoading: boolean;
  fetchQueue: (searchParams?: Partial<IChannelQueueQuery>) => void;
  refetchDuration: () => void;
  setQueue: (newQueue: IChannelCatalogItemWithState[]) => void;
  shuffle: () => void;
  addItems: (index: number, items: IChannelCatalogItemWithState[]) => void;
  moveAlong: (fromIndex: number[], toIndex: number) => void;
  removeItemsById: (items: IChannelCatalogItemWithState[]) => void;
  pristineQueue: IChannelCatalogItemWithState[];
  refetch: () => void;
}

const queueReducer = (
  state: IListPayload<IChannelCatalogItemWithState> | null,
  action: IListPayload<IChannelCatalogItemWithState>,
): IListPayload<IChannelCatalogItemWithState> => {
  return {
    ...state,
    ...action,
  };
};

const shouldFilterTable = (searchParams?: Partial<IChannelQueueQuery>) => {
  const {name, genre, updatedAt, rating, season, tags, genres} = searchParams || {};
  return Boolean(name || genre || updatedAt || rating || season || tags || genres);
};

const IsSearchParamsEqual = (
  searchParams: Partial<IChannelQueueQuery>,
  prevSearchParams: Partial<IChannelQueueQuery>,
) => {
  return (
    searchParams.name === prevSearchParams.name &&
    searchParams.genre === prevSearchParams.genre &&
    searchParams.updatedAt === prevSearchParams.updatedAt &&
    searchParams.rating === prevSearchParams.rating &&
    searchParams.season === prevSearchParams.season &&
    isEqual(searchParams.tags, prevSearchParams.tags) &&
    isEqual(searchParams.genres, prevSearchParams.genres)
  );
};

export const useMemoryQueueApi = (channelId: string): IUseQueueApiReturn => {
  const {getMemoryQueue, setMemoryQueue, setQueueModelChange} = useMemoryQueueProvider();

  const [isMemoryLoading, setIsMemoryLoading] = React.useState(false);

  const filteredQueueLength = React.useRef<number | null>(null);
  const searchParamsRef = React.useRef<Partial<IChannelQueueQuery>>(defaultParams);

  // this send data to the hook that has the list to the table
  const [queueData, setQueueData] = React.useReducer<
    React.Reducer<IListPayload<IChannelCatalogItemWithState> | null, IListPayload<IChannelCatalogItemWithState>>
  >(queueReducer, null);

  const {queueDataWithState, data, isError, isFetching, error, refetch} = useMemoryQueueApiProvider();

  const {
    data: durationData,
    isError: isDurationEror,
    error: durationError,
    isFetching: isFetchingDuration,
    refetch: refetchDuration,
  } = useFindQueueDurationQuery(
    {channelId, ...defaultParams},
    {
      skip: !channelId,
    },
  );

  React.useEffect(() => {
    setMemoryQueue(queueDataWithState || []);
  }, [queueDataWithState, setMemoryQueue]);

  React.useEffect(() => {
    if (queueData?.data || (error && (error as {status: number})?.status === 404)) {
      setIsMemoryLoading(false);
    }
  }, [error, queueData]);

  const setQueue = React.useCallback(
    (newQueue: IChannelCatalogItemWithState[]) => {
      setMemoryQueue(newQueue);
    },
    [setMemoryQueue],
  );

  const shuffleQueue = React.useCallback(() => {
    setMemoryQueue(shuffle(getMemoryQueue()));
    setQueueModelChange();
  }, [getMemoryQueue, setMemoryQueue, setQueueModelChange]);

  const removeItemsById = React.useCallback(
    (items: IChannelCatalogItemWithState[]): void => {
      setMemoryQueue(getMemoryQueue().filter(item => !items.some(i => i.id === item.id)));
      setQueueModelChange();
    },
    [getMemoryQueue, setMemoryQueue, setQueueModelChange],
  );

  const addItems = React.useCallback(
    (index: number, items: IChannelCatalogItemWithState[]): void => {
      const memoryQueue = getMemoryQueue();
      setMemoryQueue([...memoryQueue.slice(0, index), ...items, ...memoryQueue.slice(index)]);
      setQueueModelChange();
    },
    [getMemoryQueue, setMemoryQueue, setQueueModelChange],
  );

  const moveAlong = React.useCallback(
    (fromIndex: number[], toIndex: number): void => {
      const stateCopy = [...getMemoryQueue()];

      const itemsToMove = fromIndex.map(index => stateCopy[index]);
      fromIndex.reverse().forEach(index => stateCopy.splice(index, 1));
      if (toIndex > Math.max(...fromIndex)) {
        toIndex -= itemsToMove.length;
      }
      toIndex = Math.min(toIndex, stateCopy.length);
      stateCopy.splice(toIndex, 0, ...itemsToMove);
      setMemoryQueue(stateCopy);
      setQueueModelChange();
    },
    [getMemoryQueue, setMemoryQueue, setQueueModelChange],
  );

  const fetchQueue = React.useCallback(
    (searchParams?: Partial<IChannelQueueQuery>) => {
      setIsMemoryLoading(true);
      // used when filter is active, this list is reset when the user clear the form
      let tempQueue: IChannelCatalogItemWithState[] | null = null;

      const limit = searchParams?.limit || 50;
      const offset = searchParams?.offset || defaultParams.offset || 0;

      const getTotalCount = () => {
        if (error && (error as {status: number})?.status === 404) return 0;
        return Array.isArray(tempQueue) ? filteredQueueLength.current || 0 : data?.metadata.totalCount || 0;
      };

      const getTotalDuration = () => {
        if (shouldFilterTable(searchParams)) {
          return (tempQueue || []).reduce((acc, item) => acc + item.duration, 0);
        }
        return isDurationEror && (durationError as {status: number})?.status !== 404 ? 0 : durationData?.duration || 0;
      };

      if (shouldFilterTable(searchParams)) {
        tempQueue = filterQueue(getMemoryQueue(), searchParams);
      }

      if (searchParams?.sort && searchParams.sort.length > 0) {
        if (tempQueue) {
          tempQueue = sortQueue(tempQueue, searchParams.sort[0]);
        } else {
          setMemoryQueue(sortQueue(getMemoryQueue(), searchParams.sort[0]));
          if (searchParamsRef.current.sort !== undefined && searchParamsRef.current.sort[0] !== searchParams.sort[0]) {
            setQueueModelChange();
          }
        }
      }

      const slicedData = (tempQueue || getMemoryQueue()).slice(offset, limit + offset) || [];
      if (tempQueue) {
        if (!IsSearchParamsEqual(searchParamsRef.current, searchParams || defaultParams)) {
          filteredQueueLength.current = tempQueue.length;
        }
      } else {
        filteredQueueLength.current = null;
      }
      searchParamsRef.current = searchParams || defaultParams;
      setQueueData({
        data: slicedData,
        metadata: {
          sort: (searchParams?.sort || defaultParams.sort!).join('&'),
          offset: offset,
          duration: getTotalDuration(),
          limit,
          totalCount: getTotalCount(),
        },
      });
    },
    [
      data?.metadata.totalCount,
      durationData?.duration,
      durationError,
      error,
      isDurationEror,
      getMemoryQueue,
      setMemoryQueue,
      setQueueModelChange,
    ],
  );

  const pristineQueue = React.useMemo(() => {
    if (error && (error as {status: number})?.status === 404) {
      return [];
    }
    return queueDataWithState || [];
  }, [queueDataWithState, error]);

  return {
    data: queueData,
    pristineQueue,
    addItems,
    refetchDuration,
    shuffle: shuffleQueue,
    setQueue,
    isError: isError && (error as {status: number})?.status !== 404,
    isLoading: isFetching || isMemoryLoading || isFetchingDuration,
    fetchQueue,
    moveAlong,
    removeItemsById,
    refetch,
  };
};
