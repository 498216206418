import * as React from 'react';

import {Box, ContentBoxes, ContentBox, Spinner} from '@pluto-tv/assemble';

import AssetForm from 'components/assetForm';

import {INestedSeriesProps} from '../nestedPropsInterface';
import {useUserRegions} from 'helpers/useUserRegions';

import CrudError from 'components/crudError';
import {useSeriesPermissions} from 'views/content/series/permissions/useSeriesPermissions';

export default ({
  model,
  setFields,
  pristineModel,
}: // form,
// onBlur,
// onChange
INestedSeriesProps): JSX.Element => {
  const {isFetching: isUserRegionsFetching, isError: isUserRegionsError} = useUserRegions();

  const {editPermission} = useSeriesPermissions(pristineModel);

  if (isUserRegionsError) {
    return <CrudError error='Error loading page data' />;
  }

  if (isUserRegionsFetching) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} size='xlarge' />
      </Box>
    );
  }

  return (
    <ContentBoxes layout='grid' gridMaxCols={2}>
      <ContentBox title='Series Featured'>
        <AssetForm
          assetType='featuredImage'
          asset={model.featuredImage}
          contentType='series'
          contentId={model.id || ''}
          width='20rem'
          height='15rem'
          maxFileSizeKB={2048}
          minResolutionWidth={1560}
          minResolutionHeight={878}
          aspectRatioWidth={16}
          aspectRatioHeight={9}
          permission={editPermission}
          defaultUrl='http://pluto.tv.s3.amazonaws.com/assets/images/default/vod.screenshot16_9-default.jpg'
          onChange={(name, url) => {
            setFields({featuredImage: {title: name, path: url}});
          }}
        />
      </ContentBox>
      <ContentBox title='Series/Film Tile'>
        <AssetForm
          assetType='tile'
          asset={model.tile}
          contentType='series'
          contentId={model.id || ''}
          width='20rem'
          height='15rem'
          maxFileSizeKB={2048}
          minResolutionWidth={330}
          minResolutionHeight={330}
          aspectRatioWidth={1}
          aspectRatioHeight={1}
          permission={editPermission}
          defaultUrl='http://pluto.tv.s3.amazonaws.com/assets/images/default/vod.screenshot4_3-default.jpg'
          onChange={(name, url) => {
            setFields({tile: {title: name, path: url}});
          }}
        />
      </ContentBox>
      <ContentBox title='Series/Film Poster'>
        <AssetForm
          assetType='poster'
          asset={model.poster}
          contentType='series'
          contentId={model.id || ''}
          width='21.6875rem'
          height='31.25rem'
          maxFileSizeKB={2048}
          minResolutionWidth={347}
          minResolutionHeight={500}
          aspectRatioWidth={347}
          aspectRatioHeight={500}
          permission={editPermission}
          defaultUrl='http://pluto.tv.s3.amazonaws.com/assets/images/default/vod.poster-default.jpg'
          onChange={(name, url) => {
            setFields({poster: {title: name, path: url}});
          }}
        />
      </ContentBox>
      <ContentBox title='Series/Film Poster (16:9 Title Treatment)'>
        <AssetForm
          assetType='poster16_9'
          asset={model.poster16_9}
          contentType='series'
          contentId={model.id || ''}
          width='20rem'
          height='15rem'
          maxFileSizeKB={2048}
          minResolutionWidth={1560}
          minResolutionHeight={878}
          aspectRatioWidth={1560}
          aspectRatioHeight={878}
          permission={editPermission}
          onChange={(name, url) => {
            setFields({poster16_9: {title: name, path: url}});
          }}
        />
      </ContentBox>
    </ContentBoxes>
  );
};
