import {IValidatorField} from '@pluto-tv/assemble';
import {IAppName} from 'models/appNames';

export const appNameValidator: IValidatorField<IAppName>[] = [
  {
    name: 'name',
    label: 'App Name',
    required: true,
    validators: [
      (name: string): string | undefined => {
        if (!name || !name.trim().length) {
          return 'Name is required';
        } else if (!/^[a-z0-9-]*$/.test(name)) {
          return 'Name can only contain characters, numbers and dashes';
        } else if (name && name.trim().length > 100) {
          return 'Name must be less than 100 characters';
        }
      },
    ],
  },
  {
    name: 'devices',
    label: 'Associated Devices',
  },
];
