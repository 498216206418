import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

import {RootState} from 'app/store';
import {IClip, IClipEpisode, ISubClip} from 'models/clips';
import {IListPayload, IListIdQuery} from 'models/generic';

interface IEpisodeListQuery extends IListIdQuery {
  name?: string;
}

export const clipsApi = createApi({
  reducerPath: 'clipsApi',
  tagTypes: ['Clips', 'ClipEpisodes', 'ClipSubclips'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/v1/',
    prepareHeaders(headers, {getState}) {
      const token = (getState() as RootState).user.jwt;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    findById: builder.query<IClip, string>({
      query: (id: string) => ({
        url: `clips/${id}`,
        method: 'GET',
      }),
      providesTags: ['Clips'],
    }),
    findEpisodesById: builder.query<IListPayload<IClipEpisode>, IEpisodeListQuery>({
      query: ({offset = 0, limit = 30, id, name}) => ({
        url: `clips/${id}/episodes?offset=${offset}&limit=${limit}${name?.trim() ? `&name=${name}` : ''}`,
        method: 'GET',
      }),
      providesTags: ['ClipEpisodes'],
    }),
    findSubclipsById: builder.query<ISubClip[], string>({
      query: (id: string) => ({
        url: `clips/${id}/subclips`,
        method: 'GET',
      }),
      providesTags: ['ClipSubclips'],
    }),
    insert: builder.mutation<IClip, Partial<IClip>>({
      query: clip => ({
        url: `clips`,
        method: 'POST',
        body: clip,
      }),
      invalidatesTags: ['Clips'],
    }),
    update: builder.mutation<IClip, {id: string; clip: Partial<IClip>; fields: string[]}>({
      query: ({id, clip, fields}) => ({
        url: `clips/${id}`,
        method: 'PUT',
        body: {
          id: id,
          clipParams: clip,
          updateFields: fields,
        },
      }),
      invalidatesTags: ['Clips'],
    }),
    delete: builder.mutation<IClip, string>({
      query: (id: string) => ({
        url: `clips/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Clips'],
    }),
  }),
});

export const {
  useFindByIdQuery,
  useFindSubclipsByIdQuery,
  useLazyFindSubclipsByIdQuery,
  useLazyFindByIdQuery,
  useFindEpisodesByIdQuery,
  useLazyFindEpisodesByIdQuery,
  useInsertMutation,
  useUpdateMutation,
  useDeleteMutation,
  util,
} = clipsApi;
