import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {RootState} from 'app/store';
import {IChannelCatalogItem, IChannelCatalogQuery} from 'models/channelCatalog';
import {IListPayload} from 'models/generic';

const getParamsFromModel = (
  searchModel: Partial<Omit<IChannelCatalogQuery, 'updatedAt'> & {start: number; stop: number}> = {},
) => {
  return Object.entries(searchModel).reduce((acc, [key, value]) => {
    if (value === undefined) return acc;

    if (Array.isArray(value)) {
      return [
        ...acc,
        ...value.map(item => ({
          [key]: encodeURIComponent(item),
        })),
      ];
    }

    return [...acc, {[key]: encodeURIComponent(value)}];
  }, [] as {[key in keyof Partial<IChannelCatalogQuery>]: string | number}[]);
};

export const channelCatalogApi = createApi({
  reducerPath: 'channelCatalogApi',
  tagTypes: ['ChannelCatalog', 'ChannelCatalogLibrary', 'ChannelCatalogQueue'],

  baseQuery: fetchBaseQuery({
    baseUrl: '/v1/',
    prepareHeaders(headers, {getState}) {
      const token = (getState() as RootState).user.jwt;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    findLibrary: builder.query<IListPayload<IChannelCatalogItem>, Partial<IChannelCatalogQuery> & {channelId: string}>({
      query: ({
        channelId,
        offset = 0,
        limit = 25,
        sort = ['series.name:asc', 'season:asc', 'number:asc'],
        updatedAt,
        ...restParams
      }) => {
        const paramsString = getParamsFromModel({offset, limit, sort, ...updatedAt, ...restParams})
          .map(param => Object.entries(param).map(([key, value]) => `${key}=${value}`))
          .join('&');

        const searchParams = new URLSearchParams(`${paramsString}&channelId=${channelId}`);
        searchParams.append('status', 'completed');
        searchParams.append('status', 'on air');

        return {
          url: `channels/${channelId}/library?${searchParams.toString()}`,
          method: 'GET',
        };
      },
      keepUnusedDataFor: 0,
      providesTags: ['ChannelCatalog', 'ChannelCatalogLibrary'],
    }),
    findLibraryDuration: builder.query<{duration: number}, Partial<IChannelCatalogQuery> & {channelId: string}>({
      query: ({channelId, ...restParams}) => {
        const paramsString = getParamsFromModel(restParams)
          .map(param => Object.entries(param).map(([key, value]) => `${key}=${value}`))
          .join('&');
        const searchParams = new URLSearchParams(`${paramsString}`);
        return {
          url: `channels/${channelId}/library-duration?${searchParams.toString()}`,
          method: 'GET',
        };
      },
      providesTags: ['ChannelCatalog', 'ChannelCatalogLibrary'],
    }),
  }),
});

export const {
  useFindLibraryQuery,
  useLazyFindLibraryQuery,
  useFindLibraryDurationQuery,
  useLazyFindLibraryDurationQuery,
  util,
} = channelCatalogApi;
