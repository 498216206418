import * as React from 'react';
import {useParams} from 'react-router-dom';
import {Box, Spinner} from '@pluto-tv/assemble';
import {isEmpty} from 'lodash-es';

import {INestedSeriesProps} from '../nestedPropsInterface';
import EpisodeList from 'components/episodeList';

export default ({model}: INestedSeriesProps): JSX.Element => {
  const {id}: {id: string} = useParams();

  const isLoading = React.useMemo(() => isEmpty(model), [model]);

  if (isLoading) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} size='xlarge' />
      </Box>
    );
  }

  return (
    <EpisodeList
      checkboxCol='multiple'
      showFavoriteSearch={false}
      onSinglePage={true}
      presetSearch={{
        seriesID: id,
        ...(model.activeRegion && {activeRegions: [model.activeRegion.toUpperCase()]}),
        status: ['in progress', 'completed', 'on air'],
      }}
      seriesModel={model}
      presetSortCol='Episode Status'
      presetSortDir='asc'
      isSearchExpanded={true}
    />
  );
};
