import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

import {RootState} from 'app/store';
import {ILanguage} from 'models/languages';

export const languagesApi = createApi({
  reducerPath: 'languagesApi',
  tagTypes: ['Languages'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/v1/',
    prepareHeaders(headers, {getState}) {
      const token = (getState() as RootState).user.jwt;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    find: builder.query<ILanguage[], void>({
      query: () => ({
        url: `languages`,
        method: 'GET',
      }),
    }),
  }),
});

export const {useFindQuery} = languagesApi;
