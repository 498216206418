import * as React from 'react';
import {Box, Heading, Stack, Table, TdLink, ITableCol, Cluster, Icon, ISimpleScheduleEntry} from '@pluto-tv/assemble';
import {DeleteActionWithoutPopover} from 'components/deleteActionWithoutPopover';
import {IEpisodeProgramDetails} from 'models/episodes';
import {useAppPermissions} from 'app/permissions';
import seriesRoutes from 'routes/content.routes';
import Warnings from 'views/programming/channel/edit/program/components/Warnings';
import {getItemState} from 'views/programming/channel/utils';
import {IChannelCatalogItem} from 'models/channelCatalog';
import {isSimpleScheduleEntry} from 'views/programming/channel/edit/program/components/EpisodesDetails';

interface MultiSelectDetailsProps {
  timelineEpisodes: (ISimpleScheduleEntry | IChannelCatalogItem)[];
  episodes: IEpisodeProgramDetails[];
  onRemove?: (id: string) => void;
}

interface IEpisodeRow {
  id: string;
  episodeId: string;
  seriesId: string;
  name: string;
  seriesName: string;
  start?: Date;
  locked?: boolean;
}

export default ({timelineEpisodes, episodes, onRemove}: MultiSelectDetailsProps): React.ReactElement | null => {
  const {ableTo} = useAppPermissions();

  const handleRemove = (row: IEpisodeRow) => {
    if (row.start) {
      onRemove?.(row.id);
    }
  };

  const EpisodesRow = React.useMemo(() => {
    return episodes.map((episode, index) => {
      const timeline = timelineEpisodes[index];
      if (isSimpleScheduleEntry(timeline)) {
        return {
          id: timeline.id,
          episodeId: episode.id,
          seriesId: episode.series.id,
          name: episode.name,
          seriesName: episode.series.name,
          start: timeline.start,
          locked: timeline.locked,
        } as IEpisodeRow;
      }

      return {
        id: episode.id,
        episodeId: episode.id,
        seriesId: episode.series.id,
        name: episode.name,
        seriesName: episode.series.name,
      };
    });
  }, [episodes, timelineEpisodes]);

  const warnings = episodes.map(episode => getItemState(episode as unknown as IChannelCatalogItem));
  const warningsCount = warnings.reduce((acc, warning) => acc + warning.stateMsgList.length, 0);

  return (
    <Stack space='xlarge'>
      <Table
        wrapContent={true}
        cols={[
          {
            label: 'Episode Name',
            transform: row => (
              <TdLink
                row={row}
                title={row.name}
                url={`/series/${row.seriesId}/episodes/${row.episodeId}/details`}
                target='_blank'
              />
            ),
          },
          {
            label: 'Series Name',
            transform: row => (
              <TdLink
                row={row}
                title={row.seriesName}
                url={seriesRoutes.paths.seriesEditDetailsPage.replace(':id', row.seriesId)}
                target='_blank'
              />
            ),
          },
          ...(ableTo('CHANNEL_EPG_EDIT')
            ? [
                {
                  label: 'Actions',
                  colWidth: '6.25rem',
                  transform: row =>
                    onRemove &&
                    row.start &&
                    !row.locked && <DeleteActionWithoutPopover row={row} icons={['delete']} onClick={handleRemove} />,
                } as ITableCol<IEpisodeRow>,
              ]
            : []),
        ]}
        rows={EpisodesRow}
      ></Table>
      <Box
        background='slate'
        borderTop={true}
        borderSize='0.125rem'
        borderColor='onyx'
        paddingY={{mobile: 'medium', wide: 'large'}}
        paddingX={{mobile: 'medium', wide: 'xlarge'}}
      >
        <Stack space='large'>
          <Heading level='h3' color='secondary'>
            {`Warnings ${warningsCount ? `(${warningsCount})` : ''}`}
          </Heading>
          <Stack space='xxlarge'>
            {!warningsCount && (
              <Cluster justify='start' align='center' space='xxlarge'>
                <Icon icon='check' space='xxxsmall' verticalAlign='bottom' lineHeight='0px' color='success'>
                  No Warnings
                </Icon>
              </Cluster>
            )}
            {warningsCount &&
              episodes.map((episode, index) =>
                warnings[index].stateMsgList.length > 0 ? (
                  <Warnings key={episode.id} title={episode.name} warnings={warnings[index].stateMsgList} />
                ) : null,
              )}
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};
