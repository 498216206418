import * as React from 'react';
import {Dialog, Template, Heading, Icon, Stack, Paragraph, Cluster, Button} from '@pluto-tv/assemble';

export interface IConfirmationProps {
  isSaving: boolean;
  warnings: string[];
  onCancel: () => void;
  onConfirm: () => Promise<void | any>;
  onDiscard?: () => void;
}

export default ({isSaving, warnings, onCancel, onConfirm, onDiscard}: IConfirmationProps): JSX.Element => {
  // confirmation dialog should be open if there is something to confirm
  const isOpen = warnings && warnings.length > 0;

  const handleCancel = () => {
    onCancel();
  };

  const handleDiscard = () => {
    if (onDiscard) {
      onDiscard();
    } else {
      onCancel();
    }
  };

  const handleConfirm = async () => {
    await onConfirm();
  };

  return (
    <Dialog isOpen={isOpen} onClose={handleCancel} width='37.5rem'>
      <Template label='header'>
        <Heading level='h2'>
          <Icon icon='warning' color='warning' space='xxsmall'>
            Confirm changes
          </Icon>
        </Heading>
      </Template>
      <Template label='body'>
        <Stack space='large'>
          {warnings.map((w, i) => (
            <Icon key={i} icon='warning' size='medium' iconAlign='start' space='xxsmall' color='warning'>
              <Stack space='xxsmall'>
                <Paragraph color='secondary'>{w}</Paragraph>
              </Stack>
            </Icon>
          ))}
        </Stack>
      </Template>
      <Template label='footer'>
        <Cluster justify='space-between'>
          <Button type='solid' state={isSaving ? 'disabled' : ''} size='small' onClick={handleDiscard}>
            Discard Changes
          </Button>
          <Cluster space='small'>
            <Button ghost={true} state={isSaving ? 'disabled' : ''} size='small' onClick={handleCancel}>
              Review Changes
            </Button>
            <Button size='small' state={isSaving ? 'thinking' : ''} type='primary' onClick={handleConfirm}>
              Save and Proceed
            </Button>
          </Cluster>
        </Cluster>
      </Template>
    </Dialog>
  );
};
