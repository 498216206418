import {EnhancedStore, configureStore} from '@reduxjs/toolkit';

import {activeRegionsApi} from 'features/activeRegions/activeRegionsApi';
import {appnamesApi} from 'features/appnames/appnamesApi';
import {authenticationApi} from 'features/authentication/authenticationApi';
import {backendInfoApi} from 'features/backendInfo/backendInfoApi';
import {campaignsApi} from 'features/campaigns/campaignsApi';
import {categoriesApi} from 'features/categories/categoriesApi';
import {channelProgramApi} from 'features/channelProgram/channelProgramApi';
import {clipsApi} from 'features/clips/clipsApi';
import {contentApi} from 'features/content/contentApi';
import {contentRatingsApi} from 'features/contentRatings/contentRatingsApi';
import {devicesApi} from 'features/devices/devicesApi';
import {episodesApi} from 'features/episodes/episodesApi';
import {genresApi} from 'features/genres/genresApi';
import {languagesApi} from 'features/languages/languagesApi';
import {licensedTitlesApi} from 'features/licensedTitles/licensedTitlesApi';
import notificationReducer from 'features/notification/notificationSlice';
import {partnersApi} from 'features/partners/partnersApi';
import {permissionsApi} from 'features/permissions/permissionsApi';
import {ratingDescriptorsApi} from 'features/ratingDescriptors/ratingDescriptorsApi';
import {rolesApi} from 'features/roles/rolesApi';
import {runLimitTrackersApi} from 'features/runLimitTrackers/runLimitTrackersApi';
import {seriesApi} from 'features/series/seriesApi';
import {territoriesApi} from 'features/territories/territoriesApi';
import userReducer from 'features/user/userSlice';
import {usersApi} from 'features/users/usersApi';
import {vodCategoryEntriesApi} from 'features/vodCategoryEntries/vodCategoryEntriesApi';
import {vodCollectionsApi} from 'features/vodCollections/vodCollectionsApi';
import {assetsApi} from 'features/assets/assetsApi';
import {mainCategoriesApi} from 'features/mainCategories/mainCategoriesApi';
import {ageRangesApi} from 'features/ageRanges/ageRangesApi';
import {channelsApi} from 'features/channels/channelsApi';
import {channelCategoriesApi} from 'features/channelCategories/channelCategoriesApi';
import {channelCatalogApi} from 'features/channelCatalog/channelCatalogApi';
import {channelQueueApi} from 'features/channelQueue/channelQueueApi';
import {channelTimelinesApi} from 'features/channelTimelines/channelTimelinesApi';
import {featuredGroupsApi} from 'features/featuredGroups/featuredGroupsApi';

export const setupStore = (preloadedState = {}): EnhancedStore =>
  configureStore({
    preloadedState,
    reducer: {
      [backendInfoApi.reducerPath]: backendInfoApi.reducer,
      [authenticationApi.reducerPath]: authenticationApi.reducer,
      [appnamesApi.reducerPath]: appnamesApi.reducer,
      [devicesApi.reducerPath]: devicesApi.reducer,
      [campaignsApi.reducerPath]: campaignsApi.reducer,
      [territoriesApi.reducerPath]: territoriesApi.reducer,
      [contentApi.reducerPath]: contentApi.reducer,
      [rolesApi.reducerPath]: rolesApi.reducer,
      [usersApi.reducerPath]: usersApi.reducer,
      [activeRegionsApi.reducerPath]: activeRegionsApi.reducer,
      [permissionsApi.reducerPath]: permissionsApi.reducer,
      [clipsApi.reducerPath]: clipsApi.reducer,
      [contentRatingsApi.reducerPath]: contentRatingsApi.reducer,
      [genresApi.reducerPath]: genresApi.reducer,
      [categoriesApi.reducerPath]: categoriesApi.reducer,
      [languagesApi.reducerPath]: languagesApi.reducer,
      [partnersApi.reducerPath]: partnersApi.reducer,
      [channelsApi.reducerPath]: channelsApi.reducer,
      [episodesApi.reducerPath]: episodesApi.reducer,
      [seriesApi.reducerPath]: seriesApi.reducer,
      [licensedTitlesApi.reducerPath]: licensedTitlesApi.reducer,
      [runLimitTrackersApi.reducerPath]: runLimitTrackersApi.reducer,
      [ratingDescriptorsApi.reducerPath]: ratingDescriptorsApi.reducer,
      [vodCategoryEntriesApi.reducerPath]: vodCategoryEntriesApi.reducer,
      [vodCollectionsApi.reducerPath]: vodCollectionsApi.reducer,
      [assetsApi.reducerPath]: assetsApi.reducer,
      [mainCategoriesApi.reducerPath]: mainCategoriesApi.reducer,
      [ageRangesApi.reducerPath]: ageRangesApi.reducer,
      [channelCategoriesApi.reducerPath]: channelCategoriesApi.reducer,
      [channelCatalogApi.reducerPath]: channelCatalogApi.reducer,
      [channelQueueApi.reducerPath]: channelQueueApi.reducer,
      [channelTimelinesApi.reducerPath]: channelTimelinesApi.reducer,
      [channelProgramApi.reducerPath]: channelProgramApi.reducer,
      [featuredGroupsApi.reducerPath]: featuredGroupsApi.reducer,
      user: userReducer,
      notification: notificationReducer,
    },
    middleware: getDefaultMiddleware => {
      return getDefaultMiddleware()
        .concat(permissionsApi.middleware)
        .concat(rolesApi.middleware)
        .concat(activeRegionsApi.middleware)
        .concat(usersApi.middleware)
        .concat(backendInfoApi.middleware)
        .concat(authenticationApi.middleware)
        .concat(appnamesApi.middleware)
        .concat(devicesApi.middleware)
        .concat(campaignsApi.middleware)
        .concat(territoriesApi.middleware)
        .concat(contentApi.middleware)
        .concat(contentRatingsApi.middleware)
        .concat(genresApi.middleware)
        .concat(categoriesApi.middleware)
        .concat(languagesApi.middleware)
        .concat(partnersApi.middleware)
        .concat(channelsApi.middleware)
        .concat(clipsApi.middleware)
        .concat(episodesApi.middleware)
        .concat(seriesApi.middleware)
        .concat(licensedTitlesApi.middleware)
        .concat(runLimitTrackersApi.middleware)
        .concat(ratingDescriptorsApi.middleware)
        .concat(assetsApi.middleware)
        .concat(mainCategoriesApi.middleware)
        .concat(channelCategoriesApi.middleware)
        .concat(channelCatalogApi.middleware)
        .concat(channelQueueApi.middleware)
        .concat(ageRangesApi.middleware)
        .concat(vodCategoryEntriesApi.middleware)
        .concat(channelTimelinesApi.middleware)
        .concat(channelProgramApi.middleware)
        .concat(vodCollectionsApi.middleware)
        .concat(featuredGroupsApi.middleware);
    },
  });

export const store = setupStore();
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export type RootState = ReturnType<AppStore['getState']>;
