import * as React from 'react';
import {useHistory} from 'react-router-dom';
import {
  Box,
  Button,
  Cluster,
  Click,
  Cover,
  Dialog,
  FormItem,
  Grid,
  Heading,
  Icon,
  Notification,
  Pagination,
  Paragraph,
  Select,
  Sidebar,
  Spinner,
  Stack,
  Status,
  Table,
  TdLink,
  Template,
  Textarea,
  TextInput,
  Toast,
  Toggle,
  trimModel,
  useValidateForm,
  ITableCol,
  Popover,
} from '@pluto-tv/assemble';
import {DateTime as luxon} from 'luxon';
import {cloneDeep, orderBy, startCase, differenceBy, uniqBy, isEmpty} from 'lodash-es';

import {useAppPermissions} from 'app/permissions';

import seriesRoutes from 'routes/content.routes';

import NotAuthorized from 'components/notAuthorized';
import {TableActions} from 'components/tableActions';
import CrudError from 'components/crudError';
import {useLazyFindQuery, useDeleteMutation, useInsertMutation} from 'features/series/seriesApi';
import {useFindQuery as useFindGenresQuery} from 'features/genres/genresApi';
import {useFindQuery as useFindLanguagesQuery} from 'features/languages/languagesApi';
import {useLazyFindQuery as useVodCategoryEntriesQuery} from 'features/vodCategoryEntries/vodCategoryEntriesApi';

import {useUserRegions} from 'helpers/useUserRegions';

import {ISeries, ISeriesListResult} from 'models/series';
import {IUserSeriesSearch} from 'models/users';
import {TSortDirection} from 'models/generic';
import {IAvailabilityWindow} from 'models/episodes';

import {seriesCreateValidator} from 'views/content/series/validators';
import {useSeriesListPermissions, useSeriesPermissions} from 'views/content/series/permissions/useSeriesPermissions';

import SeriesSearchBar from './seriesSearchBar';
import {useAppSelector} from 'app/store/hooks';
import VodCollectionProgramTabSeriesSearchBar from 'components/vodCollectionList/vodCollectionSearchBar';
import {getSeriesListItemState} from 'views/programming/series/utils';
import {withThousandsSeparator} from 'utils/thousands-separator';
import {IVodCategoryEntryExpanded} from 'models/vodCategoryEntry';

const TABLE_COLUMN_NAME = {
  'Series Name': 'name',
  'Series Type': 'type',
  Author: 'author',
  Genre: 'genre',
  'Upload Date': 'createdAt',
  'Avail. Window': 'availabilityWindows',
  Published: 'published',
  'Active Region': 'activeRegion',
  Type: 'type',
  '# of S': 'seasonCount',
  '# of E': 'episodeCount',
  'Create At': 'createdAt',
} as const;

const DEFAULT_PAGE = 0;
const DEFAULT_ROWS_PER_PAGE = 25;

const isPresentOrFutureWindow = ({startDate, endDate}: IAvailabilityWindow): boolean => {
  const {currentDate, start, end} = {
    currentDate: new Date(),
    start: new Date(startDate),
    end: new Date(endDate),
  };
  return start > currentDate || (start <= currentDate && end >= currentDate);
};

const ActionsCol = ({
  row,
  handleEdit,
  handleDelete,
}: {
  row: ISeriesListResult;
  handleEdit: (row: ISeriesListResult) => void;
  handleDelete: (row: ISeriesListResult) => Promise<void>;
}): JSX.Element => {
  const {CAN_EDIT, CAN_DELETE} = useSeriesPermissions(row as Partial<ISeries>);

  return (
    <TableActions
      row={row}
      icons={CAN_EDIT ? ['edit'] : []}
      deleteOption={CAN_DELETE}
      onClick={(row, icon) => {
        switch (icon) {
          case 'edit':
            handleEdit(row);
            break;
          case 'delete':
            handleDelete(row);
            break;
          default:
        }
      }}
    />
  );
};

const showOnlyAVODAvailWindows = (row: ISeriesListResult) => {
  if (row.availabilityWindows && row.availabilityWindows.AVOD) {
    return row.availabilityWindows.AVOD.map(a => (
      <div key={`avod-${a._id}`}>
        {new Date(a.startDate).toLocaleDateString()} - {new Date(a.endDate).toLocaleDateString()}
      </div>
    ));
  }
};

const renderColumnState = (row: ISeriesListResult) => {
  let popoverContent;

  if (row.rowStateAndMessage?.length) {
    popoverContent = row?.rowStateAndMessage?.map((entry, index, array) => (
      <Box
        key={index}
        margin='none'
        background='charcoal'
        padding='medium'
        paddingTop={index ? 'none' : 'medium'}
        paddingBottom={index === array.length - 1 ? 'medium' : 'none'}
      >
        <Cluster wrap={false} align='start' justify='start'>
          <Box padding='xxxsmall' margin='none'>
            <Icon icon='warning' color={entry.state as any} size='large' space='xsmall' />
          </Box>
          <Box padding='xxxxxxsmall'>{entry.message}</Box>
        </Cluster>
      </Box>
    ));

    return (
      <Popover trigger='mouseenter' appendToBody={true}>
        <Template label='trigger'>
          <Icon icon='warning' color={row.rowStateAndMessage[0]?.state as any} size='large' />
        </Template>
        <Template label='popover'>{popoverContent}</Template>
      </Popover>
    );
  }
};

const getSeriesListItemRowState = row => {
  const rowStateAndMessage = getSeriesListItemState(row);

  return {
    ...row,
    state: rowStateAndMessage.length ? rowStateAndMessage[0].state : '',
    rowStateAndMessage,
  };
};
export interface ISeriesSearchParams {
  searchParams: IUserSeriesSearch;
  isSearchExpanded: boolean;
  page: number;
  rowsPerPage: number;
}

export interface ISeriesListPropsBase {
  actionsCol?: boolean;
  addNewSeries?: boolean;
  defaultSearchExpanded?: boolean;
  checkboxCol: boolean | 'multiple';
  showFavoriteSearch?: boolean;
  showMixedWhenUnpublished?: boolean;
  openNameLinkInTab?: boolean;
  publishedState?: boolean;
  search?: IUserSeriesSearch;
  hideActiveRegions?: boolean;
  seriesPreview?: boolean;
  showThumbnail?: boolean;
  inModal?: boolean;
  isModalOpen?: boolean;
  onSelect?: (series: ISeriesListResult | ISeriesListResult[] | undefined) => void;
  nameTarget?: React.HTMLAttributeAnchorTarget;
  isSearchExpanded?: boolean;
  type?: 'importing' | 'adding' | 'addVodCollection';
  defaultSearch?: IUserSeriesSearch;
  channelId?: string;
  vodCollectionId?: string;
  addVodCollection?: boolean;
  searchRegion?: string;
  selectedSeries?: ISeriesListResult | ISeriesListResult[];

  // This is used to remember searchParams and searchBarOpen values for when the List needs to re-load
  seriesSearchParams?: ISeriesSearchParams;
  setSeriesSearchParams?: (params: ISeriesSearchParams) => void;
}

export interface ISeriesListSingle extends ISeriesListPropsBase {
  checkboxCol: true;
  selectedSeries?: ISeriesListResult;
}

export interface ISeriesListMultiselect extends ISeriesListPropsBase {
  checkboxCol: 'multiple';
}

const SeriesList = React.memo(
  ({
    actionsCol = true,
    addNewSeries = true,
    inModal = false,
    checkboxCol,
    showFavoriteSearch = true,
    type,
    channelId,
    defaultSearch = {
      name: '',
      model: {},
      meta: null,
      sortCol: 'createdAt' as keyof typeof TABLE_COLUMN_NAME,
      sortDir: 'dsc',
    },
    isModalOpen,
    showMixedWhenUnpublished = false,
    openNameLinkInTab = false,
    publishedState = false,
    search,
    hideActiveRegions = false,
    vodCollectionId,
    addVodCollection = false,
    searchRegion,
    onSelect,
    selectedSeries,
    seriesSearchParams,
    setSeriesSearchParams,
  }: ISeriesListSingle | ISeriesListMultiselect) => {
    const history = useHistory();
    const {ableTo, permissions} = useAppPermissions();
    const {email = ''} = useAppSelector(store => store.user);

    const [insertSeries] = useInsertMutation(600);
    const [deleteSeries] = useDeleteMutation(600);

    const {
      activeRegions,
      isFetching: isFetchingRegions,
      isError: isErrorRegions,
      isSuccess: isSuccessRegions,
    } = useUserRegions();

    const {data: genres} = useFindGenresQuery();
    const {data: languages} = useFindLanguagesQuery();

    const {canViewOO, canViewPartner} = useSeriesListPermissions();
    const {canCreateOO, canCreatePartner} = useSeriesPermissions();

    const [currentSearch, setCurrentSearch] = React.useState<IUserSeriesSearch | undefined>(search || defaultSearch);

    const [isInitialLoad, setIsInitialLoad] = React.useState(true);
    const [selectedRow, setSelectedRow] = React.useState<ISeriesListResult[]>([]);

    const [sortCol, setSortCol] = React.useState<keyof typeof TABLE_COLUMN_NAME>(
      'createdAt' as keyof typeof TABLE_COLUMN_NAME,
    );
    const [sortDir, setSortDir] = React.useState<TSortDirection>('dsc');
    const [page, setPage] = React.useState(DEFAULT_PAGE);
    const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
    const [searchExpanded, setSearchExpanded] = React.useState(false);
    const [searchSeries, seriesResponse] = useLazyFindQuery();
    const [searchVodEntries, vodEntriesResponse] = useVodCategoryEntriesQuery();
    const isSeriesLoading = seriesResponse?.isFetching || seriesResponse?.isLoading || seriesResponse?.isUninitialized;

    const isVodEntriesLoading =
      vodEntriesResponse?.isFetching || vodEntriesResponse?.isLoading || vodEntriesResponse?.isUninitialized;

    const isError = seriesResponse?.isError;
    const isVodEntriesError = vodEntriesResponse.isError;
    const series = seriesResponse?.data;
    const vodEntries = vodEntriesResponse.data;

    const {
      form,
      model,
      onBlur,
      onChange,
      setFields,
      reset: resetCreate,
      state: formState,
    } = useValidateForm<ISeries>(seriesCreateValidator);

    const handleSearch = React.useCallback(
      (searchParams: IUserSeriesSearch, rowsPerPage = DEFAULT_ROWS_PER_PAGE, page = DEFAULT_PAGE) => {
        setCurrentSearch(searchParams);
        setPage(page);

        if (setSeriesSearchParams && seriesSearchParams) {
          setSeriesSearchParams({
            searchParams: {...seriesSearchParams.searchParams, ...searchParams},
            isSearchExpanded: seriesSearchParams.isSearchExpanded || searchExpanded,
            rowsPerPage,
            page,
          });
        }

        // Clean the selected series list after search
        if (handleSelect && type === 'importing') {
          handleSelect(undefined);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [searchExpanded, seriesSearchParams, setSeriesSearchParams],
    );

    const handleToogleSearchBar = () => {
      setSearchExpanded(!searchExpanded);
      if (setSeriesSearchParams && seriesSearchParams) {
        setSeriesSearchParams({...seriesSearchParams, isSearchExpanded: !searchExpanded});
      }
    };

    const changeSort = (columnName: keyof typeof TABLE_COLUMN_NAME) => {
      const newSortDir: TSortDirection = columnName === sortCol ? (sortDir === 'asc' ? 'dsc' : 'asc') : 'dsc';
      setSortCol(columnName);
      setSortDir(newSortDir);
      handleSearch(
        {
          ...currentSearch,
          ...(seriesSearchParams?.searchParams?.model || {}),
          sortCol: columnName,
          sortDir: newSortDir,
        } as IUserSeriesSearch,
        rowsPerPage,
      );
      setPage(DEFAULT_PAGE);
    };

    React.useEffect(() => {
      if (type === 'addVodCollection' && vodCollectionId) {
        searchVodEntries({
          id: vodCollectionId,
          offset: 0,
          limit: 2000,
          sort: '',
        });
      }
    }, [type, searchVodEntries, vodCollectionId]);

    let vodEntrySeries = [] as IVodCategoryEntryExpanded[];
    if (vodEntries?.data && !isEmpty(vodEntries.data.documents)) {
      vodEntrySeries = vodEntries?.data.documents;
    }

    React.useEffect(() => {
      if (onSelect) {
        onSelect(selectedRow);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRow]);

    const handleSelect = (row: ISeriesListResult[] | ISeriesListResult | undefined) => {
      // Fixes issue when collapsing search bar
      if (!row) return;

      // Only select all or unselect all cases
      if (Array.isArray(row) && row.length === 0) {
        // Difference
        setSelectedRow(curr => differenceBy(curr, series?.data as ISeriesListResult[], '_id'));
        return;
      }
      // Union
      if (Array.isArray(row) && row.length === series?.data.length) {
        setSelectedRow(curr => uniqBy(curr.concat(row), '_id'));
        return;
      }

      setSelectedRow(Array.isArray(row) ? row : [row]);
    };

    const handleSelectLog = (row: any, selected: boolean) => {
      setSelectedRow(selected ? curr => uniqBy(curr.concat(row), '_id') : curr => curr.filter(e => e._id !== row._id));
    };

    const handleClear = React.useCallback(() => {
      setPage(DEFAULT_PAGE);
      setRowsPerPage(DEFAULT_ROWS_PER_PAGE);
      setSortCol(defaultSearch.sortCol as keyof typeof TABLE_COLUMN_NAME);
      setSortDir(defaultSearch.sortDir);

      handleSearch(defaultSearch);
    }, [defaultSearch, handleSearch]);

    const getWindowPeriod = (timeWindow: 'day' | 'week' | 'month') => {
      const now = new Date();
      switch (timeWindow) {
        case 'day':
          return {
            start: luxon.fromJSDate(now).minus({days: 1}).toISO(),
            end: now.toISOString(),
          };
        case 'week':
          return {
            start: luxon.fromJSDate(now).minus({weeks: 1}).toISO(),
            end: now.toISOString(),
          };
        case 'month':
          return {
            start: luxon.fromJSDate(now).minus({months: 1}).toISO(),
            end: now.toISOString(),
          };
      }
    };

    const renderColForSeriesAndVodCollection = React.useMemo(() => {
      const publishedAvailWindowCol: ITableCol<ISeriesListResult>[] = [
        {
          label: 'Published',
          sortable: true,
          colMinWidth: '6.875rem',
          transform: row => (
            <Status label={row.published ? 'Published' : 'Unpublished'} state={row.published ? 'success' : 'neutral'} />
          ),
        },
        {
          label: type === 'addVodCollection' ? 'Availability Window' : 'Avail. Window',
          transform: row =>
            type === 'addVodCollection'
              ? showOnlyAVODAvailWindows(row)
              : row.availabilityWindows?.AVOD?.filter(isPresentOrFutureWindow)
                  .map(a => (
                    <div key={`avod-${a._id}`}>
                      AVOD: {new Date(a.startDate).toLocaleDateString()} - {new Date(a.endDate).toLocaleDateString()}
                    </div>
                  ))
                  .concat(
                    row.availabilityWindows?.linear?.filter(isPresentOrFutureWindow).map(a => (
                      <div key={`linear-${a._id}`}>
                        Linear: {new Date(a.startDate).toLocaleDateString()} -{' '}
                        {new Date(a.endDate).toLocaleDateString()}
                      </div>
                    )) || [],
                  ),
          sortable: false,
          colMinWidth: '16.75rem',
        },
      ];

      const noOfSeriesEpisodeCol: ITableCol<ISeriesListResult>[] = [
        {
          label: '# of S',
          transform: row => row.seasonCount?.toLocaleString(),
          sortable: true,
          colMinWidth: '5.9375rem',
        },
        {
          label: '# of E',
          transform: row => row.episodeCount?.toLocaleString(),
          sortable: true,
          colMinWidth: '5.9375rem',
        },
      ];

      return type !== 'addVodCollection'
        ? publishedAvailWindowCol.reverse().concat(noOfSeriesEpisodeCol)
        : noOfSeriesEpisodeCol.concat(publishedAvailWindowCol);
    }, [type]);

    const performSearch = React.useCallback(
      (searchParams: IUserSeriesSearch) => {
        const searchModel = cloneDeep(searchParams.model);
        // convert time window to search params and remove from query
        if (searchModel.timeWindow) {
          const window = getWindowPeriod(searchModel.timeWindow);
          searchModel.maxCreatedAt = window.end;
          searchModel.minCreatedAt = window.start;
          delete searchModel.timeWindow;
        }

        if (!searchModel.published) {
          if (showMixedWhenUnpublished) {
            delete searchModel.published;
          }
        }
        if (type === 'addVodCollection') {
          searchModel.distributeAsAVOD = true;
          searchModel.minEpisodeCount = 1;
        }
        searchSeries({
          offset: page * rowsPerPage,
          limit: rowsPerPage,
          sort: (TABLE_COLUMN_NAME[searchParams.sortCol] || defaultSearch.sortCol) + ':' + searchParams.sortDir,
          ...searchModel,
        });
      },
      [type, defaultSearch.sortCol, page, rowsPerPage, searchSeries, showMixedWhenUnpublished],
    );

    const transformSearch = () => {
      if (currentSearch && !isFetchingRegions && !isErrorRegions) {
        const activeRegionsToUse = searchRegion ? activeRegions.filter(ar => searchRegion === ar.code) : activeRegions;
        const currentSearchActiveRegions = currentSearch.model ? currentSearch.model.activeRegions : [searchRegion!];
        const defaultPublishedFlag = isInitialLoad ? {published: true} : undefined;
        performSearch({
          ...currentSearch,
          model: {
            ...currentSearch,
            ...currentSearch.model,
            ...defaultPublishedFlag,
            activeRegions:
              (currentSearchActiveRegions || []).length === 0
                ? activeRegionsToUse.map(ar => ar.code)
                : currentSearchActiveRegions,
            ...(!canViewOO &&
              canViewPartner && {
                plutoTvOO: false,
              }),
            ...(canViewOO &&
              !canViewPartner && {
                plutoTvOO: true,
              }),
          },
        });
      }
    };

    React.useEffect(() => {
      if (isSuccessRegions) {
        transformSearch();
        if (isInitialLoad) {
          setIsInitialLoad(false);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetchingRegions, isErrorRegions, currentSearch, performSearch, activeRegions, isSuccessRegions]);

    React.useEffect(() => {
      // Clean the selected series list after search
      if (type === 'importing' && series) {
        handleSelect(undefined);
      }
      if (setSeriesSearchParams && seriesSearchParams) {
        setSearchExpanded(seriesSearchParams.isSearchExpanded || searchExpanded);
        setCurrentSearch(seriesSearchParams.searchParams || currentSearch || defaultSearch);
        setRowsPerPage(seriesSearchParams.rowsPerPage || rowsPerPage);
        setPage(seriesSearchParams.page || page);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seriesSearchParams]);

    const handleCreate = async (navigateTo = false): Promise<string | undefined> => {
      let seriesId: string | undefined;

      try {
        setIsCreating(true);

        const subGenres = genres?.find(g => g.genre === model.genre)?.subGenres || [];

        const postModel: Partial<ISeries> = {
          ...model,
          published: true,
          rating: 'Not Rated',
          summary: model.description,
          seasons: [
            {
              number: 1,
              synopsis: '',
            },
          ],
          distributeAs: {
            AVOD: true,
            linear: true,
          },
          tags: [email],
          category: 'Other',
          subCategory: 'None',
          subGenre: subGenres.length > 0 ? subGenres[0] : 'Other',
        };

        const newSeries = await insertSeries(trimModel(postModel, 'name', 'summary', 'description')).unwrap();
        seriesId = newSeries.id!;

        const toastMsg = navigateTo ? (
          'Series Created'
        ) : (
          <Stack space='xxsmall'>
            <Paragraph>Series Created</Paragraph>
            <Click
              underline={true}
              hoverColor='white'
              onClick={() => history.push(seriesRoutes.paths.seriesEditDetailsPage.replace(':id', seriesId as string))}
            >
              View Series: {postModel.name}
            </Click>
          </Stack>
        );

        Toast.success('Success', toastMsg, 8000);
        setCreateOpen(false);

        setPage(0);
      } catch (e) {
        Toast.error('Error', (e as any)?.data?.message);
      } finally {
        setIsCreating(false);
      }

      return seriesId;
    };

    const handleCreateAndEdit = async () => {
      try {
        const seriesId = await handleCreate(true);

        if (seriesId) {
          history.push(seriesRoutes.paths.seriesEditDetailsPage.replace(':id', seriesId as string));
        }
      } catch (e) {}
    };

    const handleCancel = () => {
      setCreateOpen(false);
    };

    const handleEdit = (series: ISeriesListResult) => {
      const seriesUrl = seriesRoutes.paths.seriesEditDetailsPage.replace(':id', series._id);
      if (!openNameLinkInTab) {
        history.push(seriesUrl);
      } else {
        window.open(seriesUrl, '_blank');
      }
    };

    const isAddingVodEntryCheck = (row: ISeriesListResult): boolean => {
      return !!(
        type === 'addVodCollection' &&
        vodEntrySeries?.find(i => i.series?.id === row._id || i.episode?.series?.id === row._id)
      );
    };

    const getRowStatus = (row: ISeriesListResult) => {
      const isAddingVODEntry = isAddingVodEntryCheck(row);
      const isAddingChannelEntry = channelId && row.channels?.find(c => c === channelId);

      if (isAddingVODEntry || isAddingChannelEntry) {
        return 'highlight';
      }

      return undefined as any;
    };

    const getRowDisabled = row => {
      const isDisabledForVODEntry = isAddingVodEntryCheck(row);

      const isDisabledForChannelEntry = !!(channelId && row.channels?.find(c => c === channelId));
      return isDisabledForVODEntry || isDisabledForChannelEntry;
    };

    const handleDelete = async (series: ISeriesListResult) => {
      if (!ableTo('SERIES_DELETE')) return;
      try {
        await deleteSeries(series._id).unwrap();
        Toast.success('Success', 'Series Removed');
      } catch (e) {
        Toast.error('Error', `Failed to remove series`);
      }
    };

    const [createOpen, setCreateOpen] = React.useState(false);
    const openCreate = () => {
      resetCreate();
      setCreateOpen(true);
      setSearchExpanded(false);
      setFields({plutoTvOO: canCreateOO && !canCreatePartner ? true : false});
    };

    const [isCreating, setIsCreating] = React.useState(false);

    if (!ableTo('SERIES_VIEW')) {
      return <NotAuthorized />;
    }

    if (isError || isVodEntriesError) {
      return <CrudError error='Error loading page data' />;
    }

    return (
      <Sidebar fullHeight={true}>
        {!createOpen &&
          (type !== 'addVodCollection' ? (
            <SeriesSearchBar
              sortDir={sortDir}
              sortCol={sortCol}
              inModal={inModal}
              isFetching={isSeriesLoading}
              isSearchExpanded={seriesSearchParams?.isSearchExpanded || searchExpanded}
              type={type}
              setIsSearchExpanded={handleToogleSearchBar}
              showFavoriteSearch={showFavoriteSearch}
              search={currentSearch}
              onSearch={handleSearch}
              onClear={handleClear}
              canViewOO={canViewOO}
              canViewPartner={canViewPartner}
            />
          ) : (
            <VodCollectionProgramTabSeriesSearchBar
              sortDir={sortDir}
              sortCol={sortCol}
              isFetching={isSeriesLoading || isVodEntriesLoading}
              isModalOpen={isModalOpen}
              isSearchExpanded={seriesSearchParams?.isSearchExpanded || searchExpanded}
              publishedState={publishedState}
              searchRegion={searchRegion}
              setIsSearchExpanded={handleToogleSearchBar}
              hideActiveRegions={hideActiveRegions}
              search={currentSearch}
              onSearch={handleSearch}
              onClear={handleClear}
              canViewOO={canViewOO}
              canViewPartner={canViewPartner}
            />
          ))}
        <Cover
          scrolling={true}
          gutter='large'
          coverTemplateHeight='100%'
          overflow='auto'
          padding={inModal ? 'none' : {mobile: 'medium', wide: 'large'}}
        >
          <Template label='header'>
            {!type && !addVodCollection && (
              <Cluster justify='space-between' align='center' space='medium'>
                <Cluster align='end' space='small'>
                  <Heading level='h1'>Series</Heading>
                  <Cluster space='xxsmall' align='center'>
                    <Icon
                      icon='tune'
                      space='xxxsmall'
                      verticalAlign='bottom'
                      lineHeight='0px'
                      onClick={handleToogleSearchBar}
                    >
                      {withThousandsSeparator(series?.metadata.totalCount || 0)} Items
                    </Icon>
                  </Cluster>
                </Cluster>
                <Cluster space='medium' align='center'>
                  {addNewSeries ? (
                    <>
                      <Button type='primary' onClick={openCreate} permission={permissions.SERIES_CREATE}>
                        + New Series
                      </Button>
                      <Dialog
                        isOpen={createOpen}
                        onClose={() => setCreateOpen(false)}
                        width='42.8125rem'
                        height='41.2rem'
                        id='create-series-dialog'
                      >
                        <Template label='header'>
                          <Heading level='h2'>Create Series</Heading>
                        </Template>
                        <Template label='body'>
                          <Stack space='small'>
                            <FormItem {...form.name} onBlur={() => onBlur('name')}>
                              <TextInput
                                onChange={value => onChange('name', value)}
                                id='title'
                                placeholder='Series Name'
                              />
                            </FormItem>
                            <Grid rowGap='small' columnGap='xlarge'>
                              <FormItem {...form.activeRegion} onBlur={() => onBlur('activeRegion', false)}>
                                <Select
                                  value={{label: model.activeRegion || '', value: model.activeRegion}}
                                  onChange={value => {
                                    const activeRegion = value.value;

                                    setFields({
                                      activeRegion,
                                    });
                                  }}
                                  id='activeRegion'
                                  predicate='value'
                                  placeholder='Please select active region'
                                  options={activeRegions.map(ar => ({
                                    label: `${ar.name} (${ar.code})`,
                                    value: ar.code.toLowerCase(),
                                  }))}
                                />
                              </FormItem>
                              <FormItem {...form.type} onBlur={() => onBlur('type', false)}>
                                <Select
                                  onChange={value => setFields({type: value.value})}
                                  id='type'
                                  value={{label: model.type || '', value: model.type}}
                                  predicate='value'
                                  placeholder='Select Type'
                                  options={[
                                    {label: 'Film', value: 'film'},
                                    {label: 'TV', value: 'tv'},
                                    {label: 'Web Original', value: 'web-original'},
                                    {label: 'Music Video', value: 'music-video'},
                                    {label: 'Live', value: 'live'},
                                  ]}
                                />
                              </FormItem>
                              <FormItem {...form.genre} onBlur={() => onBlur('genre', false)}>
                                <Select
                                  placeholder='Please select genre'
                                  value={{label: model.genre || ''}}
                                  onChange={value => setFields({genre: value.label})}
                                  id='genre'
                                  options={(genres || []).map(g => ({label: g.genre}))}
                                  searchable={true}
                                  searchPlaceholder='Search for genre'
                                  onSearch={val =>
                                    orderBy(
                                      (genres || [])
                                        .filter(g => g.genre.toLowerCase().indexOf(val.toLowerCase()) > -1)
                                        .map(g => ({label: g.genre}), 'label'),
                                    ) || []
                                  }
                                />
                              </FormItem>
                              <FormItem {...form.metadataLanguage} onBlur={() => onBlur('metadataLanguage', false)}>
                                <Select
                                  onChange={value => setFields({metadataLanguage: value.value})}
                                  id='metadataLanguage'
                                  value={{label: model.metadataLanguage || '', value: model.metadataLanguage}}
                                  predicate='value'
                                  placeholder='Please select metadata language'
                                  searchable={true}
                                  searchPlaceholder='Search for language'
                                  onSearch={val =>
                                    orderBy(
                                      (languages || [])
                                        .filter(language => language.name.toLowerCase().indexOf(val.toLowerCase()) > -1)
                                        .map(language => ({label: language.name, value: language.iso639_1}), 'label'),
                                    ) || []
                                  }
                                  options={orderBy(
                                    (languages || []).map(language => ({
                                      label: language.name,
                                      value: language.iso639_1,
                                    })),
                                    'label',
                                  )}
                                />
                              </FormItem>
                            </Grid>
                            <Box paddingTop='xxxsmall'>
                              <FormItem {...form.description} onBlur={() => onBlur('description')}>
                                <Textarea
                                  onChange={value => onChange('description', value)}
                                  value={model.description}
                                  id='description'
                                  minHeight='6.25rem'
                                  placeholder='Short description'
                                />
                              </FormItem>
                            </Box>
                            <FormItem
                              child='Toggle'
                              helpText='If "Yes", the series belongs to PlutoTV O&O.'
                              helpTextColor='info'
                              label='Pluto TV O&O'
                              permission={canCreateOO !== canCreatePartner ? 'disabled' : 'enabled'}
                            >
                              <Toggle
                                label='Yes'
                                onChange={value => onChange('plutoTvOO', value)}
                                value={model.plutoTvOO}
                              />
                            </FormItem>
                          </Stack>
                        </Template>
                        <Template label='footer'>
                          <Cluster justify='space-between'>
                            <div></div>
                            <Cluster space='small'>
                              <Button ghost={true} onClick={handleCancel} id='cancelButton'>
                                Cancel
                              </Button>
                              <Button
                                type='primary'
                                state={
                                  !formState.isValid || !formState.isDirty ? 'disabled' : isCreating ? 'thinking' : ''
                                }
                                onClick={() => handleCreate()}
                                id='createButton'
                              >
                                Create
                              </Button>
                              <Button
                                type='primary'
                                state={
                                  !formState.isValid || !formState.isDirty ? 'disabled' : isCreating ? 'thinking' : ''
                                }
                                onClick={handleCreateAndEdit}
                                id='createEditButton'
                              >
                                Create and Edit
                              </Button>
                            </Cluster>
                          </Cluster>
                        </Template>
                      </Dialog>
                    </>
                  ) : (
                    <Box height='1.7rem'></Box>
                  )}
                </Cluster>
              </Cluster>
            )}
            {type && !addVodCollection && (
              <Cluster justify='space-between' align='center' space='medium'>
                <Cluster align='end' space='small'>
                  <Heading level='h3' color='secondary'>
                    {type === 'importing' ? 'Step 1: Select a Series' : 'Select a Series'}
                  </Heading>
                  <Cluster space='xxsmall' align='center'>
                    <Icon
                      icon='tune'
                      space='xxxsmall'
                      verticalAlign='bottom'
                      lineHeight='0px'
                      onClick={handleToogleSearchBar}
                    >
                      {withThousandsSeparator(series?.metadata.totalCount || 0)} Items
                    </Icon>
                  </Cluster>
                </Cluster>
              </Cluster>
            )}
          </Template>
          <Template label='cover'>
            <Box
              background='pewter'
              borderTop={true}
              borderSize='2px'
              borderColor='cavern'
              paddingTop={inModal ? 'none' : (series?.data?.length as number) > 0 ? 'xsmall' : 'medium'}
              paddingBottom='none'
              paddingX={inModal ? 'none' : 'large'}
              fullHeight={true}
            >
              <Table
                predicate={'_id'}
                loading={isSeriesLoading || (type === 'addVodCollection' && isVodEntriesLoading)}
                fixedHeader={true}
                wrapContent={true}
                onSort={colName => changeSort(colName as keyof typeof TABLE_COLUMN_NAME)}
                sortDir={sortDir}
                sortCol={sortCol}
                selectable={checkboxCol! as 'multiple'}
                onSelect={handleSelect}
                selectLog={handleSelectLog}
                selected={selectedRow || (selectedSeries as any)}
                emptyMsg='No Series'
                id='series-search-table'
                rowStatus={getRowStatus}
                rowDisabled={getRowDisabled}
                cols={[
                  {
                    label: '',
                    colWidth: '0.625rem',
                    zeroRightPadding: true,
                    transform: row => renderColumnState(row),
                    hidden: type !== 'addVodCollection',
                  },
                  {
                    label: 'Series Name',
                    sortable: true,
                    colMinWidth: '28.125rem',
                    transform: row =>
                      type !== 'importing' ? (
                        <TdLink
                          row={row}
                          title={row.name}
                          url={seriesRoutes.paths.seriesEditDetailsPage.replace(':id', row._id)}
                          {...(openNameLinkInTab && {target: '_blank'})}
                        />
                      ) : (
                        row.name
                      ),
                  },
                  {
                    label: 'Active Region',
                    transform: row => row.activeRegion.toUpperCase(),
                    sortable: true,
                    colMinWidth: '9.6875rem',
                    hidden: type === 'addVodCollection',
                  },
                  {
                    label: 'Created At',
                    transform: row => new Date(row.createdAt).toLocaleString(),
                    sortable: true,
                    colMinWidth: '13.75rem',
                    hidden: type === 'addVodCollection',
                  },
                  {
                    label: 'Series Type',
                    transform: row => {
                      let transformedType = startCase(row.type);

                      if (transformedType === 'Tv') {
                        if (type === 'addVodCollection') {
                          transformedType = 'TV';
                        } else {
                          transformedType = 'TV Show';
                        }
                      }

                      return transformedType;
                    },
                    sortable: true,
                    colMinWidth: '9.6875rem',
                    hidden: type !== 'addVodCollection',
                  },
                  {
                    label: 'Author',
                    transform: row => row?.author || (type !== 'addVodCollection' ? 'Author' : ''),
                    sortable: type !== 'addVodCollection',
                    colMinWidth: '9.6875rem',
                    hidden: type !== 'addVodCollection',
                  },
                  {
                    label: 'Genre',
                    transform: row => row?.genre || 'Genre',
                    sortable: true,
                    colMinWidth: '9.6875rem',
                    hidden: type !== 'addVodCollection',
                  },
                  ...renderColForSeriesAndVodCollection,
                  {
                    label: 'Type',
                    transform: row => {
                      let transformedType = startCase(row.type);

                      if (transformedType === 'Tv') {
                        transformedType = 'TV';
                      }

                      return transformedType;
                    },
                    sortable: true,
                    colMinWidth: '6.25rem',
                    hidden: type === 'addVodCollection',
                  },
                  ...((ableTo('SERIES_EDIT') || ableTo('SERIES_DELETE')) && actionsCol && type !== 'addVodCollection'
                    ? [
                        {
                          label: 'Actions',
                          colWidth: '6.25rem',
                          transform: row => (
                            <ActionsCol row={row} handleDelete={handleDelete} handleEdit={handleEdit} />
                          ),
                        } as ITableCol<ISeriesListResult>,
                      ]
                    : []),
                ]}
                rows={
                  type === 'addVodCollection'
                    ? (series?.data || []).map(i => getSeriesListItemRowState(i))
                    : series?.data || []
                }
              >
                <Template label='loading'>
                  <Cluster space='small' align='center'>
                    <Spinner />
                    <Paragraph>Loading Series List</Paragraph>
                  </Cluster>
                </Template>
                <Template label='empty'>
                  <Notification type='warning'>There are no series currently available.</Notification>
                </Template>
              </Table>
            </Box>
          </Template>
          <Template label='footer'>
            <Cluster justify='space-between'>
              <div></div>
              {(series?.data?.length as number) > 0 && (
                <Pagination
                  perPage={rowsPerPage as 25 | 50 | 75 | 100}
                  currentPage={page}
                  total={series?.metadata.totalCount || 0}
                  onPageChange={page => {
                    setPage(page);
                    document.getElementById('series-search-table')?.parentElement?.scrollTo(0, 0);
                    if (setSeriesSearchParams && seriesSearchParams) {
                      setSeriesSearchParams({...seriesSearchParams, page: page});
                    }
                  }}
                  onPerPageChange={perPage => {
                    setRowsPerPage(perPage);
                    setPage(0);
                    if (setSeriesSearchParams && seriesSearchParams) {
                      setSeriesSearchParams({...seriesSearchParams, page: 0, rowsPerPage: perPage});
                    }
                  }}
                />
              )}
            </Cluster>
          </Template>
        </Cover>
      </Sidebar>
    );
  },
);

SeriesList.displayName = 'SeriesList';
export default SeriesList;
