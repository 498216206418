import * as React from 'react';
import {useHistory} from 'react-router-dom';
import {DateTime as luxon} from 'luxon';
import {sortBy} from 'lodash-es';
import {
  Box,
  Cluster,
  Cover,
  Heading,
  Help,
  Icon,
  ITableCol,
  Notification,
  Pagination,
  Paragraph,
  Sidebar,
  Spinner,
  Stack,
  Table,
  TdLink,
  Template,
} from '@pluto-tv/assemble';

import {useAppPermissions} from 'app/permissions';

import runLimitRoutes from 'routes/runLimits.routes';

import NotAuthorized from 'components/notAuthorized';
import {TableActions} from 'components/tableActions';
import CrudError from 'components/crudError';

import {useLazyFindQuery} from 'features/licensedTitles/licensedTitlesApi';

import {ILicensedTitle, IRunLimitRes} from 'models/licensedTitles';

import {generateRunLimitUri, useRunLimitCache} from '../utils';
import {IListPayload, TSortDirection} from 'models/generic';
import {IUserLicensedTitleSearch} from 'models/users';
import LicensedTitleSearchBar from './licensedTitleSearchBar';
import {useUserRegions} from 'helpers/useUserRegions';
import {withThousandsSeparator} from 'utils/thousands-separator';

const TABLE_COLUMN_NAME = {
  Title: 1,
  Partner: 2,
  'Active Region(s)': 3,
} as const;

const SECONDS_IN_DAY = 60 * 60 * 24;

const defaultSearch: IUserLicensedTitleSearch = {
  name: '',
  model: {},
  sortCol: 'Title' as keyof typeof TABLE_COLUMN_NAME,
  sortDir: 'asc',
};

const formatDate = (seconds: number): string =>
  luxon.fromSeconds(seconds).setZone('America/Los_Angeles').toFormat('LL/dd/yyyy');

interface IRunLimitsTd {
  licensedTitleId: string;
  getPromise: (licensedTitleId: string) => Promise<IRunLimitRes>;
  isWindow?: boolean;
}

const RunLimits = ({licensedTitleId, getPromise, isWindow = false}: IRunLimitsTd): JSX.Element => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);
  const [runLimits, setRunLimits] = React.useState(0);
  const [windowText, setWindowText] = React.useState<string[]>(['N/A']);

  React.useEffect(() => {
    (async () => {
      setIsLoading(true);

      const url = generateRunLimitUri(licensedTitleId);

      try {
        const {data} = await getPromise(url);

        if (data && data.length > 0) {
          setRunLimits(data.length);

          if (isWindow) {
            const sorted = sortBy(data, rl => rl.startDate.seconds);

            let earliest = sorted[0].startDate.seconds;
            let latest = sorted[0].endDate.seconds;

            const windows: string[] = [];

            for (let i = 1; i < sorted.length; i++) {
              // More than a day gap
              if (latest + SECONDS_IN_DAY + 2 < sorted[i].startDate.seconds) {
                windows.push(`${formatDate(earliest)} - ${formatDate(latest)}`);

                earliest = sorted[i].startDate.seconds;
                latest = sorted[i].endDate.seconds;
              } else {
                if (earliest > sorted[i].startDate.seconds) {
                  earliest = sorted[i].startDate.seconds;
                }
                if (latest < sorted[i].endDate.seconds) {
                  latest = sorted[i].endDate.seconds;
                }
              }

              if (i === sorted.length - 1) {
                windows.push(`${formatDate(earliest)} - ${formatDate(latest)}`);
              }
            }

            if (windows.length === 0) {
              windows.push(`${formatDate(earliest)} - ${formatDate(latest)}`);
            }

            setWindowText(windows);
          }
        }
      } catch (e) {
        setHasError(true);
      }

      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPromise]);

  if (isLoading) {
    return <Spinner size='small' />;
  }

  if (hasError) {
    return <Help state='error'>Error Loading</Help>;
  }

  return <>{isWindow ? <Stack>{windowText.map(text => text)}</Stack> : runLimits}</>;
};

export default (): JSX.Element => {
  const history = useHistory();
  const {ableTo} = useAppPermissions();

  const {
    activeRegions,
    isFetching: isFetchingRegions,
    isError: isErrorRegions,
    isSuccess: isSuccessRegions,
  } = useUserRegions();

  const [searchLicensedTitles, licensedTitlesResponse] = useLazyFindQuery();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [isSearchExpanded, setIsSearchExpanded] = React.useState(false);

  const [currentSearch, setCurrentSearch] = React.useState<IUserLicensedTitleSearch | undefined>(defaultSearch);

  const [licensedTitles, setLicensedTitles] = React.useState<IListPayload<ILicensedTitle>>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isError, setIsError] = React.useState<boolean>();

  const handleSearch = React.useCallback((searchParams: IUserLicensedTitleSearch) => {
    setCurrentSearch(searchParams);
    setPage(0);
  }, []);

  const handleClear = () => {
    setPage(0);
    setRowsPerPage(25);
    setSortCol(defaultSearch.sortCol as keyof typeof TABLE_COLUMN_NAME);
    setSortDir(defaultSearch.sortDir);
    setCurrentSearch(defaultSearch);
  };

  const {getPromise} = useRunLimitCache<IRunLimitRes>();

  const [sortCol, setSortCol] = React.useState<keyof typeof TABLE_COLUMN_NAME>('Title');
  const [sortDir, setSortDir] = React.useState<TSortDirection>('asc');

  const performSearch = React.useCallback(
    (searchParams: IUserLicensedTitleSearch) => {
      searchLicensedTitles({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        sortfield: TABLE_COLUMN_NAME[searchParams.sortCol],
        sortdirection: searchParams.sortDir === 'asc' ? 1 : 2,
        ...searchParams.model,
      });
    },
    [page, rowsPerPage, searchLicensedTitles],
  );

  const transformSearch = React.useCallback(() => {
    if (currentSearch && !isFetchingRegions && !isErrorRegions) {
      performSearch({
        ...currentSearch,
        model: {
          ...currentSearch.model,
          regions:
            (currentSearch.model.regions || []).length === 0
              ? activeRegions.map(region => region.code.toLowerCase())
              : currentSearch.model.regions,
        },
      });
    }
  }, [activeRegions, currentSearch, isErrorRegions, isFetchingRegions, performSearch]);

  React.useEffect(() => {
    setLicensedTitles(licensedTitlesResponse?.data);
    setIsError(licensedTitlesResponse?.isError);

    if (
      licensedTitlesResponse?.isFetching ||
      licensedTitlesResponse?.isLoading ||
      licensedTitlesResponse?.isUninitialized
    ) {
      setIsLoading(true);
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    }
  }, [licensedTitlesResponse]);

  React.useEffect(() => {
    if (isSuccessRegions) {
      transformSearch();
    }
  }, [isSuccessRegions, transformSearch]);

  const handleEdit = (licensedTitle: ILicensedTitle) => {
    history.push(runLimitRoutes.paths.licensedTitlesEditPage.replace(':id', licensedTitle.id));
  };

  const changeSort = (columnName: keyof typeof TABLE_COLUMN_NAME) => {
    const newSortDir: TSortDirection = columnName === sortCol ? (sortDir === 'asc' ? 'dsc' : 'asc') : 'dsc';
    setSortCol(columnName);
    setSortDir(newSortDir);
    setCurrentSearch({
      ...currentSearch,
      sortCol: columnName,
      sortDir: newSortDir,
    } as IUserLicensedTitleSearch);
    setPage(0);
  };

  if (!ableTo('LICENSED_TITLES_VIEW')) {
    return <NotAuthorized />;
  }

  if (isError) {
    return <CrudError error='Error loading page data' />;
  }

  return (
    <Sidebar fullHeight={true}>
      <LicensedTitleSearchBar
        sortDir={sortDir}
        sortCol={sortCol}
        isFetching={isLoading}
        isSearchExpanded={isSearchExpanded}
        setIsSearchExpanded={setIsSearchExpanded}
        showFavoriteSearch={true}
        search={currentSearch}
        onSearch={handleSearch}
        onClear={handleClear}
      />
      <Cover
        scrolling={true}
        gutter='large'
        coverTemplateHeight='100%'
        overflow='auto'
        padding={{mobile: 'medium', wide: 'large'}}
      >
        <Template label='header'>
          <Cluster justify='space-between' align='center' space='medium'>
            <Cluster align='end' space='small'>
              <Heading level='h1'>Licensed Titles</Heading>
              <Cluster space='xxsmall' align='center'>
                <Icon
                  icon='tune'
                  space='xxxsmall'
                  verticalAlign='bottom'
                  lineHeight='0px'
                  onClick={() => setIsSearchExpanded(!isSearchExpanded)}
                >
                  {withThousandsSeparator(licensedTitles?.metadata.totalCount || 0)} Items
                </Icon>
              </Cluster>
            </Cluster>
          </Cluster>
        </Template>
        <Template label='cover'>
          <Box
            background='pewter'
            borderTop={true}
            borderSize='0.125rem'
            borderColor='cavern'
            paddingTop={((licensedTitles?.data || []).length as number) > 0 ? 'xsmall' : 'medium'}
            paddingBottom='none'
            paddingX='large'
            fullHeight={true}
          >
            <Table
              loading={isLoading}
              fixedHeader={true}
              wrapContent={true}
              id='lt-table'
              onSort={column => changeSort(column as keyof typeof TABLE_COLUMN_NAME)}
              sortDir={sortDir}
              sortCol={sortCol}
              cols={[
                {
                  label: 'Title',
                  sortable: true,
                  colMinWidth: '28.125rem',
                  transform: row => (
                    <TdLink
                      row={row}
                      title={row.title || row.titleClipData?.[0].title}
                      url={runLimitRoutes.paths.licensedTitlesEditPage.replace(':id', row.id)}
                    />
                  ),
                },
                {
                  label: 'Active Region(s)',
                  transform: row => row.activeRegion.toUpperCase(),
                  sortable: true,
                  colMinWidth: '10.625rem',
                },
                {
                  label: 'Partner',
                  sortable: true,
                  colMinWidth: '12.5rem',
                  transform: row => row.partnerName || row.titleClipData?.[0].partner?.name,
                },
                {
                  label: 'Pluto Avails ID',
                  field: 'plutoAvailsId',
                  colMinWidth: '12.5rem',
                },
                {
                  label: 'Avail. Window',
                  colMinWidth: '14.375rem',
                  transform: row => (
                    <RunLimits key={row.id} isWindow={true} licensedTitleId={row.id} getPromise={getPromise} />
                  ),
                },
                {
                  label: 'Programming Type',
                  colMinWidth: '14.375rem',
                  transform: row => (row.programmingType ? row.programmingType : 'N/A'),
                },
                {
                  label: 'Run Limits',
                  colMinWidth: '9.375rem',
                  transform: row => <RunLimits key={row.id} licensedTitleId={row.id} getPromise={getPromise} />,
                },
                ...(ableTo('LICENSED_TITLES_EDIT')
                  ? [
                      {
                        label: 'Actions',
                        colWidth: '6.25rem',
                        transform: row => (
                          <TableActions
                            row={row}
                            icons={['edit']}
                            onClick={(row, icon) => {
                              switch (icon) {
                                case 'edit':
                                  handleEdit(row);
                                  break;
                                default:
                              }
                            }}
                          />
                        ),
                      } as ITableCol<ILicensedTitle>,
                    ]
                  : []),
              ]}
              rows={licensedTitles?.data || []}
            >
              <Template label='loading'>
                <Cluster space='small' align='center'>
                  <Spinner />
                  <Paragraph>Loading Clips</Paragraph>
                </Cluster>
              </Template>
              <Template label='empty'>
                <Notification type='warning'>There are no clips currently available.</Notification>
              </Template>
            </Table>
          </Box>
        </Template>
        <Template label='footer'>
          <Cluster justify='space-between'>
            <div></div>
            {((licensedTitles?.data || []).length as number) > 0 && (
              <Pagination
                perPage={rowsPerPage as 25 | 50 | 75 | 100}
                currentPage={page}
                total={licensedTitles?.metadata.totalCount || 0}
                onPageChange={page => {
                  setPage(page);
                  document.getElementById('lt-table')?.parentElement?.scrollTo(0, 0);
                }}
                onPerPageChange={perPage => {
                  setRowsPerPage(perPage);
                  setPage(0);
                }}
              />
            )}
          </Cluster>
        </Template>
      </Cover>
    </Sidebar>
  );
};
