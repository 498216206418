import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {localStorageHelper} from '@pluto-tv/assemble';
import decodeJwt from 'jwt-decode';

export interface IUser {
  isAuthenticated: boolean;
  roles?: string[];
  email?: string;
  status?: string;
  name?: string;
  picture?: string;
  jwt?: string;
  regions?: string[];
}

const userSlice = createSlice({
  name: 'user',
  initialState: {roles: [], isAuthenticated: false} as IUser,
  reducers: {
    login: (state, {payload: {jwt}}: PayloadAction<{jwt: string}>) => {
      if (!jwt) return state;

      const user: Partial<IUser> = decodeJwt(jwt);
      state.isAuthenticated = true;
      state.roles = user.roles;
      state.email = user.email;
      state.status = user.status;
      state.name = user.name;
      state.picture = user.picture;
      state.jwt = jwt;
      state.regions = [];

      localStorageHelper.save('Jwt-Token', jwt);
    },
    logout: state => {
      state.isAuthenticated = false;
      state.roles = [];
      state.email = undefined;
      state.status = undefined;
      state.name = undefined;
      state.picture = undefined;
      state.jwt = undefined;
      state.regions = undefined;

      localStorageHelper.remove('Jwt-Token');
    },
    setProfile: (state, {payload: {regions}}: PayloadAction<{regions: string[]}>) => {
      state.regions = regions;
    },
  },
});

export const {login, logout, setProfile} = userSlice.actions;

export default userSlice.reducer;
