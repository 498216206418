import {IValidatorField} from '@pluto-tv/assemble';

function requiredField(validator: IValidatorField<any>): IValidatorField<any> {
  const required = (val?: string): string | undefined => {
    if (!val || !val.trim().length) {
      return `${validator.label} is required`;
    }
    return undefined;
  };

  return {
    ...validator,
    required: true,
    validators: (validator.validators || []).concat(required),
  };
}

function includeExcludeField(validator: IValidatorField<any>): IValidatorField<any> {
  const includeExcludeCheck = (newValues: string, model: Partial<any>): string | undefined => {
    const field = model[validator.name as string];
    if (
      (!newValues || !newValues.length) &&
      (!field?.exclude || !field?.exclude.length) &&
      (!field?.include || !field?.include.length)
    ) {
      return `Included ${validator.label} OR Excluded ${validator.label} is required`;
    }

    return undefined;
  };

  return {
    ...validator,
    required: true,
    validators: (validator.validators || []).concat(includeExcludeCheck),
  };
}

export {requiredField, includeExcludeField};
