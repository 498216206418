import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {RootState} from 'app/store';
import {IAssetUploadUrlRequest, IAssetUploadUrlResponse} from 'models/assets';

export const assetsApi = createApi({
  reducerPath: 'assetsApi',
  tagTypes: ['Assets'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/v1/',
    prepareHeaders(headers, {getState}) {
      const token = (getState() as RootState).user.jwt;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    createAssetUploadUrl: builder.mutation<IAssetUploadUrlResponse, IAssetUploadUrlRequest>({
      query: ({contentType, contentId, assetType, mimeType, size}) => ({
        url: `assets/${contentType}/${contentId}/${assetType}`,
        body: {mimeType, size},
        method: 'POST',
      }),
    }),
  }),
});

export const {useCreateAssetUploadUrlMutation} = assetsApi;
