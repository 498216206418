import {skipToken} from '@reduxjs/toolkit/query/react';
import * as React from 'react';
import {
  Button,
  Cover,
  Cluster,
  DateTime,
  Dialog,
  FilePicker,
  FormItem,
  Grid,
  Heading,
  Pagination,
  Select,
  Stack,
  Table,
  Template,
  TextInput,
  Box,
  Spinner,
  Notification,
  IFilePickerVerify,
  Popover,
  ImageWrapper,
  Icon,
  Center,
} from '@pluto-tv/assemble';
import {debounce, find, gte, sortBy} from 'lodash-es';

import {ICampaignSlot, IContentImageObj, ISlotImage} from 'models/campaigns';
import {IContent} from 'models/content';

import {useFindQuery} from 'features/content/contentApi';
import {useFindImagesQuery, useCreateContentUploadUrlMutation} from 'features/campaigns/campaignsApi';
import {roundUp} from 'helpers/roundNumber';
import {rewriteImageUrl} from 'utils/imageUtils';

const TABLE_COLUMN_NAME = {
  Title: 'name',
  'Episode Title': 'name',
  'Channel Name': 'name',
  'Collection Name': 'name',
  Author: 'author',
  Rating: 'rating',
  'Created At': 'createdAt',
  '# of E': 'episodeCount',
  '# of S': 'seasonCount',
  'Release Year': 'originalReleaseDate',
  'Start Availability': 'availabilityStartDate',
  'End Availability': 'availabilityEndDate',
  'Kids Mode': 'kidsMode',
  'Office Only': 'plutoOfficeOnly',
  Duration: 'duration',
  Number: 'number',
  'Main Categories': 'mainCategories',
  'Legacy Category': 'category',
  'Display Name': 'displayName',
} as const;

type contentType = 'series' | 'movie' | 'channel' | 'vodcategory';

enum movieSteps {
  none = 0, // "movie" tab is not selected
  series = 1, // select series on movie tab
  movie = 2, // select movie on movie tab
  asset = 3, // select a movie asset
}

export interface IPrimetimeBillboardSearchProps {
  isOpen: boolean;
  position: number;
  billboard?: ICampaignSlot;
  replacingImages?: boolean;
  region?: string;
  campaignID: string;
  startDate?: Date;
  endDate?: Date;
  closeHandler: () => void;
  contentFoundHandler: (position: number, content: ICampaignSlot) => void;
}

interface IImageOption {
  label: string;
  contentId?: string;
  contentType?: string;
  imageAltText?: string;
  imageSrc?: string;
  url?: string;
}

type TSelectedContent = Pick<IContent, '_id' | 'type' | 'name'>;

export default ({
  isOpen,
  position,
  billboard,
  closeHandler,
  replacingImages = false,
  region = '',
  startDate,
  endDate,
  campaignID,
  contentFoundHandler,
}: IPrimetimeBillboardSearchProps): JSX.Element => {
  const [page, setPage] = React.useState(0);
  const [contentType, setContentType] = React.useState<contentType>('series');
  const [contentTypeOption, setContentTypeOption] = React.useState<contentType>(contentType);
  const [search, setSearch] = React.useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState<25 | 50 | 75 | 100>(25);
  const [selectedRow, setSelectedRow] = React.useState<IContent>();
  const [selectedContent, setSelectedContent] = React.useState<TSelectedContent>();
  const [sortDir, setSortDir] = React.useState<'asc' | 'dsc'>('asc');
  const [tileImage, setTileImage] = React.useState<string>();
  const [backgroundImage, setBackgroundImage] = React.useState<string>();
  const [assetTitle, setAssetTitle] = React.useState<string>();
  const [tileOptions, setTileOptions] = React.useState<IImageOption[]>([]);
  const [backgroundOptions, setBackgroundOptions] = React.useState<IImageOption[]>([]);
  const [tileValue, setTileValue] = React.useState<IImageOption>({label: ''});
  const [backgroundValue, setBackgroundValue] = React.useState<IImageOption>({label: ''});
  const [tileEpisodeValue, setTileEpisodeValue] = React.useState<IImageOption>({label: ''});
  const [tilePosterEpisodeValue, setTilePosterEpisodeValue] = React.useState<IImageOption>({label: ''});
  const [backgroundPosterEpisodeValue, setBackgroundPosterEpisodeValue] = React.useState<IImageOption>({label: ''});
  const [backgroundEpisodeValue, setBackgroundEpisodeValue] = React.useState<IImageOption>({label: ''});
  const [episodeOptions, setEpisodeOptions] = React.useState<IImageOption[]>([]);
  const [posterEpisodeOptions, setPosterEpisodeOptions] = React.useState<IImageOption[]>([]);
  const [defaultTile, setDefaultTile] = React.useState<string>();
  const [defaultBackground, setDefaultBackground] = React.useState<string>();
  const [defaultPoster, setDefaultPoster] = React.useState<string>();
  const [episodePickerError, setEpisodePickerError] = React.useState(false);
  const [featuredImageError, setFeaturedImageError] = React.useState(false);
  const [sortCol, setSortCol] = React.useState<keyof typeof TABLE_COLUMN_NAME>('Title');
  const [hasBackgroundPoster, setHasBackgroundPoster] = React.useState<{label: string; posterUrl: string} | undefined>(
    undefined,
  );
  const [hasTilePoster, setHasTilePoster] = React.useState<{label: string; posterUrl: string} | undefined>(undefined);

  const [customTileImage, setCustomTileImage] = React.useState<File | undefined>();
  const [customBackgroundImage, setCustomBackgroundImage] = React.useState<File | undefined>();

  const [movieStep, setMovieStep] = React.useState<movieSteps>(movieSteps.none);
  const [movieSeries, setMovieSeries] = React.useState<{_id?: string; poster16_9?: string} | undefined>();
  const [assetSelector, setAssetSelector] = React.useState(!replacingImages);
  const toggleAssetSelector = () => {
    setAssetSelector(!assetSelector);
  };
  const [displayTilePreview, setDisplayTilePreview] = React.useState<boolean>(true);
  const [displayBackgroundPreview, setDisplayBackgroundPreview] = React.useState<boolean>(true);
  const [hasSeriesPoster, setHasSeriesPoster] = React.useState<boolean>(false);
  const [hasEpisodePoster, setHasEpisodePoster] = React.useState<boolean>(false);
  const [heading, setHeading] = React.useState<string>('Select Content for Slot');

  const canBeOpen = isOpen && region.length > 0 && startDate && endDate ? true : false;

  const getColumnName = contentType => {
    if (contentType === 'series') {
      return 'Title' as string;
    } else if (contentType === 'movie') {
      return 'Episode Title';
    } else if (contentType === 'channel') {
      return 'Channel Name';
    }
    return 'Collection Name';
  };

  const convertDuration = duration => {
    return duration ? new Date(duration * 1000).toISOString().substr(11, 8) : null;
  };

  const getImageTile = row => (contentType === 'series' ? row.imageTile : row.image);

  const changeSort = (columnName, sortDir) => {
    setSortDir(sortDir === 'asc' ? 'dsc' : 'asc');
    setSortCol(columnName as keyof typeof TABLE_COLUMN_NAME);
  };

  const changeMovieStep = (forwardOrBackward = 'forward'): void => {
    const stepsDictionary = {
      [movieSteps.none]: {
        forward: () => {
          setContentType('series');
          setMovieStep(movieSteps.series);
        },
        backward: () => null,
      },
      [movieSteps.series]: {
        forward: () => {
          setMovieSeries({
            _id: selectedRow?._id,
            poster16_9: selectedRow?.poster16_9,
          });
          setContentType('movie');
          setMovieStep(movieSteps.movie);
        },
        backward: () => null,
      },
      [movieSteps.movie]: {
        forward: () => {
          setMovieStep(movieSteps.asset);
          toggleAssetSelector();
        },
        backward: () => {
          setContentType('series');
          setMovieStep(movieSteps.series);
        },
      },
      [movieSteps.asset]: {
        forward: () => null,
        backward: () => {
          setMovieStep(movieSteps.movie);
          setContentType('movie');
          toggleAssetSelector();
        },
      },
    };

    stepsDictionary[movieStep][forwardOrBackward]();
  };

  const forceRefetch = (tab1: string, tab2: string) => {
    const fromSeriesToMovie = tab1 === 'series' && tab2 === 'movie';
    const fromMovieToSeries = tab1 === 'movie' && tab2 === 'series';
    return fromSeriesToMovie || fromMovieToSeries;
  };

  const changeType = (contentType: contentType) => {
    if (forceRefetch(contentTypeOption, contentType)) {
      refetch();
    }

    setContentTypeOption(contentType);
    if (contentType === 'movie') {
      changeMovieStep();
    } else {
      setContentType(contentType);
      setMovieStep(movieSteps.none);
      setMovieSeries(undefined);
    }
    setSortDir('asc');
    setSortCol('Title');
  };
  const hideChannelVodTab = contentType !== 'channel' && contentType !== 'vodcategory';
  const hideSeriesMovieTab = contentType !== 'series' && contentType !== 'movie';

  const resetTable = (resetSearch = false, resetSort = false) => {
    if (resetSearch) {
      setSearch('');
    }

    if (resetSort) {
      setSortDir('asc');
    }

    setPage(0);
    setSelectedRow(undefined);
  };

  const {
    data: content,
    isFetching,
    refetch,
  } = useFindQuery(
    {
      campaignID,
      search,
      sort: `${TABLE_COLUMN_NAME[sortCol]}:${sortDir}`,
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      type: contentType,
      series: movieSeries?._id,
    },
    {refetchOnMountOrArgChange: true},
  );

  const [createContentUploadUrl] = useCreateContentUploadUrlMutation();

  const {
    data: contentImages,
    isFetching: isFetchingImages,
    isError: isErrorImages,
  } = useFindImagesQuery(
    selectedContent && !assetSelector
      ? {
          campaignId: campaignID,
          type: selectedContent.type,
          contentId: selectedContent._id,
        }
      : skipToken,
    {refetchOnMountOrArgChange: true},
  );

  const setupImagePicker = React.useCallback(
    (selectedAsset?: Partial<IContent>) => {
      let assetTitle = '';
      const options = [{label: 'Upload File'}];
      if (selectedAsset) {
        setSelectedContent({
          _id: selectedAsset._id!,
          name: selectedAsset.name!,
          type: selectedAsset.type!,
        });

        assetTitle = selectedAsset.name!;

        if (selectedAsset.type === 'movie') {
          options.unshift({label: 'Series Featured'}, {label: 'Episode 16x9'});

          if (!replacingImages) {
            setTileValue({label: 'Episode 16x9'});
            setBackgroundValue({label: 'Series Featured'});
          }
        } else if (selectedAsset.type === 'channel') {
          const channelOptions = {
            label: 'Channel Featured',
          };

          options.unshift(channelOptions);

          if (!replacingImages) {
            setTileValue(channelOptions);
            setBackgroundValue(channelOptions);
          }
        } else if (selectedAsset.type === 'vodcategory') {
          const vodOptions = {
            label: 'VOD Featured',
          };

          options.unshift(vodOptions);

          if (!replacingImages) {
            setTileValue(vodOptions);
            setBackgroundValue(vodOptions);
          }
        } else if (selectedAsset.type === 'series') {
          if (!replacingImages) {
            setTileValue({label: 'Episode 16x9'});
            setBackgroundValue({label: 'Series Featured'});
          }
          options.unshift({label: 'Series Featured'}, {label: 'Episode 16x9'});
        }
      } else {
        setSelectedContent(undefined);
      }
      setAssetTitle(assetTitle);
      setTileOptions(options);
      setBackgroundOptions(options);
    },
    [replacingImages],
  );

  React.useEffect(() => {
    resetTable(true, true);
  }, [contentType, contentTypeOption]);

  React.useEffect(() => {
    if (contentImages) {
      // Reset artwork selection to default when new asset is selected
      setTileImage(undefined);
      setBackgroundImage(undefined);
      setEpisodePickerError(false);
      setFeaturedImageError(false);
      setHasEpisodePoster(false);
      setHasSeriesPoster(false);

      const allImages = [...(contentImages.tileImages || []), ...(contentImages.backgroundImages || [])];
      const hasEpPoster = allImages.some(x => x.posterUrl !== '' && x.contentType === 'movie');
      setHasEpisodePoster(hasEpPoster);

      const defaultTile =
        replacingImages && billboard
          ? find(allImages, img => img.contentId === billboard.tileImage.contentId)
          : find(contentImages.tileImages || [], img => img.isDefault);
      const defaultPoster = contentImages.poster16_9;
      const defaultBackground = find(contentImages.backgroundImages || [], img => img.isDefault);

      if (defaultTile) {
        setDefaultTile(rewriteImageUrl(defaultTile.url));
      } else {
        setDefaultTile(undefined);
      }

      if (defaultPoster) {
        setDefaultPoster(rewriteImageUrl(defaultPoster));
      } else {
        setDefaultPoster(undefined);
      }

      if (defaultBackground) {
        setDefaultBackground(rewriteImageUrl(defaultBackground.url));
      } else {
        setDefaultBackground(undefined);
        setFeaturedImageError(true);
      }

      if (contentImages.type === 'series' || contentImages.type === 'movie') {
        const episodes = sortBy(
          (contentImages.tileImages || [])
            .filter(img => gte(img.season, 0))
            .map(img => ({
              contentId: img.contentId,
              label: `S${roundUp(img.season)}E${roundUp(img.number)} - ${img.name}`,
              url: rewriteImageUrl(img.url),
              imageSrc: rewriteImageUrl(img.url),
              imageAltText: img.name,
            })),
          'label',
        );
        setEpisodeOptions(episodes);

        const episodesPoster = sortBy(
          (contentImages.tileImages || [])
            .filter(img => gte(img.season, 0) && (hasEpPoster ? img.posterUrl : true))
            .map(img => ({
              contentId: img.contentId,
              label: `S${roundUp(img.season)}E${roundUp(img.number)} - ${img.name}`,
              url: rewriteImageUrl(img.posterUrl),
              imageSrc: rewriteImageUrl(img.posterUrl),
              imageAltText: img.name,
            })),
          'label',
        );
        setPosterEpisodeOptions(episodesPoster);

        const checkPoster = (img: IContentImageObj) => {
          if (hasEpPoster) {
            // It is a new selection or content replacement
            if (!replacingImages) {
              return !!img?.posterUrl;
            }

            const bgUrl = rewriteImageUrl(billboard?.backgroundImage.url);
            const tileUrl = rewriteImageUrl(billboard?.tileImage.url);
            const posterUrl = rewriteImageUrl(img?.posterUrl);

            // It is a image replacement, but we are picking a episode poster
            if (
              billboard?.backgroundImage.contentType !== img.contentType ||
              billboard?.backgroundImage.contentType !== img.contentType
            ) {
              return !!img?.posterUrl;
            }

            // It is a image replacement, pick the selected poster if is the case
            if (tileUrl.includes('poster16_9') || bgUrl.includes('poster16_9')) {
              return !!img?.posterUrl && [bgUrl, tileUrl].includes(posterUrl);
            }
          }

          // Otherwise, select the default
          return img.isDefault && gte(img.season, 0);
        };

        const defaultPosterEpisodeImage: IContentImageObj = find(
          contentImages.tileImages || [],
          checkPoster,
        ) as IContentImageObj;

        if (defaultPosterEpisodeImage) {
          const defaultPosterEpisodeMapped = {
            label: `S${roundUp(defaultPosterEpisodeImage.season)}E${roundUp(defaultPosterEpisodeImage.number)} - ${
              defaultPosterEpisodeImage.name
            }`,
            url: rewriteImageUrl(defaultPosterEpisodeImage.posterUrl),
            contentId: defaultPosterEpisodeImage.contentId,
          };
          setTilePosterEpisodeValue(defaultPosterEpisodeMapped);
          setBackgroundPosterEpisodeValue(defaultPosterEpisodeMapped);
        } else {
          setTilePosterEpisodeValue({label: ''});
          setBackgroundPosterEpisodeValue({label: ''});
          setEpisodePickerError(true);
        }

        const defaultEpisodeImage: IContentImageObj = find(
          contentImages.tileImages || [],
          img => img.isDefault && gte(img.season, 0),
        ) as IContentImageObj;

        if (defaultEpisodeImage) {
          const defaultEpisodeMapped = {
            label: `S${roundUp(defaultEpisodeImage.season)}E${roundUp(defaultEpisodeImage.number)} - ${
              defaultEpisodeImage.name
            }`,
            url: rewriteImageUrl(defaultEpisodeImage.url),
            contentId: defaultEpisodeImage.contentId,
          };

          setTileEpisodeValue(defaultEpisodeMapped);
          setBackgroundEpisodeValue(defaultEpisodeMapped);
        } else {
          setTileEpisodeValue({label: ''});
          setBackgroundEpisodeValue({label: ''});
          setEpisodePickerError(true);
        }
      } else {
        setEpisodeOptions([]);
      }

      if (replacingImages && billboard) {
        let setTile = false;
        let setBackground = false;

        // Custom artwork
        if (billboard.tileImage.isCustomUpload) {
          setTile = true;
          setTileValue({label: 'Upload File'});

          // Using setTimeout to defer the state change since setTileValue will fight it
          setTimeout(() => setTileImage(rewriteImageUrl(billboard.tileImage.url)));
        }

        if (billboard.backgroundImage.isCustomUpload) {
          setBackground = true;
          setBackgroundValue({label: 'Upload File'});

          // Using setTimeout to defer the state change since setBackgroundValue will fight it
          setTimeout(() => setBackgroundImage(rewriteImageUrl(billboard.backgroundImage.url)));
        }

        if (!setTile && hasTilePoster?.label) {
          setTile = true;
          setTileValue(hasTilePoster);
          // Using setTimeout to defer the state change since setTileValue will fight it
          setTimeout(() => setTileImage(rewriteImageUrl(billboard.tileImage.url)));
        }

        if (!setBackground && hasBackgroundPoster?.label) {
          setBackground = true;
          setBackgroundValue(hasBackgroundPoster);
          // Using setTimeout to defer the state change since setBackgroundValue will fight it
          setTimeout(() => setBackgroundImage(rewriteImageUrl(billboard.backgroundImage.url)));
        }

        // Episode artwork
        if (!setTile && (billboard.tileImage.contentType === 'movie' || billboard.tileImage.contentType === 'series')) {
          const pickedEpisodeImage: IContentImageObj = find(
            contentImages.tileImages || [],
            img => gte(img.season, 0) && img.contentId === billboard.tileImage.contentId,
          ) as IContentImageObj;

          if (pickedEpisodeImage) {
            setTile = true;

            const pickedEpisodeMapped = {
              label: `S${roundUp(pickedEpisodeImage.season)}E${roundUp(pickedEpisodeImage.number)} - ${
                pickedEpisodeImage.name
              }`,
              url: rewriteImageUrl(pickedEpisodeImage.url),
              contentId: pickedEpisodeImage.contentId,
            };

            setTileEpisodeValue(pickedEpisodeMapped);
            setTileValue({label: 'Episode 16x9'});
          }
        }

        if (
          !setBackground &&
          (billboard.tileImage.contentType === 'movie' || billboard.tileImage.contentType === 'series')
        ) {
          const pickedEpisodeImage: IContentImageObj = find(
            contentImages.tileImages || [],
            img => gte(img.season, 0) && img.contentId === billboard.backgroundImage.contentId,
          ) as IContentImageObj;

          if (pickedEpisodeImage) {
            setBackground = true;
            const pickedEpisodeMapped = {
              label: `S${roundUp(pickedEpisodeImage.season)}E${roundUp(pickedEpisodeImage.number)} - ${
                pickedEpisodeImage.name
              }`,
              url: rewriteImageUrl(pickedEpisodeImage.url),
            };
            setBackgroundEpisodeValue(pickedEpisodeMapped);
            setBackgroundValue({label: 'Episode 16x9'});
          }
        }

        // 'Featured' artwork
        if (!setTile) {
          let labelTitle = ' Featured';
          const pickedTile = find(allImages, img => img.isDefault && img.contentId === billboard.tileImage.contentId);

          if (pickedTile) {
            setTile = true;

            if (billboard.tileImage.contentType === 'movie' || billboard.tileImage.contentType === 'series') {
              labelTitle = `Series${labelTitle}`;
            } else if (billboard.tileImage.contentType === 'channel') {
              labelTitle = `Channel${labelTitle}`;
            } else if (billboard.tileImage.contentType === 'vodcategory') {
              labelTitle = `VOD${labelTitle}`;
            }

            setTileValue({label: labelTitle});
          }
        }

        if (!setBackground) {
          let labelTitle = ' Featured';
          const pickedTile = find(
            allImages,
            img => img.isDefault && img.contentId === billboard.backgroundImage.contentId,
          );

          if (pickedTile) {
            setBackground = true;

            if (
              billboard.backgroundImage.contentType === 'movie' ||
              billboard.backgroundImage.contentType === 'series'
            ) {
              labelTitle = `Series${labelTitle}`;
            } else if (billboard.backgroundImage.contentType === 'channel') {
              labelTitle = `Channel${labelTitle}`;
            } else if (billboard.backgroundImage.contentType === 'vodcategory') {
              labelTitle = `VOD${labelTitle}`;
            }

            setBackgroundValue({label: labelTitle});
          }
        }

        // Fallback to asset defaults
        if (!setTile) {
          const defaultTile = find(contentImages.tileImages || [], img => img.isDefault);

          if (defaultTile) {
            setEpisodePickerError(false);
            setDefaultTile(rewriteImageUrl(defaultTile.url));

            if (billboard.contentType === 'movie' || billboard.contentType === 'series') {
              setTileValue({label: 'Episode 16x9'});
            } else if (billboard.contentType === 'channel') {
              setTileValue({label: 'Channel Featured'});
            } else if (billboard.contentType === 'vodcategory') {
              setTileValue({label: 'VOD Featured'});
            }
          }
        }

        if (!setBackground) {
          const defaultBackground = find(contentImages.backgroundImages || [], img => img.isDefault);

          if (defaultBackground) {
            setFeaturedImageError(false);
            setDefaultBackground(rewriteImageUrl(defaultBackground.url));

            if (billboard.contentType === 'movie' || billboard.contentType === 'series') {
              setBackgroundValue({label: 'Series Featured'});
            } else if (billboard.contentType === 'channel') {
              setBackgroundValue({label: 'Channel Featured'});
            } else if (billboard.contentType === 'vodcategory') {
              setBackgroundValue({label: 'VOD Featured'});
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentImages]);

  React.useEffect(() => {
    resetTable();
  }, [sortDir]);

  React.useEffect(() => {
    setSelectedRow(undefined);
  }, [page]);

  React.useEffect(() => {
    // If replacing a series/movie billboard that contains poster
    if (replacingImages && ['series', 'movie'].includes(contentImages?.type || '')) {
      const tileUrl = billboard?.tileImage.url || '';
      const tileType = billboard?.tileImage.contentType;
      const hasTilePosterUrl = tileUrl?.includes('poster16_9');
      const bgUrl = billboard?.backgroundImage.url || '';
      const bgType = billboard?.backgroundImage.contentType;
      const hasBgPosterUrl = bgUrl?.includes('poster16_9');

      if (hasTilePosterUrl) {
        const tileLabel = `${tileType === 'series' ? 'Series' : 'Episode'} 16x9 Poster`;
        setHasTilePoster({
          label: tileLabel,
          posterUrl: tileUrl,
        });
        setTileValue({label: tileLabel});
      } else {
        setHasTilePoster(undefined);
      }

      if (hasBgPosterUrl) {
        const bgLabel = `${bgType === 'series' ? 'Series' : 'Episode'} 16x9 Poster`;
        setHasBackgroundPoster({
          label: bgLabel,
          posterUrl: bgUrl,
        });
        setBackgroundValue({label: bgLabel});
      } else {
        setHasBackgroundPoster(undefined);
      }
    }
  }, [replacingImages, contentImages, billboard?.contentType, billboard?.backgroundImage, billboard?.tileImage]);

  React.useEffect(() => {
    // Clean out the image pickers if you go back to the asset search page
    if (assetSelector) {
      setTileImage(undefined);
      setBackgroundImage(undefined);
    }
  }, [assetSelector]);

  React.useEffect(() => {
    setupImagePicker(selectedRow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow]);

  React.useEffect(() => {
    if (replacingImages && billboard) {
      setAssetSelector(!replacingImages);
      const newTilePoster = billboard?.tileImage.url.includes('poster16_9') || false;
      const newBgPoster = billboard?.backgroundImage.url.includes('poster16_9') || false;
      const poster16_9 = newTilePoster ? billboard.tileImage.url : newBgPoster ? billboard.backgroundImage.url : '';
      setupImagePicker({
        _id: billboard.contentId,
        type: billboard.contentType,
        name: billboard.namePreview,
        tilePoster16_9: hasTilePoster?.posterUrl,
        backgroundPoster16_9: hasBackgroundPoster?.posterUrl,
        poster16_9,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billboard, replacingImages, hasTilePoster, hasBackgroundPoster]);

  React.useEffect(() => {
    if (!tileValue || !tileValue.label) {
      return;
    }

    switch (tileValue.label) {
      case 'Upload File':
        setTileImage(undefined);
        break;
      case 'Episode 16x9':
        setTileImage(rewriteImageUrl(tileEpisodeValue?.url as string));
        break;
      case 'Episode 16x9 Poster':
        const tileEpisodePoster = tilePosterEpisodeValue?.url || defaultPoster;
        setTileImage(rewriteImageUrl(tileEpisodePoster as string));
        break;
      case 'Series 16x9 Poster':
        const tileSeriesPoster =
          hasTilePoster?.label === 'Series 16x9 Poster'
            ? hasTilePoster?.posterUrl
            : movieSeries?.poster16_9 || defaultPoster;

        setTileImage(rewriteImageUrl(tileSeriesPoster as string));
        break;
      default:
        setTileImage(rewriteImageUrl(defaultBackground as string));
        break;
    }
  }, [
    tileValue,
    tileEpisodeValue,
    defaultTile,
    defaultBackground,
    defaultPoster,
    movieSeries,
    tilePosterEpisodeValue,
    hasTilePoster,
  ]);

  React.useEffect(() => {
    if (!backgroundValue || !backgroundValue.label) {
      return;
    }

    switch (backgroundValue.label) {
      case 'Upload File':
        setBackgroundImage(undefined);
        break;
      case 'Episode 16x9':
        setBackgroundImage(rewriteImageUrl(backgroundEpisodeValue?.url as string));
        break;
      case 'Episode 16x9 Poster':
        const bgEpisodeposter = backgroundPosterEpisodeValue?.url || defaultPoster;
        setBackgroundImage(rewriteImageUrl(bgEpisodeposter as string));
        break;
      case 'Series 16x9 Poster':
        const bgSeriesposter =
          hasBackgroundPoster?.label === 'Series 16x9 Poster'
            ? hasBackgroundPoster?.posterUrl
            : movieSeries?.poster16_9 || defaultPoster;

        setBackgroundImage(rewriteImageUrl(bgSeriesposter as string));
        break;
      default:
        setBackgroundImage(rewriteImageUrl(defaultBackground as string));
        break;
    }
  }, [
    backgroundValue,
    backgroundEpisodeValue,
    defaultBackground,
    defaultPoster,
    movieSeries,
    backgroundPosterEpisodeValue,
    hasBackgroundPoster,
  ]);

  React.useEffect(() => {
    if (contentImages?.type === 'series') {
      setHasSeriesPoster(!!contentImages?.poster16_9);
    }

    if (contentImages?.type === 'movie') {
      // Because movie contentImages default bg is from series
      const seriesInfo = contentImages.backgroundImages.find(img => img.posterUrl);

      if (seriesInfo) {
        setMovieSeries({
          _id: seriesInfo?.contentId,
          poster16_9: seriesInfo?.posterUrl,
        });
        setHasSeriesPoster(!!seriesInfo);
      }
    }
  }, [replacingImages, contentImages]);

  React.useEffect(() => {
    if (hasEpisodePoster) {
      setTileOptions(tOptions => includeOption(tOptions, 'Episode 16x9 Poster'));
      setBackgroundOptions(bgOptions => includeOption(bgOptions, 'Episode 16x9 Poster'));
    }

    if (hasSeriesPoster) {
      setTileOptions(tOptions => includeOption(tOptions, 'Series 16x9 Poster'));
      setBackgroundOptions(bgOptions => includeOption(bgOptions, 'Series 16x9 Poster'));
    }
  }, [hasSeriesPoster, replacingImages, hasEpisodePoster]);

  const includeOption = (currentOptions: {label: string}[], newOption: string) => {
    if (currentOptions.find(e => e?.label === newOption)) {
      return currentOptions;
    }
    const newOptions = Array.from(currentOptions);
    newOptions.splice(currentOptions.length - 1, 0, {label: newOption});
    return newOptions;
  };

  const handleFilePickerPreview = (url?: string, previewType = 'tile') => {
    const previews = {
      tile: {
        id: 'tile-file-picker',
        setDisplayPreview: setDisplayTilePreview,
      },
      background: {
        id: 'background-file-picker',
        setDisplayPreview: setDisplayBackgroundPreview,
      },
    };

    const img = new Image();
    const currentPreview = previews[previewType];

    if (!url) {
      currentPreview.setDisplayPreview(true);
      return;
    }

    img.onload = function () {
      currentPreview.setDisplayPreview(true);
    };
    img.onerror = function () {
      currentPreview.setDisplayPreview(false);
    };
    img.src = url;
  };

  const searchTitle = debounce(
    searchStr => {
      setSearch(searchStr);
      resetTable(false, true);
    },
    500,
    {leading: false, trailing: true},
  );

  const validateImage = async ([image]: File[]): Promise<IFilePickerVerify> => {
    const resolutionValidationPromise = new Promise<IFilePickerVerify>(resolve => {
      if (!image) {
        return resolve({error: true, errorMsg: {header: 'Please select an image'}});
      }

      // Image type: jpeg/jpg
      const extension = image.name.split('.').pop()?.toLowerCase();
      if (!extension || !['jpg', 'jpeg'].includes(extension)) {
        return resolve({
          error: true,
          errorMsg: {
            header: 'Error',
            msgOne: '16:9 images and JPG format only.',
            msgTwo: `'${extension}' extension is invalid.`,
          },
        });
      }

      // Max file size: 2048 KB
      const imageSize = image.size / 1024;
      if (imageSize > 2048) {
        return resolve({
          error: true,
          errorMsg: {
            header: 'Error',
            msgOne: 'Max allowed file size is 2048 KB.',
            msgTwo: `${Math.ceil(imageSize)} KB is invalid.`,
          },
        });
      }

      // Image must be 16:9
      const imageInstance = new Image();
      imageInstance.src = URL.createObjectURL(image);
      imageInstance.onload = () => {
        const {width, height} = imageInstance;

        if (width / height !== 16 / 9) {
          return resolve({
            error: true,
            errorMsg: {
              header: 'Error',
              msgOne: 'Image must be 16:9 Aspect Ratio',
              msgTwo: `${width}px (w) x ${height}px (h) is invalid.`,
            },
          });
        }

        // Min resolution: 960px (w) x 540px
        if (width < 960) {
          return resolve({
            error: true,
            errorMsg: {
              header: 'Error',
              msgOne: 'Minimum resolution is 960px (w) x 540px (h).',
              msgTwo: `${width}px (w) x ${height}px (h) is invalid.`,
            },
          });
        }

        return resolve({
          error: false,
        });
      };
    });

    return resolutionValidationPromise;
  };

  const uploadCustomImage = async (content: TSelectedContent, imageType: string, image: File): Promise<string> => {
    const result = await createContentUploadUrl({
      campaignId: campaignID,
      contentId: content._id,
      contentType: content.type,
      imageType: imageType,
      mimeType: image.type,
      size: image.size,
    }).unwrap();

    await fetch(result.uploadUrl, {
      method: 'PUT',
      headers: {
        'x-amz-acl': 'public-read',
      },
      body: image,
    });

    return result.imageUrl;
  };

  const selectContent = async () => {
    if (
      !selectedContent ||
      !contentImages ||
      !(tileImage || customTileImage) ||
      !(backgroundImage || customBackgroundImage)
    ) {
      return;
    }

    // Aditional check if is a series from movie steps
    const movieSeriesTileContent: {_id?: string; type?: 'series'} = {};
    const movieSeriesBgContent: {_id?: string; type?: 'series'} = {};

    const tileImageUrl =
      tileValue.label === 'Upload File'
        ? await uploadCustomImage(selectedContent, 'tile', customTileImage as File)
        : tileImage;

    if (replacingImages && rewriteImageUrl(movieSeries?.poster16_9) === rewriteImageUrl(tileImageUrl)) {
      movieSeriesTileContent._id = movieSeries?._id;
      movieSeriesTileContent.type = 'series';
    }

    const tileImageData: ISlotImage = {
      contentId: movieSeriesTileContent._id || selectedContent._id,
      contentType: movieSeriesTileContent.type || selectedContent.type,
      url: rewriteImageUrl(tileImageUrl),
      isCustomUpload: tileValue.label === 'Upload File',
    };

    const backgroundImageUrl =
      backgroundValue.label === 'Upload File'
        ? await uploadCustomImage(selectedContent, 'background', customBackgroundImage as File)
        : backgroundImage;

    if (replacingImages && rewriteImageUrl(movieSeries?.poster16_9) === rewriteImageUrl(backgroundImageUrl)) {
      movieSeriesBgContent._id = movieSeries?._id;
      movieSeriesBgContent.type = 'series';
    }

    const backgroundImageData: ISlotImage = {
      contentId: movieSeriesBgContent._id || selectedContent._id,
      contentType: movieSeriesBgContent.type || selectedContent.type,
      url: rewriteImageUrl(backgroundImageUrl),
      isCustomUpload: backgroundValue.label === 'Upload File',
    };

    // Loop through all the images and figure out the correct meta data for tileImage and backgroundImage
    [...(contentImages.tileImages || []), ...(contentImages.backgroundImages || [])].forEach(img => {
      if (rewriteImageUrl(img.url) === rewriteImageUrl(tileImage)) {
        tileImageData.url = rewriteImageUrl(tileImage);
        tileImageData.contentId = img.contentId;
        tileImageData.contentType = img.contentType;
      }

      // If the selected tile is a poster16x9
      if (tileImageUrl && rewriteImageUrl(img?.posterUrl) === rewriteImageUrl(tileImageUrl)) {
        tileImageData.url = rewriteImageUrl(tileImage);
        tileImageData.contentId = img.contentId;
        tileImageData.contentType = img.contentType;
      }

      if (rewriteImageUrl(img.url) === rewriteImageUrl(backgroundImage)) {
        backgroundImageData.url = rewriteImageUrl(backgroundImage);
        backgroundImageData.contentId = img.contentId;
        backgroundImageData.contentType = img.contentType;
      }

      // If the selected bg is a poster16x9
      if (backgroundImageUrl && rewriteImageUrl(img?.posterUrl) === rewriteImageUrl(backgroundImageUrl)) {
        backgroundImageData.url = rewriteImageUrl(backgroundImage);
        backgroundImageData.contentId = img.contentId;
        backgroundImageData.contentType = img.contentType;
      }
    });

    const newSlot: ICampaignSlot =
      replacingImages && billboard
        ? {
            ...billboard,
            tileImage: tileImageData,
            backgroundImage: backgroundImageData,
          }
        : {
            contentId: selectedContent._id,
            contentType: selectedContent.type,
            tileImage: tileImageData,
            backgroundImage: backgroundImageData,
            namePreview: selectedContent.name,
          };

    contentFoundHandler(position, newSlot);

    // Reset the replacingImages flag on selecting a content
    closeHandler();
  };

  React.useEffect(() => {
    const defaultHeading = `Select Content for Slot #${position + 1}`;
    if (movieStep === movieSteps.none) {
      setHeading(defaultHeading);
    }

    if (!assetSelector) {
      setHeading(`${defaultHeading.replace('Content', 'Images')}: ${assetTitle}`);
    }

    if (movieStep === movieSteps.series) {
      setHeading(`Step 1: ${defaultHeading}`);
    }

    if (!selectedRow && movieStep === movieSteps.movie) {
      setHeading(currentHeading => currentHeading.replace('Images', 'Episode').replace('Step 3', 'Step 2'));
    }

    if (selectedRow) {
      if (movieStep === movieSteps.movie) {
        const seriesName = selectedRow.seriesInfo?.name || selectedRow.name;
        setHeading(`Step 2: ${defaultHeading.replace('Content', 'Episode')}: ${seriesName}`);
      }

      if (movieStep === movieSteps.asset) {
        setHeading(`Step 3: ${defaultHeading.replace('Content', 'Images')}: ${selectedRow.seriesInfo?.name}`);
      }
    }
  }, [movieStep, selectedRow, position, assetSelector, assetTitle]);

  React.useEffect(() => {
    handleFilePickerPreview(tileImage, 'tile');
    handleFilePickerPreview(backgroundImage, 'background');
  }, [tileImage, backgroundImage]);

  // workaround to set a error message on the tile preview
  React.useEffect(() => {
    const parentDiv = document.getElementById('tile-file-picker')?.parentElement;

    setTimeout(() => {
      // Hide Upload icon
      const svg = parentDiv?.getElementsByTagName('svg')[0];

      if (!isFetchingImages && svg) {
        svg.style.display = !displayTilePreview ? 'none' : 'block';
      }
    });
  }, [isFetchingImages, displayTilePreview]);

  // workaround to set a error message on the background preview
  React.useEffect(() => {
    const parentDiv = document.getElementById('background-file-picker')?.parentElement;

    setTimeout(() => {
      // Hide Upload icon
      const svg = parentDiv?.getElementsByTagName('svg')[0];

      if (!isFetchingImages && svg) {
        svg.style.display = !displayBackgroundPreview ? 'none' : 'block';
      }
    });
  }, [isFetchingImages, displayBackgroundPreview]);

  React.useEffect(() => {
    setSelectedRow(undefined);
  }, [contentTypeOption]);

  return (
    <Dialog isOpen={canBeOpen} width='75%' height='75%' onClose={() => closeHandler()}>
      <Template label='header'>
        <Heading level='h3'>{heading}</Heading>
      </Template>
      <Template label='body'>
        <Cover gutterTop='small' scrolling={true}>
          <Template label='header'>
            {assetSelector && (
              <Cluster justify='center'>
                <Cluster buttonGroup={true}>
                  <Button active={contentTypeOption === 'series'} onClick={() => changeType('series')}>
                    Series
                  </Button>
                  <Button
                    active={contentTypeOption === 'movie'}
                    onClick={() => contentTypeOption !== 'movie' && changeType('movie')}
                  >
                    Movies
                  </Button>
                  <Button active={contentTypeOption === 'channel'} onClick={() => changeType('channel')}>
                    Channels
                  </Button>
                  <Button active={contentTypeOption === 'vodcategory'} onClick={() => changeType('vodcategory')}>
                    VOD
                  </Button>
                </Cluster>
              </Cluster>
            )}
          </Template>
          <Template label='cover'>
            {assetSelector && (
              <Cover scrolling={true} gutterBottom='medium'>
                <Template label='header'>
                  <Grid gap='medium' maxCols={3}>
                    <FormItem label='Filter'>
                      <TextInput
                        placeholder='Filter by Title'
                        iconLeft='search'
                        value={search}
                        onChange={val => searchTitle(val)}
                      />
                    </FormItem>
                    <FormItem label='Region' state='disabled'>
                      <Select options={[{label: region}]} value={{label: region}} />
                    </FormItem>
                    <FormItem label='Publication Date' state='disabled'>
                      <DateTime
                        placeholder='Publication Date'
                        id='publicationDate'
                        range={true}
                        time={true}
                        appendToBody={true}
                        value={{start: new Date(startDate || ''), end: new Date(endDate || '')}}
                      />
                    </FormItem>
                  </Grid>
                </Template>
                <Template label='cover'>
                  <Table
                    fixedHeader={true}
                    selectable={true}
                    loading={isFetching}
                    emptyMsg='No results found'
                    onSelect={row => setSelectedRow(row as IContent)}
                    selected={selectedRow}
                    sortDir={sortDir}
                    sortCol={sortCol}
                    cols={[
                      {
                        label: getColumnName(contentType),
                        sortable: true,
                        transform: row => (
                          <Popover trigger='mouseenter' appendToBody={true}>
                            <Template label='trigger'>
                              <Cluster wrap={false} space='small' align='center'>
                                <ImageWrapper
                                  backgroundColor='black'
                                  height='2.5rem'
                                  width='4.4375rem'
                                  src={rewriteImageUrl(getImageTile(row as IContent))}
                                  alt='nature image'
                                  iconSize='small'
                                />
                                <span>{row.name}</span>
                              </Cluster>
                            </Template>
                            <Template label='popover'>{row.displayName || row.name}</Template>
                          </Popover>
                        ),
                      },
                      {
                        label: 'Display Name',
                        sortable: true,
                        hidden: contentType !== 'vodcategory',
                        colWidth: '200px',
                        transform: row => row.displayName!.toLocaleUpperCase(),
                      },
                      {
                        label: 'Number',
                        sortable: true,
                        hidden: contentType !== 'channel',
                        colWidth: '200px',
                        transform: row => row.number,
                      },
                      {
                        label: 'Main Categories',
                        sortable: true,
                        hidden: hideChannelVodTab,
                        colWidth: '200px',
                        transform: row => row.mainCategories,
                      },
                      {
                        label: 'Legacy Category',
                        sortable: true,
                        hidden: contentType !== 'channel',
                        colWidth: '200px',
                        transform: row => row.legacyCategory,
                      },
                      {
                        label: 'Office Only',
                        sortable: true,
                        hidden: hideChannelVodTab,
                        colWidth: '50px',
                        transform: row =>
                          row.plutoOfficeOnly ? (
                            <Stack space='medium'>
                              <Center maxWidth='50px' intrinsic={false} textCenter={true}>
                                <Icon icon='check' color='success' size='xlarge' />
                              </Center>
                            </Stack>
                          ) : (
                            ''
                          ),
                      },
                      {
                        label: 'Kids Mode',
                        sortable: true,
                        hidden: hideChannelVodTab,
                        colWidth: '50px',
                        transform: row =>
                          row.kidsMode ? (
                            <Stack space='medium'>
                              <Center maxWidth='50px' intrinsic={false} textCenter={true}>
                                <Icon icon='check' color='success' size='xlarge' />
                              </Center>
                            </Stack>
                          ) : (
                            ''
                          ),
                      },
                      {
                        label: 'Release Year',
                        sortable: true,
                        hidden: contentType !== 'movie',
                        colWidth: '200px',
                        transform: row => (isFetching ? '' : new Date(row.originalReleaseDate!).getFullYear()),
                      },
                      {
                        label: 'Author',
                        sortable: true,
                        colWidth: '100px',
                        hidden: contentType !== 'series',
                        transform: row => row.author,
                      },
                      {
                        label: 'Rating',
                        sortable: true,
                        colWidth: '100px',
                        hidden: hideSeriesMovieTab,
                        transform: row => row?.rating?.toLocaleUpperCase(),
                      },
                      {
                        label: 'Duration',
                        sortable: true,
                        colWidth: '100px',
                        hidden: contentType !== 'movie',
                        transform: row => convertDuration(row.duration),
                      },
                      {
                        label: 'Created At',
                        sortable: true,
                        colWidth: '100px',
                        hidden: hideSeriesMovieTab,
                        transform: row => new Date(row.createdAt).toLocaleDateString(),
                      },
                      {
                        label: '# of E',
                        sortable: true,
                        colWidth: '100px',
                        hidden: contentType !== 'series',
                        transform: row => row.episodeCount,
                      },
                      {
                        label: '# of S',
                        sortable: true,
                        colWidth: '100px',
                        hidden: contentType !== 'series',
                        transform: row => row.seasonCount,
                      },
                      {
                        label: 'Start Availability',
                        sortable: true,
                        hidden: hideSeriesMovieTab,
                        colWidth: '12.5rem',
                        transform: row => new Date(row.availabilityStartDate).toLocaleDateString(),
                      },
                      {
                        label: 'End Availability',
                        sortable: true,
                        hidden: hideSeriesMovieTab,
                        colWidth: '12.5rem',
                        transform: row => new Date(row.availabilityEndDate).toLocaleDateString(),
                      },
                    ]}
                    rows={content?.data}
                    onSort={colName => changeSort(colName, sortDir)}
                  ></Table>
                </Template>
                <Template label='footer'>
                  <Cluster justify='space-between'>
                    <div></div>
                    <Pagination
                      perPage={rowsPerPage}
                      currentPage={page}
                      total={content?.metadata.totalCount || 0}
                      onPageChange={page => setPage(page)}
                      onPerPageChange={perPage => {
                        setRowsPerPage(perPage as 25 | 50 | 75 | 100);
                        setPage(0);
                      }}
                    />
                  </Cluster>
                </Template>
              </Cover>
            )}
            {!assetSelector && isFetchingImages && <Spinner center={true} size='xxxlarge' />}
            {!assetSelector && isErrorImages && (
              <Notification>Error getting image details for this asset. Please try again.</Notification>
            )}
            {!assetSelector && !isFetchingImages && !isErrorImages && (
              <Grid gap='large' minimum='22rem' maxCols={2}>
                <Stack space='xsmall'>
                  <Cluster space='medium'>
                    <FormItem
                      label='Tile'
                      {...(tileValue.label.indexOf('Featured') > -1 &&
                        featuredImageError && {
                          helpText: 'Could not find featured artwork',
                          state: 'error',
                        })}
                    >
                      <Select
                        width='12rem'
                        value={tileValue}
                        options={tileOptions}
                        onChange={value => setTileValue(value)}
                      />
                    </FormItem>
                    {tileValue.label === 'Episode 16x9' &&
                      ['series', 'movie'].includes(selectedContent?.type as string) && (
                        <FormItem
                          label='&nbsp;'
                          {...(episodePickerError && {
                            state: 'disabled',
                            helpText: 'No published episodes available',
                            helpTextColor: 'error',
                          })}
                        >
                          <Select
                            value={tileEpisodeValue}
                            width='20.5rem'
                            maxHeight='25rem'
                            searchable={true}
                            options={episodeOptions}
                            onChange={value => setTileEpisodeValue(value)}
                            {...(episodePickerError && {
                              placeholder: 'No published episodes available',
                            })}
                          />
                        </FormItem>
                      )}
                    {tileValue.label === 'Episode 16x9 Poster' &&
                      ['series', 'movie'].includes(selectedContent?.type as string) && (
                        <FormItem
                          label='&nbsp;'
                          {...(episodePickerError && {
                            state: 'disabled',
                            helpText: 'No published episodes available',
                            helpTextColor: 'error',
                          })}
                        >
                          <Select
                            value={tilePosterEpisodeValue}
                            width='20.5rem'
                            maxHeight='25rem'
                            searchable={true}
                            options={posterEpisodeOptions}
                            onChange={value => setTilePosterEpisodeValue(value)}
                            {...(episodePickerError && {
                              placeholder: 'No published episodes available',
                            })}
                          />
                        </FormItem>
                      )}
                  </Cluster>
                  {tileImage || tileValue.label === 'Upload File' ? (
                    <FilePicker
                      id='tile-file-picker'
                      accept='image/*'
                      type='advanced'
                      height='400px'
                      {...(!displayTilePreview
                        ? {
                            previewImage: undefined,
                            msgOne: 'Image not available.',
                            msgTwo: undefined,
                            headerText: '',
                          }
                        : {
                            state: tileValue.label !== 'Upload File' ? 'disabled' : '',
                            previewImage: tileImage,
                            msgOne: 'Drag and drop, or click here to browse and upload an image.',
                            msgTwo: '16:9 images and JPG format only.',
                            headerText: 'Upload Your File',
                          })}
                      clearable={tileValue.label !== 'Upload File' ? false : true}
                      verifyUpload={validateImage}
                      onChange={files => {
                        if (files.length) {
                          setCustomTileImage(files[0]);
                        }
                      }}
                    />
                  ) : (
                    <Box height='25rem' width='100%' background='onyx' />
                  )}
                </Stack>
                <Stack space='xsmall'>
                  <Cluster space='medium' align='center'>
                    <FormItem
                      label='Background'
                      {...(backgroundValue.label.indexOf('Featured') > -1 &&
                        featuredImageError && {
                          helpText: 'Could not find featured artwork',
                          state: 'error',
                        })}
                    >
                      <Select
                        width='12rem'
                        value={backgroundValue}
                        options={backgroundOptions}
                        onChange={value => setBackgroundValue(value)}
                      />
                    </FormItem>
                    {backgroundValue.label === 'Episode 16x9' &&
                      ['series', 'movie'].includes(selectedContent?.type as string) && (
                        <FormItem
                          label='&nbsp;'
                          {...(episodePickerError && {
                            state: 'disabled',
                            helpText: 'No published episodes available',
                            helpTextColor: 'error',
                          })}
                        >
                          <Select
                            appendToBody={true}
                            value={backgroundEpisodeValue}
                            width='20.5rem'
                            maxHeight='25rem'
                            searchable={true}
                            options={episodeOptions}
                            onChange={value => setBackgroundEpisodeValue(value)}
                            {...(episodePickerError && {
                              placeholder: 'No published episodes available',
                            })}
                          />
                        </FormItem>
                      )}
                    {backgroundValue.label === 'Episode 16x9 Poster' &&
                      ['series', 'movie'].includes(selectedContent?.type as string) && (
                        <FormItem
                          label='&nbsp;'
                          {...(episodePickerError && {
                            state: 'disabled',
                            helpText: 'No published episodes available',
                            helpTextColor: 'error',
                          })}
                        >
                          <Select
                            appendToBody={true}
                            value={backgroundPosterEpisodeValue}
                            width='20.5rem'
                            maxHeight='25rem'
                            searchable={true}
                            options={posterEpisodeOptions}
                            onChange={value => setBackgroundPosterEpisodeValue(value)}
                            {...(episodePickerError && {
                              placeholder: 'No published episodes available',
                            })}
                          />
                        </FormItem>
                      )}
                  </Cluster>
                  {backgroundImage || backgroundValue.label === 'Upload File' ? (
                    <FilePicker
                      id='background-file-picker'
                      accept='image/*'
                      type='advanced'
                      height='25rem'
                      state={backgroundValue.label !== 'Upload File' ? 'disabled' : ''}
                      {...(displayBackgroundPreview || backgroundValue.label === 'Upload File'
                        ? {
                            previewImage: backgroundImage,
                            msgOne: 'Drag and drop, or click here to browse and upload an image.',
                            msgTwo: '16:9 images and JPG format only.',
                            headerText: 'Upload Your File',
                          }
                        : {
                            previewImage: undefined,
                            msgOne: 'Image not available.',
                            msgTwo: undefined,
                            headerText: '',
                          })}
                      clearable={backgroundValue.label !== 'Upload File' ? false : true}
                      verifyUpload={validateImage}
                      onChange={files => {
                        if (files.length) {
                          setCustomBackgroundImage(files[0]);
                        }
                      }}
                    />
                  ) : (
                    <Box height='25rem' width='100%' background='onyx' />
                  )}
                </Stack>
              </Grid>
            )}
          </Template>
        </Cover>
      </Template>
      <Template label='footer'>
        <Cluster justify='space-between'>
          {(assetSelector || replacingImages) && movieStep !== movieSteps.movie && <div id='dasdsasa'></div>}
          {((!assetSelector && !replacingImages) || movieStep === movieSteps.movie) && (
            <Button
              type='secondary'
              icon='chevronleft'
              state={!selectedRow && movieStep !== movieSteps.movie ? 'disabled' : ''}
              onClick={() => {
                setSelectedRow(undefined);
                if (movieStep !== movieSteps.none) {
                  changeMovieStep('backward');
                } else {
                  toggleAssetSelector();
                }
              }}
            >
              Back
            </Button>
          )}
          <Cluster space='small'>
            <Button ghost={true} onClick={() => closeHandler()}>
              Cancel
            </Button>
            {assetSelector && (
              <Button
                type='primary'
                state={!selectedRow ? 'disabled' : ''}
                onClick={() => {
                  if (movieStep !== movieSteps.none) {
                    changeMovieStep();
                  } else {
                    toggleAssetSelector();
                  }
                }}
              >
                Next
              </Button>
            )}
            {!assetSelector && (
              <Button
                type='primary'
                state={
                  !selectedContent || !(tileImage || customTileImage) || !(backgroundImage || customBackgroundImage)
                    ? 'disabled'
                    : ''
                }
                onClick={() => selectContent()}
              >
                Select
              </Button>
            )}
          </Cluster>
        </Cluster>
      </Template>
    </Dialog>
  );
};
