import {IValidatorField} from '@pluto-tv/assemble';
import {IRole} from 'models/roles';

export const roleDetailsValidator: IValidatorField<IRole>[] = [
  {
    name: 'name',
    label: 'Role Type Name',
    required: true,
    validators: [
      (name: string): string | undefined => {
        if (!name || !name.trim().length) {
          return 'Role Name is required';
        } else if (name && name.trim().length > 100) {
          return 'Role Name must be less than 100 characters';
        }
      },
    ],
  },
  {
    name: 'description',
    label: 'Description',
  },
  {
    name: 'permissions',
  },
];
