import {ITimeline} from 'features/channelTimelines/channelTimelinesApi';
import {DateTime as luxon} from 'luxon';
import {ICalendarDay} from '@pluto-tv/assemble';

export interface IWeek {
  startDate: Date;
  endDate: Date;
}

export const mondayThisWeek = (date: Date): Date => {
  const d = new Date(date);
  const day = d.getDay();
  const diff = d.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(d.setDate(diff));
};

export const offsetDate = (base: Date, count: number): Date => {
  const date = new Date(base);
  date.setDate(base.getDate() + count);
  return date;
};

export const currentWeek = (today: Date): Date[] => {
  const monday = mondayThisWeek(today);
  const response = [monday];

  for (let index = 1; index < 7; index++) {
    response.push(offsetDate(monday, index));
  }
  return response;
};

export const startEndOfWeek = (today: Date): IWeek => {
  const monday = mondayThisWeek(today);
  return {
    startDate: monday,
    endDate: offsetDate(monday, 6),
  };
};

export const nextMonday = (day: Date): Date => {
  return mondayThisWeek(offsetDate(day, 7));
};

export const prevSunday = (day: Date): Date => {
  return offsetDate(mondayThisWeek(day), -1);
};

export const nextDay = (day: Date): Date => {
  return offsetDate(day, 1);
};

export const prevDay = (day: Date): Date => {
  return offsetDate(day, -1);
};

export const getStringMonth = (month: number): string => {
  switch (month + 1) {
    case 1:
      return 'Jan';
    case 2:
      return 'Feb';
    case 3:
      return 'Mar';
    case 4:
      return 'Apr';
    case 5:
      return 'May';
    case 6:
      return 'Jun';
    case 7:
      return 'Jul';
    case 8:
      return 'Aug';
    case 9:
      return 'Sep';
    case 10:
      return 'Oct';
    case 11:
      return 'Nov';
    case 12:
      return 'Dec';

    default:
      return '';
  }
};

export const findMaxManualOverride = (
  events: ITimeline[] | null,
): {maxManualOverride: Date | null; todayPlus30Min: Date} => {
  let maxManualOverride: Date | null = null;
  const today = new Date();
  const todayPlus30Min = new Date(today.setMinutes(today.getMinutes() + 30));
  events?.forEach(event => {
    if (event.manualOverrideMarker && event.start >= todayPlus30Min) {
      maxManualOverride = event.stop;
    }
  });

  return {maxManualOverride, todayPlus30Min};
};

export const getLuxonObject = (date: Date | string | number | undefined): luxon | undefined => {
  if (typeof date === 'number') {
    return luxon.fromMillis(date);
  } else if (typeof date === 'string') {
    return luxon.fromISO(date);
  } else if (date instanceof Date) {
    return luxon.fromJSDate(date);
  } else {
    return undefined;
  }
};

export const formatScreeningTime = (
  startDate: Date | string | number | undefined,
  allotment: number,
  tz = 'America/Los_Angeles',
): string => {
  const start = getLuxonObject(startDate);
  if (!start) return 'N/A';

  start.setZone(tz);
  const end = start.plus({seconds: allotment});
  return `${start.toFormat('LLL dd, yyyy / h:mm a')} - ${end.toFormat('h:mm a')}`;
};

export const formatDateWithTimezone = (
  date: Date | string,
  formatString = 'yyyy-LL-dd',
  tz = 'America/Los_Angeles',
): string => {
  const dateTime = typeof date === 'string' ? luxon.fromISO(date) : luxon.fromJSDate(date);
  dateTime.setZone(tz);
  return dateTime.toFormat(formatString);
};

export const getRepeatDays = (
  timeframe: string,
  sunRepeat: boolean,
  monRepeat: boolean,
  tueRepeat: boolean,
  wedRepeat: boolean,
  thuRepeat: boolean,
  friRepeat: boolean,
  satRepeat: boolean,
): string[] => {
  // only if timeframe is weeks repeatDays is used
  if (timeframe !== 'week') {
    return [];
  }
  // Check for the days that are selected
  const repeatDays: string[] = [];
  if (sunRepeat) {
    repeatDays.push('Sunday');
  }
  if (monRepeat) {
    repeatDays.push('Monday');
  }
  if (tueRepeat) {
    repeatDays.push('Tuesday');
  }
  if (wedRepeat) {
    repeatDays.push('Wednesday');
  }
  if (thuRepeat) {
    repeatDays.push('Thursday');
  }
  if (friRepeat) {
    repeatDays.push('Friday');
  }
  if (satRepeat) {
    repeatDays.push('Saturday');
  }
  return repeatDays;
};

export const miliSecsToStr = (milisecs: number): string => {
  const time = Math.round(milisecs / 1000);

  const hours = Math.floor(time / 3600) || 0;
  const minutes = Math.floor((time % 3600) / 60) || 0;
  const seconds = time - (hours * 3600 + minutes * 60);

  if (minutes === 0 && hours === 0 && seconds > 0) {
    return `${seconds} second(s)`;
  } else if (hours === 0 && minutes > 0) {
    return `${minutes} minute(s)`;
  } else {
    return `${hours} hour(s)${minutes > 0 ? ` and ${minutes} minute(s)` : ''}`;
  }
};

export const areDatesInDifferentDays = (date1: Date, date2: Date): boolean => {
  return (
    date1.getFullYear() !== date2.getFullYear() ||
    date1.getMonth() !== date2.getMonth() ||
    date1.getDate() !== date2.getDate()
  );
};

export const disablePastDates = (date: ICalendarDay, startDate?: Date): ICalendarDay => {
  let isDisabled = false;
  const now = new Date();
  now.setHours(0, 0, 0, 0);

  if (startDate && date?.date) {
    let disableFrom = startDate;
    if (now > startDate) {
      disableFrom = now;
    }
    if (date.date < disableFrom) {
      isDisabled = true;
    }
  }
  return {...date, isDisabled};
};
