import * as React from 'react';

import {IFeaturedGroupQuery, useLazySearchQuery} from 'features/featuredGroups/featuredGroupsApi';
import {IFeaturedGroup} from 'models/featuredGroup';
import {IListPayload} from 'models/generic';
import {FetchBaseQueryError} from '@reduxjs/toolkit/query';
import {SerializedError} from '@reduxjs/toolkit';
import {IStateMsgList} from 'views/programming/featuredGroups/utils';

interface ISearchQueryProviderContext {
  data: IListPayload<IFeaturedGroup> | undefined;
  currentData: IListPayload<IFeaturedGroup> | undefined;
  isError: boolean;
  isFetching: boolean;
  isLoading: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  fetch: (params: IFeaturedGroupQuery) => void;
}

export interface IFeaturedGroupWithState extends Omit<Partial<IFeaturedGroup>, 'state'> {
  state: string;
  stateMsgList: IStateMsgList[];
}

const FeaturedGroupsSearchProviderContext = React.createContext<ISearchQueryProviderContext | null>(null);

export const FeaturedGroupsSearchQueryProvider = ({children}: {children: React.ReactNode}): JSX.Element => {
  const [fetch, {data, isError, isFetching, isLoading, error, currentData}] = useLazySearchQuery();

  const value = React.useMemo(
    () => ({
      data,
      isError: isError && (error as {status: number}).status !== 404,
      isFetching,
      isLoading,
      currentData,
      error,
      fetch,
    }),
    [data, isError, error, isFetching, isLoading, currentData, fetch],
  );

  return (
    <FeaturedGroupsSearchProviderContext.Provider value={value}>
      {children}
    </FeaturedGroupsSearchProviderContext.Provider>
  );
};

export const useFeaturedGroupsSearchQuery = (): ISearchQueryProviderContext => {
  const context = React.useContext(FeaturedGroupsSearchProviderContext);
  if (!context) {
    throw new Error('useSearchQuery must be used within a SearchQueryProvider');
  }
  return context;
};
