import {IAvailbilityWindow} from 'components/availability-window-form';

import {TLanguageCode} from './languages';
import {INextTimeline} from './episodes';

export const SOURCE_TYPES = [
  'DASH',
  'FLV',
  'HLS',
  'liveAdsparx',
  'liveCBS',
  'liveCBSFW',
  'liveCBSi',
  'liveDash',
  'liveDashDVB',
  'liveFubo',
  'liveGDaiDash',
  'liveGDaiHLS',
  'liveHLS',
  'liveStitch',
  'liveUrl',
  'liveUrlDash',
  'liveUrlHLS',
  'MP4',
  'RTMP',
  'WebM',
] as const;

export const ADSPARX_AD_POLICIES = ['CuesInPlaylist', 'AutoDetect'] as const;

export type TSourceType = typeof SOURCE_TYPES[number];
export type TAdSparxAdPolicy = typeof ADSPARX_AD_POLICIES[number];

export interface ICCSubtitle {
  addedToVideo?: boolean;
  // DAL returns `id`, ES returns `_id`
  id: string;
  _id?: string;
  uri: string;
  language: TLanguageCode;
  updatedAt: string | Date;
}

export interface ITimedText extends ICCSubtitle {
  type: 'cc' | 'subtitle';
}

export interface IDiscreteAudio {
  audioType: string;
  uri: string;
  language: TLanguageCode;
  updatedAt: string | Date;
  id: string;
}

export interface ITranscodeOutput {
  _id: string;
  url: string;
  type: string;
  dimensions?: {
    width: number;
    height: number;
  };
}

export interface ISource {
  id?: string;
  _id?: string;
  file: string;
  type: TSourceType;
  encryption?: string;
  adSparxAdPolicy?: TAdSparxAdPolicy;
}

export interface ISubClip {
  id: string;
  duration: number;
  sources: ISource[];
  startAt: number;
  inPoint?: number;
  url: string;
}

export interface IFragment {
  breakpoints?: number[];
  subClips: ISubClip[];
  _id: string;
}

export interface IClipTranscode {
  _id: string;
  output: ITranscodeOutput[];
  finishedAt: string | Date;
  externalID: string;
  transcoderName: string;
  transcodeMode: string;
  libVersion: string;
  transcoderVersion: string;
  encryptionParams?: {
    schema: string;
    rotationInterval: number;
    keyFileName: string;
  };
}

export interface IAvailbilityWindows {
  AVOD?: IAvailbilityWindow[];
  linear?: IAvailbilityWindow[];
}

export interface IClip {
  _id: string;
  id?: string;
  plutoAvailsID?: string;
  contentUUID?: string;
  cc?: ICCSubtitle[];
  subtitles?: ICCSubtitle[];
  timedText?: ITimedText[];
  discreteAudio?: IDiscreteAudio[];
  name: string;
  summary: string;
  description?: string;
  rating: string;
  category?: string;
  partner?: string;
  language?: TLanguageCode;
  copyright?: string;
  directors?: string[];
  actors: string[];
  writers: string[];
  producers: string[];
  originalReleaseDate: string | Date;
  author?: {
    name: string;
    [key: string]: any;
  };
  programmingType: string;
  code: string;
  framerate: number;
  outPoint: number;
  liveBroadcast: boolean;
  contentStatus: 'origin' | 'downloading' | 'uploaded' | 'ready' | 'processing' | 'failed';
  duration: number;
  createdAt: string;
  inPoint: number;
  isUsed?: boolean;
  provider: string;
  breakpoints: number[];
  promotional: boolean;
  thumbnail: string;
  published: boolean;
  url: string;
  activeRegion: string;
  uploaded?: string;
  updatedAt?: string | Date;
  regionFilter?: string[];
  tags?: string[];
  captionsRequired?: boolean;
  channelAssociation?: string;
  availabilityWindows?: IAvailbilityWindows;
  distributeAs?: {
    linear: boolean;
    AVOD: boolean;
    SVOD?: boolean;
    TVOD?: boolean;
  };
  color?: 'c' | 'b/w';
  origin?: {
    url: string;
    type?: string;
    size?: number;
    inPoint?: number;
    outPoint?: number;
  };
  markups?: {
    _id: string;
    id?: string;
    type: string;
    start: number;
    end: number;
    startTimeCode: string;
    endTimeCode: string;
  }[];
  sources?: ISource[];
  fragments?: IFragment[];
  transcodes?: IClipTranscode[];
  uri?: string;
  productionCompany?: string[];
  productionCountries?: string[];
  productionYear?: number;
}

export interface IClipEpisode {
  id: string;
  name: string;
  series: {
    id: string;
    name: string;
  };
  adPodsDuration?: number;
  number: number;
  season: number;
  duration: number;
  published: boolean;
  allotment?: number;
  status?: string;
  lastAired?: string | Date;
  nextTimeline?: INextTimeline;
  activeRegion?: string;
  // DELETE THIS WHEN MOCKS ARE GONE
  [key: string]: any;
}
